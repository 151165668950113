export const environment = {
  production: true,

  // firebaseConfig: {
  //   apiKey: "AIzaSyBdlgxHUXrzn0mEKyJ3--j5V__uC5Ttvrs",
  //   authDomain: "t-port-prod.firebaseapp.com",
  //   databaseURL: "https://t-port-prod.firebaseio.com",
  //   projectId: "t-port-prod",
  //   storageBucket: "t-port-prod",
  //   messagingSenderId: "116134082704",
  //   appId: "1:116134082704:web:be6c0d224c6e3521910015",
  //   measurementId: "G-0ES9L56XF0"
  // },
  // ALGOLIA_APP_ID:'HM8F1G1ZS1',
  // ALGOLIA_SEARCH_KEY:'f4be4ed88525d0b9efe7986263f1d44c',
  // ALGOLIA_INDEX_ITEM: 'ITEM',
  // ALGOLIA_INDEX_EVENT_SEARCH: 'EVENT_SEARCH',
  // BASE_URL: 'https://t-port-mng.tmls.jp',
  // BASE_CUST_URL: 'https://t-port.tmls.jp',
  // GIFT_CENTER: 'giftcenter@timeless-gift.co.jp',
  // SYTE_TYPE: '',
  // NO_IMAGE_MAIN: 'https://firebasestorage.googleapis.com/v0/b/t-port/o/items%2Fdummy_500.jpg?alt=media',
  // NO_IMAGE_THUMB_L: 'https://firebasestorage.googleapis.com/v0/b/t-port/o/items%2Fthumbs%2Fdummy_165.jpg?alt=media',
  // NO_IMAGE_THUMB_S: 'https://firebasestorage.googleapis.com/v0/b/t-port/o/items%2Fthumbs%2Fdummy_70.jpg?alt=media',
  // ASK_FOR_CONSENT: false


  // firebaseConfig: {
  //   apiKey: "AIzaSyBpU1cph9Wf4WILbkpt-Lgmdj8D3MCG8II",
  //   authDomain: "test-project-2297e.firebaseapp.com",
  //   databaseURL: "https://test-project-2297e.firebaseio.com",
  //   projectId: "test-project-2297e",
  //   storageBucket: "t-port-stg",
  //   messagingSenderId: "739905566405",
  //   appId: "1:739905566405:web:389f55508e322cf9bde2ac",
  //   measurementId: "G-2197VM0HYR"
  // },
  // ALGOLIA_APP_ID:'HM8F1G1ZS1',
  // ALGOLIA_SEARCH_KEY:'f4be4ed88525d0b9efe7986263f1d44c',
  // ALGOLIA_INDEX_ITEM: 'ITEM_STG',
  // ALGOLIA_INDEX_EVENT_SEARCH: 'EVENT_SEARCH_STG',
  // BASE_URL: 'https://stg.t-port-mng.tmls.jp',
  // BASE_CUST_URL: 'https://stg.t-port.tmls.jp',
  // GIFT_CENTER: 't-port-info@timeless-gift.co.jp',
  // SYTE_TYPE: 'STG',
  // NO_IMAGE_MAIN: 'https://firebasestorage.googleapis.com/v0/b/t-port/o/items%2Fdummy_500.jpg?alt=media',
  // NO_IMAGE_THUMB_L: 'https://firebasestorage.googleapis.com/v0/b/t-port/o/items%2Fthumbs%2Fdummy_165.jpg?alt=media',
  // NO_IMAGE_THUMB_S: 'https://firebasestorage.googleapis.com/v0/b/t-port/o/items%2Fthumbs%2Fdummy_70.jpg?alt=media',
  // ASK_FOR_CONSENT: false


  // firebaseConfig: {
  //   apiKey: "AIzaSyAkoQ9etelYuG8pCLc4rIxVzb5aL1NoGlg",
  //   authDomain: "t-port-dev3.firebaseapp.com",
  //   databaseURL: "https://t-port-dev3.firebaseio.com",
  //   projectId: "t-port-dev3",
  //   storageBucket: "t-port-dev3",
  //   messagingSenderId: "349325289812",
  //   appId: "1:349325289812:web:92e4d1ef61dedf2519c0c1",
  //   measurementId: "G-RQFE2J1RFP"
  // },
  // ALGOLIA_APP_ID:'HM8F1G1ZS1',
  // ALGOLIA_SEARCH_KEY:'f4be4ed88525d0b9efe7986263f1d44c',
  // ALGOLIA_INDEX_ITEM: 'ITEM_DEV3',
  // ALGOLIA_INDEX_EVENT_SEARCH: 'EVENT_SEARCH_DEV3',
  // BASE_URL: 'https://t-port-mng-dev3.firebaseapp.com',
  // BASE_CUST_URL: 'https://t-port-dev3.firebaseapp.com',
  // GIFT_CENTER: 'maki-takahashi@timeless-gift.co.jp',
  // SYTE_TYPE: 'DEV3',
  // NO_IMAGE_MAIN: 'https://firebasestorage.googleapis.com/v0/b/t-port/o/items%2Fdummy_500.jpg?alt=media',
  // NO_IMAGE_THUMB_L: 'https://firebasestorage.googleapis.com/v0/b/t-port/o/items%2Fthumbs%2Fdummy_165.jpg?alt=media',
  // NO_IMAGE_THUMB_S: 'https://firebasestorage.googleapis.com/v0/b/t-port/o/items%2Fthumbs%2Fdummy_70.jpg?alt=media',
  // ASK_FOR_CONSENT: false


  firebaseConfig: {
    apiKey: "AIzaSyDIT92vbTE6KYC6A6eXet2TMgaS1DqGUtw",
    authDomain: "t-port-demo.firebaseapp.com",
    databaseURL: "https://t-port-demo.firebaseio.com",
    projectId: "t-port-demo",
    storageBucket: "t-port-demo",
    messagingSenderId: "261013603068",
    appId: "1:261013603068:web:73a5a46bf5ea0a16f88d79",
    measurementId: "G-XL7C2LP4SG"
  },
  ALGOLIA_APP_ID:'HM8F1G1ZS1',
  ALGOLIA_SEARCH_KEY:'f4be4ed88525d0b9efe7986263f1d44c',
  ALGOLIA_INDEX_ITEM: 'ITEM_DEMO',
  ALGOLIA_INDEX_EVENT_SEARCH: 'EVENT_SEARCH_DEMO',
  BASE_URL: 'https://demo.t-port-mng.tmls.jp',
  BASE_CUST_URL: 'https://demo.t-port.tmls.jp',
  GIFT_CENTER: 't-port-info@timeless-gift.co.jp',
  SYTE_TYPE: 'DEMO',
  NO_IMAGE_MAIN: 'https://firebasestorage.googleapis.com/v0/b/t-port/o/items%2Fdummy_500.jpg?alt=media',
  NO_IMAGE_THUMB_L: 'https://firebasestorage.googleapis.com/v0/b/t-port/o/items%2Fthumbs%2Fdummy_165.jpg?alt=media',
  NO_IMAGE_THUMB_S: 'https://firebasestorage.googleapis.com/v0/b/t-port/o/items%2Fthumbs%2Fdummy_70.jpg?alt=media',
  ASK_FOR_CONSENT: false

};
