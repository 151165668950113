<div class="content-block content-block-max">
  <div class="video">
    <video controls id="video_ctl" #videoPlayer 
      poster="https://firebasestorage.googleapis.com/v0/b/t-port-demo/o/movie_thumb.jpg?alt=media&token=4cfeffe2-e920-4670-b612-33a565c53305">
        <source src="https://firebasestorage.googleapis.com/v0/b/t-port-demo/o/t-port.mp4?alt=media&token=52d44372-6dec-4d66-83cf-702e885f235d" 
          type="video/mp4" 
        />
        Browser not supported
    </video>
  </div>
</div>