import { Component, OnInit, EventEmitter, Output, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { DateAdapter } from '@angular/material/core';
import { JpDateAdapter } from '../../util/adapter/jp-date-adapter';

import { SessionService } from '../../service/session.service';
import { PlaceService } from '../../service/place.service';

import { SearchCondOrder } from '../../models/search_cond_order';
import { Session } from '../../models/session';
import { Place } from '../../models/place';
import { InputSelect } from '../../models/input_select';
import * as constant from '../../models/constant';

import moment from 'moment';

@Component({
  selector: 'app-search-order',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    OverlayModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: JpDateAdapter }
  ],
  templateUrl: './search-order.component.html',
  styleUrls: ['./search-order.component.scss']
})
export class SearchOrderComponent implements OnInit {
  @Output() search_order_event = new EventEmitter<SearchCondOrder>();
  public is_cross_search: boolean = false;
  private subscription: Subscription = new Subscription();
  public session: Session = new Session();
  public places: InputSelect[] = [];
  public select_place: string = '';
  public members: InputSelect[] = [];
  public select_member: string = '';
  public cond: SearchCondOrder = new SearchCondOrder();
  public oldest_event_date: Date = moment(new Date().setHours(0,0,0,0)).add(-1, 'y').toDate();
  private isBrowser: boolean;

  constructor(
    private sv_session: SessionService,
    private sv_place: PlaceService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.cond.event.exclude_ck = false; // 検索条件の「完了した挙式の注文を除く」のチェックはデフォルトでは外しておく。
  }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.pipe(take(1)).subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.session = session;
          this.is_cross_search = session.data.cross_search_flg;

          if (session.data.current_company && session.data.current_place) { // 所属企業・会場あり
            this.getPlaces();
            if (!this.is_cross_search) {
              this.select_place = session.data.current_place;
              this.getMembers();
            }
          }
        }
      })
    );
    if (this.isBrowser) {
      $('.search-block').addClass('order');
    }
  }

  private getPlaces(): void {
    if (!this.session.data) return;
    this.members = [];
    this.subscription.add(
      this.sv_place.getPlaces(this.session.data.current_company).pipe(take(1)).subscribe(places => {
        if (this.is_cross_search) {
          places.forEach(place => {
            this.places.push({
              key: place.objectID,
              value: place.name,
              grep_colum: null,
              select: false,
              disabled: false
            });
          });
        } else {
          places.forEach(place => {
            if (place.objectID == this.session.data?.current_place) {
              this.places.push({
                key: place.objectID,
                value: place.name,
                grep_colum: null,
                select: false,
                disabled: false
              });
              // return true;
            }
          });
        }
        this.places.unshift({key: '', value: '', grep_colum: null, select: false, disabled: false});
      })
    );
  }

  private getMembers(): void {
    if (!this.session.data) return;
    if (this.session.data.current_place) {
      this.subscription.add(
        this.sv_place.getMembers(this.select_place).subscribe(members => {
          this.members = [];
          members.forEach(member => {
            this.members.push({
              key: member.objectID,
              value: member.last_name + member.first_name,
              grep_colum: null,
              select: false,
              disabled: false
            });
          });
          this.members.unshift({key: '', value: '', grep_colum: null, select: false, disabled: false});
        })
      );
    }
    else {
      this.members = [];
      let mdata = {
        key: this.session.data.uid,
        value: this.session.data.last_name + this.session.data.first_name,
        grep_colum: '',
        select: false,
        disabled: false
      };
      this.members.push(mdata);
    }
  }

  public onChangePlace(): void {
    if (!this.session.data) return;
    if (this.session.data.current_place && !this.select_place) {
      this.members = [];
    } else {
      this.getMembers();
    }
  }

  public setYourself(): void {
    if (!this.session.data) return;
    if (this.session.data.current_place) {
      this.cond.event.place_id = this.session.data.current_place;
      this.select_place = this.session.data.current_place;
      this.onChangePlace();
    }
    setTimeout(() => {
      if (this.session.data) {
        this.cond.event.staff = this.session.data.uid;
        this.select_member = this.session.data.uid;
      }
    }, 100);
  }

  public search(event: any): void {
    $('.search-trigger').click(); // 検索エリア閉じる
    this.search_order_event.emit(this.cond);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.isBrowser) {
      $('.search-block').removeClass('order');
    }
  }

}
