import { Injectable, Inject } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import * as firestore from 'firebase/firestore';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Observable, of, Subject, Subscription} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SessionService } from '../service/session.service';
import { ItemService } from '../service/item.service';

import { Session } from '../models/session';
import { Giftbox } from '../models/giftbox';
import { GiftboxItem } from '../models/giftbox_item';
import { GiftboxInfo } from '../models/giftbox_info';
import { Event } from '../models/event';
import { Guest } from '../models/guest';
import { ProcessResult } from '../models/process_result';
import * as constant from '../models/constant';

import { environment } from '../../environments/environment';
import algoliasearch from 'algoliasearch';

declare const debugLog: any;

const ALGOLIA_ID = environment.ALGOLIA_APP_ID;
const ALGOLIA_SEARCH_KEY = environment.ALGOLIA_SEARCH_KEY;
const ALGOLIA_INDEX_ITEM = environment.ALGOLIA_INDEX_ITEM;

@Injectable({
  providedIn: 'root'
})
export class GiftboxService {

  private giftboxCollection!: AngularFirestoreCollection<Giftbox>;
  private giftboxDocument!: AngularFirestoreDocument<Giftbox>;
  private guestCollection!: AngularFirestoreCollection<Guest>;
  private session: Session = new Session();
  private update_user: {uid: string, name: string} = {uid: '', name: ''};
  private giftboxes: Observable<Giftbox[]> = of([]);
  private giftbox: Observable<Giftbox | null> = of(null);
  private guests: Observable<Guest[]> = of([]);
  public subscription: Subscription = new Subscription();

  constructor(
    private firestore: AngularFirestore,
    private sv_session: SessionService,
    private sv_item: ItemService,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService
  ) {

  }

  /**
   * 更新ユーザー設定
   *
   * @param {Session} session
   * @memberof GiftboxService
   */
  initialize(session: Session): void {
    if (!session || !session.data) return;
    this.session = session;
    this.update_user = {
      uid: session.data.uid,
      name: session.data.last_name + session.data.first_name
    };
  }

  /**
   * ギフトセット一覧を取得
   *
   * @returns {Observable<Giftbox[]>}
   * @memberof GiftboxService
   */
  getGiftboxes(event_id: string, cart_in: boolean = false): Observable<Giftbox[]> {
    if (!cart_in) {
      this.giftboxCollection = this.firestore.collection<Giftbox>('giftbox', ref => ref
        .where('event_id', '==', event_id)
        .where('delete_flg', '==', false)
        .orderBy('created', 'desc')
      );
    } else {
      this.giftboxCollection = this.firestore.collection<Giftbox>('giftbox', ref => ref
        .where('event_id', '==', event_id)
        .where('delete_flg', '==', false)
        .where('cart_in', '==', true)
        .where('status', '==', 0)
        .orderBy('cart_in_updated', 'desc')
        .orderBy('created', 'desc')
      );
    }
    this.giftboxes = this.giftboxCollection.snapshotChanges().pipe(
      map (datas =>
        datas.map(doc => ({
          objectID: doc.payload.doc.id,
          event_id: doc.payload.doc.data().event_id,
          name: doc.payload.doc.data().name,
          items: doc.payload.doc.data().items,
          sendlist: 0,
          total_price: doc.payload.doc.data().total_price,
          total_delive_fee: doc.payload.doc.data().total_delive_fee,
          tax_target_price: doc.payload.doc.data().tax_target_price,
          reduced_tax_target_price: doc.payload.doc.data().reduced_tax_target_price,
          status: doc.payload.doc.data().status,
          status_name: '',
          cart_in: doc.payload.doc.data().cart_in,
          latest_order: doc.payload.doc.data().latest_order,
          latest_branch: doc.payload.doc.data().latest_branch,
          created: doc.payload.doc.data().created,
          cart_in_updated: doc.payload.doc.data().cart_in_updated,
          order_updated: doc.payload.doc.data().order_updated,
          updated: doc.payload.doc.data().updated,
          update_user: doc.payload.doc.data().update_user,
          delete_flg: doc.payload.doc.data().delete_flg,
          selected: doc.payload.doc.id == this.local_storage.get(constant.STORAGE_LOCAL_GIFTBOX)
        }))
      )
    );
    return this.giftboxes.pipe(
      catchError(this.handleError<Giftbox[]>('getGiftboxes', []))
    );
  }


  /**
   * ギフトセット検索
   *　（ギフトセット名の前方一致のみ）
   * @param {string} event_id
   * @param {string} keyward
   * @returns {Observable<Giftbox[]>}
   * @memberof GiftboxService
   */
  searchGiftboxes(event_id: string, keyward: string): Observable<Giftbox[]> {
    this.giftboxCollection = this.firestore.collection<Giftbox>('giftbox', ref => ref
      .where('event_id', '==', event_id)
      .where('delete_flg', '==', false)
      .orderBy('name')
      .orderBy('created', 'desc')
      .startAt(keyward)
      .endAt(keyward + '\uf8ff')
    );
    this.giftboxes = this.giftboxCollection.snapshotChanges().pipe(
      map (datas =>
        datas.map(doc => ({
          objectID: doc.payload.doc.id,
          event_id: doc.payload.doc.data().event_id,
          name: doc.payload.doc.data().name,
          items: doc.payload.doc.data().items,
          sendlist: 0,
          total_price: doc.payload.doc.data().total_price,
          total_delive_fee: doc.payload.doc.data().total_delive_fee,
          tax_target_price: doc.payload.doc.data().tax_target_price,
          reduced_tax_target_price: doc.payload.doc.data().reduced_tax_target_price,
          status: doc.payload.doc.data().status,
          status_name: '',
          cart_in: doc.payload.doc.data().cart_in,
          latest_order: doc.payload.doc.data().latest_order,
          latest_branch: doc.payload.doc.data().latest_branch,
          created: doc.payload.doc.data().created,
          cart_in_updated: doc.payload.doc.data().cart_in_updated,
          order_updated: doc.payload.doc.data().order_updated,
          updated: doc.payload.doc.data().updated,
          update_user: doc.payload.doc.data().update_user,
          delete_flg: doc.payload.doc.data().delete_flg,
          selected: doc.payload.doc.id == this.local_storage.get(constant.STORAGE_LOCAL_GIFTBOX)
        }))
      )
    );
    return this.giftboxes.pipe(
      catchError(this.handleError<Giftbox[]>('searchGiftboxes', []))
    );
  }

  /**
   * ギフトセットに登録されている商品情報を取得
   *   商品情報の詳細はAlgoliaから取得、個数と熨斗はgiftboxのitems（サブコレクション）から取得
   *
   * @param {string} giftbox_id
   * @returns {Observable<GiftboxItem[]>}
   * @memberof GiftboxService
   */
  getItemsOfGiftbox(giftbox_id: string): Observable<GiftboxItem[]> {
    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_SEARCH_KEY);
    const index = client.initIndex(ALGOLIA_INDEX_ITEM);

    const search_result: Subject<GiftboxItem[]> = new Subject();
    const result = search_result.asObservable();

    const giftbox_document: AngularFirestoreDocument<Giftbox> = this.firestore.doc<Giftbox>(`giftbox/${giftbox_id}`);
    const giftbox_items = giftbox_document.collection('items', ref => ref.orderBy('created', 'desc'));

    let items_data: any[];
    let item_ids: string[];

    this.subscription.add(
      giftbox_items.snapshotChanges().subscribe(items => {
        items_data = items;
        item_ids = [];
        items.forEach(item => {
          item_ids.push(item.payload.doc.id);
        });
        if (item_ids.length) {
          index.getObjects(item_ids, {attributesToRetrieve: this.getFieldNames()})
          .then(async ({ results }) => {
            let res_items: GiftboxItem[] = [];
            await Promise.all(results.map(async (data: any) => {
              let obj: GiftboxItem = {
                giftbox_id: giftbox_id,
                giftbox_item_objectID: data.objectID,
                num: 0,
                item_name: data.name,
                item_beare_code: data.beare_code,
                item_code: data.code,
                item_cost: data.cost,
                item_price: data.price,
                item_reduced_tax_flg: data.reduced_tax_flg,
                item_category: data.category,
                item_thumb_s: data.thumb_s,
                item_thumb_l: data.thumb_l,
                item_brand: data.brand,
                item_delete_flg: data.delete_flg
              };
              // algoliaから取得した商品情報のうち個数をDB値で上書き
              items_data.forEach(item => {
                if (data.objectID == item.payload.doc.id) {
                  obj.num = item.payload.doc.data().num;
                }
              })
              res_items.push(obj);
            }));
            // すべての非同期処理が完了した後にres_itemsを渡す
            search_result.next(res_items);
          });
        }
      })
    );
    return result.pipe(
      catchError(this.handleError<GiftboxItem[]>('getItemsFromGiftbox', []))
    );
  }

  private getFieldNames() :string[]{
    return [
      "giftbox_id",
      "objectID",
      "name",
      "beare_code",
      "code",
      "cost",
      "price",
      "reduced_tax_flg",
      "category",
      "thumb_s",
      "thumb_l",
      "brand",
      "status",
      "delete_flg"
    ];
  }

  /**
   * ギフトセット商品合計金額
   *
   * @param {string} key
   * @returns {Observable<number>}
   * @memberof GiftboxService
   */
  // getItemPriceTotal(key: string): Observable<number> {
  //   let get_result: Subject<number> = new Subject();
  //   let result = get_result.asObservable();
  //   this.countGiftboxItems(key).subscribe

  //   let item_keys: string[] =
  //   this.firestore.doc<Giftbox>(`giftbox/${key}`).collection('items').snapshotChanges().pipe(
  //     map(docs => {
  //       let arr: string[] = [];
  //       docs.map(doc => {
  //         arr.push(doc.payload.doc.id);
  //       });
  //       return arr;
  //       // this.sv_debug.log(item_keys);
  //       // this.sv_item.getItemPriceTotal(item_keys).subscribe(price => get_result.next(price));
  //     })
  //   );
  //   return result;
  // }

  /**
   * ギフトセットの贈る相手を取得
   *
   * @param {string} event_id
   * @param {string} giftbox_id
   * @returns {Observable<Guest[]>}
   * @memberof GiftboxService
   */
  getSendlistsOfGiftbox(event_id: string, giftbox_id: string): Observable<Guest[]> {
    let guest_subject: Subject<Guest[]> = new Subject();
    let guest_result = guest_subject.asObservable();

    this.guestCollection = this.firestore.doc<Event>(`event/${event_id}`).collection<Guest>('guest', ref => ref
      .where('giftbox_ids', 'array-contains', giftbox_id)
      .where('delete_flg', '==', false)
      .orderBy('last_kname')
      .orderBy('first_kname')
    );

    this.guests = this.guestCollection.snapshotChanges().pipe(
      map(docs =>
        docs.map(doc => ({
          objectID: doc.payload.doc.id,
          event_id: event_id,
          giftbox_ids: doc.payload.doc.data().giftbox_ids,
          group_id: doc.payload.doc.data().group_id,
          deliv_date: doc.payload.doc.data().deliv_date,
          deliv_fee: doc.payload.doc.data().deliv_fee,
          card: doc.payload.doc.data().card,
          noshi: doc.payload.doc.data().noshi,
          first_name: doc.payload.doc.data().first_name,
          last_name: doc.payload.doc.data().last_name,
          first_kname: doc.payload.doc.data().first_kname,
          last_kname: doc.payload.doc.data().last_kname,
          zip: doc.payload.doc.data().zip,
          addr1: doc.payload.doc.data().addr1,
          addr2: doc.payload.doc.data().addr2,
          addr3: doc.payload.doc.data().addr3,
          addr4: doc.payload.doc.data().addr4,
          tel: doc.payload.doc.data().tel,
          email: doc.payload.doc.data().email,
          created: doc.payload.doc.data().created,
          updated: doc.payload.doc.data().updated,
          update_user: doc.payload.doc.data().update_user,
          group_updated: doc.payload.doc.data().group_updated,
          giftbox_updated: doc.payload.doc.data().giftbox_updated,
          delete_flg: doc.payload.doc.data().delete_flg,
          giftbox$: null,
          group_name$: null
        }))
      )
    );
    this.subscription.add(
      this.guests.subscribe(guests => guest_subject.next(guests))
    );
    return guest_result.pipe(
      catchError(this.handleError<Guest[]>('getSendlistsOfGiftbox', []))
    );
  }

  /**
   * ギフトセット内の商品ID取得（個数カウント）
   *
   * @param {string} giftboxID
   * @returns {Observable<string[]>}
   * @memberof GiftboxService
   */
  countGiftboxItems(giftboxID: string): Observable<string[]> {
    const result: Observable<string[]> = this.firestore.doc<Giftbox>(`giftbox/${giftboxID}`).collection('items').snapshotChanges().pipe(
      map(docs => docs.map(doc => doc.payload.doc.id
    )));
    return result;
  }

  /**
   * ギフトセット内の贈る相手の人数を取得
   *
   * @param {string} event_id
   * @param {string} giftboxID
   * @returns {Observable<string[]>}
   * @memberof GiftboxService
   */
  countGiftboxSendlist(event_id: string, giftboxID: string): Observable<string[]> {
    const result: Observable<string[]> = this.firestore.doc<Event>(`event/${event_id}`).collection<Guest>('guest', ref => ref
      .where('giftbox_ids', 'array-contains', giftboxID)
      .where('delete_flg', '==', false)
    ).snapshotChanges().pipe(
      map(docs => docs.map(doc => doc.payload.doc.id))
    );
    return result;
  }

  /**
   * ギフトセットを取得（詳細）
   *
   * @param {string} key
   * @returns {Observable<Giftbox | null>}
   * @memberof GiftboxService
   */
  getGiftbox(key: string): Observable<Giftbox | null> {
    this.giftboxDocument = this.firestore.doc<Giftbox>(`giftbox/${key}`);

    const giftbox: Observable<Giftbox | null> = this.giftboxDocument.snapshotChanges().pipe(
      map(doc => {
        if (!doc.payload.exists) {
          return null;
        }
        let obj: Giftbox = {
          objectID: doc.payload.id,
          event_id: doc.payload.data().event_id,
          name: doc.payload.data().name,
          items: doc.payload.data().items,
          sendlist: 0,
          total_price: doc.payload.data().total_price,
          total_delive_fee: doc.payload.data().total_delive_fee,
          tax_target_price: doc.payload.data().tax_target_price,
          reduced_tax_target_price: doc.payload.data().reduced_tax_target_price,
          status: doc.payload.data().status,
          cart_in: doc.payload.data().cart_in,
          latest_order: doc.payload.data().latest_order,
          latest_branch: doc.payload.data().latest_branch,
          created: doc.payload.data().created,
          cart_in_updated: doc.payload.data().cart_in_updated,
          order_updated: doc.payload.data().order_updated,
          updated: doc.payload.data().updated,
          update_user: doc.payload.data().update_user,
          delete_flg: doc.payload.data().delete_flg,
          selected: false
        };
        return obj;
    }));

    return giftbox.pipe(
      catchError(this.handleError<Giftbox | null>(`getGiftbox id=${key}`, null))
    );
  }

  /**
   * ギフトセット名を取得
   *
   * @param {string} key
   * @returns {Observable<string | null>}
   * @memberof GuestService
   */
  getGiftboxName(key: string): Observable<string | null> {
    const giftboxDocument = this.firestore.doc<Giftbox>(`giftbox/${key}`);
    let giftbox_name: Observable<string | null> = giftboxDocument.snapshotChanges().pipe(
      map (doc => {
        if (!doc.payload.exists) {
          return null;
        }
        return doc.payload.data().name;
      })
    );
    return giftbox_name.pipe(
      catchError(this.handleError<string | null>('getGiftboxName', null))
    );
  }

  /**
   * 選択中のギフトセットを保存
   *  （次回ログイン時も引き継ぐためシステム内では削除しない）
   * @memberof GiftboxService
   */
  saveSelectedGiftbox(giftbox_id: string): void {
    this.local_storage.set(constant.STORAGE_LOCAL_GIFTBOX, giftbox_id);
  }

  /**
   * ギフトセットに商品を追加
   *
   * @param {string} key giftbox DocumentID
   * @param {string} item_objectID giftbox.items DocumentID（追加する商品のDocumentIDと同じ）
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GiftboxService
   */
  addGiftboxItem(key: string, item_objectID: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const giftbox_ref = this.firestore.doc<Giftbox>(`giftbox/${key}`).ref;
    const giftbox_items_doc = this.firestore.doc<Giftbox>(`giftbox/${key}`).collection('items').doc(item_objectID);

    giftbox_items_doc.get().subscribe(doc => {
      // 商品がギフトセットに既に追加済みの場合は処理を中止
      if (doc.exists) {
        result_subject.next({result: false, msg: constant.MSG_ERR_GIFTBOX_EXISTS, id: null});
        return;
      }

      let giftbox_item_ref = giftbox_items_doc.ref;
      this.firestore.firestore.runTransaction(async transaction =>
        await Promise.all([
          transaction.get(giftbox_ref),
          transaction.get(giftbox_item_ref),
        ])
        .then(() => {
          transaction.set(giftbox_item_ref,{
            'noshi': 0,
            'num': 1,
            'created': new Date()
          });
        })
        .then(() => {
          transaction.update(giftbox_ref, {
            'updated': new Date(),
            'update_user': this.update_user
          })
        })
      )
      .then(() => {
        debugLog("Transaction successfully committed!");
        result_subject.next({result: true, msg: constant.MSG_SET_SUCCESSED, id: null});
      })
      .catch(error => {
        debugLog("Transaction failed: ", error);
        result_subject.next({result: false, msg: constant.MSG_SET_FAILED, id: null});
      });
    });
    return result;
  }

  /**
   * テストデータ投入用
   *
   * @memberof GiftboxService
   */
  test_add(): void {
    let giftbox_data = require('../datas/giftbox.json');
    giftbox_data.forEach((data: any, i: number) => {
      giftbox_data[i].created = new Date(data.created);
      giftbox_data[i].updated = new Date(data.updated);
      const doc_key = this.firestore.createId();
      this.giftboxCollection.doc(doc_key).set(data);
    });
  }

  /**
   * ギフトセット新規追加
   *
   * @param {string} name
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GiftboxService
   */
  add(name: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const add_data: Giftbox = {
      objectID: "",
      event_id: this.session.data!.event!.objectID,
      name: name,
      items: 0,
      sendlist: 0,
      total_price: 0,
      total_delive_fee: 0,
      tax_target_price: 0,
      reduced_tax_target_price: 0,
      status: 0,
      cart_in: false,
      latest_order: "",
      latest_branch: "",
      created: new Date(),
      cart_in_updated: null,
      order_updated: null,
      updated: new Date(),
      update_user: this.update_user,
      delete_flg: false,
      selected: false
    }
    const doc_key = this.firestore.createId();
    const giftbox_ref = this.firestore.collection('giftbox').doc(doc_key).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(giftbox_ref);
      transaction.set(giftbox_ref, add_data);
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_SET_SUCCESSED, id: doc_key});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_SET_FAILED, id: null});
    });
    return result;
  }

  /**
   * ギフトセット削除
   *
   * @param {string} key
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GiftboxService
   */
  del(key: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const giftbox_ref = this.firestore.doc<Giftbox>(`giftbox/${key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(giftbox_ref);
      transaction.update(giftbox_ref, {
        'delete_flg': true,
        'updated': new Date(),
        'update_user': this.update_user
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * ギフトセット商品の削除
   *
   * @param {string} key giftbox DocumentID
   * @param {string} item_key giftbox.items DocumentID
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GiftboxService
   */
  delItem(key: string, item_key: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const giftbox_ref = this.firestore.doc<Giftbox>(`giftbox/${key}`).ref;
    const giftbox_item_ref = this.firestore.doc<Giftbox>(`giftbox/${key}`).collection('items').doc(item_key).ref;

    this.firestore.firestore.runTransaction(async transaction =>
      await Promise.all([
        transaction.get(giftbox_ref),
        transaction.get(giftbox_item_ref),
      ])
      .then(() => {
        transaction.delete(giftbox_item_ref);
      })
      .then(() => {
        transaction.update(giftbox_ref, {
          'updated': new Date(),
          'update_user': this.update_user
        })
      })
    )
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * ギフトセット商品の個数を変更
   *
   * @param {string} key giftbox DocumentID
   * @param {string} item_key giftbox.items DocumentID
   * @param {number} num 商品数
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GiftboxService
   */
  updateItemNum(key: string, item_key: string, num: number): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const giftbox_ref = this.firestore.doc<Giftbox>(`giftbox/${key}`).ref;
    const giftbox_item_ref = this.firestore.doc<Giftbox>(`giftbox/${key}`).collection('items').doc(item_key).ref;

    this.firestore.firestore.runTransaction(async transaction =>
      await Promise.all([
        transaction.get(giftbox_ref),
        transaction.get(giftbox_item_ref),
      ])
      .then(() => {
        transaction.update(giftbox_item_ref,{
          'num': num
        });
      })
      .then(() => {
        transaction.update(giftbox_ref, {
          'updated': new Date(),
          'update_user': this.update_user
        })
      })
    )
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * ギフトセット商品の熨斗の名前を変更
   *
   * @param {string} key giftbox DocumentID
   * @param {string} item_key giftbox.items DocumentID
   * @param {number} noshi 熨斗の名前のタイプ
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GiftboxService
   */
  // updateItemNoshi(key: string, item_key: string, noshi: number): Observable<ProcessResult> {
  //   let result_subject: Subject<ProcessResult> = new Subject();
  //   let result: Observable<ProcessResult> = result_subject.asObservable();
  //   const giftbox_ref = this.firestore.doc<Giftbox>(`giftbox/${key}`).ref;
  //   const giftbox_item_ref = this.firestore.doc<Giftbox>(`giftbox/${key}`).collection('items').doc(item_key).ref;

  //   this.firestore.firestore.runTransaction(async transaction =>
  //     await Promise.all([
  //       transaction.get(giftbox_ref),
  //       transaction.get(giftbox_item_ref),
  //     ])
  //     .then(() => {
  //       transaction.update(giftbox_item_ref,{
  //         'noshi': noshi
  //       });
  //     })
  //     .then(() => {
  //       transaction.update(giftbox_ref, {
  //         'updated': new Date(),
  //         'update_user': this.update_user
  //       })
  //     })
  //   )
  //   .then(() => {
  //     this.sv_debug.log("Transaction successfully committed!");
  //     result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED});
  //   })
  //   .catch(error => {
  //     this.sv_debug.log("Transaction failed: ", error);
  //     result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED});
  //   });
  //   return result;
  // }

  /**
   * ギフトセット商品の熨斗の名前を変更
   *
   * @param {string} event_id
   * @param {string} key guest DocumentID
   * @param {number} noshi 熨斗の名前のタイプ
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GiftboxService
   */
  updateNoshi(event_id: string, key: string, noshi: number): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const guest_ref = this.firestore.doc<Event>(`event/${event_id}`).collection('guest').doc<Guest>(key).ref;

    this.firestore.firestore.runTransaction(async transaction =>
      transaction.get(guest_ref)
      .then(() => {
        transaction.update(guest_ref, {
          'noshi': noshi,
          'updated': new Date(),
          'update_user': this.update_user
        })
      })
    )
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * ギフトセット 贈る相手 カード種類を変更
   *
   * @param {string} event_id
   * @param {string} key guest DocumentID
   * @param {number} card カード種類
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GiftboxService
   */
  updateCard(event_id: string, key: string, card: number): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const guest_ref = this.firestore.doc<Event>(`event/${event_id}`).collection('guest').doc<Guest>(key).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(guest_ref);
      transaction.update(guest_ref, {
        'card': card,
        'updated': new Date(),
        'update_user': this.update_user
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * ギフトセット贈る相手 納品日を変更
   *
   * @param {string} event_id
   * @param {string} key event/guest DocumentID
   * @param {Date} deliv_date 納品日
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GiftboxService
   */
  updateDelivDate(event_id: string, key: string, deliv_date: Date): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const guest_ref = this.firestore.doc<Event>(`event/${event_id}`).collection('guest').doc<Guest>(key).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(guest_ref);
      transaction.update(guest_ref, {
        'deliv_date': deliv_date,
        'updated': new Date(),
        'update_user': this.update_user
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * ギフトセットから贈る相手を削除
   *
   * @param {string} event_id
   * @param {string} key guest DocumentID
   * @param {string} giftbox_id
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GiftboxService
   */
  delGuest(event_id: string, deliv_date: Date, key: string, giftbox_id:string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const guest_ref = this.firestore.doc<Event>(`event/${event_id}`).collection('guest').doc<Guest>(key).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(guest_ref);
      transaction.update(guest_ref, {
        // 'giftbox_ids': firestore.FieldValue.arrayRemove(giftbox_id),

        'giftbox_ids': firestore.arrayRemove(giftbox_id),
        'deliv_date': deliv_date,
        'card': 0,
        'noshi': 0,
        'updated': new Date(),
        'update_user': this.update_user
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * ギフトセットを贈る相手を追加
   *
   * @param {string} event_id
   * @param {string} key event/guest DocumentID
   * @param {string} giftbox_id
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GiftboxService
   */
  addGuest(event_id: string, key: string, giftbox_id:string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const guest_ref = this.firestore.doc<Event>(`event/${event_id}`).collection('guest').doc<Guest>(key).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(guest_ref);
      transaction.update(guest_ref, {
        // 'giftbox_ids': firestore.FieldValue.arrayUnion(giftbox_id),
        'giftbox_ids': [giftbox_id],  // ギフトセットは1人1つという仕様変更に伴い、暫定的に後勝ちで上書くよう修正。
        'giftbox_updated': new Date(),
        'updated': new Date(),
        'update_user': this.update_user
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_SET_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_SET_FAILED, id: null});
    });
    return result;
  }

  /**
   * ギフトセットをカートに追加または削除
   *
   * @param {string} key
   * @param {boolean} cart_in true:カートへ追加、false:カートから削除
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GiftboxService
   */
  cartInOrDel(key: string, cart_in: boolean): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const giftbox_ref = this.firestore.doc<Giftbox>(`giftbox/${key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(giftbox_ref);
      transaction.update(giftbox_ref, {
        'cart_in': cart_in,
        'cart_in_updated': new Date(),
        'updated': new Date(),
        'update_user': this.update_user
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * ギフトセットのステータス更新
   *
   * @param {string} key
   * @param {number} status
   * @returns {Observable<ProcessResult>}
   * @memberof GiftboxService
   */
  updateStatus(key: string, status: number): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const giftbox_ref = this.firestore.doc<Giftbox>(`giftbox/${key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(giftbox_ref);
      transaction.update(giftbox_ref, {
        'status': status,
        'updated': new Date(),
        'update_user': this.update_user
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * 失敗したHttp操作を処理します。
   * アプリを持続させます。
   * @param operation - 失敗した操作の名前
   * @param result - observableな結果として返す任意の値
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: リモート上のロギング基盤にエラーを送信する
      console.error(error); // かわりにconsoleに出力

      // TODO: ユーザーへの開示のためにエラーの変換処理を改善する
      // this.log(`${operation} failed: ${error.message}`);

      // 空の結果を返して、アプリを持続可能にする
      return of(result as T);
    };
  }

}
