export interface IItemAttentionAdjust {
  vol: number,
  prev_vol: number,
  vol_base_date: any,
  prev_vol_base_date: any,
  item_remove_date: any,
  msg_start: any,
  msg_end: any,
  price_adjust_msg_start: any,
  price_adjust_msg_end: any
}
export class ItemAttentionAdjust implements IItemAttentionAdjust {
  vol: number = 0;
  prev_vol: number = 0;
  vol_base_date: any = null;
  prev_vol_base_date: any = null;
  item_remove_date: any = null;
  msg_start: any = null;
  msg_end: any = null;
  price_adjust_msg_start: any = null;
  price_adjust_msg_end: any = null;
}
