import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private close_subject = new Subject<string>();
  public close_result$ = this.close_subject.asObservable();

  constructor() { }

  /**
   * モーダルを閉じる
   *
   * @memberof ModalService
   */
  public close() {
    this.close_subject.next('');
  }
}
