<nav class="nav-content">
  <div class="side-navigation">
    <div class="scroll-box">
      <div class="account">
        <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.5 42.4" class="icon-user" width="24px" height="23px">
              <path class="icon-color" d="M13.3,15c4.1,0,7.5-3.4,7.5-7.5S17.4,0,13.3,0C9.1,0,5.8,3.4,5.8,7.5S9.1,15,13.3,15z M13.3,2c3,0,5.5,2.5,5.5,5.5S16.3,13,13.3,13c-3,0-5.5-2.5-5.5-5.5S10.2,2,13.3,2z"/>
              <path class="icon-color" d="M13.3,18C13.3,18,13.3,18,13.3,18C1.6,18.2,0.1,41.3,0,42.3c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3h24.5c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7C26.5,41.3,25,18.2,13.3,18z M2.1,41.4C2.5,37,4.7,20.2,13.3,20c8.6,0.2,10.8,17,11.2,21.4H2.1z"/>
            </svg>
          <span class="name">{{user_name}}</span>さま
        </div>
      </div>
      <!-- 管理用 -->
      <ul class="nav-list" *ngIf="user_role==2">
        <li class="item"><a routerLink="/order-list/{{order_list_type.DEFAULT}}" routeLinkActive="current" (click)="closeMenu()">注文情報</a></li>
        <li class="item"><a routerLink="/event-list" routeLinkActive="current" (click)="closeMenu()">挙式情報</a></li>
        <li class="item"><a routerLink="/items" routeLinkActive="current" (click)="closeMenu()">商品カタログ</a></li>
        <li class="item" *ngIf="is_manager"><a routerLink="/place" routeLinkActive="current" (click)="closeMenu()">会場・メンバー情報</a></li>
        <li class="item"><a routerLink="/member-account" (click)="closeMenu()">アカウント</a></li>
        <!-- <li class="item"><a routerLink="" class="icon-badge" routeLinkActive="current" (click)="closeMenu()">通知・お知らせ
          <span *ngIf="!no_info_not_read" class="badge">{{header_count?.info_not_read_cnt}}</span>
        </a></li> -->

        <li class="item"><a href="https://firebasestorage.googleapis.com/v0/b/t-port-demo/o/manual_20210419.pdf?alt=media&token=7809f95b-808e-43cf-bf07-460ca74c1515" target="_brunk" (click)="closeMenu()">操作マニュアル</a></li>
        <li class="menu_part"></li>
        <li class="item"><a routerLink="/howto" (click)="closeMenu()">HOW TO<span class="cap">（新郎新婦様向け）</span></a></li>
        <li class="item"><a routerLink="/faq" (click)="closeMenu()">Q & A<span class="cap">（新郎新婦様向け）</span></a></li>
        <li class="menu_part"></li>
        <li class="item"><a routerLink="/terms-and-policy/policy" (click)="closeMenu()">プライバシーポリシー</a></li>
        <li class="item"><a routerLink="/sign_in" routeLinkActive="current" (click)="signOut()">サインアウト</a></li>
        <li class="menu_part"></li>
      </ul>
      <div id="algolia_logo"></div>
    </div>
  </div>
</nav>
