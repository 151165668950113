export interface ICompany {
  objectID: string;
  name: string;
  kname: string;
  zip: string;
  addr1: string;
  addr2: string;
  addr3: string;
  addr4: string;
  tel: string;
  fax: string;
  email: string;
  represent_user_uid: string;
  represent_user_email: string;
  token: string;
  slf_company_code: string; // Selfy 得意先コード
  slf_place_code: string; // Selfy T-PORT用会場コード
  slf_id: string; // Selfy 得意先コードに紐づくセルフィー用のID
  delive_fee_type: number; // 送料区分
  onew_link: boolean; // ONE-W連携（ゲストリスト）可否
  onew_base_key: string; // ONE-W連携する際の暗号化共通キー
  created: Date;
  updated: Date;
  updated_user: {
    uid: string;
    name: string;
  };
  delete_flg: boolean;
}
export class Company implements ICompany {
  objectID: string = '';
  name: string = '';
  kname: string = '';
  zip: string = '';
  addr1: string = '';
  addr2: string = '';
  addr3: string = '';
  addr4: string = '';
  tel: string = '';
  fax: string = '';
  email: string = '';
  represent_user_uid: string = '';
  represent_user_email: string = '';
  token: string = '';
  slf_company_code: string = '';
  slf_place_code: string = '';
  slf_id: string = '';
  delive_fee_type: number = 0;
  onew_link: boolean = false;
  onew_base_key: string = '';
  created: Date = new Date();
  updated: Date = new Date();
  updated_user: {
    uid: string;
    name: string;
  } = {uid: '', name: ''};
  delete_flg: boolean = false;
}
