import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { FirebaseUISignInSuccessWithAuthResult, FirebaseUISignInFailure } from 'firebaseui-angular';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { SpinnerComponent } from '../spinner/spinner.component';
import { ModalMessageComponent } from '../modal-message/modal-message.component';

import { AuthService } from '../../service/auth.service';
import { SessionService } from '../../service/session.service';
import * as constant from '../../models/constant';
import * as environment from '../../../environments/environment';

import $ from 'jquery';
import { debuglog } from 'node:util';

declare function showConsentConfirmMsg(): void;
declare const debugLog: any;

@Component({
  selector: 'hag_signIn',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SpinnerComponent,
    ModalMessageComponent,
  ],
  templateUrl: './sign_in.component.html',
  styleUrls: ['./sign_in.component.scss']
})

export class SignInComponent implements OnInit {
  public user: Observable<firebase.User | null> = of(null);
  public login_message: string = '';
  public role: number = -1;
  public site_type: string = '';
  private subscription: Subscription = new Subscription();
  private isBrowser: boolean;

  constructor(
    private sv_auth: AuthService,
    private sv_session: SessionService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.site_type = environment.environment.SYTE_TYPE;
    // 規約同意画面の表示設定が有効な場合、個人情報保護法に基づく規約同意表示
    if (environment.environment.ASK_FOR_CONSENT) {
      this.sv_session.getTermsConsent();
      this.sv_session.termsConsent.subscribe(consent => {
        if (!consent) {
          showConsentConfirmMsg();
        }
        this.signInInit();
      });
    } else {
      this.signInInit();
    }
    if (this.isBrowser) {
      if (!$('body').hasClass('login')) {
        $('body').addClass('login');
      }
    }
  }

  // 規約同意
  public onModalConsentMsgEvent(event: boolean) {
    this.local_storage.set(constant.STORAGE_LOCAL_MNG_TERMS_CONSENT, event);
    if (event) { // 同意する
      this.signInInit();
    } else { // 同意しない
      this.routing(event);
    }
    if (this.isBrowser) {
      $('#consent-modal-message').fadeOut();
    }
  }

  delConsent() {
    if (!this.isBrowser) return;
    this.local_storage.set(constant.STORAGE_LOCAL_MNG_TERMS_CONSENT, null);
  }

  // サインイン準備
  private signInInit() {
    this.login_message = "";
    this.user = this.sv_auth.signInInit();
    this.afAuth.onAuthStateChanged(user => {
      if (user?.isAnonymous) {
        debugLog("Delete AnonymousUser");
        user.delete();  // ユーザー登録直後で匿名ユーザーが残っている場合の処理
      }
      else {
        if (this.isBrowser) {
          if (user && user.providerData.length > 0) {
            console.log('signIn');
            this.routing();
          } else {
            console.log('Not signIn');
          }
        }
      }
    });
  }

  // 通常サインイン
  signIn() {
    if (!this.isBrowser) return;
    let email: string = $('#email').val() as string;
    let password: string = $('#password').val() as string;
    this.sv_auth.signIn(email, password);
    this.subscription.add(
      this.sv_auth.login_message_result.subscribe(message => {
        this.login_message = message;
      })
    );
    this.routing();
  }

  // サインアウト
  async signOut() {
    this.sv_auth.sign0ut();
    // this.login_message = constant.MSG_LOGOUT;
    this.login_message = '';
  }

  public toTop(): void {
    this.routing();
  }

  // パスワード再設定
  passwordReset() {
    if (!this.isBrowser) return;
    let email: string = $('#email').val() as string;
    this.sv_auth.passwordReset(email);
    return false;
  }

  // SNSサインイン 成功時のコールバック
  successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
    this.sv_auth.callSignInSuccess();
    this.subscription.add(
      this.sv_auth.login_message_result.subscribe(message => this.login_message = message)
    );
    this.routing();
  }

  // SNSサインイン 失敗時のコールバック
  async errorCallback(errorData: FirebaseUISignInFailure) {
    this.login_message = this.sv_auth.signInFailure(errorData);
  }

  private routing(consent:boolean = true): void {
    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.subscribe(session => {
        // 規約同意画面の表示設定が無効
        if (!environment.environment.ASK_FOR_CONSENT) {
          if (!session || !session.data) return;
          this.role = session.data.current_role;
          if (session.login && (this.role == constant.ROLE.MNG) ) {
            setTimeout(() => this.router.navigate([`../order-list/${constant.ORDER_LIST_TYPE.DEFAULT}`], {relativeTo: this.route}), 100);
          } else {
            this.sv_auth.sign0ut();
          }
        }
        // 規約同意画面の表示設定が有効
        else {
          // if (consent) {
            if (!session || !session.data) return;
            this.role = session.data.current_role;
            if (session.login && (this.role == constant.ROLE.MNG) ) {
              setTimeout(() => this.router.navigate([`../order-list/${constant.ORDER_LIST_TYPE.DEFAULT}`], {relativeTo: this.route}), 100);
            } else {
              this.sv_auth.sign0ut();
            }
          // } else {
          //   // 規約同意しなかった場合、ログインしてたらログアウトしてトップへ
          //   if (session) {
          //     this.sv_auth.sign0ut();
          //   }
          //   setTimeout(() => this.router.navigate(['/'], {relativeTo: this.route}), 300);
          // }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.isBrowser) {
      if ($('body').hasClass('login')) {
        $('body').removeClass('login');
      }
    }
  }

}
