<div class="content-block content-block-max">
  <div class="content-block-inner">
    <div class="back-link"><a routerLink="/order-list/{{order_list_type.DEFAULT}}">注文一覧へ戻る</a></div>

    <div class="flex-box">
      <div class="title-content mng mg-style2">発注履歴</div>
    </div>
    <div class="mg-style2">
      <dl class="flex-box j-content-start text-m-style2">
          <dt class="dl-title">注文No</dt>
          <dd>{{order_base_info.order_no}}</dd>
      </dl>
      <dl class="flex-box j-content-start text-m-style2">
        <dt class="dl-title">挙式日</dt>
        <dd>{{order_base_info.event_date | date}}</dd>
      </dl>
      <div class="title-content"><a routerLink="/event-detail/{{order_base_info.event_id}}">{{order_base_info.event_name}}</a></div>
    </div>
  </div><!--/content-block-inner-->

  <div class="inner-header">
    <div class="result">{{order_datas.length}}件</div>
  </div>

  <div class="list-wrap">

    <a *ngFor="let order of order_datas; index as i" (click)="selected(i)" class="item-list">
      <div>
        <dl class="flex-box j-content-start a-items-start">
          <dt class="dl-title w5e">顧客注文</dt>
          <dd>{{order.order_branch?.order_request_date.toDate() | date : 'short'}}（{{order.order_branch?.order_request_user?.name}}様）</dd>
        </dl>
        <dl class="flex-box j-content-start">
          <dt class="dl-title w5e">会場発注</dt>
          <dd *ngIf="order.order_branch?.order_date">{{order.order_branch?.order_date.toDate() | date : 'short'}}（{{order.order_branch?.order_user?.name}}様）</dd>
        </dl>
        <dl class="flex-box j-content-start">
          <dt class="dl-title w5e">受注</dt>
          <dd *ngIf="order.order_branch?.order_accept_date">{{order.order_branch?.order_accept_date.toDate() | date : 'short'}}（タイムレス）</dd>
        </dl>
        <dl class="flex-box j-content-start">
          <dt class="dl-title w5e">合計金額</dt>
          <dd *ngIf="taxable_info[i]">
            {{(order.order_branch!.price + order.order_branch!.tax + order?.order_branch!.reduced_tax) | currency : 'JPY'}}<span class="cap2 s blank_l">(税込)</span>
            <span class="m10 left">送料{{(order.order_branch!.deliv_fee + order.order_branch!.deliv_fee_tax) | currency : 'JPY'}}<span class="cap2 s blank_l">(税込)</span></span>
          </dd>
          <dd *ngIf="!taxable_info[i]">
            {{order.order_branch!.price | currency : 'JPY'}}
            <span>（送料{{order.order_branch!.deliv_fee | currency : 'JPY'}}）</span>
          </dd>
        </dl>
        <div *ngIf="!taxable_info[i]">
          <span class="cap">（※税込み総額表示の対象期間外のご注文のため、税抜き価格で表示しています）</span>
        </div>
      </div>
    </a><!--/item-list-->

  </div><!--/list-wrap-->

</div><!--/content-block-->
