import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

// ディレクティブ
import { ClickStopPropagation2Directive } from '../../util/directive/click-stop-propagation2';

import { ModalComponent } from '../modal/modal.component';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { ItemDetailComponent } from '../item-detail/item-detail.component';

import { SessionService } from '../../service/session.service';
import { ItemService } from '../../service/item.service';
import { GiftboxService } from '../../service/giftbox.service';
import { AccountService } from '../../service/account.service';
import { CompanyService } from '../../service/company.service';
import { CommonService } from '../../service/common.service';
import { ConfigService } from '../../service/config.service';
import { PlaceService } from '../../service/place.service';
import { ModalService } from '../../service/modal.service';

import { Item } from '../../models/item';
import { Session } from '../../models/session';
import { ItemAttentionAdjust } from '../../models/item_attention_adjust';
import { ItemPriceAdjust } from '../../models/item_place_adjust';
import * as constant from '../../models/constant';

import moment from 'moment';
import { environment } from '../../../environments/environment';

declare function resetCommonrEvent(): void;
declare function resetItemEvent(): void;
declare function pageTopShow(): void;
declare const debugLog: any;

@Component({
  selector: 'app-items',
  standalone: true,
  imports: [
    CommonModule,
    NgxPageScrollModule,
    NgxPageScrollCoreModule,
    ModalComponent,
    ModalMessageComponent,
    ItemDetailComponent,
  ],
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  function_category = ["item", "search"];
  public items: Item[] = [];
  public image_url: string[] = [];
  private item_attention_adjust: ItemAttentionAdjust = new ItemAttentionAdjust();
  public items_attention: {type: string, msg: string}[] = [];
  private item_attention_vol_use: boolean = true;
  private item_attention_prev_vol_use: boolean = true;
  private vol_base_date: any = null;
  private prev_vol_base_date: any = null;
  private vol: number = 0;
  private prev_vol: number = 0;

  private company_id: string = '';
  private place_id: string = '';
  private place_ids: string[] = []; // 会場横断権限ありのプランナーが使用する会場ID（所属企業内の全ての会場ID）
  public place_tax_rate: {rule: boolean, rate: number} = {rule: false, rate: 0};
  private is_cross_search: boolean = false;

  private _categories: string[] = [];
  public get categories(): string[] {
    return this._categories;
  }
  public set categories(value: string[]) {
    this._categories = value;
  }

  private _price_ranges: string[] = [];
  public get price_ranges(): string[] {
    return this._price_ranges;
  }
  public set price_ranges(value: string[]) {
    this._price_ranges = value;
  }

  private _keywords: string[] = [];
  public get keywords(): string[] {
    return this._keywords;
  }
  public set keywords(value: string[]) {
    this._keywords = value;
  }

  private search_word: string = (this.keywords) ? this.keywords.join(' ') : '';
  private category_type = constant.ITEM_CATEGORY;
  private price_range_type = constant.ITEM_PRICE_RANGE;
  public status = constant.ITEM_STATUS;
  private session: Session = new Session();
  public role_cust: boolean = false;
  private selected_giftbox: string = '';
  public show_item_detail: boolean = false;
  public select_item_key: string = '';
  private subscription: Subscription = new Subscription();

  public dummy_img_url = environment.NO_IMAGE_MAIN;
  public dummy_thumb_l_url = environment.NO_IMAGE_THUMB_L;
  public dummy_thumb_s_url = environment.NO_IMAGE_THUMB_S;
  public rate = constant.TAX_RATE;
  public Math = Math;
  private isBrowser: boolean;
  // @Output('cdkDropDropped') dropped: EventEmitter<CdkDragDrop<string>> = new EventEmitter<CdkDragDrop<string>>();

  constructor(
    private sv_session: SessionService,
    private sv_item: ItemService,
    private sv_giftbox: GiftboxService,
    private sv_account: AccountService,
    private sv_company: CompanyService,
    private sv_common: CommonService,
    private sv_config: ConfigService,
    private sv_place: PlaceService,
    private sv_modal: ModalService,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.subscription.add(
      this.sv_modal.close_result$.subscribe(() => {
        this.show_item_detail = false;
      })
    );
    // ログインセッション取得
    this.sv_session.loginCheck();
    let session_exists: boolean = false;
    this.subscription.add(
      this.sv_session.sessionState.pipe(take(1)).subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.session = session;
          this.role_cust = constant.ROLE.CUST == session.data.current_role;
          this.is_cross_search = session.data.cross_search_flg;
          this.company_id = session.data.current_company;
          this.place_id = session.data.current_place;
          this.sv_giftbox.initialize(session);
        }

        if (this.place_id) {
          this.subscription.add(this.sv_place.getPlace(this.company_id, this.place_id).subscribe(place => {
            if (place) {
              this.item_attention_vol_use = place.item_attention_vol_use;
              this.item_attention_prev_vol_use = place.item_attention_prev_vol_use;
              this.vol_base_date = place.vol_base_date ? place.vol_base_date: null;
              this.prev_vol_base_date = place.prev_vol_base_date ? place.prev_vol_base_date : null;
              this.vol = place.vol ? place.vol : 0;
              this.prev_vol = place.prev_vol ? place.prev_vol : 0;
              this.place_tax_rate = place.place_tax_rate ? place.place_tax_rate : {rule: false, rate: 0};
            }
          }));
        }
        if (this.is_cross_search) {
          this.subscription.add(
            this.sv_company.getPlaceIds(this.company_id).subscribe(place_ids => {
              this.place_ids = place_ids ? place_ids : [];
            })
          );
        }
        if (!session_exists) {
          this.sv_account.initialize(session);
          this.sv_item.initialize(session);
          this.subscription.add(this.sv_config.getItemAttentionAdjust().pipe(take(1)).subscribe(item_attention_adjust => {
            if (item_attention_adjust) {
              this.item_attention_adjust = item_attention_adjust;
            }
            this.init();
          }));
          session_exists = true;
        }
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent();  // ヘッダーエリアのイベント再設定
      resetItemEvent(); // 商品系画面のイベント再設定
    }
  }

  private init(): void {
    if (!this.isBrowser) return;
    // 商品検索条件セッション取得
    // キーワード
    if(this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_KEY)) {
      this.keywords = this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_KEY);
      this.search_word = (this.keywords) ? this.keywords.join(' ') : '';
    }

    // カテゴリー
    if(this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_CATEGORY)) {
      this.category_type = this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_CATEGORY);
      this.categories = [];
      this.category_type.forEach((cate, i) => {
        this.category_type[i].checked = cate.checked;
        this.categories.push(cate.key.toString());
      });
    }
    else {
      this.categories = [];
      this.category_type.forEach((cate, i) => {
        this.category_type[i].checked = false;
        this.categories.push(cate.key.toString());
      });
    }

    // 価格帯
    if(this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_PRICE_RANGE)) {
      this.price_range_type = this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_PRICE_RANGE);
      this.price_ranges = [];
      this.price_range_type.forEach((range, r) => {
        this.price_range_type[r].checked = range.checked;
        this.price_ranges.push(range.key.toString());
      });
    }
    else {
      this.price_ranges = [];
      this.price_range_type.forEach((range, r) => {
        this.price_range_type[r].checked = false;
        this.price_ranges.push(range.key.toString());
      });
    }

    // 商品検索結果セッション取得（検索結果セッションがあり、有効な検索条件がある場合のみ）
    if(this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH)) {
      this.items = this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH);
      if (!this.is_cross_search) {
        this.items = this.items.filter(item => !item.place_without.includes(this.place_id) && item.place_limited.length < 1 || item.place_limited.includes(this.place_id));
      }
      // SOLDOUT情報はリアルタイムで反映させるため最新を取得し上書き
      this.subscription.add(
        this.sv_item.getItems().subscribe(items_data => {
          if (!this.is_cross_search) { // 一般プランナー
            items_data = items_data.filter(data =>
              (data.status === constant.ITEM_STATUS.COMMINGSOON || data.status === constant.ITEM_STATUS.PUBLIC || data.status === constant.ITEM_STATUS.SOLDOUT) &&
              !(data.place_without.includes(this.place_id)) &&
              (data.place_limited.length < 1 || data.place_limited.includes(this.place_id))
            );
          } else { // 会場横断権限プランナー
            items_data = items_data.filter(data =>
              (data.status === constant.ITEM_STATUS.COMMINGSOON || data.status === constant.ITEM_STATUS.PUBLIC || data.status === constant.ITEM_STATUS.SOLDOUT) &&
              !(this.place_ids.every(id => data.place_without.includes(id))) &&
              (data.place_limited.length < 1 || this.place_ids.some(id => data.place_limited.includes(id)))
            );
          }

          if (items_data.length === this.items.length) {
            this.items.forEach((item, j) => {
              let item_data = items_data.filter(d => d.objectID === item.objectID);
              if (item_data.length) {
                this.items[j].status = item_data[0].status;
              }
              if (j === this.items.length -1) {
                this.setItemAttention();
                this.sortImages(this.items);
              }
            });
          } else {
            this.categories = [];
            this.category_type.forEach((cate, c) => {
              if (cate.checked) {
                this.categories.push(cate.key.toString());
              }
            });
            this.price_ranges = [];
            this.price_range_type.forEach((range, r) => {
              if (range.checked) {
                this.price_ranges.push(range.key.toString());
              }
            });
            setTimeout(() => this.search(), 1500); // Algoliaが変更検知して更新される時間を考慮
          }
        })
      );
    }
    // 再検索
    else {
      this.search();
    }
  }

  private sortImages(items: Item[]): void {
    if (!items) return;
    items.forEach((item, i) => {
      if (item.image) {
        item.image.forEach(img => {
          if (img.no === 1) {
            this.image_url[i] = img.url;
          }
        });
      } else {
        this.image_url[i] = '';
      }
    });
    setTimeout(() => {
      pageTopShow();
    }, 0);
  }

  public search() {
    this.subscription.add(
      this.sv_item.searchItems(this._categories, this._price_ranges, this._keywords).subscribe(items => {
        // 会場横断権限のあるプランナーは現在の所属企業(current_company)の全会場を対象に、いずれかの会場で表示対象であればその商品を表示。
        if (this.is_cross_search) {
          let item_ids: string[] = [];
          if (this.place_ids && items.length) {
            items.forEach((item, index) => {
              if (!this.place_ids.every(id => item.place_without.includes(id)) &&
                  (item.place_limited.length < 1 || this.place_ids.some(id => item.place_limited.includes(id)))
              ) {
                item_ids[index] = item.objectID;
              }
              if (index == items.length -1) {
                this.items = items.filter(item => item_ids.includes(item.objectID));
                this.setStorage();
                this.setItemAttention();
                this.sortImages(this.items);
              }
            });
          } else {
            this.items = [];
          }
        }
        // 一般プランナーは現在の所属会場で表示対象となっている商品を表示。
        else {
          this.items = items.filter(item => !item.place_without.includes(this.place_id) && (item.place_limited.length < 1 || item.place_limited.includes(this.place_id)) );
          this.setStorage();
          this.setItemAttention();
          this.sortImages(this.items);
        }
      })
    );
  }

  private setStorage(): void {
    if (this._categories.length < 4) {
      this.category_type.forEach((cateType, i) => {
        if (this._categories.includes('category:' + cateType.key)) {
          this.category_type[i].checked = true;
        } else {
          this.category_type[i].checked = false;
        }
      });
    } else {
      this.category_type.forEach((cateType, i) => {
        this.category_type[i].checked = false;
      });
    }

    if (this._price_ranges.length < 4) {
      this.price_range_type.forEach((rangeType, r) => {
        if (this._price_ranges.includes(rangeType.key)) {
          this.price_range_type[r].checked = true;
        } else {
          this.price_range_type[r].checked = false;
        }
      });
    } else {
      this.price_range_type.forEach((rangeType, r) => {
        this.price_range_type[r].checked = false;
      });
    }

    if (this.isBrowser) {
      this.local_storage.set(constant.STORAGE_LOCAL_ITEM_SEARCH_KEY, this.keywords);
      this.local_storage.set(constant.STORAGE_LOCAL_ITEM_SEARCH_CATEGORY, this.category_type);
      this.local_storage.set(constant.STORAGE_LOCAL_ITEM_SEARCH_PRICE_RANGE, this.price_range_type);
      this.local_storage.set(constant.STORAGE_LOCAL_ITEM_SEARCH, this.items);
    }

    this.sv_session.getItemSearchKeyword();
    this.sv_session.getItemSearchCategory();
    this.sv_session.getItemSearchPriceRange();
    this.sv_session.getItemSearchList();
  }

  private setItemAttention(): void {
    // 挙式日制限メッセージ表示制御
    this.items_attention = [];
    let vol_base_date_str: string = this.vol_base_date ? moment(this.vol_base_date).format('YYYY/MM/DD') : moment(this.item_attention_adjust.vol_base_date).format('YYYY/MM/DD');
    let prev_vol_base_date_str: string = this.prev_vol_base_date ? moment(this.prev_vol_base_date).format('YYYY/MM/DD'): moment(this.item_attention_adjust.prev_vol_base_date).format('YYYY/MM/DD');
    let vol: number = this.vol ? this.vol : this.item_attention_adjust.vol;
    let prev_vol: number = this.prev_vol ? this.prev_vol : this.item_attention_adjust.prev_vol;
    let apply_item_price_adjusts: ItemPriceAdjust[];
    let no_apply_item_price_adjusts: ItemPriceAdjust[];
    // 新旧メッセージ表示期限の判定
    let today: Date = moment(new Date().setHours(0,0,0,0)).toDate();
    let msg_end: Date = moment(this.item_attention_adjust.msg_end).toDate();
    let price_adjust_msg_end: Date = moment(this.item_attention_adjust.price_adjust_msg_end).toDate();

    // 価格改定情報を取得
    this.subscription.add(this.sv_item.getItemPriceAdjusts().subscribe(price_adjusts => {
      this.items.forEach((item, i) => {
        apply_item_price_adjusts = [];
        no_apply_item_price_adjusts = [];
        this.items_attention[i] = {type: '', msg: ''};

        if (price_adjusts) {
          if (!this.is_cross_search) { // 一般プランナー
            apply_item_price_adjusts = price_adjusts.filter(p =>
              p.apply_beare_code === item.beare_code &&
              p.base_date &&
              moment(p.base_date).toDate() > today &&
              !p.place_without.includes(this.place_id) &&
              (p.place_limited.length < 1 || p.place_limited.includes(this.place_id))
            );
            no_apply_item_price_adjusts = price_adjusts.filter(p =>
              p.no_apply_beare_code === item.beare_code &&
              p.base_date &&
              moment(p.base_date).toDate() > today &&
              !p.place_without.includes(this.place_id) &&
              (p.place_limited.length < 1 || p.place_limited.includes(this.place_id))
            );
          } else { // 会場横断権限ありのプランナー
            apply_item_price_adjusts = price_adjusts.filter(p =>
              p.apply_beare_code === item.beare_code &&
              p.base_date &&
              moment(p.base_date).toDate() > today &&
              !this.place_ids.every(id => p.place_without.includes(id)) &&
              (p.place_limited.length < 1 || this.place_ids.some(id => p.place_limited.includes(id)))
            );
            no_apply_item_price_adjusts = price_adjusts.filter(p =>
              p.no_apply_beare_code === item.beare_code &&
              p.base_date &&
              moment(p.base_date).toDate() > today &&
              !this.place_ids.every(id => p.place_without.includes(id)) &&
              (p.place_limited.length < 1 || this.place_ids.some(id => p.place_limited.includes(id)))
            );
          }
        }

        // 価格改定商品の場合は価格改定メッセージ制御
        let price_adjust_date_str: string;
        if (/*price_adjust_msg_end > today && */apply_item_price_adjusts.filter(Boolean).length && !no_apply_item_price_adjusts.filter(Boolean).length) {
          price_adjust_date_str = moment(apply_item_price_adjusts[0].base_date).format('YYYY/MM/DD');
          this.items_attention[i] = {type: 'vol', msg: price_adjust_date_str + constant.ITEM_ATTENTION_MSG_SHORT.vol};
        }
        else if (/*price_adjust_msg_end > today && */no_apply_item_price_adjusts.filter(Boolean).length) {
          price_adjust_date_str = moment(no_apply_item_price_adjusts[0].base_date).format('YYYY/MM/DD');
          this.items_attention[i] = {type: 'prev_vol', msg: price_adjust_date_str + constant.ITEM_ATTENTION_MSG_SHORT.prev_vol};
        }
        // 価格改定商品でない場合は新旧商品メッセージ制御
        else {
          if (msg_end > today) {
            if (!item.vol_no || item.vol_no.length < 1) {
              // 継続商品扱いで常に注文可能
              this.items_attention[i] =  {type: '', msg: ''};
            } else {
              if (this.item_attention_vol_use && item.vol_no.length && item.vol_no.includes(vol) && !item.vol_no.includes(prev_vol)) {
                // 新規商品
                this.items_attention[i] = {type: 'vol', msg: prev_vol_base_date_str + constant.ITEM_ATTENTION_MSG_SHORT.vol};
              } else if (this.item_attention_prev_vol_use && item.vol_no.length && !item.vol_no.includes(vol)) {
                // 旧商品で
                this.items_attention[i] = {type: 'prev_vol', msg: vol_base_date_str + constant.ITEM_ATTENTION_MSG_SHORT.prev_vol};
              } else {
                // 継続商品
                this.items_attention[i] =  {type: '', msg: ''};
              }
            }
          }
        }
      });
    }));
  }

  public updateFavorite(event: any): void {
    // DBを更新
    let item_key: string = $(event.target).data('item_objectid');
    let favorite_in: boolean = !$(event.target).data('current_favorite');
    this.sv_account.updateFavorite(item_key, favorite_in);
    // 表示を更新
    if (this.isBrowser) {
      $(event.target).data('current_favorite', favorite_in);
      $(event.target).toggleClass('favorite-item');
    }
  }

  private addGiftboxItem(event: any): void {
    let item_objectID = $(event.target).data('item_objectid');
    if (!this.selected_giftbox) {
      alert(constant.MSG_ERR_GIFTBOX_SELECT);
      return;
    }
    this.sv_giftbox.addGiftboxItem(this.selected_giftbox, item_objectID).subscribe(data => {
      if (!data.result) { // 成功の場合メッセージは出さない。（商品がギフトセットエリアに表示されるだけ）
        alert(data.msg);
      }
    });
  }

  public clearSearchCond(): void {
    this.sv_common.clearItemSearch();
    this.category_type = constant.ITEM_CATEGORY;
    this.price_range_type = constant.ITEM_PRICE_RANGE;
    this.category_type.forEach(cate => cate.checked = false);
    this.price_range_type.forEach(price => price.checked = false);

    this.categories = [
      constant.ITEM_CATEGORY_TYPE.KEEPSAKE,
      constant.ITEM_CATEGORY_TYPE.CATALOG,
      constant.ITEM_CATEGORY_TYPE.SWEETS,
      constant.ITEM_CATEGORY_TYPE.PLUS_ONE_ITEM,
    ];
    this.price_ranges = [];
    this.keywords = [];
    this.search();
  }

  public detail(item_key: string) {
    this.select_item_key = item_key;
    this.show_item_detail = true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    // this.sv_item.subscription.unsubscribe();
    // this.sv_giftbox.subscription.unsubscribe();
  }

}
