import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withFetch, withJsonpSupport } from '@angular/common/http';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';

// import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
// import { getAuth, provideAuth } from '@angular/fire/auth';
// import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
// import { initializeAppCheck, ReCaptchaEnterpriseProvider, provideAppCheck } from '@angular/fire/app-check';
// import { getFirestore, provideFirestore } from '@angular/fire/firestore';
// import { getFunctions, provideFunctions } from '@angular/fire/functions';
// import { getStorage, provideStorage } from '@angular/fire/storage';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/compat/storage';
import { AngularFireFunctions, REGION } from '@angular/fire/compat/functions';
import { LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
registerLocaleData(localeJa);
import { DateAdapter } from '@angular/material/core';
import { JpDateAdapter } from './util/adapter/jp-date-adapter';

import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    importProvidersFrom(
      AngularFireModule.initializeApp(environment.firebaseConfig),
      AngularFireAuthModule,
      AngularFirestoreModule
    ),
    AngularFirestore,
    AngularFireFunctions,
    { provide: REGION, useValue: 'asia-northeast1' },
    { provide: BUCKET, useValue: environment.firebaseConfig.storageBucket },
    { provide: LOCALE_ID, useValue: 'ja-JP' },
    DatePipe,
    { provide: DateAdapter, useClass: JpDateAdapter }, // Datepickerのカレンダー内の日付表示のカスタマイズ
    provideHttpClient(withFetch()),
    // provideFirebaseApp(() => initializeApp({"projectId":"t-port-dev3","appId":"1:349325289812:web:92e4d1ef61dedf2519c0c1","databaseURL":"https://t-port-dev3.firebaseio.com","storageBucket":"t-port-dev3.appspot.com","apiKey":"AIzaSyAkoQ9etelYuG8pCLc4rIxVzb5aL1NoGlg","authDomain":"t-port-dev3.firebaseapp.com","messagingSenderId":"349325289812","measurementId":"G-RQFE2J1RFP"})),
    // provideAuth(() => getAuth()),
    // provideAnalytics(() => getAnalytics()),
    // ScreenTrackingService,
    // UserTrackingService,
    // provideAppCheck(() => {
    //   // TODO get a reCAPTCHA Enterprise here https://console.cloud.google.com/security/recaptcha?project=_
    //   const provider = new ReCaptchaEnterpriseProvider("6LdD-5wqAAAAALgreUeVycEt1eiYfGQrZymr_imz");
    //   return initializeAppCheck(undefined, { provider, isTokenAutoRefreshEnabled: true });
    // }),
    // provideFirestore(() => getFirestore()),
    // provideFunctions(() => getFunctions()),
    // provideStorage(() => getStorage()),
    provideAnimationsAsync(),
  ]
};
