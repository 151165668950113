import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestoreCollectionGroup } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { Observable, Subject, Subscription, forkJoin, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { GiftboxService } from './giftbox.service';
import { SessionService } from './session.service';
import { ConfigService } from './config.service';

import { Order } from '../models/order';
import { OrderBranch } from '../models/order_branch';
import { OrderedGiftbox } from '../models/ordered_giftbox';
import { OrderGiftbox } from '../models/order_giftbox';
import { OrderItem } from '../models/order_item';
import { OrderGuest } from '../models/order_guest';
import { OrderDataSet } from '../models/order_data_set';
import { Event } from '../models/event';
import { OrderData } from '../models/order_data';
import { ProcessResult } from '../models/process_result';
// import { GiftboxData } from '../models/giftbox_data';
import { GiftboxData2 } from '../models/giftbox_data2';
import { Session } from '../models/session';
import { Giftbox } from '../models/giftbox';
import { SearchCondOrder } from '../models/search_cond_order';
import * as constant from '../models/constant';
import * as rito from '../models/rito';

import moment from 'moment';

declare const debugLog: any;

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private orderCollection!: AngularFirestoreCollection<Order>;
  private orderDocument!: AngularFirestoreDocument<Order>;
  private orderBranch: Observable<OrderBranch[]> = of([]);
  private update_user: {uid: string, name: string} = {uid: '', name: ''};
  private session: Session = new Session();
  private subscription: Subscription = new Subscription();
  private rate = constant.TAX_RATE;
  private Math = Math;

  constructor(
    private firestore: AngularFirestore,
    private sv_giftbox: GiftboxService,
    private sv_session: SessionService,
    private sv_config: ConfigService,
  ) {
    moment.locale("ja", { weekdaysShort: ["日","月","火","水","木","金","土"] });
  }

  /**
   * 更新ユーザー設定
   *
   * @param {Session} session
   * @memberof OrderService
   */
  initialize(session: Session): void {
    if (!session || !session.data) return;
    this.session = session;
    this.update_user = {
      uid: session.data.uid,
      name: session.data.last_name + session.data.first_name
    };
  }

  /**
   * ユーザーの担当の注文情報を取得
   *
   * @param {string} staff_key
   * @returns {Observable<{order_key: string, event_id: string}[]>}
   * @memberof OrderService
   */
  getOrders(staff_key: string | null): Observable<OrderData[]> {
    if (!staff_key) {
      this.orderCollection = this.firestore.collection<Order>('order', ref => ref
        .where('delete_flg', '==', false)
      );
    } else {
      this.orderCollection = this.firestore.collection<Order>('order', ref => ref
        .where('staff_ids', 'array-contains', staff_key)
        .where('delete_flg', '==', false)
      );
    }
    const result: Observable<OrderData[]> = this.orderCollection.snapshotChanges().pipe(
      map(orders => orders.map(order => {
        return {
          order_id: order.payload.doc.id,
          order_no: order.payload.doc.data().order_no,
          order_created: order.payload.doc.data().created,
          event_id: order.payload.doc.data().event_id,
          event_name: '',
          event_date: null,
          event_status: -1,
          order_limit: null,
          order_branch: null
        }
      }))
    );
    return result.pipe(
      catchError(this.handleError<OrderData[]>('getOrders', []))
    );
  }

  getOrdersOfEvent(event_id: string): Observable<OrderData[]> {
    this.orderCollection = this.firestore.collection<Order>('order', ref => ref
      .where('event_id', '==', event_id)
      .where('delete_flg', '==', false)
    );
    const result: Observable<OrderData[]> = this.orderCollection.snapshotChanges().pipe(
      map(orders => orders.map(order => {
        return {
          order_id: order.payload.doc.id,
          order_no: order.payload.doc.data().order_no,
          order_created: order.payload.doc.data().created,
          event_id: order.payload.doc.data().event_id,
          event_name: '',
          event_date: null,
          event_status: -1,
          order_limit: null,
          order_branch: null
        }
      }))
    );
    return result.pipe(
      catchError(this.handleError<OrderData[]>('getOrdersOfEvent', []))
    );
  }

  /**
   * 最新ブランチ取得
   *  （order_id を指定した時はそのorder_id で絞り込んだ最新branchを取得。未指定の場合は全ての最新branch）
   * @param {string | null} order_id
   * @returns {Observable<OrderData[]>}
   * @memberof OrderService
   */
  getLatestBranches(order_id: string | null): Observable<OrderData[]> {
    let order_branch:  AngularFirestoreCollectionGroup<OrderBranch>;
    if (!order_id) {
      order_branch = this.firestore.collectionGroup<OrderBranch>('branch', ref => ref
        .where('latest', '==', true)
        .where('delete_flg', '==', false)
        .orderBy('order_request_date', 'desc')
        .orderBy('order_date', 'desc')
      );
    } else {
      order_branch = this.firestore.collectionGroup<OrderBranch>('branch', ref => ref
        .where('order_id', '==', order_id)
        .where('latest', '==', true)
        .where('delete_flg', '==', false)
        .orderBy('order_request_date', 'desc')
        .orderBy('order_date', 'desc')
        .limit(1)
      );
    }
    const order_branch_data: Observable<OrderData[]> = order_branch.snapshotChanges().pipe(
      map(branch_docs =>
        branch_docs.map(bdoc => ({
          order_id: bdoc.payload.doc.data().order_id,
          order_no: '',
          order_created: null,
          event_id: '',
          event_name: '',
          event_date: null,
          event_status: -1,
          order_limit: null,
          order_branch: {
            objectID: bdoc.payload.doc.id,
            order_id: bdoc.payload.doc.data().order_id,
            branch_no: bdoc.payload.doc.data().branch_no,
            status: bdoc.payload.doc.data().status,
            latest: bdoc.payload.doc.data().latest,
            price: bdoc.payload.doc.data().price,
            tax_target_price: bdoc.payload.doc.data().tax_target_price,
            reduced_tax_target_price: bdoc.payload.doc.data().reduced_tax_target_price,
            tax: bdoc.payload.doc.data().tax,
            reduced_tax: bdoc.payload.doc.data().reduced_tax,
            unit_price: bdoc.payload.doc.data().unit_price,
            deliv_fee: bdoc.payload.doc.data().deliv_fee,
            deliv_fee_tax: bdoc.payload.doc.data().deliv_fee_tax,
            order_request_date: bdoc.payload.doc.data().order_request_date,
            order_request_user: bdoc.payload.doc.data().order_request_user,
            order_date: bdoc.payload.doc.data().order_date,
            order_user: bdoc.payload.doc.data().order_user,
            order_accept_date: bdoc.payload.doc.data().order_accept_date,
            order_accept_user: bdoc.payload.doc.data().order_accept_user,
            cancel_request_date: bdoc.payload.doc.data().cancel_request_date,
            cancel_request_user: bdoc.payload.doc.data().cancel_request_user,
            order_cancel_date: bdoc.payload.doc.data().order_cancel_date,
            order_cancel_user: bdoc.payload.doc.data().order_cancel_user,
            cancel_date: bdoc.payload.doc.data().cancel_date,
            cancel_user: bdoc.payload.doc.data().cancel_user,
            created: bdoc.payload.doc.data().created,
            updated: bdoc.payload.doc.data().updated,
            delete_flg: bdoc.payload.doc.data().delete_flg
          }
        }))
      )
    );
    return order_branch_data.pipe(
      catchError(this.handleError<OrderData[]>('searchOrdersFromRequestDatas', []))
    );
  }

  /**
   * 発注の降順でブランチIDを取得（会場からタイムレスへの発注）
   *　　
   * @returns {Observable<{branch_id: string, status: number}[]>}
   * @memberof OrderService
   */
  getLatestOrderBranchId(order_key: string): Observable<{branch_id: string, status: number, order_date: Date}[]> {
    const order_branch = this.firestore.doc<Order>(`order/${order_key}`).collection<OrderBranch>('branch', ref => ref
      // .where('status', '>=', constant.ORDER_STATUS.ORDER)
      // .where('status', '<=', constant.ORDER_STATUS.CANCEL_REQUEST)
      .where('latest', '==', false)
      .where('delete_flg', '==', false)
      .where('order_date', '>=', new Date('2000/01/01 00:00:00'))
      .orderBy('order_date', 'desc')
      .orderBy('order_accept_date', 'desc')
    );
    const latest_branch_id: Observable<{branch_id: string, status: number, order_date: Date}[]> = order_branch.snapshotChanges().pipe(
      map(branch_docs =>
        branch_docs.map(bdoc => ({
          branch_id: bdoc.payload.doc.id,
          status: bdoc.payload.doc.data().status,
          order_date: bdoc.payload.doc.data().order_date
        })
      ))
    );
    return latest_branch_id.pipe(
      catchError(this.handleError<{branch_id: string, status: number, order_date: Date}[]>('getLatestOrderBranchId', []))
    );
  }

  /**
   * 発注履歴取得
   *
   * @param {string} order_key
   * @returns {Observable<OrderData[]>}
   * @memberof OrderService
   */
  getOrderHistory(order_key: string): Observable<OrderData[]> {
    const order_branch = this.firestore.doc<Order>(`order/${order_key}`).collection<OrderBranch>('branch', ref => ref
      .where('latest', '==', false)
      .where('delete_flg', '==', false)
      .orderBy('order_request_date', 'desc')
      .orderBy('order_date', 'desc')
    );
    const order_branch_data: Observable<OrderData[]> = order_branch.snapshotChanges().pipe(
      map(branch_docs =>
        branch_docs.map(bdoc => ({
          order_id: bdoc.payload.doc.data().order_id,
          order_no: '',
          order_created: null,
          event_id: '',
          event_name: '',
          event_date: null,
          event_status: -1,
          order_limit: null,
          order_branch: {
            objectID: bdoc.payload.doc.id,
            order_id: bdoc.payload.doc.data().order_id,
            branch_no: bdoc.payload.doc.data().branch_no,
            status: bdoc.payload.doc.data().status,
            latest: bdoc.payload.doc.data().latest,
            price: bdoc.payload.doc.data().price,
            tax_target_price: bdoc.payload.doc.data().tax_target_price,
            reduced_tax_target_price: bdoc.payload.doc.data().reduced_tax_target_price,
            tax: bdoc.payload.doc.data().tax,
            reduced_tax: bdoc.payload.doc.data().reduced_tax,
            unit_price: bdoc.payload.doc.data().unit_price,
            deliv_fee: bdoc.payload.doc.data().deliv_fee,
            deliv_fee_tax: bdoc.payload.doc.data().deliv_fee_tax,
            order_request_date: bdoc.payload.doc.data().order_request_date,
            order_request_user: bdoc.payload.doc.data().order_request_user,
            order_date: bdoc.payload.doc.data().order_date,
            order_user: bdoc.payload.doc.data().order_user,
            order_accept_date: bdoc.payload.doc.data().order_accept_date,
            order_accept_user: bdoc.payload.doc.data().order_accept_user,
            cancel_request_date: bdoc.payload.doc.data().cancel_request_date,
            cancel_request_user: bdoc.payload.doc.data().cancel_request_user,
            order_cancel_date: bdoc.payload.doc.data().order_cancel_date,
            order_cancel_user: bdoc.payload.doc.data().order_cancel_user,
            cancel_date: bdoc.payload.doc.data().cancel_date,
            cancel_user: bdoc.payload.doc.data().cancel_user,
            created: bdoc.payload.doc.data().created,
            updated: bdoc.payload.doc.data().updated,
            delete_flg: bdoc.payload.doc.data().delete_flg
          }
        }))
      )
    );
    return order_branch_data.pipe(
      catchError(this.handleError<OrderData[]>('getOrderHistory', []))
    );
  }

  /**
   * 発注依頼日で検索
   *
   * @param {SearchCondOrder} cond
   * @returns {Observable<OrderData[]>}
   * @memberof OrderService
   */
  searchOrdersFromRequestDatas(cond: SearchCondOrder, latest: boolean): Observable<OrderData[]> {
    const request_date_from = cond.order_request_date_from ? cond.order_request_date_from : ''
    const request_date_to = cond.order_request_date_to ? moment(cond.order_request_date_to.setHours(23, 59, 59, 59)).toDate() : '';

    // AND条件はチェーンでの記述が必要なためパターンごとにクエリを作成
    let order_branch!: AngularFirestoreCollectionGroup<OrderBranch>;
    if (request_date_from && request_date_to) {
      order_branch = this.firestore.collectionGroup<OrderBranch>('branch', ref => ref
        .where('latest', '==', latest)
        .where('order_request_date', '>=', request_date_from)
        .where('order_request_date', '<=', request_date_to)
        .where('delete_flg', '==', false)
        .orderBy('order_request_date', 'desc')
        .orderBy('order_date', 'desc')
      );
    } else if (!request_date_from && !request_date_to){
      order_branch = this.firestore.collectionGroup<OrderBranch>('branch', ref => ref
        .where('latest', '==', latest)
        .where('delete_flg', '==', false)
        .orderBy('order_request_date', 'desc')
        .orderBy('order_date', 'desc')
      );
    } else if (!request_date_from) {
      order_branch = this.firestore.collectionGroup<OrderBranch>('branch', ref => ref
        .where('latest', '==', latest)
        .where('order_request_date', '<=', request_date_to)
        .where('delete_flg', '==', false)
        .orderBy('order_request_date', 'desc')
        .orderBy('order_date', 'desc')
      );
    } else if (!request_date_to) {
      order_branch = this.firestore.collectionGroup<OrderBranch>('branch', ref => ref
        .where('latest', '==', latest)
        .where('order_request_date', '>=', request_date_from)
        .where('delete_flg', '==', false)
        .orderBy('order_request_date', 'desc')
        .orderBy('order_date', 'desc')
      );
    }

    const order_branch_data: Observable<OrderData[]> = order_branch.snapshotChanges().pipe(
      map(branch_docs =>
        branch_docs.map(bdoc => ({
          order_id: bdoc.payload.doc.data().order_id,
          order_no: '',
          order_created: null,
          event_id: '',
          event_name: '',
          event_date: null,
          event_status: -1,
          order_limit: null,
          order_branch: {
            objectID: bdoc.payload.doc.id,
            order_id: bdoc.payload.doc.data().order_id,
            branch_no: bdoc.payload.doc.data().branch_no,
            status: bdoc.payload.doc.data().status,
            latest: bdoc.payload.doc.data().latest,
            price: bdoc.payload.doc.data().price,
            tax_target_price: bdoc.payload.doc.data().tax_target_price,
            reduced_tax_target_price: bdoc.payload.doc.data().reduced_tax_target_price,
            tax: bdoc.payload.doc.data().tax,
            reduced_tax: bdoc.payload.doc.data().reduced_tax,
            unit_price: bdoc.payload.doc.data().unit_price,
            deliv_fee: bdoc.payload.doc.data().deliv_fee,
            deliv_fee_tax: bdoc.payload.doc.data().deliv_fee_tax,
            order_request_date: bdoc.payload.doc.data().order_request_date,
            order_request_user: bdoc.payload.doc.data().order_request_user,
            order_date: bdoc.payload.doc.data().order_date,
            order_user: bdoc.payload.doc.data().order_user,
            order_accept_date: bdoc.payload.doc.data().order_accept_date,
            order_accept_user: bdoc.payload.doc.data().order_accept_user,
            cancel_request_date: bdoc.payload.doc.data().cancel_request_date,
            cancel_request_user: bdoc.payload.doc.data().cancel_request_user,
            order_cancel_date: bdoc.payload.doc.data().order_cancel_date,
            order_cancel_user: bdoc.payload.doc.data().order_cancel_user,
            cancel_date: bdoc.payload.doc.data().cancel_date,
            cancel_user: bdoc.payload.doc.data().cancel_user,
            created: bdoc.payload.doc.data().created,
            updated: bdoc.payload.doc.data().updated,
            delete_flg: bdoc.payload.doc.data().delete_flg
          }
        }))
      )
    );
    return order_branch_data.pipe(
      catchError(this.handleError<OrderData[]>('searchOrdersFromRequestDatas', []))
    );
  }

  /**
   * 発注日で検索
   *
   * @param {SearchCondOrder} cond
   * @returns {Observable<OrderData[]>}
   * @memberof OrderService
   */
  searchOrdersFromOrderDate(cond: SearchCondOrder, latest: boolean): Observable<OrderData[]> {
    const order_date_from = cond.order_date_from ? cond.order_date_from : '';
    const order_date_to = cond.order_date_to ? moment(cond.order_date_to.setHours(23, 59, 59, 59)).toDate() : '';

    //  AND条件はチェーンでの記述が必要なためパターンごとにクエリを作成
    let order_branch!: AngularFirestoreCollectionGroup<OrderBranch>;
    if (order_date_from && order_date_to) {
      order_branch = this.firestore.collectionGroup<OrderBranch>('branch', ref => ref
        .where('latest', '==', latest)
        .where('order_date', '>=', order_date_from)
        .where('order_date', '<=', order_date_to)
        .where('delete_flg', '==', false)
        .orderBy('order_date', 'desc')
        .orderBy('order_request_date', 'desc')
      );
    } else if (!order_date_from && !order_date_to){
      order_branch = this.firestore.collectionGroup<OrderBranch>('branch', ref => ref
        .where('latest', '==', latest)
        .where('delete_flg', '==', false)
        .orderBy('order_request_date', 'desc')
        .orderBy('order_date', 'desc')
      );
    } else if (!order_date_from) {
      order_branch = this.firestore.collectionGroup<OrderBranch>('branch', ref => ref
        .where('latest', '==', latest)
        .where('order_date', '<=', order_date_to)
        .where('delete_flg', '==', false)
        .orderBy('order_date', 'desc')
        .orderBy('order_request_date', 'desc')
      );
    } else if (!order_date_to) {
      order_branch = this.firestore.collectionGroup<OrderBranch>('branch', ref => ref
        .where('latest', '==', latest)
        .where('order_date', '>=', order_date_from)
        .where('delete_flg', '==', false)
        .orderBy('order_date', 'desc')
        .orderBy('order_request_date', 'desc')
      );
    }

    const order_branch_data: Observable<OrderData[]> = order_branch.snapshotChanges().pipe(
      map(branch_docs =>
        branch_docs.map(bdoc => ({
          order_id: bdoc.payload.doc.data().order_id,
          order_no: '',
          order_created: null,
          event_id: '',
          event_name: '',
          event_date: null,
          event_status: -1,
          order_limit: null,
          order_branch: {
            objectID: bdoc.payload.doc.id,
            order_id: bdoc.payload.doc.data().order_id,
            branch_no: bdoc.payload.doc.data().branch_no,
            status: bdoc.payload.doc.data().status,
            latest: bdoc.payload.doc.data().latest,
            price: bdoc.payload.doc.data().price,
            tax_target_price: bdoc.payload.doc.data().tax_target_price,
            reduced_tax_target_price: bdoc.payload.doc.data().reduced_tax_target_price,
            tax: bdoc.payload.doc.data().tax,
            reduced_tax: bdoc.payload.doc.data().reduced_tax,
            unit_price: bdoc.payload.doc.data().unit_price,
            deliv_fee: bdoc.payload.doc.data().deliv_fee,
            deliv_fee_tax: bdoc.payload.doc.data().deliv_fee_tax,
            order_request_date: bdoc.payload.doc.data().order_request_date,
            order_request_user: bdoc.payload.doc.data().order_request_user,
            order_date: bdoc.payload.doc.data().order_date,
            order_user: bdoc.payload.doc.data().order_user,
            order_accept_date: bdoc.payload.doc.data().order_accept_date,
            order_accept_user: bdoc.payload.doc.data().order_accept_user,
            cancel_request_date: bdoc.payload.doc.data().cancel_request_date,
            cancel_request_user: bdoc.payload.doc.data().cancel_request_user,
            order_cancel_date: bdoc.payload.doc.data().order_cancel_date,
            order_cancel_user: bdoc.payload.doc.data().order_cancel_user,
            cancel_date: bdoc.payload.doc.data().cancel_date,
            cancel_user: bdoc.payload.doc.data().cancel_user,
            created: bdoc.payload.doc.data().created,
            updated: bdoc.payload.doc.data().updated,
            delete_flg: bdoc.payload.doc.data().delete_flg
          }
        }))
      )
    );
    return order_branch_data.pipe(
      catchError(this.handleError<OrderData[]>('searchOrdersFromOrderDate', []))
    );
  }

  /**
   * 注文情報を取得
   *
   * @param {string} order_key
   * @returns {Observable<OrderData[]>}
   * @memberof OrderService
   */
  getOrderData(order_key: string): Observable<OrderData[]> {
    let order_branch_data: Observable<OrderData[]>;
    const order_branch = this.firestore.doc<Order>(`order/${order_key}`).collection<OrderBranch>('branch', ref => ref
      .where('latest', '==', true)
      .where('delete_flg', '==', false)
    );
    order_branch_data = order_branch.snapshotChanges().pipe(
      map(branch_docs =>
        branch_docs.map(bdoc => ({
          order_id: order_key,
          order_no: '',
          order_created: null,
          event_id: '',
          event_name: '',
          event_date: null,
          event_status: -1,
          order_limit: null,
          order_branch: {
            objectID: bdoc.payload.doc.id,
            order_id: bdoc.payload.doc.data().order_id,
            branch_no: bdoc.payload.doc.data().branch_no,
            status: bdoc.payload.doc.data().status,
            latest: bdoc.payload.doc.data().latest,
            price: bdoc.payload.doc.data().price,
            tax_target_price: bdoc.payload.doc.data().tax_target_price,
            reduced_tax_target_price: bdoc.payload.doc.data().reduced_tax_target_price,
            tax: bdoc.payload.doc.data().tax,
            reduced_tax: bdoc.payload.doc.data().reduced_tax,
            unit_price: bdoc.payload.doc.data().unit_price,
            deliv_fee: bdoc.payload.doc.data().deliv_fee,
            deliv_fee_tax: bdoc.payload.doc.data().deliv_fee_tax,
            order_request_date: bdoc.payload.doc.data().order_request_date,
            order_request_user: bdoc.payload.doc.data().order_request_user,
            order_date: bdoc.payload.doc.data().order_date,
            order_user: bdoc.payload.doc.data().order_user,
            order_accept_date: bdoc.payload.doc.data().order_accept_date,
            order_accept_user: bdoc.payload.doc.data().order_accept_user,
            cancel_request_date: bdoc.payload.doc.data().cancel_request_date,
            cancel_request_user: bdoc.payload.doc.data().cancel_request_user,
            order_cancel_date: bdoc.payload.doc.data().order_cancel_date,
            order_cancel_user: bdoc.payload.doc.data().order_cancel_user,
            cancel_date: bdoc.payload.doc.data().cancel_date,
            cancel_user: bdoc.payload.doc.data().cancel_user,
            created: bdoc.payload.doc.data().created,
            updated: bdoc.payload.doc.data().updated,
            delete_flg: bdoc.payload.doc.data().delete_flg
          }
        }))
      )
    );
    return order_branch_data.pipe(
      catchError(this.handleError<OrderData[]>('getOrders', []))
    );
  }

  /**
   * キャンセル依頼
   *
   * @param {string} giftbox_key
   * @param {string} order_key
   * @param {string} branch_key
   * @returns {Observable<ProcessResult>}
   * @memberof OrderService
   */
  cancelRequest(giftbox_key: string, order_key: string, branch_key: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const branch_ref = this.firestore.doc<Order>(`order/${order_key}`).collection<OrderBranch>('branch').doc(branch_key).ref;
    const giftbox_ref = this.firestore.doc<Giftbox>(`giftbox/${giftbox_key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await Promise.all([branch_ref, giftbox_ref])
      .then(() => {
        transaction.update(branch_ref, {
          'status': constant.ORDER_STATUS.CANCEL_REQUEST,
          'cancel_request_date': new Date(),
          'cancel_request_user': this.update_user,
          'updated': new Date(),
          'update_user': this.update_user
        });
        transaction.update(giftbox_ref, {
          'status': constant.ORDER_STATUS.CANCEL_REQUEST,
          'updated': new Date(),
          'update_user': this.update_user
        });
      })
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * キャンセル発注
   *
   * @param {string} giftbox_key
   * @param {string} order_key
   * @param {string} branch_key
   * @returns {Observable<ProcessResult>}
   * @memberof OrderService
   */
  cancelOrder(giftbox_key: string, order_key: string, branch_key: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const branch_ref = this.firestore.doc<Order>(`order/${order_key}`).collection<OrderBranch>('branch').doc(branch_key).ref;
    const giftbox_ref = this.firestore.doc<Giftbox>(`giftbox/${giftbox_key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await Promise.all([branch_ref, giftbox_ref])
      .then(() => {
        transaction.update(branch_ref, {
          'status': constant.ORDER_STATUS.CANCEL_ORDER,
          'order_cancel_date': new Date(),
          'order_cancel_user': this.update_user,
          'updated': new Date(),
          'update_user': this.update_user
        });
        transaction.update(giftbox_ref, {
          'status': constant.ORDER_STATUS.CANCEL_ORDER,
          'updated': new Date(),
          'update_user': this.update_user
        });
      })
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * 注文キャンセル
   *　（顧客画面ギフトセットより呼び出し）
   * @param {string} giftbox_key
   * @param {string} order_key
   * @param {string} branch_key
   * @returns {Observable<ProcessResult>}
   * @memberof OrderService
   */
  cancel(giftbox_key: string, order_key: string, branch_key: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const branch_ref = this.firestore.doc<Order>(`order/${order_key}`).collection<OrderBranch>('branch').doc(branch_key).ref;
    const giftbox_ref = this.firestore.doc<Giftbox>(`giftbox/${giftbox_key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await Promise.all([branch_ref, giftbox_ref])
      .then(() => {
        transaction.update(branch_ref, {
          'status': constant.ORDER_STATUS.CANCEL,
          'cancel_date': new Date(),
          'cancel_user': this.update_user,
          'updated': new Date(),
          'update_user': this.update_user
        });
        transaction.update(giftbox_ref, {
          'status': constant.ORDER_STATUS.CANCEL,
          'updated': new Date(),
          'update_user': this.update_user
        });
      })
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * キャンセル発注・キャンセル
   *　（挙式を無効に設定したタイミングで呼ばれる。その挙式の注文が全てキャンセル、またはキャンセル発注となる）
   * @param {string} order_key
   * @param {string} branch_key
   * @param {number} status
   * @param {OrderedGiftbox[]} gbs
   * @returns {Observable<ProcessResult>}
   * @memberof OrderService
   */
  cancelForEventUnsubscribed(order_key: string, branch_key: string, status: number, gbs: OrderedGiftbox[]): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();

    const branch_ref = this.firestore.doc(`order/${order_key}`).collection('branch').doc(branch_key).ref;
    let giftbox_refs: firebase.firestore.DocumentReference[] = [];
    // 対象ギフトセットのDocumentReferenceを取得
    gbs.forEach(gb => {
      giftbox_refs.push(this.firestore.collection('giftbox').doc<Giftbox>(`${gb.order_giftbox.objectID}`).ref);
    });

    let new_status: number = status;  // 更新ステータス
    if (
      status == constant.ORDER_STATUS.DEFAULT ||
      status == constant.ORDER_STATUS.REQUEST ||
      status == constant.ORDER_STATUS.REQUEST_AFTER_ORDER ||
      status == constant.ORDER_STATUS.ORDER
    ) {
      new_status = constant.ORDER_STATUS.CANCEL;
    }
    else if (status == constant.ORDER_STATUS.CANCEL_REQUEST) {
      new_status = constant.ORDER_STATUS.CANCEL;
    }
    else if (
      status == constant.ORDER_STATUS.REQUEST_AFTER_ACCEPT ||
      status == constant.ORDER_STATUS.ORDER_AFTER_ACCEPT ||
      status == constant.ORDER_STATUS.ACCEPT
    ) {
      new_status = constant.ORDER_STATUS.CANCEL_ORDER;
    }
    // else {
    //   return;
    // }

    this.firestore.firestore.runTransaction(async transaction => {
      let giftbox_read_task: any[] = [];
      giftbox_refs.forEach(ref => {
        giftbox_read_task.push(transaction.get(ref));
      });
      await Promise.all([branch_ref, ...giftbox_read_task])
      .then(() => {
        if (new_status == constant.ORDER_STATUS.CANCEL) {
          transaction.update(branch_ref, {
            'status': new_status,
            'cancel_date': new Date(),
            'cancel_user': this.update_user,
            'updated': new Date(),
            'update_user': this.update_user
          });
          giftbox_refs.forEach(ref => {
            transaction.update(ref, {
              'status': new_status,
              'updated': new Date(),
              'update_user': this.update_user
            });
          });
        }
        else if (new_status == constant.ORDER_STATUS.CANCEL_ORDER) {
          transaction.update(branch_ref, {
            'status': new_status,
            'order_cancel_date': new Date(),
            'order_cancel_user': this.update_user,
            'cancel_request_date': new Date(),  // 本来キャンセル依頼は顧客操作となるが挙式無効化によるキャンセルの場合はプランナーとなる
            'cancel_request_user': this.update_user, // 本来キャンセル依頼は顧客操作となるが挙式無効化によるキャンセルの場合はプランナーとなる
            'updated': new Date(),
            'update_user': this.update_user
          });
          giftbox_refs.forEach(ref => {
            transaction.update(ref, {
              'status': new_status,
              'updated': new Date(),
              'update_user': this.update_user
            });
          });
        }
      })
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });

    return result;
  }

  /**
   * 最新注文データ詳細取得
   *
   * @param {string} latest_order_key
   * @param {string} latest_branch_key
   * @returns {Observable<OrderedGiftbox[]>}
   * @memberof OrderService
   */
  public getOrderdGiftboxes(latest_order_key: string, latest_branch_key: string): Observable<OrderedGiftbox[]> {
    let ordered_giftboxes: OrderedGiftbox[] = [];

    let data = this.firestore.doc<Order>(`order/${latest_order_key}`).collection('branch').doc<OrderBranch>(latest_branch_key)
      .collection<OrderGiftbox>('giftbox', ref => ref.where('delete_flg', '==', false).orderBy('created', 'desc')).snapshotChanges().pipe(
        map(giftbox_docs => {
          giftbox_docs.map(giftbox_doc => {
            // giftbox
            let giftbox_data: OrderGiftbox = {
              objectID: giftbox_doc.payload.doc.id,
              name: giftbox_doc.payload.doc.data().name,
              price: giftbox_doc.payload.doc.data().price,
              tax_target_price: giftbox_doc.payload.doc.data().tax_target_price,
              reduced_tax_target_price: giftbox_doc.payload.doc.data().reduced_tax_target_price,
              tax: giftbox_doc.payload.doc.data().tax,
              reduced_tax: giftbox_doc.payload.doc.data().reduced_tax,
              deliv_fee: giftbox_doc.payload.doc.data().deliv_fee,
              deliv_fee_tax: giftbox_doc.payload.doc.data().deliv_fee_tax,
              unit_price: giftbox_doc.payload.doc.data().unit_price,
              unit_tax: giftbox_doc.payload.doc.data().unit_tax,
              unit_reduced_tax: giftbox_doc.payload.doc.data().unit_reduced_tax,
              created: new Date(),
              updated: new Date(),
              delete_flg: false
            };
            // item
            let item_data: Observable<OrderItem[]> = this.firestore.doc(giftbox_doc.payload.doc.ref).collection<OrderItem>('item').snapshotChanges().pipe(
              map(item_docs => {
                let order_items: OrderItem[] = [];
                item_docs.map (item_doc=> {
                  let item_obj: OrderItem = {
                    objectID: item_doc.payload.doc.id,
                    beare_code: item_doc.payload.doc.data().beare_code,
                    name: item_doc.payload.doc.data().name,
                    num: item_doc.payload.doc.data().num,
                    price: item_doc.payload.doc.data().price,
                    reduced_tax_flg: item_doc.payload.doc.data().reduced_tax_flg,
                    thumb_s: item_doc.payload.doc.data().thumb_s,
                    brand: item_doc.payload.doc.data().brand,
                    created: new Date(),
                    updated: new Date(),
                    delete_flg: false
                  }
                  order_items.push(item_obj);
                })
                return order_items;
              })
            );
            // guest
            let guest_data: Observable<OrderGuest[]> = this.firestore.doc(giftbox_doc.payload.doc.ref).collection<OrderGuest>('guest').snapshotChanges().pipe(
              map(guest_docs => {
                let order_guests: OrderGuest[] = [];
                guest_docs.map (guest_doc=> {
                  let guest_obj: OrderGuest = {
                    objectID: guest_doc.payload.doc.id,
                    deliv_date: guest_doc.payload.doc.data().deliv_date,
                    deliv_fee: guest_doc.payload.doc.data().deliv_fee,
                    card: guest_doc.payload.doc.data().card,
                    noshi: guest_doc.payload.doc.data().noshi,
                    first_name: guest_doc.payload.doc.data().first_name,
                    last_name: guest_doc.payload.doc.data().last_name,
                    first_kname: guest_doc.payload.doc.data().first_kname,
                    last_kname: guest_doc.payload.doc.data().last_kname,
                    zip: guest_doc.payload.doc.data().zip,
                    addr1: guest_doc.payload.doc.data().addr1,
                    addr2: guest_doc.payload.doc.data().addr2,
                    addr3: guest_doc.payload.doc.data().addr3,
                    addr4: guest_doc.payload.doc.data().addr4,
                    tel: guest_doc.payload.doc.data().tel,
                    created: new Date(),
                    updated: new Date(),
                    delete_flg: false
                  }
                  order_guests.push(guest_obj);
                })
                return order_guests;
              })
            );

            let ordered_giftbox: OrderedGiftbox = {
              order_giftbox: giftbox_data,
              order_items$: item_data,
              order_guests$: guest_data,
              order_items: [],
              order_guests: []
            };
            ordered_giftboxes.push(ordered_giftbox);
          });
          return ordered_giftboxes;
        })
      );

    return data;
  }

  /**
   * 注文データ作成
   *
   * @private
   * @param {firestore.DocumentReference[]} refs // 注文データドキュメント参照先
   * @param {any[]} datas // 注文データ
   * @param {(firestore.DocumentReference[] | null)} branch_other_refs // 注文データ履歴ドキュメント参照先
   * @param {firestore.DocumentReference[]} giftbox_refs // 注文対象ギフトセットドキュメント参照先
   * @param {string} order_key // 最新注文ID（注文対象ギフトセット更新用）
   * @param {string} branch_key // 最新ブランチID（注文対象ギフトセット更新用）
   * @param {number} latest_status // ステータス判定（注文対象ギフトセット更新用）
   * @returns {Observable<boolean>}
   * @memberof OrderService
   */
  private createRequestData(
    refs: firebase.firestore.DocumentReference[],
    datas: any[],
    branch_other_refs: firebase.firestore.DocumentReference[] | null,
    giftbox_refs: firebase.firestore.DocumentReference[],
    order_key: string,
    branch_key: string,
    latest_status: number
  ): Observable<boolean> {

    let result_subject: Subject<boolean> = new Subject();
    let result: Observable<boolean> = result_subject.asObservable();

    this.firestore.firestore.runTransaction(async transaction => {
      // 対象外のbranch
      let b_read_task: any[] = [];
      if (branch_other_refs) {
        branch_other_refs.forEach(bref => {
          b_read_task.push(transaction.get(bref));
        });
      }
      // 対象の注文情報
      let read_task: any[] = [];
      refs.forEach(ref => {
        read_task.push(transaction.get(ref));
      });
      // 対象のギフトセット情報
      let g_read_task = [];
      giftbox_refs.forEach(gref => {
        g_read_task.push(transaction.get(gref));
      });

      await Promise.all([b_read_task, read_task])
      .then(() => {
        // 対象外の注文情報（branch)の最新フラグを全てfalseにする
        if (branch_other_refs) {
          branch_other_refs.forEach(bref => {
            transaction.update(bref, {
              'latest': false
            });
          });
        }
        // 対象の注文情報を登録
        datas.forEach((data, j) => {
          transaction.set(refs[j], data, {merge: true});
        });
        // 対象ギフトセットのステータス、最新注文ID更新
        let status: number = constant.ORDER_STATUS.REQUEST;
        if (latest_status == constant.ORDER_STATUS.REQUEST_AFTER_ORDER || latest_status == constant.ORDER_STATUS.ORDER) {
          status = constant.ORDER_STATUS.REQUEST_AFTER_ORDER;
        } else if (latest_status == constant.ORDER_STATUS.REQUEST_AFTER_ACCEPT || latest_status == constant.ORDER_STATUS.ORDER_AFTER_ACCEPT || latest_status == constant.ORDER_STATUS.ACCEPT) {
          status = constant.ORDER_STATUS.REQUEST_AFTER_ACCEPT;
        }
        giftbox_refs.forEach(gref => {
          transaction.update(gref, {
            'status': status,
            'latest_order': order_key,
            'latest_branch': branch_key,
            'order_updated': new Date(),
            'update_user': this.update_user
          });
        })
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next(true);
      this.subscription.unsubscribe();
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next(false);
      this.subscription.unsubscribe();
    });

    return result;
  }

  /**
   * ステータス更新
   *
   * @private
   * @param {string} order_key
   * @param {string} branch_key
   * @memberof OrderService
   */
  order(order_key: string, branch_key: string, giftbox_ids: string[], latest_status: number): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const branch_ref = this.firestore.doc(`order/${order_key}`).collection('branch').doc(branch_key).ref;
    let giftbox_refs: any[] = [];
    giftbox_ids.forEach(gid => {
      giftbox_refs.push(this.firestore.doc<Giftbox>(`giftbox/${gid}`).ref);
    });

    let status: number = constant.ORDER_STATUS.ORDER;
    if (latest_status <= constant.ORDER_STATUS.REQUEST_AFTER_ORDER) {
      status = constant.ORDER_STATUS.ORDER;
    } else if (latest_status >= constant.ORDER_STATUS.REQUEST_AFTER_ACCEPT) {
      status = constant.ORDER_STATUS.ORDER_AFTER_ACCEPT;
    }

    this.firestore.firestore.runTransaction(async transaction => {
      let giftbox_read_task: any[] = [];
      giftbox_refs.forEach(ref => {
        giftbox_read_task.push(transaction.get(ref));
      });
      await Promise.all([branch_ref, ...giftbox_read_task])
      .then(() => {
        transaction.update(branch_ref, {
          'status': status,
          'order_date': new Date(),
          'order_user': this.update_user,
          'updated': new Date(),
          'update_user': this.update_user
        });
        giftbox_refs.forEach(ref => {
          transaction.update(ref, {
            'status': status,
            'latest_order': order_key,
            'latest_branch': branch_key,
            'updated': new Date(),
            'update_user': this.update_user
          });
        })
      })
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_ORDER_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_ORDER_FAILED, id: null});
    });
    return result;
  }

  /**
   * 最新フラグ更新
   *
   * @private
   * @param {string} order_key
   * @param {string} branch_key
   * @memberof OrderService
   */
  private updateLatest(order_key: string, branch_key: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();

    const branch_ref = this.firestore.collection('order').doc(order_key).collection('branch', ref => ref.where('delete_flg', '==', false)).doc().ref;

    this.firestore.firestore.runTransaction(async transaction =>
      await transaction.get(branch_ref)
      .then(() => {
        transaction.update(branch_ref, {
          'latest': false
        })
      })
    )
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * 注文データの整合性チェック
   *
   * @private
   * @param {OrderDataSet} dataset
   * @returns {boolean}
   * @memberof OrderService
   */
  private paramInvalid(dataset: OrderDataSet): boolean {
    let result: boolean = false;

    const failed_order: boolean = !dataset.order_ref || !dataset.order_data;
    const failed_branch: boolean = !dataset.branch_ref || !dataset.branch_data;
    const failed_giftbox: boolean = !dataset.giftbox_refs.length || !dataset.giftbox_datas.length;
    const failed_item: boolean = !dataset.item_refs.length || !dataset.item_datas.length;
    const failed_guest: boolean = !dataset.guest_refs.length || !dataset.guest_datas.length;

    if (failed_order || failed_branch || failed_giftbox || failed_item || failed_guest) {
      result = true;
    };

    return result;
  }

  /**
   * 注文するギフトセットのデータを取得
   *
   * @private
   * @param {string} event_id
   * @param {string[]} giftbox_ids
   * @returns {Observable<GiftboxData2[]>}
   * @memberof OrderService
   */
  private getGiftboxData(event_id: string, giftbox_ids: string[]): Observable<GiftboxData2[]> {
    let subject: Subject<GiftboxData2[]> = new Subject();
    let result: Observable<GiftboxData2[]> = subject.asObservable();
    let arr: GiftboxData2[] = [];

    giftbox_ids.forEach(async giftbox_id => {
      forkJoin ([
        this.sv_giftbox.getGiftbox(giftbox_id).pipe(take(1)),
        this.sv_giftbox.getItemsOfGiftbox(giftbox_id).pipe(take(1)),
        this.sv_giftbox.getSendlistsOfGiftbox(event_id, giftbox_id).pipe(take(1))
      ]).subscribe (([giftbox, items, guests]) => {
        arr.push({
          giftbox: giftbox,
          giftbox_item: items,
          giftbox_guest: guests
        });
        if (giftbox_ids.length == arr.length) {
          subject.next(arr);
        }
      });
    });
    return result;
  }

  /**
   * 注文No.作成
   *  （年月日時分 + 本日生成されたorderデータの件数 を連結した文字列）※年は下2桁 200305210805:20年3月5日21時08分05件
   * @private
   * @returns {Observable<string>}
   * @memberof OrderService
   */
  private createOrderNo(): Observable<string> {
    let today: Date = moment(new Date().setHours(0,0,0,0)).toDate();
    let now: Date = new Date();
    this.orderCollection = this.firestore.collection<Order>('order', ref => ref
      .where('created', '>=', today)
      .where('created', '<', moment(today).add('d', 1).toDate())
    );
    const result: Observable<string> = this.orderCollection.snapshotChanges().pipe(
      map(orders => moment(now).format('YYMMDDHHmm') + (orders.length + 1))
    );
    return result.pipe(
      catchError(this.handleError<string>('createOrderNo'))
    );
  }

  private countBranch(order_id: string): Observable<number> {
    let result_subject: Subject<number> = new Subject();
    let result = result_subject.asObservable();
    let branch_num: number = 0;

    this.subscription.add(
      this.firestore.doc(`order/${order_id}`).collection('branch').get().subscribe(branchs => {
        branchs.forEach(() => {
          branch_num++;
        });
        result_subject.next(branch_num);
      })
    );
    return result;
  }

  /**
   * 失敗したHttp操作を処理します。
   * アプリを持続させます。
   * @param operation - 失敗した操作の名前
   * @param result - observableな結果として返す任意の値
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: リモート上のロギング基盤にエラーを送信する
      console.error(error); // かわりにconsoleに出力

      // TODO: ユーザーへの開示のためにエラーの変換処理を改善する
      // this.log(`${operation} failed: ${error.message}`);

      // 空の結果を返して、アプリを持続可能にする
      return of(result as T);
    };
  }

}
