<div class="wrapper-login">
	<div class="main-login">
			<h1 class="logo"><img src="assets/img/logo.svg" alt="THE TIMELESS"></h1>
      <h1 class="screen_name">会場管理サイト</h1>

      <div *ngIf="(user | async)?.uid && role; else dispSignIn">
        <app-spinner></app-spinner>
      </div>

      <ng-template #dispSignIn>
        <div class="login-box">
          <div class="about-text">ご登録IDのメールアドレスに<br>パスワード変更のご案内を送信いたします。</div>
          <div class="login_msg">{{login_message}}</div>
          <input id="email" class="id" type="text" placeholder="ID" (keyup.enter)="passwordReset()">
          <button type="button" style="width: 100%; text-align: right; margin-right: 10px; font-size: small;" (click)="clear()">クリア</button>
          <button type="button" class="add-btn" (click)="passwordReset()">パスワードを変更</button>
        </div>
      </ng-template>

      <div class="text_s"><a routerLink="/sign_in">サインインはこちら</a></div>

  </div><!--/main-login-->

	<footer class="footer-login">
		<div class="footer-inner">
			&copy; TIMELESS CO./All Rights Reserved.
		</div>
	</footer><!--/footer-login-->
</div><!--/wrapper-login-->
