import { Component, OnInit, OnDestroy, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { DateAdapter } from '@angular/material/core';
import { JpDateAdapter } from '../../util/adapter/jp-date-adapter';

import { ModalMessageComponent } from '../../common/modal-message/modal-message.component';
import { SpinnerComponent } from '../../common/spinner/spinner.component';

import { EventService } from '../../service/event.service';
import { SessionService } from '../../service/session.service';
import { PlaceService } from '../../service/place.service';
import { CompanyService } from '../../service/company.service';
import { OrderService } from '../../service/order.service';
import { CommonService } from '../../service/common.service';
import { ZipCodeService } from '../../service/zip-code.service';

import { Session } from '../../models/session';
import { Event } from '../../models/event';
import { Mail } from '../../models/mail';
import { InputSelect } from '../../models/input_select';
import * as constant from '../../models/constant';

import { environment } from '../../../environments/environment';

import moment from 'moment';

declare function resetCommonrEvent(): void;
declare function showSimpleMsg(msg: string): void;
declare function showConfirmMsg(msg: string): void;
declare const debugLog: any;

@Component({
  selector: 'app-event-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    OverlayModule,
    ModalMessageComponent,
    SpinnerComponent,
  ],
  providers: [
    { provide: DateAdapter, useClass: JpDateAdapter }
  ],
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.scss']
})
export class EventEditComponent implements OnInit {
  @ViewChild('eventForm', { static: false }) eventForm!: NgForm;
  public session: Session = new Session();
  private is_cross_search: boolean = false;
  private event_id: string = '';
  private event_date: any = null;
  public regist_min_date: Date = new Date(0);
  public regist_max_date: Date = new Date(0);
  private cust_limit: Date = new Date(0);
  private guest_limit: Date = new Date(0);
  public ready: boolean = false;
  public places: InputSelect[] = [];
  public select_place: string = '';
  public members1: InputSelect[] = [];
  public members2: InputSelect[] = [];
  public select_member: string = '';
  public pattern = constant.INPUT_PATTERN;
  private subscription: Subscription = new Subscription();
  public editData: Event = new Event();
  public unsubscribed: boolean = false;
  private cancel_order_targets: {order_key: string, branch_key: string, status: number}[] = [];
  private postalCodeSubscription!: Subscription;
  public company: any; // AoT対策
  public place: any; // AoT対策

  constructor(
    private sv_session: SessionService,
    private sv_event: EventService,
    private sv_place: PlaceService,
    private sv_company: CompanyService,
    private sv_order: OrderService,
    private sv_common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private sv_zipcode: ZipCodeService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {

  }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add (
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.session = session;
          this.sv_event.initialize(session);
          this.is_cross_search = session.data.cross_search_flg;

          this.subscription.add(
            this.route.params.subscribe(params => {
              this.event_id = params['id'];
              this.getEvent();
              // 所属企業・会場あり
              if (this.session.data?.current_company && this.session.data.current_place) {
                this.getPlaces();
              }
              // 所属企業・会場なし（フリープランナー等）
              else {
                this.getMembers();
              }
            })
          );
        }
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント設定
    }
  }

  ngAfterViewInit() {
    // ビューは初期化されているがthis.accountFormが完全な状態でない可能性あり、ngDoCheck()で条件指定の上で再実施。
    this.zipCodeCheck();
  }

  ngDoCheck() {
    if (this.eventForm && !this.postalCodeSubscription) {
      this.zipCodeCheck();
    }
  }

  changeZipCode() {
    this.addressComplement();
  }

  private zipCodeCheck(): void {
    // ビューが完全に初期化された後で`valueChanges`で郵便番号の入力をリアルタイムでチェックし住所補完。
    if (this.eventForm && this.eventForm.controls['zip']) {
      this.postalCodeSubscription = this.eventForm.controls['zip']?.valueChanges.subscribe(value => {
        const postalCodePattern = /^(\d{7}|\d{3}-\d{4})$/;
        if (postalCodePattern.test(value)) {
          // 郵便番号のフォーマットが正しい場合のみ処理実行
          this.addressComplement();
        }
      });
    }
  }

  private addressComplement() {
    // 住所補完
    const postalCode = this.eventForm.controls['zip']?.value;
    if (postalCode && postalCode.length === 7) { // 郵便番号が正しく7桁か確認
      this.sv_zipcode.getAddress(postalCode).subscribe(response => {
        if (response.results && response.results.length > 0) {
          const result = response.results[0];
          this.eventForm.controls['addr1'].patchValue(result.address1);
          this.eventForm.controls['addr2'].patchValue(result.address2);
          this.eventForm.controls['addr3'].patchValue(result.address3);
        } else {
          alert('該当する住所が見つかりませんでした。');
        }
      }, error => {
        debugLog('Error fetching address:', error);
      });
    }
  }

  public back(): void {
    this.location.back();
  }

  private getEvent(): void {
    this.subscription.add(
      this.sv_event.getEvent(this.event_id).subscribe(event => {
        if (!event) return;
        this.editData.objectID = event.objectID;
        this.editData.name = event.name;
        this.editData.date = moment(event.date.toDate()).toDate();
        this.editData.company_id = event.company_id;
        this.editData.company_name = event.company_name;
        this.editData.place_id = event.place_id;
        this.editData.place_name = event.place_name;
        this.editData.staff_ids = event.staff_ids;
        this.editData.memo = event.memo;
        this.editData.cust1_id = event.cust1_id;
        this.editData.cust2_id = event.cust2_id;
        this.editData.status = event.status;
        this.editData.token_guest = {
          id: event.token_guest.id,
          limit: moment(event.token_guest.limit.toDate()).toDate()
        };
        this.guest_limit = moment(event.token_guest.limit.toDate()).toDate(); // ゲスト登録期限のDB値（登録期限の調整時にDB値を使）
        this.editData.token_cust = {
          id: event.token_cust.id,
          limit: moment(event.token_cust.limit.toDate()).toDate()
        };
        this.cust_limit = moment(event.token_cust.limit.toDate()).toDate();　 // 顧客登録期限のDB値（登録期限の調整時にDB値を使用）
        this.editData.transfer_first_name = event.transfer_first_name;
        this.editData.transfer_last_name = event.transfer_last_name;
        this.editData.transfer_first_kname = event.transfer_first_kname;
        this.editData.transfer_last_kname = event.transfer_last_kname;
        this.editData.transfer_zip = event.transfer_zip;
        this.editData.transfer_addr1 = event.transfer_addr1;
        this.editData.transfer_addr2 = event.transfer_addr2;
        this.editData.transfer_addr3 = event.transfer_addr3;
        this.editData.transfer_addr4 = event.transfer_addr4;
        this.editData.transfer_tel = event.transfer_tel;
        this.editData.table_card_zip = event.table_card_zip;
        this.editData.table_card_addr1 = event.table_card_addr1;
        this.editData.table_card_addr2 = event.table_card_addr2;
        this.editData.table_card_addr3 = event.table_card_addr3;
        this.editData.table_card_addr4 = event.table_card_addr4;
        this.editData.table_card_tel = event.table_card_tel;
        this.editData.created = event.created;

        this.unsubscribed = this.editData.status == constant.EVENT_STATUS.UNSUBSCRIBED;
        this.event_date = event.date;

        this.regist_min_date = moment(event.date.toDate()).add(-1, 'y').toDate();
        this.regist_max_date = event.date.toDate();

        this.select_place = event.place_id;
        this.onChangePlace();
        this.ready = true;
      })
    );
  }

  private getPlaces(): void {
    if (!this.session.data) return;
    this.members1 = [];
    this.members2 = [];
    this.subscription.add(
      this.sv_place.getPlaces(this.session.data.current_company).pipe(take(1)).subscribe(places => {
        this.places = [];
        if (this.is_cross_search) {
          places.forEach(place => {
            this.places.push({
              key: place.objectID,
              value: place.name,
              grep_colum: null,
              select: false,
              disabled: false
            });
          });
        }
        else {
          places.forEach(place => {
            if (place.objectID == this.session.data?.current_place) {
              this.places.push({
                key: place.objectID,
                value: place.name,
                grep_colum: null,
                select: false,
                disabled: false
              });
              // return true;
            }
          });
        }
        this.places.unshift({key: '', value: '', grep_colum: null, select: false, disabled: false});
      })
    );
  }

  private getMembers(): void {
    if (!this.session.data) return;
    if (this.session.data.current_place) {
      this.subscription.add(
        this.sv_place.getMembers(this.select_place).subscribe(members => {
          this.members1 = [];
          this.members2 = [];
          members.forEach(member => {
            let mdata = {
              key: member.objectID,
              value: member.last_name + member.first_name,
              grep_colum: '',
              select: false,
              disabled: false
            }
            this.members1.push(mdata);
            this.members2.push(mdata);
          });
          this.members1.unshift({key: '', value: '', grep_colum: null, select: false, disabled: false});
          this.members2.unshift({key: '', value: '', grep_colum: null, select: false, disabled: false});
        })
      );
    }
    else {
      this.members1 = [];
      this.members2 = [];
      let mdata = {
        key: this.session.data.uid,
        value: this.session.data.last_name + this.session.data.first_name,
        grep_colum: '',
        select: false,
        disabled: false
      };
      this.members1.push(mdata);
      this.members2.push(mdata);
    }
  }

  public onChangePlace(): void {
    if (!this.session.data) return;
    if (this.session.data.current_place && !this.select_place) {
      this.members1 = [];
      this.members2 = [];
    } else {
      this.getMembers();
    }
  }

  public setYourself(): void {
    if (!this.session.data) return;
    if (this.session.data.current_place) {
      this.editData.place_id = this.session.data.current_place;
      this.select_place = this.session.data.current_place;
      this.onChangePlace();
    }
    setTimeout(() => {
      if (this.session.data) {
        this.editData.staff_ids[0] = this.session.data.uid;
        this.select_member = this.session.data.uid;
      }
    }, 100);
  }

  public onChangeStaff(type: number) {
    if (type == 1) {
      this.members2.forEach((member2, i) => {
        if (member2.key == this.select_member) {
          this.members2[i].disabled = true;
        } else {
          this.members2[i].disabled = false;
        }
      });
    } else if (type == 2) {
      this.members1.forEach((member1, j) => {
        if (member1.key == this.select_member) {
          this.members1[j].disabled = true;
        } else {
          this.members1[j].disabled = false;
        }
      });
    }
    if (this.editData.staff_ids[0] == this.editData.staff_ids[1]) {
      this.editData.staff_ids[1] = '';
    }
  }

  public save() {
    this.ready = false;
    if (this.unsubscribed) {
      this.editData.status = constant.EVENT_STATUS.UNSUBSCRIBED;
    }
    this.subscription.add(
      this.sv_event.save(this.editData.objectID, this.editData).subscribe(data => {
        if (data.result) {
          if (this.unsubscribed && this.cancel_order_targets && this.cancel_order_targets.length > 0) {
            this.cancelAll();
          } else {
            this.ready = true;
            showSimpleMsg(data.msg as string);
          }
        } else {
          this.ready = true;
          debugLog(data.msg);
        }
      })
    );
  }

  public onModalSimpleMsgEvent(event: boolean) {
    if (event) {
      this.router.navigate([`/event-detail/${this.editData.objectID}`], {relativeTo: this.route});
    }
  }

  public setRegistDate(event: any): void {
    // 挙式日の入力値変更に合わせて登録期限を調整
    if (moment(this.editData.token_cust.limit).isAfter(moment(this.editData.date), 'day')) {
      this.editData.token_cust.limit = moment(this.editData.date).toDate();
    } else {
      this.editData.token_cust.limit = moment(this.cust_limit).toDate();
    }
    if (moment(this.editData.token_guest.limit).isAfter(moment(this.editData.date), 'day')) {
      this.editData.token_guest.limit = moment(this.editData.date).toDate();
    } else {
      this.editData.token_guest.limit = moment(this.guest_limit).toDate();
    }
    this.regist_min_date = moment(this.editData.date).add(-1, 'y').toDate();
    this.regist_max_date = this.editData.date;
  }

  public setUnsubscribed(): void {
    this.ready = false;
    // 無効に更新時
    if (this.unsubscribed) {
      this.subscription.add(
        this.sv_order.getOrdersOfEvent(this.event_id).subscribe(orders => { // この挙式の注文を取得
          this.cancel_order_targets = [];
          if (orders.length) {
            // 処理中の注文が1件でもあったら確認アラート
            orders.forEach((order, i) => {
              setTimeout(() => {
                this.sv_order.getOrderData(order.order_id).subscribe(data => { // 注文の最新ブランチを取得
                  if (data.length) {
                    if (data[0].order_branch!.status >= constant.ORDER_STATUS.REQUEST && data[0].order_branch!.status <= constant.ORDER_STATUS.CANCEL_REQUEST) {
                      this.cancel_order_targets[i] = {order_key: order.order_id, branch_key: data[0].order_branch!.objectID, status: data[0].order_branch!.status};
                    }
                    if (i == orders.length -1) {
                      if (this.cancel_order_targets && this.cancel_order_targets.length > 0) {
                        this.ready = true;
                        showConfirmMsg(constant.MSG_CONFIRM_EVENT_UNSUBSCRIBE);
                      } else {
                        this.ready = true;
                        this.unsubscribed = true;
                      }
                    }
                  }
                });
              }, i * 500);
            });
          } else {
            this.ready = true;
            this.unsubscribed = true;
          }
        })
      );
    }
    // 有効に更新時
    else {
      this.sv_order.getOrdersOfEvent(this.event_id).subscribe(orders => {
        if (orders.length) {
          this.editData.status = constant.EVENT_STATUS.ORDERED;  // 初回注文済み
        }
        else {
          if (this.editData.cust1_id && this.editData.cust2_id) {
            this.editData.status = constant.EVENT_STATUS.REGISTED;  // 顧客登録済み
          } else if (!this.editData.cust1_id && !this.editData.cust2_id) {
            this.editData.status = constant.EVENT_STATUS.DEFAULT; // デフォルト（顧客未登録）
          } else {
            this.editData.status = constant.EVENT_STATUS.REGISTING; // 顧客登録中
          }
        }
        this.ready = true;
        this.unsubscribed = false;
      })
    }
  }

  public onModalConfirmMsgEvent(msg: boolean) {
    if (msg) {
      this.unsubscribed = true;
    } else {
      this.unsubscribed = false;
    }
  }

  private cancelAll(): void {
    this.ready = false;
    let res_order_cancel_cnt = 0; // キャンセル発注の処理数
    let failed_count = 0;

    this.cancel_order_targets.forEach((data, i) => {
      setTimeout(() => {
        this.subscription.add(
          this.sv_order.getOrderdGiftboxes(data.order_key, data.branch_key).subscribe(gbs => {
            if (gbs) {
              this.sv_order.cancelForEventUnsubscribed(data.order_key, data.branch_key, data.status, gbs).subscribe(res => {
                if (!res.result) failed_count++;
                if (res.result &&
                    ( data.status == constant.ORDER_STATUS.REQUEST_AFTER_ORDER ||
                      data.status == constant.ORDER_STATUS.REQUEST_AFTER_ACCEPT ||
                      data.status == constant.ORDER_STATUS.ORDER ||
                      data.status == constant.ORDER_STATUS.ORDER_AFTER_ACCEPT ||
                      data.status == constant.ORDER_STATUS.ACCEPT)
                ) {
                  res_order_cancel_cnt++;
                }
                if (i == this.cancel_order_targets.length -1) {
                  if (res_order_cancel_cnt > 0) { // ギフトセンターにキャンセル発注の通知メール
                    if (this.session.data) {
                      this.sv_place.getPlace(this.session.data.current_company, this.session.data.current_place).subscribe(place => {
                        if (place) {
                          this.sendMail(place.name);
                        } else {
                          this.sv_company.getCompany(this.session.data!.current_company).subscribe(company => {
                            this.sendMail(company.name); // フリープランナー向け
                          })
                        }
                      });
                    }
                  }
                  if (failed_count == 0) {
                    showSimpleMsg(constant.MSG_UPDATE_SUCCESSED);
                  }
                  this.ready = true;
                }
              })
            }
          })
        );
      }, i * 500);
    })

  }

  private sendMail(place_name: string): void {
    if (!this.session.data) return;
    let event_date: string = moment(this.event_date).format('YYYY年MM月DD日(ddd)');
    let event_info: string = `挙式日：${event_date}<br> 会場名：${place_name}<br> 挙式名：${this.editData.name}<br> ご担当者：${this.session.data.last_name} ${this.session.data.first_name}様<br>`;
    let subject: string = constant.MAIL_SUBJECT_REORDER;
    let title: string = constant.MAIL_SUBJECT_REORDER;
    let body: string = constant.MAIL_BODY_REORDER;
    let mail: Mail = {
      subject: subject,
      title: title,
      name: 'タイムレスギフトセンターご担当者',
      email: environment.GIFT_CENTER,
      message: body + "<p>" + event_info,
      bcc: ''
    };
    this.subscription.add(
      this.sv_common.sendMail(mail).subscribe(send => {
        debugLog(send.msg);
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.postalCodeSubscription) {
      this.postalCodeSubscription.unsubscribe();
    }
  }

}
