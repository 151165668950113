export interface IPlace {
  objectID: string;
  name: string;
  kname: string;
  zip: string;
  addr1: string;
  addr2: string;
  addr3: string;
  addr4: string;
  tel: string;
  fax: string;
  email: string;
  token: string;
  item_attention_vol_use: boolean; // 新商品メッセージ可否
  item_attention_prev_vol_use: boolean; // 旧商品メッセージ可否
  vol_base_date: any; // 会場別の新商品表示の基準挙式日（※全体の基準日はconfigのitem_attention_adjustで設定）
  prev_vol_base_date: any; // 会場別の旧商品表示の基準挙式日（※全体の基準日はconfigのitem_attention_adjustで設定）
  vol: number; // 会場別の最新Vol_no（※全体の最新Vol_noはconfigのitem_attention_adjustで設定）
  prev_vol: number; // 会場別の前回Vol_no（※全体の前回Vol_noはconfigのitem_attention_adjustで設定）
  place_tax_rate: { // 会場独自の消費税率
    rule: boolean, // true:適用する、false:適用しない（共通税率8%/10%が適用される）
    rate: number | null  // 適用する場合の税率
  };
  created: Date;
  updated: Date;
  updated_user: {
    uid: string;
    name: string;
  };
  delete_flg: boolean;
}
export class Place implements IPlace {
  objectID: string = '';
  name: string = '';
  kname: string = '';
  zip: string = '';
  addr1: string = '';
  addr2: string = '';
  addr3: string = '';
  addr4: string = '';
  tel: string = '';
  fax: string = '';
  email: string = '';
  token: string = '';
  item_attention_vol_use: boolean = true;
  item_attention_prev_vol_use: boolean = true;
  vol_base_date: any = null;
  prev_vol_base_date: any = null;
  vol: number = 0;
  prev_vol: number = 0;
  place_tax_rate: {
    rule: boolean,
    rate: number
  } = {rule: false, rate: 0}
  created: Date = new Date();
  updated: Date = new Date();
  updated_user: {
    uid: string;
    name: string;
  } = {uid: '', name: ''}
  delete_flg: boolean = false;
}
