[
  {
    "name" : "親族",
    "created" : "2019/07/30 00:00:00",
    "updated" : "2019/07/30 00:00:00",
    "update_user" : {
      "uid" : "0JV6EwCTnOWFWuXlaFZimT7XG3l1",
      "name" : "システム"
    },
    "delete_flg" : false
  },
  {
    "name" : "友人",
    "created" : "2019/07/30 00:00:00",
    "updated" : "2019/07/30 00:00:00",
    "update_user" : {
      "uid" : "0JV6EwCTnOWFWuXlaFZimT7XG3l1",
      "name" : "システム"
    },
    "delete_flg" : false
  },
  {
    "name" : "会社",
    "created" : "2019/07/30 00:00:00",
    "updated" : "2019/07/30 00:00:00",
    "update_user" : {
      "uid" : "0JV6EwCTnOWFWuXlaFZimT7XG3l1",
      "name" : "システム"
    },
    "delete_flg" : false
  }
]