import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable, of, Subject} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SessionService } from './session.service';

import { Session } from '../models/session';
import { Company } from '../models/company';
import { Place } from '../models/place';
import { Account } from '../models/account';
import { ProcessResult } from '../models/process_result';
import * as constant from '../models/constant';

declare const debugLog: any;

@Injectable({
  providedIn: 'root'
})
export class PlaceService {
  private companyDocument!: AngularFirestoreDocument<Company>;
  private placeCollection!: AngularFirestoreCollection<Place>;
  private accountCollection!: AngularFirestoreCollection<Account>;
  private place: Observable<Place | null> = of(null);
  private places: Observable<Place[]> = of([]);
  private accounts: Observable<Account[]> = of([]);
  private session: Session = new Session();
  private updated_user: {uid: string, name: string} = {uid: '', name: ''};

  constructor(
    private firestore: AngularFirestore,
    private sv_session: SessionService,
  ) {

  }

  /**
   * 更新ユーザー設定
   *
   * @param {Session} session
   * @memberof GuestService
   */
  initialize(session: Session): void {
    if (!session || !session.data) return;
    this.session = session;
    this.updated_user = {
      uid: session.data.uid,
      name: session.data.last_name + session.data.first_name
    };
  }

  /**
   * 会場を取得
   *
   * @param {string} company_key
   * @param {string} place_key
   * @returns {Observable<Place | null>}
   * @memberof PlaceService
   */
  getPlace(company_key: string, place_key: string): Observable<Place | null> {
    this.companyDocument = this.firestore.doc<Company>(`company/${company_key}`);
    this.place = this.companyDocument.collection<Place>('place').doc<Place>(place_key).snapshotChanges().pipe(
      map(doc => {
        if (!doc.payload.exists) {
          return null;
        }
        let obj = {
          objectID: doc.payload.id,
          name: doc.payload.data().name,
          kname: doc.payload.data().kname,
          zip: doc.payload.data().zip,
          addr1: doc.payload.data().addr1,
          addr2: doc.payload.data().addr2,
          addr3: doc.payload.data().addr3,
          addr4: doc.payload.data().addr4,
          tel: doc.payload.data().tel,
          fax: doc.payload.data().fax,
          email: doc.payload.data().email,
          token: doc.payload.data().token,
          item_attention_vol_use: doc.payload.data().item_attention_vol_use,
          item_attention_prev_vol_use: doc.payload.data().item_attention_prev_vol_use,
          vol_base_date: doc.payload.data().vol_base_date,
          prev_vol_base_date: doc.payload.data().prev_vol_base_date,
          vol: doc.payload.data().vol,
          prev_vol: doc.payload.data().prev_vol,
          place_tax_rate: doc.payload.data().place_tax_rate,
          created: doc.payload.data().created,
          updated: doc.payload.data().updated,
          updated_user: doc.payload.data().updated_user,
          delete_flg: doc.payload.data().delete_flg
        };
        return obj;
      })
    );
    return this.place.pipe(
      catchError(this.handleError<Place | null>('getPlaces', null))
    );
  }

  /**
   * 企業の会場一覧
   *
   * @param {string} company_key
   * @returns {Observable<Place[]>}
   * @memberof PlaceService
   */
  getPlaces(company_key: string): Observable<Place[]> {
    this.placeCollection = this.firestore.doc<Company>(`company/${company_key}`).collection<Place>('place', ref => ref
      .where('delete_flg', '==', false)
      .orderBy('kname')
    );
    this.places = this.placeCollection.snapshotChanges().pipe(
      map(docs =>
        docs.map(doc => ({
          objectID: doc.payload.doc.id,
          name: doc.payload.doc.data().name,
          kname: doc.payload.doc.data().kname,
          zip: doc.payload.doc.data().zip,
          addr1: doc.payload.doc.data().addr1,
          addr2: doc.payload.doc.data().addr2,
          addr3: doc.payload.doc.data().addr3,
          addr4: doc.payload.doc.data().addr4,
          tel: doc.payload.doc.data().tel,
          fax: doc.payload.doc.data().fax,
          email: doc.payload.doc.data().email,
          token: doc.payload.doc.data().token,
          item_attention_vol_use: doc.payload.doc.data().item_attention_vol_use,
          item_attention_prev_vol_use: doc.payload.doc.data().item_attention_prev_vol_use,
          vol_base_date: doc.payload.doc.data().vol_base_date,
          prev_vol_base_date: doc.payload.doc.data().prev_vol_base_date,
          vol: doc.payload.doc.data().vol,
          prev_vol: doc.payload.doc.data().prev_vol,
          place_tax_rate: doc.payload.doc.data().place_tax_rate,
          created: doc.payload.doc.data().created,
          updated: doc.payload.doc.data().updated,
          updated_user: doc.payload.doc.data().updated_user,
          delete_flg: doc.payload.doc.data().delete_flg
      })))
    );
    return this.places.pipe(
      catchError(this.handleError<Place[]>('getPlaces', []))
    );
  }

  /**
   * 会場のメンバーを取得
   *
   * @param {string} place_key
   * @returns {Observable<Account[]>}
   * @memberof PlaceService
   */
  getMembers(place_key: string): Observable<Account[]> {
    this.accountCollection = this.firestore.collection<Account>('account', ref => ref
      .where('place', 'array-contains', place_key)
      .where('delete_flg', '==', false)
      .orderBy('last_kname')
      .orderBy('first_kname')
    );
    this.accounts = this.accountCollection.snapshotChanges().pipe(
      map(docs =>
        docs.map(doc => ({
          objectID: doc.payload.doc.id,
          email: doc.payload.doc.data().email,
          default_password: '',
          event_id: doc.payload.doc.data().event_id,
          first_name: doc.payload.doc.data().first_name,
          last_name: doc.payload.doc.data().last_name,
          first_kname: doc.payload.doc.data().first_kname,
          last_kname: doc.payload.doc.data().last_kname,
          zip: doc.payload.doc.data().zip,
          addr1: doc.payload.doc.data().addr1,
          addr2: doc.payload.doc.data().addr2,
          addr3: doc.payload.doc.data().addr3,
          addr4: doc.payload.doc.data().addr4,
          tel: doc.payload.doc.data().tel,
          favorite_items: doc.payload.doc.data().favorite_items,
          company: doc.payload.doc.data().company,
          place:doc.payload.doc.data().place,
          current_company: doc.payload.doc.data().current_company,
          current_place: doc.payload.doc.data().current_place,
          info: doc.payload.doc.data().info,
          role: doc.payload.doc.data().role,
          current_role: doc.payload.doc.data().current_role,
          cust_type: doc.payload.doc.data().cust_type,
          manager_flg: doc.payload.doc.data().manager_flg,
          cross_search_flg: doc.payload.doc.data().cross_search_flg,
          status: doc.payload.doc.data().status,
          created: doc.payload.doc.data().created,
          updated: doc.payload.doc.data().updated,
          updated_user: doc.payload.doc.data().updated_user,
          delete_flg: doc.payload.doc.data().delete_flg
      })))
    );
    return this.accounts.pipe(
      catchError(this.handleError<Account[]>('getMembers', []))
    );
  }

  /**
   * 会場登録・編集
   *
   * @param {string} company_id
   * @param {string} key
   * @param {Place} place 編集データ
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof PlaceService
   */
  save(company_id: string, key: string | null, place: Place): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    if (!key) {
      key = this.firestore.createId();
    }
    const place_ref = this.firestore.doc<Company>(`company/${company_id}`).collection('place').doc(key).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(place_ref);
      transaction.set(place_ref, {
        'name': place.name,
        'kname': place.kname,
        'zip': place.zip,
        'addr1': place.addr1,
        'addr2': place.addr2,
        'addr3': place.addr3,
        'addr4': place.addr4,
        'tel': place.tel.toString(),
        'fax': place.fax.toString(),
        'email': place.email,
        'token': place.token,
        'item_attention_vol_use': place.item_attention_vol_use,
        'item_attention_prev_vol_use': place.item_attention_prev_vol_use,
        'vol_base_date': place.vol_base_date,
        'prev_vol_base_date': place.prev_vol_base_date,
        'vol': place.vol,
        'prev_vol': place.prev_vol,
        'place_tax_rate': place.place_tax_rate,
        'created': place.created ? place.created : new Date,
        'delete_flg': false,
        'updated': new Date(),
        'update_user': place.updated_user ? place.updated_user : this.updated_user
      }, {'merge': true});
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * 会場削除
   *
   * @param {string} company_id
   * @param {string} key
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof PlaceService
   */
  del(company_id: string, key: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const place_ref = this.firestore.doc<Company>(`company/${company_id}`).collection('place').doc(key).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(place_ref);
      transaction.update(place_ref, {
        'delete_flg': true,
        'updated': new Date(),
        'update_user': this.updated_user
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * メンバー登録用URLトークン作成
   *
   * @returns {string}
   * @memberof EventService
   */
  createToken(): string {
    const len = 15;
    const str = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_$+-";
    let result: string = "";
    for (var i = 0; i < len; i++) {
      result += str[Math.floor(Math.random() * str.length)];
    }
    return result;
  }

  /**
   * 失敗したHttp操作を処理します。
   * アプリを持続させます。
   * @param operation - 失敗した操作の名前
   * @param result - observableな結果として返す任意の値
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: リモート上のロギング基盤にエラーを送信する
      console.error(error); // かわりにconsoleに出力

      // TODO: ユーザーへの開示のためにエラーの変換処理を改善する
      // this.log(`${operation} failed: ${error.message}`);

      // 空の結果を返して、アプリを持続可能にする
      return of(result as T);
    };
  }


}
