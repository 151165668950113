import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject, ViewChild } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';

import { ModalMessageComponent } from '../../common/modal-message/modal-message.component';

import { SessionService } from '../../service/session.service';
import { PlaceService } from '../../service/place.service';
import { AccountService } from '../../service/account.service';
import { ZipCodeService } from '../../service/zip-code.service';

import { Session } from '../../models/session';
import { Place } from '../../models/place';
import * as constant from '../../models/constant';

import $ from 'jquery';

declare function resetCommonrEvent(): void;
declare function resetPlaceEvent(): void;
declare function showSimpleMsg(msg: string): void;
declare const debugLog: any;

@Component({
  selector: 'app-place',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    ModalMessageComponent,
  ],
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.scss']
})
export class PlaceComponent implements OnInit {
  @ViewChild('placeForm', { static: false }) placeForm!: NgForm;
  function_category = ["search"];
  private subscription: Subscription = new Subscription();
  private session: Session = new Session();
  private is_cross_search: boolean = false;
  private company_id: string = '';
  private place_id: string = '';
  private uid: string = '';
  public places: Place[] = [];
  public pages: number[] = [];
  public select_page_index: number = 0;
  public start: number = 0;
  public len: number = constant.LIST_ROW_MAX;
  public pattern = constant.INPUT_PATTERN;
  private orders: string[] = ['name', 'kname'];
  public add_flg = false;
  private saved: boolean = false;
  public editData: Place | null = null;
  public edit_place_id: string = '';
  private del_target_id: string = '';
  private _keywords: string[] = [];
  public get keywords(): string[] {
    return this._keywords;
  }
  public set keywords(value: string[]) {
    this._keywords = value;
  }
  private postalCodeSubscription!: Subscription;

  constructor(
    private sv_session: SessionService,
    private sv_place: PlaceService,
    private sv_account: AccountService,
    private sv_zipcode: ZipCodeService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {

  }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add (
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.session = session;
          this.uid = session.data.uid;
          this.company_id = session.data.current_company;
          this.place_id = session.data.current_place;
          this.is_cross_search = session.data.cross_search_flg;
          this.sv_place.initialize(session);
          if (!this.is_cross_search) {
            // TODO：権限なしアラート表示。OKでトップへ戻る
          }
          this.getPlaces();
        }
      })
    );

    // 編集エリア開閉準備
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント設定
      if (!$('body').hasClass('giftbox-open')) {
        $('body').addClass('giftbox-open');
      }
    }
    // 新規登録用挙式情報の初期化
    this.editData = new Place();
  }

  ngAfterViewInit() {
    // ビューは初期化されているがthis.accountFormが完全な状態でない可能性あり、ngDoCheck()で条件指定の上で再実施。
    this.zipCodeCheck();
  }

  ngDoCheck() {
    if (this.placeForm && !this.postalCodeSubscription) {
      this.zipCodeCheck();
    }
  }

  changeZipCode() {
    this.addressComplement();
  }

  private zipCodeCheck(): void {
    // ビューが完全に初期化された後で`valueChanges`で郵便番号の入力をリアルタイムでチェックし住所補完。
    if (this.placeForm && this.placeForm.controls['zip']) {
      this.postalCodeSubscription = this.placeForm.controls['zip']?.valueChanges.subscribe(value => {
        const postalCodePattern = /^(\d{7}|\d{3}-\d{4})$/;
        if (postalCodePattern.test(value)) {
          // 郵便番号のフォーマットが正しい場合のみ処理実行
          this.addressComplement();
        }
      });
    }
  }

  private addressComplement() {
    // 住所補完
    const postalCode = this.placeForm.controls['zip']?.value;
    if (postalCode && postalCode.length === 7) { // 郵便番号が正しく7桁か確認
      this.sv_zipcode.getAddress(postalCode).subscribe(response => {
        if (response.results && response.results.length > 0) {
          const result = response.results[0];
          this.placeForm.controls['addr1'].patchValue(result.address1);
          this.placeForm.controls['addr2'].patchValue(result.address2);
          this.placeForm.controls['addr3'].patchValue(result.address3);
        } else {
          alert('該当する住所が見つかりませんでした。');
        }
      }, error => {
        debugLog('Error fetching address:', error);
      });
    }
  }

  private getPlaces(): void {
    if (this.is_cross_search) {
      this.subscription.add(
        this.sv_place.getPlaces(this.company_id).subscribe(places => {
          this.places = places;
          // ページ設定
          this.pages = [];
          for(let i = 1; i <= Math.ceil(places.length / this.len); i++) {
            this.pages.push(i);
          }
          resetPlaceEvent();
        })
      );
    } else {
      this.subscription.add(
        this.sv_place.getPlace(this.company_id, this.place_id).subscribe(place => {
          if (place) {
            this.places = [];
            this.places[0] = place;
          }
          // ページ設定
          this.pages = [];
          this.pages.push(1);
          resetPlaceEvent();
        })
      );
    }
  }

  // public search() {
  //   if (!this.keywords) return false;

  //   let result: Place[] = [];
  //   this.keywords.forEach(keyword => {
  //     this.orders.forEach(order => {
  //       this.subscription.add(
  //         this.sv_guest.searchGuests(this.event_id, keyword, order).subscribe(guests => {
  //           guests.forEach(guest => {
  //             result.push(guest);
  //           });
  //         })
  //       );
  //     });
  //     this.guests = result;
  //   });
  //   return false;
  // }

  public paging(page: number, index: number): void {
    this.select_page_index = index;
    this.start = this.len * index;
  }

  public toggleEdit(place: any | null, ev: any): void {
    let current_target: boolean = false;
    // 特定の会場を編集時
    if (place) {
      if (this.add_flg) this.add_flg = false; // 新規追加エリアが開いていたら閉じる
      current_target = this.edit_place_id == place.objectID;
    }
    // 新規追加時
    else {
      if (this.add_flg && this.editData) {
        this.add_flg = false;  // 新規追加で編集時
        return;
      }
      current_target = true; // 新規追加の初期表示時
    }
    if (!place && !this.add_flg) return;

    // 編集対象データをセット
    this.editData = {
      objectID: '',
      name: place ? place.name : '',
      kname: place ? place.kname : '',
      zip: place ? place.zip : '',
      addr1: place ? place.addr1 : '',
      addr2: place ? place.addr2 : '',
      addr3: place ? place.addr3 : '',
      addr4: place ? place.addr4 : '',
      tel: place ? place.tel : '',
      fax: place ? place.fax : '',
      email: place ? place.email : '',
      token: place ? place.token: '',
      item_attention_vol_use: place ? place.item_attention_vol_use : true,
      item_attention_prev_vol_use: place ? place.item_attention_prev_vol_use : true,
      vol_base_date: (place && place.vol_base_date) ? place.vol_base_date : null,
      prev_vol_base_date: (place && place.prev_vol_base_date) ? place.prev_vol_base_date : null,
      vol: (place && place.vol) ? place.vol : 0,
      prev_vol: (place && place.prev_vol) ? place.prev_vol : 0,
      place_tax_rate: (place && place.place_tax_rate) ? place.place_tax_rate : {rule: false, rate: null},
      created: this.add_flg ? new Date() : place.created,
      updated: new Date(),
      updated_user: {uid: this.uid, name: this.session.data!.last_name + this.session.data!.first_name},
      delete_flg: false
    };
    // 特定会場の編集エリアの開閉
    if (isPlatformBrowser(this.platformId)) {
      $('.js-listRow').css('background-color', '#fff');
      if (current_target) {
        this.edit_place_id = '';  // close
      } else {
        $(ev.target).parents('.js-listRow').css('background-color', '#e5e5e5');
        this.edit_place_id = place.objectID;  // open
      }
    }
  }

  public toggleAddOpen() {
    // 新規追加エリア開閉状態制御
    if (this.add_flg) {
      this.add_flg = false;
    } else {
      this.add_flg = true;
      this.editData = null;
    }
    this.toggleEdit(null, null);
    return false;
  }

  // private selected(index: number): boolean {
  //   const event_id = this.places[index].objectID;
  //   this.router.navigate([`/event-detail/${event_id}`], {relativeTo: this.route});
  //   return false;
  // }

  public save(place_key: string | null, event: any): void {
    if (!this.editData) return;

    this.editData.token = this.sv_place.createToken();
    this.subscription.add(
      this.sv_place.save(this.company_id, place_key, this.editData).subscribe(data => {
        if (data.result) {
          this.edit_place_id = '';
        }
        showSimpleMsg(data.msg as string);
        if (this.add_flg) {
          this.add_flg = false;
          if (isPlatformBrowser(this.platformId)) {
            $('.js-listRow').first().find('.js-editBtnSendListChange').click();
            $('.js-listRow').first().css('background-color', '#fff');
          }
        }
      })
    );
  }

  public setDelTargetId(event: any) {
    this.del_target_id = $(event.target).data('id');
  }

  public onModalMsgEvent(msg: boolean) {
    if (!msg) {
      this.del_target_id = '';
      return;
    }
    setTimeout(() => {
      if (this.del_target_id) {
        this.del();
      }
    }, 300);
  }

  private del(): void {
    if (this.del_target_id) {
      this.subscription.add(
        this.sv_place.del(this.company_id, this.del_target_id).subscribe(data => {
          if (!data.result) {
            showSimpleMsg(data.msg as string);
          }
          // メンバーの所属会場情報から該当の会場を削除
          this.subscription.add(this.sv_place.getMembers(this.del_target_id).subscribe(members=> {
            members.forEach(member => {
              this.subscription.add(this.sv_account.updatePlace(member.objectID, this.del_target_id, true).subscribe(update_rel => {
                if (!update_rel.result) {
                  debugLog('メンバーの会場削除失敗', member.objectID);
                }
              }))
            });
            this.del_target_id = '';
          }))
        })
      );
    } else {
      showSimpleMsg(constant.MSG_UPDATE_FAILED);
      this.del_target_id = '';
    }
  }

  // private onModalSimpleMsgEvent(event: boolean) {
  //   setTimeout(() => {
  //     this.getEvents();
  //     this.editData = new Place();
  //     toggleCreateArea();
  //   }, 1000);
  // }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.postalCodeSubscription) {
      this.postalCodeSubscription.unsubscribe();
    }
    // 編集エリア開閉の設定を解除
    if (isPlatformBrowser(this.platformId)) {
      if ($('body').hasClass('giftbox-open')) {
        $('body').removeClass('giftbox-open');
      }
    }
  }

}
