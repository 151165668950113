import { Injectable, Inject } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { Observable, of, Subject, Subscription} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SessionService } from './session.service';

import { Item } from '../models/item';
import { Session } from '../models/session';
import { ItemPriceAdjust } from '../models/item_place_adjust';
import * as constant from '../models/constant';

import algoliasearch from 'algoliasearch';
import { environment } from '../../environments/environment';

declare const debugLog: any;

const ALGOLIA_ID = environment.ALGOLIA_APP_ID;
const ALGOLIA_SEARCH_KEY = environment.ALGOLIA_SEARCH_KEY;
const ALGOLIA_INDEX_ITEM = environment.ALGOLIA_INDEX_ITEM;

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  private itemCollection!: AngularFirestoreCollection<Item>;
  private itemDocument!: AngularFirestoreDocument<Item>;
  private items: Observable<Item[]> = of([]);
  private item: Observable<Item | null> = of(null);
  private session: Session = new Session();
  private session_ck_subject: Subject<boolean> = new Subject();
  public session_ck_result: Observable<boolean> = this.session_ck_subject.asObservable();
  public subscription: Subscription = new Subscription();

  constructor(
    private firestore: AngularFirestore,
    private sv_session: SessionService,
  ) {}

  initialize(session: Session): void {
    this.session = session;
  }

  getItems(): Observable<Item[]> {
    this.items = this.search_set(this.firestore.collection<Item>('item', ref => ref
      .where('category', 'array-contains-any', constant.ITEM_CATEGORY_KEYS)
      .where('delete_flg', '==', false)
    ).snapshotChanges());
    return this.items.pipe(
      catchError(this.handleError<Item[]>('getItems', []))
    );
  }

  getItem(key: string): Observable<Item | null> {
    let get_result: Subject<Item | null> = new Subject();
    let result = get_result.asObservable();
    this.itemDocument = this.firestore.doc<Item>(`item/${key}`);
    result = this.itemDocument.snapshotChanges().pipe(
      map(doc => {
        if (!doc.payload.exists) {
          return null;
        }
        let obj = {
          objectID: doc.payload.id,
          name: doc.payload.data().name,
          code: doc.payload.data().code,
          beare_code: doc.payload.data().beare_code,
          place_limited: doc.payload.data().place_limited,
          place_without: doc.payload.data().place_without,
          price: doc.payload.data().price,
          reduced_tax_flg: doc.payload.data().reduced_tax_flg,
          image: doc.payload.data().image,
          thumb_l: doc.payload.data().thumb_l,
          thumb_s: doc.payload.data().thumb_s,
          size_w: doc.payload.data().size_w,
          size_h: doc.payload.data().size_h,
          size_d: doc.payload.data().size_d,
          size_diameter: doc.payload.data().size_diameter,
          size_other: doc.payload.data().size_other,
          volume: doc.payload.data().volume,
          box_size_w: doc.payload.data().box_size_w,
          box_size_h: doc.payload.data().box_size_h,
          box_size_d: doc.payload.data().box_size_d,
          color: doc.payload.data().color,
          dishwasher: doc.payload.data().dishwasher,
          microwave: doc.payload.data().microwave,
          allergy: doc.payload.data().allergy,
          accessories: doc.payload.data().accessories,
          set: doc.payload.data().set,
          expire_date: doc.payload.data().expire_date,
          wrapping: doc.payload.data().wrapping,
          brand: doc.payload.data().brand,
          category: doc.payload.data().category,
          description: doc.payload.data().description,
          tag: doc.payload.data().tag,
          status: doc.payload.data().status,
          level: doc.payload.data().level,
          note: doc.payload.data().note,
          vol_no: doc.payload.data().vol_no,
          created: doc.payload.data().created,
          updated: doc.payload.data().updated,
          delete_flg: doc.payload.data().delete_flg,
          favorite: this.session.data?.favorite_items ? this.session.data.favorite_items.includes(doc.payload.id) : false
        };
        return obj;
      })
    );
    return result.pipe(
      catchError(this.handleError<Item | null>(`getItem id=${key}`, null))
    );
  }

  getItemPriceAdjusts(beare_code: string | null = null): Observable<ItemPriceAdjust[]> {
    let data: Observable<DocumentChangeAction<ItemPriceAdjust>[]>;
    if (beare_code) {
      data = this.firestore.collection<ItemPriceAdjust>('item_price_adjust', ref => ref
        .where('apply_beare_code', '==', beare_code)
        .where('delete_flg', '==', false)
      ).snapshotChanges();
    } else {
      data = this.firestore.collection<ItemPriceAdjust>('item_price_adjust', ref => ref
        .where('delete_flg', '==', false)
      ).snapshotChanges();
    }
    const item_price_adjusts: Observable<ItemPriceAdjust[]> = data.pipe(map(docs =>
      docs.map(doc => ({
        objedtID: doc.payload.doc.id,
        apply_beare_code: doc.payload.doc.data().apply_beare_code,
        no_apply_beare_code: doc.payload.doc.data().no_apply_beare_code,
        base_date: doc.payload.doc.data().base_date,
        place_limited: doc.payload.doc.data().place_limited,
        place_without: doc.payload.doc.data().place_without,
        created: doc.payload.doc.data().created,
        updated: doc.payload.doc.data().updated,
        delete_flg: doc.payload.doc.data().delete_flg
      })))
    );
    return item_price_adjusts.pipe(
      catchError(this.handleError<ItemPriceAdjust[]>('getItemPriceAdjusts', []))
    );
  }

  getItemStatus(key: string): Observable<number> {
    let get_result: Subject<number> = new Subject();
    let result = get_result.asObservable();
    this.itemDocument = this.firestore.doc<Item>(`item/${key}`);
    result = this.itemDocument.snapshotChanges().pipe(
      map(doc => {
        if (!doc.payload.exists) {
          return -1;
        }
        return doc.payload.data().status;
      })
    );
    return result.pipe(
      catchError(this.handleError<number>(`getItemStatus id=${key}`))
    );
  }

  searchItems(categories: string[], price_ranges: string[], keywords: string[]): Observable<Item[]> {
    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_SEARCH_KEY);
    const index = client.initIndex(ALGOLIA_INDEX_ITEM);

    // カテゴリー
    if (categories.length) {
      categories.forEach((cate, i) => {
        categories[i] = `category:${cate}`;
      });
    } else {
      categories = [];
      constant.ITEM_CATEGORY.forEach((cate, i) => {
        categories[i] = `category:${cate.key}`;
      });
    }

    // 価格帯
    let cond_range: string[] = [];
    if (price_ranges.length) {
      price_ranges.forEach(range => {
        switch(range) {
          case 'range1':
            cond_range.push('(price <= 2999)');
            break;
          case 'range2':
            cond_range.push('(price: 3000 TO 4999)');
            break;
          case 'range3':
            cond_range.push('(price: 5000 TO 9999)');
            break;
          case 'range4':
            cond_range.push('(price >= 10000)');
            break;
        };
      });
    } else {
      cond_range = ['(price <= 2999)', '(price: 3000 TO 4999)', '(price: 5000 TO 9999)', '(price >= 10000)'];
    }

    let search_category = '(' + categories.join(' OR ') + ')';
    let search_price_range = price_ranges.length ? '(' + cond_range.join(' OR ') + ')' : null;
    let search_keyword = keywords ? keywords.join(' ').toString() : '';
    let search_subject: Subject<Item[]> = new Subject();
    let search_result = search_subject.asObservable();
    let favorite_items = this.session.data?.favorite_items;
    let search_status = '(status:' + constant.ITEM_STATUS.COMMINGSOON + ' OR status:' + constant.ITEM_STATUS.PUBLIC + ' OR status:' + constant.ITEM_STATUS.SOLDOUT + ')';
    let search_del_flg = 'delete_flg: false';

    let filter = '';
    filter += search_category ? search_category + ' AND ' : '';
    filter += search_price_range ? search_price_range + ' AND ' : '';
    filter += search_status + ' AND ' + search_del_flg;

    index.search(search_keyword,{
      filters: filter,
      attributesToRetrieve: this.getFieldNames(),
      hitsPerPage: 500
    })
    .then(({ hits }) => {
      const updateHits = hits.map(item => ({
        ...item,
        favorite: favorite_items ? favorite_items.includes(item.objectID) : false
      }));
      search_subject.next(updateHits as Item[]);
    })
    .catch(err => {
      debugLog(err.message);
    });

    return search_result.pipe(
      catchError(this.handleError<Item[]>('searchItems', []))
    );
  }

  private getFieldNames() :string[]{
    return [
      "objectID",
      "name",
      "code",
      "beare_code",
      "place_limited",
      "place_without",
      "price",
      "reduced_tax_flg",
      "image",
      "thumb_l",
      "thumb_s",
      "size_w",
      "size_h",
      "size_d",
      "size_diameter",
      "size_other",
      "volume",
      "box_size_w",
      "box_size_h",
      "box_size_d",
      "color",
      "dishwasher",
      "microwave",
      "allergy",
      "accessories",
      "set",
      "expire_date",
      "wrapping",
      "brand",
      "description",
      "category",
      "tag",
      "status",
      "level",
      "note",
      "vol_no",
      "created",
      "updated",
      "delete_flg",
      "favorite",
    ];
  }

  private search_set(docs: Observable<DocumentChangeAction<Item>[]>): Observable<Item[]> {
    const items_data: Observable<Item[]> = docs.pipe(
      map (datas => {
        let items = datas.map(doc => {
          let obj: Item = {
            objectID: doc.payload.doc.id,
            name: doc.payload.doc.data().name,
            code: doc.payload.doc.data().code,
            beare_code: doc.payload.doc.data().beare_code,
            place_limited: doc.payload.doc.data().place_limited,
            place_without: doc.payload.doc.data().place_without,
            price: doc.payload.doc.data().price,
            reduced_tax_flg: doc.payload.doc.data().reduced_tax_flg,
            image: doc.payload.doc.data().image,
            thumb_l: doc.payload.doc.data().thumb_l,
            thumb_s: doc.payload.doc.data().thumb_s,
            size_w: doc.payload.doc.data().size_w,
            size_h: doc.payload.doc.data().size_h,
            size_d: doc.payload.doc.data().size_d,
            size_diameter: doc.payload.doc.data().size_diameter,
            size_other: doc.payload.doc.data().size_other,
            volume: doc.payload.doc.data().volume,
            box_size_w: doc.payload.doc.data().box_size_w,
            box_size_h: doc.payload.doc.data().box_size_h,
            box_size_d: doc.payload.doc.data().box_size_d,
            color: doc.payload.doc.data().color,
            dishwasher: doc.payload.doc.data().dishwasher,
            microwave: doc.payload.doc.data().microwave,
            allergy: doc.payload.doc.data().allergy,
            accessories: doc.payload.doc.data().accessories,
            set: doc.payload.doc.data().set,
            expire_date: doc.payload.doc.data().expire_date,
            wrapping: doc.payload.doc.data().wrapping,
            brand: doc.payload.doc.data().brand,
            category: doc.payload.doc.data().category,
            description: doc.payload.doc.data().description,
            tag: doc.payload.doc.data().tag,
            status: doc.payload.doc.data().status,
            level: doc.payload.doc.data().level,
            note: doc.payload.doc.data().note,
            vol_no: doc.payload.doc.data().vol_no,
            created: doc.payload.doc.data().created,
            updated: doc.payload.doc.data().updated,
            delete_flg: doc.payload.doc.data().delete_flg,
            favorite: this.session.data?.favorite_items ? this.session.data.favorite_items.includes(doc.payload.doc.id) : false
          };
          return obj;
        });
        return items;
      })
    );

    return items_data;
  }

  /**
   * 失敗したHttp操作を処理します。
   * アプリを持続させます。
   * @param operation - 失敗した操作の名前
   * @param result - observableな結果として返す任意の値
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: リモート上のロギング基盤にエラーを送信する
      console.error(error); // かわりにconsoleに出力

      // TODO: ユーザーへの開示のためにエラーの変換処理を改善する
      // this.log(`${operation} failed: ${error.message}`);

      // 空の結果を返して、アプリを持続可能にする
      return of(result as T);
    };
  }


}
