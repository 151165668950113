import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { SessionService } from '../../service/session.service';
import { GuestService } from '../../service/guest.service';

import { Guest } from '../../models/guest';
import { Event } from '../../models/event';

declare function resetCommonrEvent(): void;
declare const debugLog: any;

@Component({
  selector: 'app-event-guests',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './event-guests.component.html',
  styleUrls: ['./event-guests.component.scss']
})
export class EventGuestsComponent implements OnInit {
  public event: Event = new Event();
  public event_date: string = '';
  public onew_link: boolean = false;
  public guests: Guest[] = [];
  public invalid: boolean[] = [];
  private event_id: string = '';
  private subscription: Subscription = new Subscription();

  constructor(
    private sv_session: SessionService,
    private sv_guest: GuestService,
    private route: ActivatedRoute,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.subscribe(session => {
        if (!session) this.sv_session.logout();
      })
    );

    // 得意先からONE-W連携フラグ取得
    this.sv_session.getCompanyInfo();
    this.subscription.add(
      this.sv_session.companyInfo.subscribe(company => {
        this.onew_link = company.onew_link;
      })
    );

    // 挙式情報取得
    this.sv_session.getEventInfo();
    this.subscription.add(
      this.sv_session.eventInfo.subscribe(event => {
        this.event = event;
      })
    );

    // ゲスト情報取得
    this.subscription.add(
      this.route.params.subscribe(params => {
        this.event_id = params['id'];
        this.sv_guest.getGuests(this.event_id).subscribe(guests => {
          this.guests = guests;
          guests.forEach((guest, i) => {
            this.invalid[i] =
              ( !guest.last_name || !guest.first_name || !guest.last_kname ||
                !guest.first_kname || !guest.zip || !guest.addr1 || !guest.addr2 || !guest.tel);
          });
        });
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント設定
    }
  }

  public back(): void {
    this.location.back();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
