import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZipCodeService {
  private baseUrl = 'https://zipcloud.ibsnet.co.jp/api/search';

  constructor(private http: HttpClient) { }

  public getAddress(postalCode: string): Observable<any> {
    return this.http.get(`${this.baseUrl}?zipcode=${postalCode}`);
  }
}
