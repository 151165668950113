import { Component, OnInit, OnDestroy, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Subscription } from 'rxjs';

import { SwitchTabComponent } from '../../common/switch-tab/switch-tab.component';
import { MemberListComponent } from '../member-list/member-list.component';
import { MemberInvitationComponent } from '../member-invitation/member-invitation.component';

import { SessionService } from '../../service/session.service';
import { SwitchTabService } from '../../service/switch-tab.service';
import { PlaceService } from '../../service/place.service';

import { TabModel } from '../../models/tab-model';
import { Place } from '../../models/place';
import * as constant from '../../models/constant';

declare function resetCommonrEvent(): void;
declare const debugLog: any;

@Component({
  selector: 'app-member',
  standalone: true,
  imports: [
    SwitchTabComponent,
    RouterLink,
  ],
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit {
  @ViewChild(SwitchTabComponent, {static: false}) switch_tab!: SwitchTabComponent;
  function_category = ["search"];
  private company_id: string = '';
  private place_id: string = '';
  public place: Place = new Place();
  private ready: boolean = false;
  private tab: TabModel[] = [];
  private _keywords: string[] = [];
  public get keywords(): string[] {
    return this._keywords;
  }
  public set keywords(value: string[]) {
    this._keywords = value;
  }
  private orders: string[] = ['first_name', 'last_name', 'first_kname', 'last_kname'];
  private subscription: Subscription = new Subscription();

  constructor(
    private sv_session: SessionService,
    private sv_switch_tab: SwitchTabService,
    private sv_place: PlaceService,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.local_storage.remove(constant.STORAGE_LOCAL_PLACE_INFO);
      localStorage.removeItem(constant.STORAGE_LOCAL_PLACE_INFO);
    }

    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.company_id = session.data.current_company;
          this.subscription.add(
            this.route.params.subscribe(params => {
              this.place_id = params['id'];
              this.getPlace();
            })
          );
        }
      })
    );

    if (this.tab) {
      this.tab.push(new TabModel('メンバー', MemberListComponent, true));
      this.tab.push(new TabModel('メンバー招待', MemberInvitationComponent, false));
      this.sv_switch_tab.tabs = this.tab;
    }
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント再設定
    }
  }

  private getPlace(): void {
    this.subscription.add(
      this.sv_place.getPlace(this.company_id, this.place_id).subscribe(place => {
        if (place) {
          this.place = place;
          this.setPlaceInfo();
        }
      })
    );
  }

  private setPlaceInfo(): void {
    if (this.ready) return;
    this.local_storage.set(constant.STORAGE_LOCAL_PLACE_INFO, this.place);
    this.sv_session.placeSubject.next(this.place);
    this.ready = true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
