import { Injectable, Inject } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { Observable, of, Subject, lastValueFrom} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SessionService } from '../service/session.service';

import { Session } from '../models/session';
import { Event } from '../models/event';
import { Guest } from '../models/guest';
import { GuestGroup } from '../models/guest_group';
import { ProcessResult } from '../models/process_result';
import * as constant from '../models/constant';

declare const debugLog: any;

@Injectable({
  providedIn: 'root'
})
export class GuestService {
  // ゲスト
  private guestCollection!: AngularFirestoreCollection<Guest>;
  private eventDocument!: AngularFirestoreDocument<Event>;
  private guests: Observable<Guest[]> = of([]);
  private guest: Observable<Guest | null> = of(null);
  // グループ
  private groupCollection!: AngularFirestoreCollection<GuestGroup>;
  private groupDocument!: AngularFirestoreDocument<GuestGroup>;
  private groups: Observable<GuestGroup[]> = of([]);
  private group: Observable<GuestGroup | null> = of(null);
  // グループ内のゲスト
  private groupGuestCollection!: AngularFirestoreCollection<Guest>;
  private group_guests: Observable<Guest[]> = of([]);

  private session: Session = new Session();
  private update_user: {uid: string, name: string} = {uid: '', name: ''};


  constructor(
    private firestore: AngularFirestore,
    private sv_session: SessionService,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
  ) {

  }

  /**
   * 更新ユーザー設定
   *
   * @param {Session} session
   * @memberof GuestService
   */
  initialize(session: Session): void {
    if (!session || !session.data) return;
    this.session = session;
    this.update_user = {
      uid: session.data.uid,
      name: session.data.last_name + session.data.first_name
    };
  }


  /**
   * ゲスト一覧を取得
   *
   * @param {string} event_id
   * @param {(string|null)} order
   * @returns {Observable<Guest[]>}
   * @memberof GuestService
   */
  getGuests(event_id: string, order: string | null = null): Observable<Guest[]> {
    this.eventDocument = this.firestore.doc<Event>(`event/${event_id}`);
    const guests = this.eventDocument.collection('guest', ref => ref
      .where('delete_flg', '==', false)
      .orderBy('last_kname')
      .orderBy('first_kname')
      // .orderBy(order ? `${order}` : 'created', sort ? sort : 'desc')
    );
    this.guests = guests.snapshotChanges().pipe(
      map (datas =>
        datas.map(doc => {
          const data = doc.payload.doc.data() as Guest;
          return {
            event_id: event_id,
            objectID: doc.payload.doc.id,
            giftbox_ids: data.giftbox_ids,
            group_id: data.group_id,
            deliv_date: data.deliv_date,
            deliv_fee: data.deliv_fee,
            card: data.card,
            noshi: data.noshi,
            first_name: data.first_name,
            last_name: data.last_name,
            first_kname: data.first_kname,
            last_kname: data.last_kname,
            zip: data.zip,
            addr1: data.addr1,
            addr2: data.addr2,
            addr3: data.addr3,
            addr4: data.addr4,
            tel: data.tel,
            email: data.email,
            created: data.created,
            updated: data.updated,
            update_user: data.update_user,
            group_updated: data.group_updated,
            giftbox_updated: data.giftbox_updated,
            delete_flg: data.delete_flg,
            giftbox$: null,
            group_name$: null
          };
        })
      )
    );
    return this.guests.pipe(
      catchError(this.handleError<Guest[]>('getGuests', []))
    );
  }

  /**
   * 贈る相手を検索
   *　（姓、名、姓カナ、名カナの前方一致のみ）
   * @param {string} event_id
   * @param {string} keyward
   * @param {string} order 姓、名、姓カナ、名カナ のいずれか
   * @returns {Observable<Guest[]>}
   * @memberof GuestService
   */
  searchGuests(event_id: string, keyward: string, order: string): Observable<Guest[]> {
    this.guestCollection = this.firestore.doc<Event>(`event/${event_id}`).collection<Guest>('guest', ref => ref
      .where('delete_flg', '==', false)
      .orderBy(`${order}`)
      .orderBy('created', 'desc')
      .startAt(keyward)
      .endAt(keyward + '\uf8ff')
    );
    this.guests = this.guestCollection.snapshotChanges().pipe(
      map (datas =>
        datas.map(doc => ({
          event_id: event_id,
          objectID: doc.payload.doc.id,
          giftbox_ids: doc.payload.doc.data().giftbox_ids,
          group_id: doc.payload.doc.data().group_id,
          deliv_date: doc.payload.doc.data().deliv_date,
          deliv_fee: doc.payload.doc.data().deliv_fee,
          card: doc.payload.doc.data().card,
          noshi: doc.payload.doc.data().noshi,
          first_name: doc.payload.doc.data().first_name,
          last_name: doc.payload.doc.data().last_name,
          first_kname: doc.payload.doc.data().first_kname,
          last_kname: doc.payload.doc.data().last_kname,
          zip: doc.payload.doc.data().zip,
          addr1: doc.payload.doc.data().addr1,
          addr2: doc.payload.doc.data().addr2,
          addr3: doc.payload.doc.data().addr3,
          addr4: doc.payload.doc.data().addr4,
          tel: doc.payload.doc.data().tel,
          email: doc.payload.doc.data().email,
          created: doc.payload.doc.data().created,
          updated: doc.payload.doc.data().updated,
          update_user: doc.payload.doc.data().update_user,
          group_updated: doc.payload.doc.data().group_updated,
          giftbox_updated: doc.payload.doc.data().giftbox_updated,
          delete_flg: doc.payload.doc.data().delete_flg,
          giftbox$: null,
          group_name$: null
        }))
      )
    );
    return this.guests.pipe(
      catchError(this.handleError<Guest[]>('searchGuests', []))
    );
  }

  /**
   * グループ一覧を取得
   *
   * @param {string} event_id
   * @returns {Observable<GuestGroup[]>}
   * @memberof GuestService
   */
  getGroups(event_id: string): Observable<GuestGroup[]> {
    this.eventDocument = this.firestore.doc<Event>(`event/${event_id}`);
    const groups = this.eventDocument.collection('guest_group', ref => ref
      .where('delete_flg', '==', false)
      .orderBy('created', 'desc')
    );
    this.groups = groups.snapshotChanges().pipe(
      map (datas =>
        datas.map(doc => {
          const data = doc.payload.doc.data() as GuestGroup;
          return {
            event_id: event_id,
            objectID: doc.payload.doc.id,
            name: data.name,
            created: data.created,
            updated: data.updated,
            update_user: data.update_user,
            delete_flg: data.delete_flg,
            guestCount$: this.getGroupGeustCount(event_id, doc.payload.doc.id)
          }
        })
      )
    );
    return this.groups.pipe(
      catchError(this.handleError<GuestGroup[]>('getGuestGroups', []))
    );
  }


  /**
   * グループを取得
   *
   * @param {string} key
   * @returns {Observable<GuestGroup | null>}
   * @memberof GuestService
   */
  getGroup(key: string): Observable<GuestGroup | null> {
    this.groupDocument = this.firestore.doc<GuestGroup>(`guest_group/${key}`);
    this.group = this.groupDocument.snapshotChanges().pipe(
      map (doc => {
        if (!doc.payload.exists) {
          return null;
        }
        let obj = {
          objectID: doc.payload.id,
          event_id: doc.payload.data().event_id,
          name: doc.payload.data().name,
          created: doc.payload.data().created,
          updated: doc.payload.data().updated,
          update_user: doc.payload.data().update_user,
          delete_flg: doc.payload.data().delete_flg,
          guestCount$: this.getGroupGeustCount(doc.payload.data().event_id, doc.payload.id)
        };
        return obj;
      })
    );
    return this.group.pipe(
      catchError(this.handleError<GuestGroup | null>('getGroup', null))
    );
  }


  /**
   * グループ名を取得
   *
   * @param {string} event_id
   * @param {string} key
   * @returns {Observable<string | null>}
   * @memberof GuestService
   */
  getGroupName(event_id: string, key: string): Observable<string | null> {
    this.eventDocument = this.firestore.doc<Event>(`event/${event_id}`);
    const groupDocument = this.eventDocument.collection('guest_group').doc<GuestGroup>(key);
    let group_name: Observable<string | null> = groupDocument.snapshotChanges().pipe(
      map (doc => {
        if (!doc.payload.exists) {
          return null;
        }
        return doc.payload.data().name;
      })
    );
    return group_name.pipe(
      catchError(this.handleError<string | null>('getGroupName', null))
    );
  }


  /**
   * グループ内のゲスト一覧を取得（50音順）
   *
   * @param {string} event_id
   * @param {string} group_id
   * @returns {Observable<Guest[]>}
   * @memberof GuestService
   */
  getGroupGeusts(event_id: string, group_id: string): Observable<Guest[]> {
    const group_guests = this.firestore.doc<Event>(`event/${event_id}`).collection<Guest>('guest', ref => ref
      .where('group_id', '==', group_id)
      .where('delete_flg', '==', false)
      // .orderBy('group_updated', 'desc')
      .orderBy('last_kname')
      .orderBy('first_kname')
    );
    this.group_guests = group_guests.snapshotChanges().pipe(
      map (datas =>
        datas.map(doc => ({
          objectID: doc.payload.doc.id,
          event_id: event_id,
          giftbox_ids: doc.payload.doc.data().giftbox_ids,
          group_id: doc.payload.doc.data().group_id,
          deliv_date: doc.payload.doc.data().deliv_date,
          deliv_fee: doc.payload.doc.data().deliv_fee,
          card: doc.payload.doc.data().card,
          noshi: doc.payload.doc.data().noshi,
          first_name: doc.payload.doc.data().first_name,
          last_name: doc.payload.doc.data().last_name,
          first_kname: doc.payload.doc.data().first_kname,
          last_kname: doc.payload.doc.data().last_kname,
          zip: doc.payload.doc.data().zip,
          addr1: doc.payload.doc.data().addr1,
          addr2: doc.payload.doc.data().addr2,
          addr3: doc.payload.doc.data().addr3,
          addr4: doc.payload.doc.data().addr4,
          tel: doc.payload.doc.data().tel,
          email: doc.payload.doc.data().email,
          created: doc.payload.doc.data().created,
          updated: doc.payload.doc.data().updated,
          update_user: doc.payload.doc.data().update_user,
          group_updated: doc.payload.doc.data().group_updated,
          giftbox_updated: doc.payload.doc.data().giftbox_updated,
          delete_flg: doc.payload.doc.data().delete_flg,
          giftbox$: null,
          group_name$: null
        }))
      )
    );
    return this.group_guests.pipe(
      catchError(this.handleError<Guest[]>('getGuestGroups', []))
    );
  }

  /**
   * グループ内のゲスト数を取得
   *
   * @param {string} event_id
   * @param {string} group_id
   * @returns {Observable<number>}
   * @memberof GuestService
   */
  getGroupGeustCount(event_id: string, group_id: string): Observable<number> {
    this.eventDocument = this.firestore.doc<Event>(`event/${event_id}`);
    const groupGuestCollection = this.eventDocument.collection<Guest>('guest', ref => ref
      .where('group_id', '==', group_id)
      .where('delete_flg', '==', false)
    );
    let groupGuestLen: Observable<number> = groupGuestCollection.snapshotChanges().pipe(
      map (datas => datas.length)
    );
    return groupGuestLen.pipe(
      catchError(this.handleError<number>('getGroupGeustCount'))
    );
  }


  /**
   * どのグループにも属していないゲストの一覧を取得（50音順）
   *
   * @param {string} event_id
   * @param {string} group_id
   * @returns {Observable<Guest[]>}
   * @memberof GuestService
   */
  getNoGroupGeusts(event_id: string, group_id: string): Observable<Guest[]> {
    this.groupGuestCollection = this.firestore.doc<Event>(`event/${event_id}`).collection<Guest>('guest', ref => ref
      .where('group_id', '==', '')
      .where('delete_flg', '==', false)
      .orderBy('last_kname')
      .orderBy('first_kname')
    );
    this.group_guests = this.groupGuestCollection.snapshotChanges().pipe(
      map (datas =>
        datas.map(doc => ({
          objectID: doc.payload.doc.id,
          event_id: doc.payload.doc.data().event_id,
          giftbox_ids: doc.payload.doc.data().giftbox_ids,
          group_id: doc.payload.doc.data().group_id,
          deliv_date: doc.payload.doc.data().deliv_date,
          deliv_fee: doc.payload.doc.data().deliv_fee,
          card: doc.payload.doc.data().card,
          noshi: doc.payload.doc.data().noshi,
          first_name: doc.payload.doc.data().first_name,
          last_name: doc.payload.doc.data().last_name,
          first_kname: doc.payload.doc.data().first_kname,
          last_kname: doc.payload.doc.data().last_kname,
          zip: doc.payload.doc.data().zip,
          addr1: doc.payload.doc.data().addr1,
          addr2: doc.payload.doc.data().addr2,
          addr3: doc.payload.doc.data().addr3,
          addr4: doc.payload.doc.data().addr4,
          tel: doc.payload.doc.data().tel,
          email: doc.payload.doc.data().email,
          created: doc.payload.doc.data().created,
          updated: doc.payload.doc.data().updated,
          update_user: doc.payload.doc.data().update_user,
          group_updated: doc.payload.doc.data().group_updated,
          giftbox_updated: doc.payload.doc.data().giftbox_updated,
          delete_flg: doc.payload.doc.data().delete_flg,
          giftbox$: null,
          group_name$: null
        }))
      )
    );
    return this.group_guests.pipe(
      catchError(this.handleError<Guest[]>('getGuestGroups', []))
    );
  }

  /**
   * ゲスト削除
   *
   * @param {string} event_id
   * @param {string} key
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GuestService
   */
  del(event_id: string, key: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const guest_ref = this.firestore.doc<Event>(`event/${event_id}`).collection('guest').doc(key).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(guest_ref);
      transaction.update(guest_ref, {
        'delete_flg': true,
        'updated': new Date(),
        'update_user': this.update_user
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * グループ削除
   * （グループを削除しグループに登録されているゲストを解除する）
   *
   * @param {string} event_id
   * @param {string} key
   * @returns {Observable<ProcessResult>}
   * @memberof GuestService
   */
  delGroup(event_id: string, key: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();

    this.firestore.firestore.runTransaction(async transaction => {
      // 対象のグループの削除フラグを更新
      const guestGroupRef = this.firestore.collection(`event/${event_id}/guest_group`).doc(key).ref;
      transaction.update(guestGroupRef, {
        'delete_flg': true,
        'updated': new Date(),
        'update_user': this.update_user
      });

      // 'guest' コレクション内の該当するドキュメントを取得し、更新
      const guestsSnap = await lastValueFrom(this.firestore.collection(`event/${event_id}/guest`, ref => ref.where('group_id', '==', key)).get());

      guestsSnap.forEach(doc => {
        transaction.update(doc.ref, {
          'group_id': '',
          'updated': new Date(),
          'update_user': this.update_user
        });
      });
    })
    .then(() => {
      debugLog("[guest_group] Transaction successfully");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("[guest_group] Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });

    return result;
  }

  /**
   * グループ内のゲスト削除（ゲストを個別に指定）
   *
   * @param {string} event_id
   * @param {string} key
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GuestService
   */
  delGroupGuest(event_id: string, key: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const guest_ref = this.firestore.doc<Event>(`event/${event_id}`).collection('guest').doc<Guest>(key).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(guest_ref);
      transaction.update(guest_ref, {
        'group_id': '',
        'updated': new Date(),
        'update_user': this.update_user
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * グループ内のゲスト追加
   *
   * @param {string} event_id
   * @param {string} key
   * @param {string} group_id
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GuestService
   */
  addGroupGuest(event_id: string, key: string, group_id: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const guest_ref = this.firestore.doc<Event>(`event/${event_id}`).collection('guest').doc<Guest>(key).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(guest_ref);
      transaction.update(guest_ref, {
        'group_id': group_id,
        'group_updated': new Date(),
        'updated': new Date(),
        'update_user': this.update_user
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_SET_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_SET_FAILED, id: null});
    });
    return result;
  }

  /**
   * ゲスト登録・編集
   *
   * @param {string} event_id
   * @param {string} key
   * @param {Guest} guest 編集データ
   * @returns {Observable<ProcessResult>} 処理結果
   * @memberof GuestService
   */
  save(event_id: string, key: string | null, guest:Guest): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    if (!key) {
      key = this.firestore.createId();
    }
    const guest_ref = this.firestore.doc<Event>(`event/${event_id}`).collection('guest').doc(key).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(guest_ref);
      transaction.set(guest_ref, {
        'first_name': guest.first_name,
        'last_name': guest.last_name,
        'first_kname': guest.first_kname,
        'last_kname': guest.last_kname,
        'zip': guest.zip,
        'addr1': guest.addr1,
        'addr2': guest.addr2,
        'addr3': guest.addr3,
        'addr4': guest.addr4,
        'tel': guest.tel.toString(),
        'email': guest.email,
        'giftbox_ids': guest.giftbox_ids,
        'group_id': guest.group_id,
        'deliv_date': guest.deliv_date,
        'deliv_fee': guest.deliv_fee,
        'card': guest.card,
        'noshi': guest.noshi,
        'created': guest.created ? guest.created : new Date,
        'delete_flg': false,
        'updated': new Date(),
        'group_updated': guest.group_updated,
        'giftbox_updated': guest.giftbox_updated,
        'update_user': guest.update_user ? guest.update_user : this.update_user
      }, {'merge': true});
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * ゲストグループ登録・編集
   *
   * @param {string} event_id
   * @param {(string | null)} key
   * @param {GuestGroup} group
   * @returns {Observable<ProcessResult>}
   * @memberof GuestService
   */
  saveGroup(event_id: string, key: string | null, group:GuestGroup): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    if (!key) {
      key = this.firestore.createId();
    }
    const guest_group_ref = this.firestore.doc<Event>(`event/${event_id}`).collection('guest_group').doc<GuestGroup>(key).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(guest_group_ref);
      transaction.set(guest_group_ref, {
        'name': group.name,
        'created': group.created,
        'delete_flg': false,
        'updated': new Date(),
        'update_user': this.update_user
      }, {'merge': true});
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * テストデータ投入用（ゲスト）
   *
   * @memberof GuestService
   */
  test_guest_add(): void {
    let guest_data = require('../datas/guest.json');
    guest_data.forEach((data: any, i: number) => {
      guest_data[i].deliv_date = data.deliv_date ? new Date(data.deliv_date) : null;
      guest_data[i].created = new Date();
      guest_data[i].updated = new Date();
      guest_data[i].group_updated = data.group_updated ? new Date(data.group_updated) : null;
      guest_data[i].giftbox_updated = data.giftbox_updated ? new Date(data.giftbox_updated) : null;
      const doc_key = this.firestore.createId();
      this.firestore.collection('event').doc('cKuntx8VeyzKCYFKYH3V').collection('guest').doc(doc_key).set(data);
      // this.firestore.collection('guest').doc(doc_key).set(data);
    });
  }

  /**
   * テストデータ投入用（グループ）
   *
   * @memberof GuestService
   */
  test_guest_group_add(): void {
    let guest_group = require('../datas/guest_group.json');
    guest_group.forEach((data: any, i: number) => {
      guest_group[i].created = new Date(data.created);
      guest_group[i].updated = new Date(data.updated);
      const doc_key = this.firestore.createId();
      this.firestore.collection('event').doc('cKuntx8VeyzKCYFKYH3V').collection('guest_group').doc(doc_key).set(data);
      // this.firestore.collection('guest_group').doc(doc_key).set(data);
    });
  }

  /**
   * 失敗したHttp操作を処理します。
   * アプリを持続させます。
   * @param operation - 失敗した操作の名前
   * @param result - observableな結果として返す任意の値
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: リモート上のロギング基盤にエラーを送信する
      console.error(error); // かわりにconsoleに出力

      // TODO: ユーザーへの開示のためにエラーの変換処理を改善する
      // this.log(`${operation} failed: ${error.message}`);

      // 空の結果を返して、アプリを持続可能にする
      return of(result as T);
    };
  }

  // tmp(): void {
  //   this.firestore.collection<Guest>('guest').get().pipe(
  //     map(datas =>
  //       datas.forEach(doc => {
  //         let guest_ref = doc.ref;
  //         this.firestore.firestore.runTransaction(async transaction => {
  //           await transaction.get(guest_ref);
  //           transaction.update(guest_ref, {
  //             'group_updated': new Date(null)
  //           });
  //         })
  //         .then(() => {
  //           debugLog("Transaction successfully committed!");
  //         })
  //         .catch(error => {
  //           debugLog("Transaction failed: ", error);
  //         });
  //       })
  //     )
  //   );
  // }

}
