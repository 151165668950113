<div id="modal-error" class="modal-box bg-gry">
  <div class="confirmation-box">
    <div class="text-m js-modal-msg"></div>
    <div class="text-center mgt20">
      <button type="button" class="btn-item gray w-style1 js-modalErrorOkBtn" (click)="ok_click()">OK</button>
    </div>
  </div><!--/confirmation-box -->
</div><!--/modal-box-->

<div id="modal-confirmation" class="modal-box">
  <div class="modal-content">
    <div class="text-center">
      <div class="text-confirmation flex-box j-content-center"><span class="icon-note js-modal-title"></span></div>
    </div>
    <div class="text-m js-modal-msg"></div>
    <div class="text-center mgt20">
      <button type="button" class="btn-item gray w-style1">OK</button>
    </div>
  </div><!--/modal-content-->
</div><!--/modal-box-->

<div id="modal-reorder" class="modal-box">
  <div class="modal-content">
    <div class="text-m js-modal-msg">変更した内容でこのギフトセットを再注文しますか。<br>
    まだ商品、贈る相手を編集中の場合は「中止する」を選択してください。</div>
    <div class="flex-box j-content-center mgt20">
      <button type="button" class="btn-item w-style1 js-modalReorderBtn" (click)="re_order_click(true)"><span>再注文する</span></button>
      <button type="button" class="btn-item white w-style1 js-modalOrderStopBtn" (click)="re_order_click(false)"><span>中止する</span></button>
    </div>
  </div><!--/modal-content-->
</div><!--/modal-box-->

<div id="modal-order-cancel" class="modal-box">
  <div class="modal-content">
    <div class="text-m js-modal-msg">このギフトセットの注文をキャンセルしますか。この操作は取り消しできません。</div>
    <div class="flex-box j-content-center mgt20">
      <button type="button" class="btn-item w-style1 js-modalReorderBtn" (click)="order_cancel_click(true)"><span>注文キャンセル</span></button>
      <button type="button" class="btn-item white w-style1 js-modalOrderStopBtn" (click)="order_cancel_click(false)"><span>中止する</span></button>
    </div>
  </div><!--/modal-content-->
</div><!--/modal-box-->

<div id="modal-reorder-cancel" class="modal-box">
  <div class="modal-content">
    <div class="text-m js-modal-msg">ギフトセットの商品数または贈る相手が0件です。この注文をキャンセルしますか。<br>
    まだ商品、贈る相手を編集中の場合は「中止する」を選択してください。</div>
    <div class="flex-box j-content-center mgt20">
      <button type="button" class="btn-item w-style1 js-modalReorderBtn" (click)="re_order_cancel_click(true)"><span>注文キャンセル</span></button>
      <button type="button" class="btn-item white w-style1 js-modalOrderStopBtn" (click)="re_order_cancel_click(false)"><span>中止する</span></button>
    </div>
  </div><!--/modal-content-->
</div><!--/modal-box-->

<div id="modal-no-transfer" class="modal-box">
  <div class="modal-content">
    <div class="text-m js-modal-msg">不在時転送先が未登録です。処理を中止しました。</div>
    <div class="text-center mgt20">
      <button type="button" class="btn-item gray w-style1" (click)="no_transfer_ok_click()">OK</button>
    </div>
  </div><!--/modal-content-->
</div><!--/modal-box-->

<div id="modal-message" class="modal-box">
  <div class="modal-content">
    <div class="text-m js-modal-msg"></div>
    <div class="flex-box j-content-center mgt20">
      <button type="button" class="btn-item w-style1" (click)="msg_click(true)"><span>はい</span></button>
      <button type="button" class="btn-item white w-style1" (click)="msg_click(false)"><span>いいえ</span></button>
    </div>
  </div><!--/modal-content-->
</div><!--/modal-box-->

<div id="modal-type-message" class="modal-box">
  <div class="modal-content">
    <div class="text-m js-modal-msg"></div>
    <div class="flex-box j-content-center mgt20">
      <input type="hidden" class="type_name" value="">
      <button type="button" class="btn-item w-style1" (click)="type_msg_click($event, true)"><span>はい</span></button>
      <button type="button" class="btn-item white w-style1" (click)="type_msg_click($event, false)"><span>いいえ</span></button>
    </div>
  </div><!--/modal-content-->
</div><!--/modal-box-->

<div id="consent-modal-message" class="consent-modal-box">
  <div class="modal-content consent">
    <div class="js-modal-msg">
      当社のWEBサイトでは、お客様により良いサービスを提供するため、Cookieを利用しています。収集される情報は当社のプライバシーポリシーに基づいて管理されます。
      Cookieの使用に同意いただける場合は「同意する」ボタンをクリックし、当社のCookieについての詳細は
      <a routerLink="/terms-and-policy/policy" style="color: #fff;text-decoration-line: underline;padding:0 3px;">プライバシーポリシー</a>
      をご覧ください。
    </div>
    <div class="flex-box j-content-end">
      <button type="button" class="btn-item consent" (click)="msg_click(true)"><span>同意する</span></button>
      <!-- <button type="button" class="btn-item consent" (click)="msg_click(false)"><span>同意しない</span></button> -->
    </div>
  </div><!--/modal-content-->
</div><!--/consent-modal-box-->


<!-- カートへ入れるダイアログ -->
<!-- <div id="modal-cart-in" class="modal-box">
  <div class="modal-content">
    <div class="text-m js-modal-msg"></div>
    <div class="flex-box j-content-center mgt20">
      <button type="button" class="btn-item w-style1" (click)="cart_in_click(true)"><span>はい</span></button>
      <button type="button" class="btn-item white w-style1" (click)="cart_in_click(false)"><span>いいえ</span></button>
    </div>
  </div>
</div> -->

<!-- 注文ダイアログ -->
<div id="modal-order" class="modal-box">
  <div class="modal-content">
    <div class="text-m js-modal-msg"></div>
    <div class="flex-box j-content-center mgt20">
      <button type="button" class="btn-item w-style1" (click)="order_click(true)"><span>はい</span></button>
      <button type="button" class="btn-item white w-style1" (click)="order_click(false)"><span>いいえ</span></button>
    </div>
  </div>
</div>

<div id="modal-joiintnames" class="modal-box">
  <div class="about-box">
    <div class="title">連名にする場合の方法</div>
    <div class="ex-image">
      <img src="assets/img/img_jointnames_sp.png" alt="" class="sp">
      <img src="assets/img/img_jointnames_pc.png" alt="" class="pc">
    </div>
  </div><!--/about-box -->
  <button type="button" class="modal-close"><div class="icon-btn"><span class="color-gry"></span></div></button>
</div><!--/modal-box-->

<div id="modal-shippingfee" class="modal-box">
	<div class="about-box">
		<div class="title">熨斗カード</div>
		<div class="thanks-card-wrap">
      <div class="thanks-card-pattern">
				<img src="assets/img/noshi_card.jpg" alt="">
			</div><!--/thanks-card-pattern -->
			<div class="modal-cont-title">パターンA</div>
			<div class="thanks-card-pattern">
				<img src="assets/img/thanks_card_a.png" alt="">
			</div><!--/thanks-card-pattern -->
			<div class="modal-cont-title">パターンB</div>
			<div class="thanks-card-pattern">
				<img src="assets/img/thanks_card_b.png" alt="">
			</div><!--/thanks-card-pattern -->
		</div><!--/thanks-card-wrap -->
	</div><!--/about-box -->
	<button type="button" class="modal-close"><div class="icon-btn"><span class="color-gry"></span></div></button>
</div><!--/modal-box-->

<div id="modal-deskcard" class="modal-box">
	<div class="about-box">
    <div class="title">卓上カード</div>
    <div class="thanks-card-wrap">
      <div class="text-m" style="margin-top: 15px;">
        原則として挙式日の2日前または3日前の木曜日納品にてお届けいたします。<br>
        納品先ご住所は各挙式情報にてご指定いただけます。<br>
        ご指定がない場合は各会場／サロンへのお届けとなります。
      </div>
      <div class="thanks-card-pattern">
				<img src="assets/img/desk_card.png" alt="">
			</div><!--/thanks-card-pattern -->
		</div><!--/thanks-card-wrap -->
	</div><!--/about-box -->
	<button type="button" class="modal-close"><div class="icon-btn"><span class="color-gry"></span></div></button>
</div><!--/modal-box-->

<div id="modal-other"></div>
