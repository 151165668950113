import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { SpinnerComponent } from '../spinner/spinner.component';

import { AuthService } from '../../service/auth.service';
import { SessionService } from '../../service/session.service';
import * as constant from '../../models/constant';

import $ from 'jquery';

declare const debugLog: any;

@Component({
  selector: 'app-pw-reset',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    SpinnerComponent,
  ],
  templateUrl: './pw-reset.component.html',
  styleUrls: ['./pw-reset.component.scss']
})
export class PwResetComponent implements OnInit {
  public user: Observable<firebase.User | null> = of(null);
  public login_message: string = '';
  public role: number = -1;
  private subscription: Subscription = new Subscription();
  private isBrowser: boolean;

  constructor(
    private sv_auth: AuthService,
    private sv_session: SessionService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.login_message = "";
    this.user = this.sv_auth.signInInit();
    this.afAuth.onAuthStateChanged(user => {
      if (user && user.providerData.length > 0) {
        console.log('signIn');
        this.routing();
      } else {
        console.log('Not signIn');
      }
    });
    if (this.isBrowser) {
      if (!$('body').hasClass('login')) {
        $('body').addClass('login');
      }
    }
  }

  // パスワード再設定
  public passwordReset() {
    let email: string = $('#email').val() as string;
    this.sv_auth.passwordReset(email);
    return false;
  }

  public clear(): void {
    $('#email').val('');
  }

  private routing(): void {
    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) return;
        this.role = session.data.current_role;
        if (this.role == 1) {
          this.router.navigate(['../items'], {relativeTo: this.route});
        } else if (this.role == 2 || this.role == 3) {
          this.router.navigate([`../order-list/${constant.ORDER_LIST_TYPE.DEFAULT}`], {relativeTo: this.route});
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.isBrowser) {
      if ($('body').hasClass('login')) {
        $('body').removeClass('login');
      }
    }
  }

}
