import { Component, OnInit, Input } from '@angular/core';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-event-qr',
  standalone: true,
  imports: [
    QRCodeModule,
  ],
  templateUrl: './event-qr.component.html',
  styleUrls: ['./event-qr.component.scss']
})
export class EventQrComponent implements OnInit {
  @Input() url: string = '';

  constructor() {

  };

  ngOnInit() {
  }

}
