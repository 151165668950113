import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule, Location, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ModalMessageComponent } from '../../common/modal-message/modal-message.component';

import { AccountService } from '../../service/account.service';
import { EventService } from '../../service/event.service';
import { PlaceService } from '../../service/place.service';
import { SessionService } from '../../service/session.service';

import { Account } from '../../models/account';
import { EventSearch } from '../../models/event_search';
import { Place } from '../../models/place';
import { InputSelect } from '../../models/input_select';
import * as constant from '../../models/constant';

import moment from 'moment';
import $ from 'jquery';

declare function resetCommonrEvent(): void;
declare function resetMemberEvent(): void;
declare function showSimpleMsg(msg: string): void;
declare function showConfirmMsg(msg: string): void;
declare const debugLog: any;

@Component({
  selector: 'app-member-change',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ModalMessageComponent,
  ],
  templateUrl: './member-change.component.html',
  styleUrls: ['./member-change.component.scss']
})
export class MemberChangeComponent implements OnInit {
  public place: Place = new Place();
  private place_id: string = '';
  public staff_id: string = '';
  public staff: Account | null = null;
  public events: EventSearch[] = [];
  public members: InputSelect[] = [];
  public select_member: string = '';
  private target_index: number = -1;
  private all: boolean = false;
  private message: string = '';
  public pages: number[] = [];
  public select_page_index: number = 0;
  public start: number = 0;
  public len: number = constant.LIST_ROW_MAX;
  private subscription: Subscription = new Subscription();

  constructor(
    private sv_place: PlaceService,
    private sv_account: AccountService,
    private sv_session: SessionService,
    private sv_event: EventService,
    private route: ActivatedRoute,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add (
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        }
      })
    );
    this.sv_session.getPlaceInfo();
    this.subscription.add (
      this.sv_session.placeInfo.subscribe(placeInfo => {
        if (placeInfo) {
          this.place = placeInfo;
          this.place_id = placeInfo.objectID;
          this.route.params.subscribe(params => {
            this.staff_id = params['id'];
            this.getStaff();
            this.getEevnts();
            this.getMembers();
          });
        }
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent();
    }
  }

  public back(): void {
    this.location.back();
  }

  private getStaff(): void {
    this.sv_account.getAccount(this.staff_id).subscribe(staff => {
      if (staff) {
        this.staff = staff;
      }
    })
  }

  private getEevnts(): void {
    this.sv_event.getEventSearchesForMember(this.place_id, this.staff_id).subscribe(events => {
      this.events = events;
      // ページ設定
      this.pages = [];
      for(let i = 1; i <= Math.ceil(events.length / this.len); i++) {
        this.pages.push(i);
      }
    })
  }

  private getMembers(): void {
    this.subscription.add(
      this.sv_place.getMembers(this.place_id).subscribe(members => {
        this.members = [];
        members.forEach(member => {
          this.members.push({
            key: member.objectID,
            value: member.last_name + member.first_name,
            grep_colum: null,
            select: false,
            disabled: false
          });
        });
      })
    );
  }

  public changeStaff(i:number, event: any):void {
    this.target_index = i;
    let name = this.events[i].name;
    showConfirmMsg(`${name}の担当を変更します。<br/>よろしいですか？`);
  }

  public changeStaffAll():void {
    this.all = true;
    this.target_index = -1;
    showConfirmMsg('全ての担当を変更します。<br/>よろしいですか？');
  }

  public onModalMsgEvent(msg: boolean){
    if (!msg) {
      // selectedを戻す
      let member_index = 0;
      this.members.forEach((m, i) => {
        if (this.staff_id == m.key) member_index = i;
      });
      if (isPlatformBrowser(this.platformId)) {
        if (!this.all) {
          $(`#select_${this.target_index} option[value="${member_index}: ${this.staff_id}"]`).prop('selected', true);
        } else {
          $(`#all_select option[value="${member_index}: ${this.staff_id}"]`).prop('selected', true);
        }
      }
      return;
    }
    setTimeout(() => {
      if (!this.all && this.target_index >= 0) {
        this.updateMainStaff(this.target_index);
      } else if (this.all) {
        this.events.forEach((event, index) => {
          this.updateMainStaff(index);
        });
      }
    }, 300);
  }

  private updateMainStaff(i: number): void {
    if (!this.staff) return;
    let event_id = this.events[i].objectID;
    let updated_user = {uid: this.staff_id, name: this.staff.last_name + this.staff.first_name};
    let staff_ids: string[] = this.events[i].staff_ids;
    staff_ids.forEach((sid, j) => {
      if (staff_ids[j] && this.staff_id == staff_ids[j]) {
        staff_ids[j] = this.select_member;
      }
    });
    if (!staff_ids.length) {
      staff_ids.push(this.select_member);
    }
    this.subscription.add(
      this.sv_event.updateStaff(event_id, staff_ids, updated_user).subscribe(data => {
        if (!data.result) {
          alert(data.msg);
        }
        this.target_index = -1;
      })
    );
  }

  public paging(page: number, index: number): void {
    this.select_page_index = index;
    this.start = this.len * index;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
