export interface IEvent {
  objectID: string;
  name: string;
  date: any; // 日付計算のためmomentで変換するのでanyで定義
  memo: string;
  company_id: string;
  company_name: string; // 特定企業、会場に所属しないフリープランナーの場合に使用
  place_id: string;
  place_name: string; // 特定企業、会場に所属しないフリープランナーの場合に使用
  cust1_id: string;
  cust2_id: string;
  staff_ids: string[];
  status: number;
  token_guest: {
    id: string
    limit: any  // 日付計算のためmomentで変換するのでanyで定義
  };
  token_cust: {
    id: string
    limit: any // 日付計算のためmomentで変換するのでanyで定義
  };
  // sender_first_name: string;
  // sender_last_name: string;
  // sender_first_kname: string;
  // sender_last_kname: string;
  // sender_zip: string;
  // sender_addr1: string;
  // sender_addr2: string;
  // sender_addr3: string;
  // sender_addr4: string;
  // sender_tel: string;
  transfer_first_name: string;
  transfer_last_name: string;
  transfer_first_kname: string;
  transfer_last_kname: string;
  transfer_zip: string;
  transfer_addr1: string;
  transfer_addr2: string;
  transfer_addr3: string;
  transfer_addr4: string;
  transfer_tel: string;
  table_card_zip: string; // 卓上カード送り先
  table_card_addr1: string;
  table_card_addr2: string;
  table_card_addr3: string;
  table_card_addr4: string;
  table_card_tel: string;
  created: Date;
  updated: Date;
  updated_user: {
    uid: string;
    name: string;
  };
  delete_flg: boolean;
}
export class Event implements IEvent {
  objectID: string = '';
  name: string = '';
  date: any = null // 日付計算のためmomentで変換するのでanyで定義
  memo: string = '';
  company_id: string = '';
  company_name: string = ''; // 特定企業、会場に所属しないフリープランナーの場合に使用
  place_id: string = '';
  place_name: string = ''; // 特定企業、会場に所属しないフリープランナーの場合に使用
  cust1_id: string = '';
  cust2_id: string = '';
  staff_ids: string[] = [];
  status: number = 0;
  token_guest: {
    id: string
    limit: any  // 日付計算のためmomentで変換するのでanyで定義
  } = {id: '', limit: null};
  token_cust: {
    id: string
    limit: any // 日付計算のためmomentで変換するのでanyで定義
  } = {id: '', limit: null};
  transfer_first_name: string = '';
  transfer_last_name: string = '';
  transfer_first_kname: string = '';
  transfer_last_kname: string = '';
  transfer_zip: string = '';
  transfer_addr1: string = '';
  transfer_addr2: string = '';
  transfer_addr3: string = '';
  transfer_addr4: string = '';
  transfer_tel: string = '';
  table_card_zip: string = ''; // 卓上カード送り先
  table_card_addr1: string = '';
  table_card_addr2: string = '';
  table_card_addr3: string = '';
  table_card_addr4: string = '';
  table_card_tel: string = '';
  created: Date = new Date();
  updated: Date = new Date();
  updated_user: {
    uid: string;
    name: string;
  } = {uid: '', name: ''};
  delete_flg: boolean = false;
}
