import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { SpinnerComponent } from '../spinner/spinner.component';

import { CommonService } from '../../service/common.service';
import { SessionService } from '../../service/session.service';

import moment from 'moment';
import $ from 'jquery';

declare function resetCommonrEvent(): void;

@Component({
  selector: 'app-terms-and-policy',
  standalone: true,
  imports: [
    CommonModule,
    SpinnerComponent,
    NgxPageScrollModule,
    NgxPageScrollCoreModule,
  ],
  templateUrl: './terms-and-policy.component.html',
  styleUrls: ['./terms-and-policy.component.scss']
})
export class TermsAndPolicyComponent implements OnInit {

  public title: string = '';
  public body: string = '';
  public terms_open: boolean = false;
  public terms_body: string = '';
  public session_exist: boolean = false;
  private type: string = '';
  public ready: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private sv_common: CommonService,
    private sv_session: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
    this.sv_session.loginCheck();
    this.subscription.add (
      this.sv_session.sessionState.subscribe(session => {
        if (!session) {
          this.session_exist = false;
          // ハンバーガーメニュー非表示。ロゴのリンクを無効に。
        } else {
          this.session_exist = true;
        }
        // コンテンツ本文を取得
        this.route.params.subscribe(params => {
          this.type = params['type'];
          switch(this.type) {
            case 'terms': // ご利用規約
              // this.getTerms();
              break;
            case 'policy':
              this.getPolicy(); // プライバシーポリシー
              break;
            default: // それ以外の場合は not found ページへ
              this.router.navigate(['/not-found-page'], {relativeTo: this.route});
              this.ready = true;
          }
        })
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント再設定
    }
  }

  // private getTerms(): void {
  //   this.sv_common.getTerms().subscribe(terms => {
  //     if (terms) {
  //       this.body = terms.body.replace(/\\n/g, '\n');
  //       this.title = 'ご利用規約'
  //       $('html, body').animate({ scrollTop: 0 }, '1');
  //     } else {
  //       this.body = null;
  //     }
  //     this.ready = true;
  //     resetCommonrEvent();
  //   });
  // }

  private getPolicy(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.sv_common.getPolicy().subscribe(terms => {
      if (terms) {
        this.title = 'プライバシーポリシー';
        this.body = this.sv_common.getActiveLink(terms.body);
        $('.terms').html(this.body);
        $('html, body').animate({ scrollTop: 0 }, '1');
      } else {
        this.body = '';
      }
      this.ready = true;
      resetCommonrEvent(); // ngOnInit()内では表示タイミング的にイベントが正常に設定されない場合あり。ready設定後のここでも実行。
    });
  }

  back(): void {
    window.history.back();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
