import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MatProgressSpinnerModule, MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-spinner',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  private overlayRef: OverlayRef;
  public isLoading: boolean = false;

  constructor(
    private overlay: Overlay
  ) {
    // オーバーレイの参照を作成
    this.overlayRef = this.overlay.create({
      hasBackdrop: true, // 背景を暗くする
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });
  }

  ngOnInit() {
    // ローディング開始
    this.isLoading = true;
    this.overlayRef.attach(new ComponentPortal(MatProgressSpinner));
  }

  ngOnDestroy() {
    // ローディング終了
    this.isLoading = false;
    this.overlayRef.detach();
  }

}
