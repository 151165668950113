import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { stringify, parse } from 'flatted';

import { Subscription, forkJoin, Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { ModalComponent } from '../../common/modal/modal.component';
import { ModalMessageComponent } from '../../common/modal-message/modal-message.component';
import { ItemDetailComponent } from '../../common/item-detail/item-detail.component';

import { OrderService } from '../../service/order.service';
import { SessionService } from '../../service/session.service';
import { EventService } from '../../service/event.service';
import { CompanyService } from '../../service/company.service';
import { PlaceService } from '../../service/place.service';
import { CommonService } from '../../service/common.service';
import { ConfigService } from '../../service/config.service';
import { ItemService } from '../../service/item.service';
import { ModalService } from '../../service/modal.service';

import { Session } from '../../models/session';
import { OrderData } from '../../models/order_data';
import { OrderedGiftbox } from '../../models/ordered_giftbox';
import { OrderBranch } from '../../models/order_branch';
import { Mail } from '../../models/mail';
import * as constant from '../../models/constant';
import * as rito from '../../models/rito';

import { environment } from '../../../environments/environment';

import moment from 'moment';
import $ from 'jquery';

declare function resetCommonrEvent(): void;
declare function showSimpleMsg(msg: string): void;
declare function showConfirmMsg(msg: string): void;
declare const debugLog: any;

@Component({
  selector: 'app-order-detail',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ModalComponent,
    ModalMessageComponent,
    ItemDetailComponent,
  ],
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {

  private order_id: string = '';
  private order_limit: Date = new Date(0);
  private session: Session = new Session();
  public order_info: OrderData = new OrderData();
  public order_list_type = constant.ORDER_LIST_TYPE;
  public ordered_giftboxes: OrderedGiftbox[] = []; // この発注のギフトセット情報
  public event_ordered_giftboxes: OrderedGiftbox[] = []; // この挙式の全発注のギフトセット情報
  public item_changes: any[] = [];
  public item_status_arr: Observable<number>[] = [];
  private guest_changes: any[] = [];
  public guest_change_exists: boolean[] = [];
  private latest_ordered_giftboxes: OrderedGiftbox[] = [];
  public latest_ordered_gb_guest_count: number[] = [];
  public delive_fee: number = constant.DELIVE_FEE;
  public delive_fee_additional: number = constant.DELIVE_FEE_ADDITIONAL;
  public delive_fee_okinawa: number = constant.DELIVE_FEE_OKINAWA;
  public delive_fee_limit_price = constant.DELIVE_FEE_LIMIT_PRICE;
  public is_additional: boolean = false; // 送料区分判定（true:送料別、false:送料込み）
  public place_tax_rate: {rule: boolean, rate: number | null} = {rule: false, rate: 0};
  public status = constant.ORDER_STATUS;
  public item_status = constant.ITEM_STATUS;
  private today: Date = moment(new Date().setHours(0,0,0,0)).toDate(); // 現在日付（発注期限超過判定のため時間は考慮しない）
  public limit_over: boolean = false;
  public ready: boolean = false;
  private act_type: string = '';
  public okinawa_honto_num: number = 0;
  public dummy_thumb_s_url = environment.NO_IMAGE_THUMB_S;
  public taxable: boolean = true;  // 税込総額表示の対象（2021/1/29以前に生成された注文データは税額のデータを持っていないため総額表示の対象外とする）
  private taxable_limit: Date = moment(new Date(constant.TAXABLE_DATE).setHours(0,0,0,0)).toDate();
  public contain_untaxable_order: boolean = false; // 挙式の注文データに総額表示対象外の注文が含まれているか判定
  public show_item_detail: boolean = false;
  public select_item_key: string = '';
  private subscription: Subscription = new Subscription();
  public rate = constant.TAX_RATE;
  public Math = Math;

  constructor(
    private sv_session: SessionService,
    private sv_order: OrderService,
    private sv_event: EventService,
    private sv_company: CompanyService,
    private sv_place: PlaceService,
    private sv_common: CommonService,
    private sv_config: ConfigService,
    private sv_item: ItemService,
    private sv_modal: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {

  }

  ngOnInit() {
    this.subscription.add(
      this.sv_modal.close_result$.subscribe(() => {
        this.show_item_detail = false;
      })
    );
    // ログインセッション取得
    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.session = session;
          this.is_additional = session.data.delive_fee_type == constant.CAMPANY_DELIVE_FEE_TYPE.ADDITIONAL;
          this.sv_event.initialize(session);
          this.sv_order.initialize(session);
          if (session.data.current_place) {
            this.sv_place.getPlace(session.data.current_company, session.data.current_place).subscribe(place => {
              if (place) {
                this.place_tax_rate = place.place_tax_rate;
              }
            })
          }
        }
      })
    );

    this.subscription.add(
      this.route.params.subscribe(params => {
        this.order_id = params['id'];
        // ストレージから注文基本情報(order, branch)を取得
        this.sv_session.getOrderBaseInfo();
        this.sv_session.orderBaseInfo.subscribe(base => {
          // 送料改定対応
          if (moment(base.event_date).isBefore(moment(new Date(constant.NEW_DELIVE_FEE_DATE)))) {
            this.delive_fee = constant.DELIVE_FEE;
            this.delive_fee_additional = constant.DELIVE_FEE_ADDITIONAL;
          } else {
            this.delive_fee = constant.NEW_DELIVE_FEE;
            this.delive_fee_additional = constant.NEW_DELIVE_FEE_ADDITIONAL;
          }
          // 注文詳細情報を取得
          this.getOrderDate(base);
        });
      })
    );

    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント設定
    }
  }

  private getOrderDate(base: OrderData): void {
    if (!base.order_branch) return;
    //// この注文情報が履歴の場合
    if (!base.order_branch.latest) {
      this.order_info = base; // 注文基本情報はストレージの値を流用。
      this.limit_over = moment(this.order_info.order_limit).unix() < moment(this.today).unix();
      // 注文期限超過判定
      this.subscription.add(
        this.sv_config.getOrderLimit(this.order_info.event_date).subscribe(adjust => {
          this.limit_over = adjust.limit_over;
        })
      );
      // 総額表示判定
      this.taxable = moment(this.order_info.order_branch?.order_request_date).unix() >= moment(this.taxable_limit).unix();
      // 注文詳細情報（ギフトセット、ゲスト）を取得
      this.getOrderDetail();
      this.saveOrderInfo();
    }
    //// この注文情報が最新の場合
    else {
      // ストレージから取得した注文基本情報のうち、branchは最新の情報に置き換える。（branch配下のデータは顧客側で随時変更される可能性があるため）
      this.subscription.add(
        this.sv_order.getOrderData(this.order_id).subscribe(datas => {
          if (!datas) return;

          // branchを最新に。
          let data = datas[0];
          if (data.order_branch) {
            // 日付系はUnix変換
            let b = data.order_branch;
            data.order_branch.order_request_date = b.order_request_date ? moment(b.order_request_date.toDate()).toDate() : null;
            data.order_branch.order_date = b.order_date ? moment(b.order_date.toDate()).toDate() : null;
            data.order_branch.order_accept_date = b.order_accept_date ? moment(b.order_accept_date.toDate()).toDate() : null;
            data.order_branch.cancel_request_date = b.cancel_request_date ? moment(b.cancel_request_date.toDate()).toDate() : null;
            data.order_branch.order_cancel_date = b.order_cancel_date ? moment(b.order_cancel_date.toDate()).toDate() : null;
            data.order_branch.cancel_date = b.cancel_date ? moment(b.cancel_date.toDate()).toDate() : null;
          }
          // orderとeventの情報はストレージの値を流用。
          data.order_no = base.order_no;
          data.event_id = base.event_id;
          data.event_name = base.event_name;
          data.event_date = base.event_date;
          data.event_status = base.event_status;
          data.order_limit = base.order_limit;

          this.order_info = data;
          // 注文期限超過判定
          this.limit_over = moment(this.order_info.order_limit).unix() < moment(this.today).unix();
          // 総額表示判定
          this.taxable = moment(this.order_info.order_branch?.order_request_date).unix() >= moment(this.taxable_limit).unix();
          // 注文詳細情報（ギフトセット、ゲスト）を取得
          this.getOrderDetail();
          // 直近のタイムレス発注内容を取得（差分比較のため）
          if (
            this.order_info.order_branch?.status == constant.ORDER_STATUS.REQUEST_AFTER_ORDER ||
            this.order_info.order_branch?.status == constant.ORDER_STATUS.REQUEST_AFTER_ACCEPT ||
            this.order_info.order_branch?.status == constant.ORDER_STATUS.CANCEL_REQUEST ||
            this.order_info.order_branch?.status == constant.ORDER_STATUS.CANCEL_ORDER ||
            this.order_info.order_branch?.status == constant.ORDER_STATUS.CANCEL_BY_ADMIN
          ) {
            this.getLatestOrderDetail(); // 最後に会場からタイムレスへ発注した内容を取得
          }
          this.saveOrderInfo();
        })
      );
    }
  }

  private getOrderDetail() {
    if (!this.order_info.order_branch) return;
    this.subscription.add(
      this.sv_order.getOrderdGiftboxes(this.order_info.order_id, this.order_info.order_branch.objectID).pipe(take(1)).subscribe(orderd_giftboxes => {
        let ordered: OrderedGiftbox[] = [];
        orderd_giftboxes.forEach(ordered_giftbox => {
          let data: OrderedGiftbox = ordered_giftbox;
          forkJoin([
            data.order_items$.pipe(take(1)),
            data.order_guests$.pipe(take(1))
          ]).subscribe(([items, guests]) => {
            data.order_items = items;
            // 現在の商品ステータスチェック
            this.item_status_arr = [];
            items.forEach((item, k) => {
              this.item_status_arr[k] = this.sv_item.getItemStatus(item.objectID);
            });
            // 沖縄本島チェック
            this.okinawa_honto_num = 0;
            guests.forEach((guest, i) => {
              guests[i].deliv_date = moment(guest.deliv_date.toDate()).toDate();
              let regexp = new RegExp(guest.zip);
              rito.HONTO_OKINAWA_ZIP_LIST.forEach(zip => {
                if (regexp.test(zip)) {
                  this.okinawa_honto_num++;
                }
              });
            });
            data.order_guests = guests;
            ordered.push(data);
          });
        });
        this.ordered_giftboxes = ordered;

        // 最終発注との差分チェック用配列を初期化
        setTimeout(() => {
          ordered.forEach((gb, g) => {
            this.item_changes[g] = new Array(gb.order_items.length).fill(false);
            this.guest_changes[g] = new Array(gb.order_guests.length).fill(false);
            this.latest_ordered_gb_guest_count[g] = 0;
          });
          this.ready = true;
        }, 500);
      })
    );
  }

  private getLatestOrderDetail() {
    // 直近で発注されたデータを取得する（差分チェックのため）
    this.subscription.add(
      this.sv_order.getLatestOrderBranchId(this.order_info.order_id).subscribe(branches => {
        if (branches.length) {
          branches.forEach((branch, i) => {
            if (branch.status == constant.ORDER_STATUS.REQUEST) {
              branches.splice(i, 1);
            }
          });
          let latest_branch_id = branches[0].branch_id; // 発注が最も最近の1件を
          this.sv_order.getOrderdGiftboxes(this.order_info.order_id, latest_branch_id).pipe(take(1)).subscribe(latest_orderd_giftboxes => {
            let latest_ordered: OrderedGiftbox[] = [];
            latest_orderd_giftboxes.forEach(latest_ordered_giftbox => {
              let data: OrderedGiftbox = latest_ordered_giftbox;
              forkJoin([
                data.order_items$.pipe(take(1)),
                data.order_guests$.pipe(take(1))
              ]).subscribe(([items, guests]) => {
                data.order_items = items;
                guests.forEach((guest, i) => guests[i].deliv_date = moment(guest.deliv_date.toDate()).toDate());
                data.order_guests = guests;
                latest_ordered.push(data);
              });
            });
            this.latest_ordered_giftboxes = latest_orderd_giftboxes;
            setTimeout(() => this.checkDiff(), 500);
          })
        }
      })
    );
  }

  private checkDiff(): void {
    this.ordered_giftboxes.forEach((gb, g) => {
      this.guest_change_exists[g] = false;
      this.latest_ordered_giftboxes.forEach(l_gb => {
        if (gb.order_giftbox.objectID === l_gb.order_giftbox.objectID) {
          // 商品に関する変更
          gb.order_items.forEach((item, i) => {
            let item_hit: boolean = false;
            l_gb.order_items.forEach((l_item, r) => {
              if (item.objectID === l_item.objectID) {
                item_hit = true;
                if (item.num !== l_item.num) {
                  this.item_changes[g][i] = true;
                } else {
                  this.item_changes[g][i] = false;
                }
              }
              if (r === l_gb.order_items.length -1) {
                if (!item_hit) {
                  this.item_changes[g][i] = true;
                }
              }
            });
          });
          // 贈る相手に関する変更
          gb.order_guests.forEach((guest, k) => {
            let guest_hit: boolean = false;
            l_gb.order_guests.forEach((l_guest, n) => {
              if (guest.objectID === l_guest.objectID) {
                guest_hit = true;
                if (guest.first_name !== l_guest.first_name ||
                    guest.last_name !== l_guest.last_name ||
                    guest.zip !== l_guest.zip ||
                    guest.addr1 !== l_guest.addr1 ||
                    guest.addr2 !== l_guest.addr2 ||
                    guest.addr3 !== l_guest.addr3 ||
                    guest.addr4 !== l_guest.addr4 ||
                    guest.tel !== l_guest.tel ||
                    guest.card !== l_guest.card ||
                    moment(guest.deliv_date).unix() !== moment(l_guest.deliv_date).unix() // Timestampのままだと比較が正常にできなかったためUnixで比較。
                  ) {
                  this.guest_changes[g][k] = true;
                  this.guest_change_exists[g] = true;
                  this.latest_ordered_gb_guest_count[g] = l_gb.order_guests.length;
                } else {
                  this.guest_changes[g][k] = false;
                }
              }
              if (n === l_gb.order_guests.length -1) {
                if (!guest_hit) {
                  this.guest_changes[g][k] = true;
                  this.guest_change_exists[g] = true;
                }
                this.latest_ordered_gb_guest_count[g] = l_gb.order_guests.length;
              }
            });
          });
          // return true;
        }
      });
    });
  }

  public setDetail(type: number): void {
    if (!isPlatformBrowser(this.platformId)) return;

    if (type == 0) {
      this.local_storage.set(constant.STORAGE_LOCAL_ORDER_DETAIL, stringify(this.ordered_giftboxes)); // 循環参照しているクラスなのでJSONのエラー回避のためflattedでシリアル化。
      this.sv_session.orderDetailSubject.next(this.ordered_giftboxes);
      this.router.navigate([`/order-gift/${this.order_info.order_branch?.objectID}/order`], {relativeTo: this.route});
    } else {
      let data_type = 'gift';
      if (type == 1) {
        data_type = 'gift';
      } else if (type == 2) {
        data_type = 'sendlist';
      }
      this.getOrderdGiftboxesForEvent(data_type);
    }
  }

  private getOrderdGiftboxesForEvent(data_type: string) {
    // 挙式のorderを全て取得
    this.subscription.add(
      this.sv_order.getOrdersOfEvent(this.order_info.event_id).subscribe(orders => {
        // order内に総額表示対象でないデータが存在するか確認
        let untaxable: OrderData[] = orders.filter(od => moment(od.order_created.toDate()).unix() < moment(this.taxable_limit).unix());
        this.contain_untaxable_order = (untaxable && untaxable.length > 0);

        this.event_ordered_giftboxes = [];
        let total_price: number = 0;
        let total_deliv_fee: number = 0;
        orders.forEach(o => {
          this.sv_order.getLatestBranches(o.order_id).subscribe(branches => {
            if (!branches[0].order_branch) return;
            let branch: OrderBranch = branches[0].order_branch;
            if (
                branch.status == constant.ORDER_STATUS.DEFAULT ||
                branch.status == constant.ORDER_STATUS.CANCEL_REQUEST ||
                branch.status == constant.ORDER_STATUS.CANCEL_ORDER ||
                branch.status == constant.ORDER_STATUS.CANCEL ||
                branch.status == constant.ORDER_STATUS.CANCEL_BY_ADMIN
              ) {
              return;
            }
            // 合計金額を算出
            if (this.taxable && !this.contain_untaxable_order) {
              total_price += (branch.price + branch.tax + branch.reduced_tax);
              total_deliv_fee += (branch.deliv_fee + branch.deliv_fee_tax);
            } else {
              total_price += branch.price;
              total_deliv_fee += branch.deliv_fee;
            }
            // 挙式内のキャンセル系以外の各最新ブランチから各ギフトセットを取り出し、中の商品を取り出す
            let order_id: string = o.order_id;
            let branch_id: string = branch.objectID;
            this.sv_order.getOrderdGiftboxes(order_id, branch_id).pipe(take(1)).subscribe(orderd_gbs => {
              orderd_gbs.forEach(gb => {
                forkJoin([
                  gb.order_items$.pipe(take(1)),
                  gb.order_guests$.pipe(take(1))
                ]).subscribe(([items, guests]) => {
                  gb.order_items = items;
                  guests.forEach((guest, i) => {
                    guests[i].deliv_date = moment(guest.deliv_date.toDate()).toDate();
                  });
                  gb.order_guests = guests;
                  this.event_ordered_giftboxes.push(gb);
                });
              });
            });
          });
        });
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            // 発注総額
            this.local_storage.set(constant.STORAGE_LOCAL_EVENT_ORDER_TOTAL_PRICE, {price: total_price, deliv_fee: total_deliv_fee, contain_untaxable_order: this.contain_untaxable_order});
            this.sv_session.eventOrderTotalPriceSubject.next({price: total_price, deliv_fee: total_deliv_fee, contain_untaxable_order: this.contain_untaxable_order});
            // 発注した全ギフトセット
            this.local_storage.set(constant.STORAGE_LOCAL_ORDER_DETAIL, stringify(this.event_ordered_giftboxes)); // 循環参照しているクラスなのでJSONのエラー回避のためflattedでシリアル化。
            this.sv_session.orderDetailSubject.next(this.event_ordered_giftboxes);
            if (data_type == 'gift') {
              this.router.navigate([`/order-gift/${this.order_info.order_branch?.objectID}/event`], {relativeTo: this.route});
            } else if (data_type == 'sendlist') {
              this.router.navigate([`/order-sendlist/${this.order_info.order_branch?.objectID}/event`], {relativeTo: this.route});
            }
          }
        }, 1500);
      })
    );
  }

  public setDetailGiftbox(index: number): void {
    if (!isPlatformBrowser(this.platformId)) return;

    let order_detail_giftbox = this.ordered_giftboxes[index];
    let guest_changes = this.guest_changes[index];

    this.local_storage.set(constant.STORAGE_LOCAL_ORDER_DETAIL_GIFTBOX, stringify(order_detail_giftbox)); // 循環参照しているクラスなのでJSONのエラー回避のためflattedでシリアル化。
    this.local_storage.set(constant.STORAGE_LOCAL_ORDER_DETAIL_GUEST_CHANGE, guest_changes);
    this.sv_session.orderDetailGiftboxSubject.next(order_detail_giftbox);
    this.sv_session.orderDetailGuestChangeSubject.next(guest_changes);
    this.router.navigate([`/order-sendlist/${order_detail_giftbox.order_giftbox.objectID}/order`], {relativeTo: this.route});
  }

  public order_msg(type: string): boolean {
    this.act_type = type;
    this.subscription.add(
      this.sv_event.getEvent(this.order_info.event_id).pipe(take(1)).subscribe(event => {
        if (event) {
          // 新郎新婦ともに登録済みの場合のみ発注可能とする。
          if (!event.cust1_id || !event.cust2_id) {
            showSimpleMsg(constant.MSG_SET_FAILED_NOT_CUST_REGIST);
          } else {
            showConfirmMsg(constant.MSG_ORDER_CONFIR_MNG);
          }
        }
      })
    );
    return false;
  }

  public onModalMsgEvent(msg: boolean) {
    if (!msg) return;
    setTimeout(() => {
      if (this.act_type == 'ORDER') {
        this.order();
      } else if (this.act_type == 'CANCEL_ORDER') {
        this.cancelOrder();
      }
    }, 300);
  }

  private order() {
    if (!this.order_info.order_branch) return;

    let order_key = this.order_info.order_id;
    let branch_key = this.order_info.order_branch.objectID;
    let giftbox_ids: string[] = [];
    this.ordered_giftboxes.forEach(gb => {
      giftbox_ids.push(gb.order_giftbox.objectID);
    });
    this.subscription.add(
      this.sv_order.order(order_key, branch_key, giftbox_ids, this.order_info.order_branch.status).subscribe(data => {
        showSimpleMsg(data.msg as string);
        if (data.result && this.order_info.order_branch) {
          let status: number = constant.ORDER_STATUS.ORDER;
          if (this.order_info.order_branch.status <= constant.ORDER_STATUS.REQUEST_AFTER_ORDER) {
            status = constant.ORDER_STATUS.ORDER;
          } else if (this.order_info.order_branch.status >= constant.ORDER_STATUS.REQUEST_AFTER_ACCEPT) {
            status = constant.ORDER_STATUS.ORDER_AFTER_ACCEPT;
          }
          // ギフトセンターへ通知メール送信
          if (this.session.data) {
            if (this.session.data.current_place) { // 会場がある場合
              this.sv_place.getPlace(this.session.data.current_company, this.session.data.current_place).subscribe(place => {
                if (place) {
                  this.sendMail(status, place.name);
                }
              });
            } else { // フリープランナーなど会場がない場合
              this.sv_company.getCompany(this.session.data.current_company).subscribe(company => {
                this.sendMail(status, company.name);
              });
            }
          }
        }
      })
    );
  }

  private sendMail(status: number, place_name: string): void {
    if (!this.order_info.order_branch || !this.session.data) return;
    let event_date: string = moment(this.order_info.event_date).format('YYYY年MM月DD日(ddd)');
    let order_user_name: string = this.order_info.order_branch.order_user ? this.order_info.order_branch.order_user.name : this.session.data.last_name + this.session.data.first_name;
    let event_info: string = `挙式日：${event_date}<br> 会場名：${place_name}<br> 挙式名：${this.order_info.event_name}<br> ご注文者：${this.order_info.order_branch.order_request_user.name} 様<br> ご担当者：${order_user_name} 様<br>`;
    let subject = constant.MAIL_SUBJECT_ORDER;
    let title = constant.MAIL_SUBJECT_ORDER;
    let body = constant.MAIL_BODY_ORDER;
    // タイムレス受注後の再発注、またはキャンセル発注
    if (status == constant.ORDER_STATUS.ORDER_AFTER_ACCEPT || status == constant.ORDER_STATUS.CANCEL_ORDER) {
      subject = constant.MAIL_SUBJECT_REORDER;
      title = constant.MAIL_SUBJECT_REORDER;
      body = constant.MAIL_BODY_REORDER;
    }
    let mail: Mail = {
      subject: subject,
      title: title,
      name: 'タイムレスギフトセンターご担当者',
      email: environment.GIFT_CENTER,
      message: body + "<p>" + event_info,
      bcc: ''
    };
    this.subscription.add(
      this.sv_common.sendMail(mail).subscribe(send => {
        debugLog(send.msg);
      })
    );
  }

  private cancelOrder() {
    if (!this.order_info.order_branch) return;
    let order_key = this.order_info.order_id;
    let branch_key = this.order_info.order_branch.objectID;
    let giftbox_id = ''; // 対象はステータスがキャンセル依頼のデータ。キャンセル依頼は注文内のギフトセットが1件の時のみ生成される。
    this.ordered_giftboxes.forEach(gbs => {
      giftbox_id = gbs.order_giftbox.objectID;
    });
    if (!giftbox_id) {
      showSimpleMsg(constant.MSG_UPDATE_FAILED);
      return;
    }
    if (this.session.data) {
      this.subscription.add(
        this.sv_order.cancelOrder(giftbox_id, order_key, branch_key).subscribe(data => {
          showSimpleMsg(data.msg as string);
          if (data.result) {
            let status: number = constant.ORDER_STATUS.CANCEL_ORDER;
            if (this.session.data?.current_place) { // 会場がある場合
              this.sv_place.getPlace(this.session.data.current_company, this.session.data.current_place).subscribe(place => {
                if (place) {
                  this.sendMail(status, place.name);
                }
              });
            } else { // フリープランナーなど会場がない場合
              this.sv_company.getCompany(this.session.data!.current_company).subscribe(company => {
                this.sendMail(status, company.name);
              });
            }
          }
        })
      );
    }
  }

  private saveOrderInfo(): void {
    this.local_storage.set(constant.STORAGE_LOCAL_ORDER_INFO, this.order_info);
    this.sv_session.orderSubject.next(this.order_info);
  }

  public printPage(): void {
    try {
      document.execCommand('print', false, '');  // for Safari
    } catch {
      window.print();
    }
  }

  public detail(item_key: string) {
    this.select_item_key = item_key;
    this.show_item_detail = true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
