import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Subscription, Observable, Subject, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

import { SessionService } from '../../service/session.service';
import { OrderService } from '../../service/order.service';
import { EventService } from '../../service/event.service';

import { LandingPageComponent } from '../../common/landing-page/landing-page.component';
import { MemberRegistComponent } from '../../mng/member-regist/member-regist.component';
import { RepresentativeRegistComponent } from '../../mng/representative-regist/representative-regist.component';
import { ItemsComponent } from '../../common/items/items.component';
import { OrderListComponent } from '../../mng/order-list/order-list.component';
import { EventListComponent } from '../../mng/event-list/event-list.component';
import { TermsAndPolicyComponent } from '../../common/terms-and-policy/terms-and-policy.component';

import { HeaderCount } from '../../models/header_count';
import { OrderCount } from '../../models/order_count';
import { EventSearch } from '../../models/event_search';
import { OrderData } from '../../models/order_data';
import * as constant from '../../models/constant';

import moment from 'moment';
import { environment } from '../../../environments/environment';

declare const debugLog: any;

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() componentRef: any;
  public searchable: boolean = true; // 虫眼鏡アイコン表示制御
  private subscription: Subscription = new Subscription();
  private uid: string = "";
  public user_role: number = -1;
  public session_exist: boolean = false;
  public order_limit: Date = new Date(0);
  public order_limit_alert: Date = new Date(0);
  public today: Date = moment(new Date().setHours(0,0,0,0)).toDate();
  public header_count: HeaderCount = new HeaderCount();
  public cust_regist: boolean = false;
  public guest_regist: boolean = false;
  public member_regist: boolean = false;
  public landing: boolean = false;
  public representative_regist: boolean = false;
  public order_list_type = constant.ORDER_LIST_TYPE;
  public terms_and_policy: boolean = false;

  public no_info_not_read: boolean = true;
  public no_request_order: boolean = false;
  public no_change_order: boolean = false;
  public syte_type: string = environment.SYTE_TYPE;

  constructor(
    private sv_session: SessionService,
    private sv_order: OrderService,
    private sv_event: EventService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    moment.updateLocale("ja", null);
  }

  ngOnInit() {
    this.subscription.add(
      this.sv_session.sessionState.subscribe(session => {
        if (session && session.data) {
          this.uid = session.data.uid;
          this.user_role = session.data.current_role;
          this.sv_event.initialize(session);
          this.sv_order.initialize(session);
          if (session.data.current_role == constant.ROLE.MNG) {
            this.session_exist = true;
            // 最新ブランチ（全体）を取得
            this.sv_order.getLatestBranches(null).subscribe(orders => {
              this.countOrder(orders); // 担当の注文情報を取得
            });
            this.sv_session.countInfoMng();
          }
          this.sv_session.header_count.subscribe(header_cnts => {
            this.header_count = header_cnts;
            this.dispControll();
          });
        }
        else {
          this.session_exist = false;
          this.user_role = -1;
        }
      })
    );
  }

  dispControll(): void {
    this.no_info_not_read = this.header_count.info_not_read_cnt < 1;
    this.no_request_order = this.header_count.request_order_cnt < 1;
    this.no_change_order = this.header_count.change_order_cnt < 1;
  }

  /**
   * 注文数を取得
   *
   * @private
   * @param {string} event_id
   * @returns {Observable<{new: number, change: number}>}
   * @memberof AuthService
   */
  private countOrder(latest_orders: OrderData[]): Observable<{new: number, change: number}> {
    let sub: Subject<{new: number, change: number}> = new Subject();
    let res: Observable<{new: number, change: number}> = sub.asObservable();
    this.subscription.add(
      forkJoin([
        this.sv_event.getEventSearches().pipe(take(1)), // 担当施行
        this.sv_order.getOrders(this.uid).pipe(take(1)), // 担当注文
      ]).subscribe(([events, orders]) => {
        // 後続処理は注文検索と共通のため、注文検索と仕様を合わせ挙式日をUnixに変換。（注文検索で使用のalgolia内で挙式日はUnix形式）
        events.forEach((ev, i) => {
          events[i].date = moment(ev.date.toDate()).unix();
        });
        // event, orders, latest_orders の積集合を抽出
        let datas = this.setSearchData(events, orders, latest_orders, [], true);
        let request_num: number = 0; // 新規注文数
        let change_num: number = 0; // 注文変更数
        datas.forEach((data, j) => {
          // ヘッダーの注文数カウント
          if (data.order_branch?.status == constant.ORDER_STATUS.REQUEST) {
            request_num++;
          }
          if (data.order_branch?.status == constant.ORDER_STATUS.REQUEST_AFTER_ORDER ||
              data.order_branch?.status == constant.ORDER_STATUS.REQUEST_AFTER_ACCEPT ||
              data.order_branch?.status == constant.ORDER_STATUS.CANCEL_REQUEST) {
            change_num++;
          }
        });
        let order_count: OrderCount = {request: request_num, change: change_num};
        this.sv_session.orderCountSubject.next(order_count);
        this.sv_session.orderListSubject.next(datas);
      })
    );

    return res;
  }

  private setSearchData(events: EventSearch[], orders1: OrderData[], orders2: OrderData[], orders3: OrderData[], init: boolean): OrderData[] {
    // キャンセルの注文を除外（ただし一度でもタイムレスへ発注された注文は残す）
    orders2.forEach((val2, i) => {
      if (val2.order_branch?.status == constant.ORDER_STATUS.CANCEL && !val2.order_branch.order_date) {
        orders2.splice(i, 1);
      }
    });
    if (!init) { // 検索時
      orders3.forEach((val3, j) => {
        if (val3.order_branch?.status == constant.ORDER_STATUS.CANCEL && !val3.order_branch.order_date) {
          orders3.splice(j, 1);
        }
      });
    }
    // event, orders1, orders2, orders3 の積集合を抽出
    //【A】→ event, orders1
    let data1: OrderData[] = [];
    orders1.forEach(val1 => {
      events.forEach(ev => {
        if (val1.event_id == ev.objectID) {
          let merge: OrderData = {
            order_id: val1.order_id,
            order_no: val1.order_no,
            order_created: val1.order_created,
            event_id: val1.event_id,
            event_name: ev.name,
            event_status: ev.status,
            event_date: moment.unix(ev.date).toDate(),
            order_limit: moment.unix(ev.date).add(-14, 'd').toDate(),
            order_branch: null
          };
          data1.push(merge);
        }
      });
    });
    //【B】→ orders2, orders3
    let data2: OrderData[] = [];
    if (!init) { // 検索時
      orders2.forEach(val2 => {
        orders3.forEach(val3 => {
          if (val2.order_id == val3.order_id) data2.push(val2);
        });
      });
    } else { // 初期表示時
      data2 = orders2;
    }
    // 【A】【B】
    let result: OrderData[] = [];
    data2.forEach(d2 => {
      data1.forEach(d1 => {
        if (d2.order_id == d1.order_id) {
          let merge_result: OrderData = {
            order_id: d1.order_id,
            order_no: d1.order_no,
            order_created: d1.order_created,
            event_id: d1.event_id,
            event_name: d1.event_name,
            event_status: d1.event_status,
            event_date: d1.event_date,
            order_limit: d1.order_limit, // order-list.componentで再設定
            order_branch: d2.order_branch
          };
          result.push(merge_result);
        }
      });
    });
    return result;
  }

  ngOnChanges(){
    this.member_regist = (this.componentRef instanceof MemberRegistComponent);
    this.representative_regist = (this.componentRef instanceof RepresentativeRegistComponent);
    this.landing = (this.componentRef instanceof LandingPageComponent);
    this.terms_and_policy = (this.componentRef instanceof TermsAndPolicyComponent);
    if (
      (this.componentRef instanceof ItemsComponent) ||
      (this.componentRef instanceof OrderListComponent) ||
      (this.componentRef instanceof EventListComponent)
    ) {
      this.searchable = true;
    } else {
      this.searchable = false;
    }
  }

}
