import { SearchCondEvent } from './search_cond_event';
import * as constant from './constant';

export interface ISearchCondOrder {
	event: SearchCondEvent;
	order_request_date_from: Date | null;
	order_request_date_to: Date | null;
	order_date_from: Date | null;
	order_date_to: Date | null;
	order_status_type: {
		key: string;
		value: string;
		checked: boolean;
	}[];
}
export class SearchCondOrder implements ISearchCondOrder {
	event: SearchCondEvent = new SearchCondEvent();
	order_request_date_from: Date | null = null;
	order_request_date_to: Date | null = null;
	order_date_from: Date | null = null;
	order_date_to: Date | null = null;
	order_status_type: {
		key: string;
		value: string;
		checked: boolean;
	}[] = constant.SEARCH_ORDER_STATUS;
}
