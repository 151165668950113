import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ModalMessageComponent } from '../../common/modal-message/modal-message.component';

import { SessionService } from '../../service/session.service';
import { CommonService } from '../../service/common.service';
import { AccountService } from '../../service/account.service';
import { CompanyService } from '../../service/company.service';

import { Mail } from '../../models/mail';
import { Place } from '../../models/place';
import * as constant from '../../models/constant';

import { environment } from '../../../environments/environment';

import $ from 'jquery';

declare function resetMemberInvitation(): void;
declare const debugLog: any;

@Component({
  selector: 'app-member-invitation',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ModalMessageComponent,
  ],
  templateUrl: './member-invitation.component.html',
  styleUrls: ['./member-invitation.component.scss']
})
export class MemberInvitationComponent implements OnInit {

  public mail_addr: string = "";
  public member_exists: boolean = false;
  private all_del: boolean = false;
  private company_id: string = '';
  private place: Place = new Place();
  private company_members: {id: string, target: any}[] = [];
  private subscription: Subscription = new Subscription();

  constructor(
    private sv_session: SessionService,
    private sv_commom: CommonService,
    private sv_account: AccountService,
    private sv_company: CompanyService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add (
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.company_id = session.data.current_company;
          this.sv_session.getPlaceInfo();
          this.sv_session.placeInfo.subscribe(placeInfo => {
            if (placeInfo) {
              this.place = placeInfo;
            }
          });
        }
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      resetMemberInvitation();
    }
  }

  public removeMailAddr(): void {
    this.mail_addr = "";
  }

  public onModalMsgEvent(msg: boolean): void {
    if (!isPlatformBrowser(this.platformId)) return;
    if (!msg) return;

    if (this.all_del) {
      $('#mails').fadeOut(500, function() {
        $('#mails').empty();
        $('#mails').show();
      });
    } else {
      $('#mails .js-listRow').each(function(){
        if ($(this).attr('data-del') == "1") {
          $(this).fadeOut(500, function() {
            $(this).remove();
          });
        }
      });
    }
    this.all_del = false;
    setTimeout(() => this.count(), 300);
  }

  public count(): void {
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        if ($('#mails .js-listRow').length > 0) {
          this.member_exists = true;
        } else {
          this.member_exists = false;
        }
      }
    }, 500);
  }

  public invite(): void {
    if (!isPlatformBrowser(this.platformId)) return;

    this.sv_company.getMembers(this.company_id).subscribe(c_memebers => {
      this.company_members = [];
      let mails: string[] = [];
      $('#mails .js-listRow').each(function(){
        mails.push($(this).find('.js-mailText').text());
      });

      mails.forEach((addr:string, i:number) => {
        let target = $('#mails .js-listRow').eq(i).find('.state-item');
        if (!$(target).find('button').length) {
          return;
        }
        let exist: boolean = false;
        // 企業のメンバーとして既ににアカウントが登録されている場合、そのアカウントの使用会場にこの会場を追加する処理をするため、company_membersに保存
        c_memebers.forEach(cm => {
          if (cm.email == addr) {
            this.company_members.push({id: cm.objectID, target: target});
            exist = true;
            return;
          }
        });
        if (!exist) {
          this.send(target, addr, i);
        }
      });

      // 既存アカウントの会場更新
      this.addPlace();
    });
  }

  private addPlace(): void {
    this.company_members.forEach(cm => {
      this.subscription.add(
        this.sv_account.updatePlace(cm.id, this.place.objectID).subscribe(data => {
          if (isPlatformBrowser(this.platformId)) {
            $(cm.target).parent().css('width', 'auto');
            if (data.result) {
              $(cm.target).text('追加完了');
            } else {
              $(cm.target).addClass('error-text');
              $(cm.target).html('メンバー<br>追加失敗');
            }
          }
        })
      );
    })
  }

  private send(target: any, addr: string, i: number): void {
    let url: string = `${environment.BASE_URL}/member-regist/${this.company_id}/${this.place.objectID}/${this.place.token}`;
    let mail: Mail = {
      subject: constant.MAIL_SUBJECT_REGIST_INVI,
      title: constant.MAIL_SUBJECT_REGIST_INVI,
      name: "",
      email: addr,
      message: constant.MAIL_BODY_REGIST_INVI + "<br>" + url,
      bcc: ''
    };
    this.sv_commom.sendMail(mail).subscribe(data => {
      if (isPlatformBrowser(this.platformId)) {
        $(target).find('button').removeClass();
        $(target).parent().css('width', 'auto');
        if (data.result) {
          $(target).text('送信完了');
        } else {
          $(target).addClass('error-text');
          $(target).html('招待メール<br>送信失敗');
        }
      }
    });
  }

  public clear(): void {
    this.all_del = true;
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      if ($('body').hasClass('giftbox-open')) {
        $('body').removeClass('giftbox-open');
      }
    }
  }

}
