import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Subject, Observable, of, Subscription } from 'rxjs';
import { stringify, parse } from 'flatted';

import { Session } from '../models/session';
import { GiftboxInfo } from '../models/giftbox_info';
import { OrderData } from '../models/order_data';
import { OrderedGiftbox } from '../models/ordered_giftbox';
import { Giftbox } from '../models/giftbox';
import { HeaderCount } from '../models/header_count';
import { OrderCount } from '../models/order_count';
import { Place } from '../models/place';
import { Item } from '../models/item';
import { Event } from '../models/event';
import { Company } from '../models/company';
import * as constant from '../models/constant';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public session = new Session();

  public sessionSubject: Subject<Session> = new Subject();
  public sessionState: Observable<Session> = this.sessionSubject.asObservable();

  public termsConsentSubject: Subject<Boolean> = new Subject();
  public termsConsent = this.termsConsentSubject.asObservable();

  public giftboxSubject: Subject<GiftboxInfo> = new Subject();
  public giftboxInfo = this.giftboxSubject.asObservable();

  public orderBaseSubject: Subject<OrderData> = new Subject();
  public orderBaseInfo = this.orderBaseSubject.asObservable();

  public orderSubject: Subject<OrderData> = new Subject();
  public orderInfo = this.orderSubject.asObservable();

  public orderDetailSubject: Subject<OrderedGiftbox[]> = new Subject();
  public orderDetail = this.orderDetailSubject.asObservable();

  public eventOrderTotalPriceSubject: Subject<{price: number, deliv_fee: number, contain_untaxable_order: boolean}> = new Subject();
  public eventOrderTotalPrice = this.eventOrderTotalPriceSubject.asObservable();

  public orderDetailGiftboxSubject: Subject<OrderedGiftbox> = new Subject();
  public orderDetailGiftbox = this.orderDetailGiftboxSubject.asObservable();

  public orderDetailGuestChangeSubject: Subject<boolean[]> = new Subject();
  public orderDetailGuestChange = this.orderDetailGuestChangeSubject.asObservable();

  public placeSubject: Subject<Place> = new Subject();
  public placeInfo = this.placeSubject.asObservable();

  public eventSubject: Subject<Event> = new Subject();
  public eventInfo = this.eventSubject.asObservable();

  public companySubject: Subject<Company> = new Subject();
  public companyInfo = this.companySubject.asObservable();

  private header_count_subject: Subject<HeaderCount> = new Subject();
  public header_count: Observable<HeaderCount> = this.header_count_subject.asObservable();

  public orderCountSubject: Subject<OrderCount> = new Subject();
  public orderCount: Observable<OrderCount> = this.orderCountSubject.asObservable();

  public orderListSubject: Subject<OrderData[]> = new Subject();
  public orderList: Observable<OrderData[]> = this.orderListSubject.asObservable();

  // 商品検索条件（キーワード）
  public itemSearchKeywordSubject: Subject<string[]> = new Subject();
  public itemSearchKeyword = this.itemSearchKeywordSubject.asObservable();

  // 商品検索条件（カテゴリー）
  public itemSearchCategorySubject: Subject<{key: string, value: string, checked: boolean}[]> = new Subject();
  public itemSearchCategory = this.itemSearchCategorySubject.asObservable();

  // 商品検索条件（価格帯）
  public itemSearchPriceRangeSubject: Subject<{key: string, value: string, checked: boolean}[]> = new Subject();
  public itemSearchPriceRange = this.itemSearchPriceRangeSubject.asObservable();

  // 商品検索結果
  public itemSearchListSubject: Subject<Item[]> = new Subject();
  public itemSearchList = this.itemSearchListSubject.asObservable();

  public subscription: Subscription = new Subscription();

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  public loginCheck(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(user => {
        if (!user?.isAnonymous) {
          this.sessionSubject.next(this.local_storage.get(constant.STORAGE_LOCAL_MNG_LOGIN));
        }
      })
    );
  }

  /**
   * ログアウト
   * （プログラム上での強制ログアウト用。画面操作でのログアウトはAuthServiceで管理）
   * @memberof SessionService
   */
  public logout(): void {
    this.local_storage.remove(constant.STORAGE_LOCAL_MNG_LOGIN);
    this.local_storage.remove(constant.STORAGE_LOCAL_GIFTBOX_INFO);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(constant.STORAGE_LOCAL_MNG_LOGIN);
      localStorage.removeItem(constant.STORAGE_LOCAL_GIFTBOX_INFO);
    }
    this.router.navigate(['/']);
    this.afAuth.signOut();
  }

  public getGiftboxInfo(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.giftboxSubject.next(this.local_storage.get(constant.STORAGE_LOCAL_GIFTBOX_INFO));
      })
    );
  }

  public getTermsConsent(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.termsConsentSubject.next(this.local_storage.get(constant.STORAGE_LOCAL_MNG_TERMS_CONSENT));
      })
    );
  }

  public getOrderBaseInfo(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.orderBaseSubject.next(this.local_storage.get(constant.STORAGE_LOCAL_ORDER_BASE_INFO));
      })
    );
  }

  public getOrderInfo(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.orderSubject.next(this.local_storage.get(constant.STORAGE_LOCAL_ORDER_INFO));
      })
    );
  }

  public getOrderDetail(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.orderDetailSubject.next(parse(this.local_storage.get(constant.STORAGE_LOCAL_ORDER_DETAIL)));
      })
    );
  }

  public getEventOrderTotalPrice(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.eventOrderTotalPriceSubject.next(this.local_storage.get(constant.STORAGE_LOCAL_EVENT_ORDER_TOTAL_PRICE));
      })
    );
  }

  public getOrderDetailGiftbox(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.orderDetailGiftboxSubject.next(parse(this.local_storage.get(constant.STORAGE_LOCAL_ORDER_DETAIL_GIFTBOX)));
      })
    );
  }

  public getOrderDetailGuestChange(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.orderDetailGuestChangeSubject.next(this.local_storage.get(constant.STORAGE_LOCAL_ORDER_DETAIL_GUEST_CHANGE));
      })
    );
  }

  public getPlaceInfo(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.placeSubject.next(this.local_storage.get(constant.STORAGE_LOCAL_PLACE_INFO));
      })
    );
  }

  public getEventInfo(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.eventSubject.next(this.local_storage.get(constant.STORAGE_LOCAL_EVENT_INFO));
      })
    );
  }

  public getCompanyInfo(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.companySubject.next(this.local_storage.get(constant.STORAGE_LOCAL_COMPANY_INFO));
      })
    );
  }

  public getItemSearchKeyword(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.itemSearchKeywordSubject.next(this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_KEY));
      })
    );
  }

  public getItemSearchCategory(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.itemSearchCategorySubject.next(this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_CATEGORY));
      })
    );
  }

  public getItemSearchPriceRange(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.itemSearchPriceRangeSubject.next(this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_PRICE_RANGE));
      })
    );
  }

  public getItemSearchList(): void {
    this.subscription.add(
      this.afAuth.authState.subscribe(() => {
        this.itemSearchListSubject.next(this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH));
      })
    );
  }

  /**
   * ヘッダー部の各バッジの数値を取得（顧客画面）
   *
   * @param {string} event_id
   * @memberof SessionService
   */
  public countInfoCust(event_id: string) :void {
    let count_obj: HeaderCount = {
      giftbox_cnt: 0,
      cart_cnt: 0,
      info_not_read_cnt: 0,
      request_order_cnt: 0,
      change_order_cnt: 0
    };
    // ギフトセット数、カートに入っているギフトセット数
    this.countGiftbox(event_id).subscribe(giftbox => {
      count_obj.giftbox_cnt = giftbox.giftbox;
      count_obj.cart_cnt = giftbox.cart;
      this.header_count_subject.next(count_obj);
    });
    // 未読の通知数　TODO：info も下記の形式で。この方法でカートとかのカウントが消えずに両方ちゃんと表示される事をかくにんずみ。
    // this.countGiftbox2(event_id).subscribe(e => {
    //   count_obj.info_not_read_cnt = e;
    //   this.header_count_subject.next(count_obj);
    // })
  }

  /**
   * ヘッダー部の各バッジの数値を取得（管理画面）
   *
   * @param {string} uid
   * @memberof SessionService
   */
  public countInfoMng() :void {
    let count_obj: HeaderCount = {
      giftbox_cnt: 0,
      cart_cnt: 0,
      info_not_read_cnt: 0,
      request_order_cnt: 0,
      change_order_cnt: 0
    };
    // 新規注文数、注文変更数
    this.orderCount.subscribe(order => {
      count_obj.request_order_cnt = order.request;
      count_obj.change_order_cnt = order.change;
      this.header_count_subject.next(count_obj);
    });
  }

  /**
   * ギフトセットの種類数とカートに入っている数を取得
   *
   * @private
   * @param {string} event_id
   * @returns {Observable<{giftbox_count: number, cart_count: number}>}
   * @memberof SessionService
   */
  private countGiftbox(event_id: string): Observable<{giftbox: number, cart: number}> {
    let sub: Subject<{giftbox: number, cart: number}> = new Subject();
    let res: Observable<{giftbox: number, cart: number}> = sub.asObservable();
    this.afs.collection<Giftbox>('giftbox', ref => ref
      .where('event_id', '==', event_id)
      .where('delete_flg', "==", false)
    ).snapshotChanges().subscribe(docs => {
      let i: number = 0; // ギフトセット種類数
      let j: number = 0; // カートに入っている未発注のギフトセット数
      docs.forEach(doc => {
        i++;
        if (doc.payload.doc.data().cart_in && doc.payload.doc.data().status == 0) {
          j++;
        }
      });
      sub.next({giftbox: i, cart: j});
    });
    return res;
  }

  /**
   * 失敗したHttp操作を処理します。
   * アプリを持続させます。
   * @param operation - 失敗した操作の名前
   * @param result - observableな結果として返す任意の値
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: リモート上のロギング基盤にエラーを送信する
      console.error(error); // かわりにconsoleに出力

      // TODO: ユーザーへの開示のためにエラーの変換処理を改善する
      // this.log(`${operation} failed: ${error.message}`);

      // 空の結果を返して、アプリを持続可能にする
      return of(result as T);
    };
  }

}
