export class TabModel {
  private _name: string;
  private _current: boolean;
  private _contents: any;

  constructor(name: string, contents: any, current: boolean) {
    this._name = name;
    this._contents = contents;
    this._current = current;
  }

  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }
  public get contents(): any {
    return this._contents;
  }
  public set contents(value: any) {
    this._contents = value;
  }
  public get current(): boolean {
    return this._current;
  }
  public set current(value: boolean) {
    this._current = value;
  }
}