<div class="content-block content-block-max">
  <div class="content-block-inner">
    <div class="flex-box j-content-start a-items-start text-note-box">
      <div class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg></div>
      <p>指定したメールアドレスに招待メールが送信されます。<br>
      招待されたユーザーが登録作業を完了するとこの会場のメンバーとして登録されます。<br>
    </div>
  </div><!--/content-block-->

  <div class="header-item-wrap">
    <div class="inner-header j-content-end">
      <div class="giftbox-make-btn js-createMailAddressBtn" (click)="removeMailAddr()">
        <div class="icon-giftplus">
          <div class="icon-plus-l"></div>メールアドレスを追加
        </div>
      </div>
    </div><!--/inner-header-->

    <div class="gift-make-block js-createArea" style="padding-top: 10px;">
      <div *ngIf="mail_addr && email.errors?.['email'] && (email.dirty || email.touched)" class="error-text member_invi input-error-text">※メールアドレスの形式が不正です</div>
      <div class="gift-make-block-inner">
        <div class="make-wrap">
          <div class="gift-box-input">
            <input class="form-gift-box js-newMailAddress" type="email" id="email" name="email"
              required email #email="ngModel" [(ngModel)]="mail_addr" placeholder="招待者のメールアドレスを入力してください"
              [class.error-item]="mail_addr && email.errors && (email.dirty || email.touched)"/>
          </div>
          <div class="btn-wrap">
            <button type="button" data-target="modal-error" class="btn-preserve js-modalCreateMailAddressSaveBtn" (click)="count()" [disabled]="email.errors">追加</button>
            <button type="button" class="btn-cancel js-modalCreateMailAddressCancelBtn">キャンセル</button>
          </div>
        </div><!--/make-wrap-->
      </div><!--/gift-make-block-inner-->
    </div><!--/gift-make-block-->

  </div><!--/header-item-wrap-->

  <div class="list-wrap">
    <div class="order-item-list order-item-list-single">
      <div class="order-list-wrap-inner" style="margin-top: 0; border-top: none;">

        <div id="mails"></div>

        <!-- テンプレート -->
        <div class="hidden-wrap js-newMailAddressRowBase">
          <div class="order-item-list-inner giftbox-detail h-style1 js-listRow" data-del="0">
            <div class="order-list-cont-box-odd">
              <div class="mail-text js-mailText"></div>
            </div>
            <div class="order-list-cont-box-even j-cont-center">
              <div class="state-item">
                <button type="button" data-target="modal-message" class="btn-trash js-trashBtnMenberInvite"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 54"><path class="icon-color" d="M50,8h-12l-5-8H18L13,8H1a1,1,0,0,0,0,2H5V49a5,5,0,0,0,5,5H41a5,5,0,0,0,5-5V10h4a1,1,0,0,0,0-2ZM19.05,2H32L35.7,8H15.3ZM44,49a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V10H44Z"/><path class="icon-color" d="M18.5,18a1,1,0,0,0-1,1V44a1,1,0,0,0,2,0V19A1,1,0,0,0,18.5,18Z"/><path class="icon-color" d="M32.5,18a1,1,0,0,0-1,1V44a1,1,0,0,0,2,0V19A1,1,0,0,0,32.5,18Z"/></svg></button>
              </div>
            </div>
          </div>
        </div><!-- テンプレート -->

      </div><!--/order-list-wrap-inner-->
    </div><!--/order-item-list-->
  </div><!--/list-wrap-->

</div><!--/content-block-->

<div class="btn-box">
  <div class="flex-box j-content-center">
    <button class="btn-item js-saveBtnSenderTransfer" (click)="invite()" [disabled]="!member_exists"><span>招待</span></button>
    <button data-target="modal-message" class="btn-item white js-trashBtnMenberInvite" (click)="clear()" ><span>クリア</span></button>
  </div>
</div>

<app-modal-message (msg_event)="onModalMsgEvent($event)"></app-modal-message>
