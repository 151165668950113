[
  {
    "event": {
      "id": "ev001",
      "name": "古高家挙式"
    },
    "name": "親族用A",
    "items":[
      {
        "id": "item_001",
        "name": "商品A",
        "num": 1,
        "noshi": 0
      },
      {
        "id": "item_002",
        "name": "商品C",
        "num": 1,
        "noshi": 0
      }
    ],
    "sendlist": [
      {
        "id": "send_001",
        "delive_date": "2020/01/01 00:00:00",
        "delive_fee": 0,
        "card": 0
      },
      {
        "id": "send_002",
        "delive_date": "2020/01/02 00:00:00",
        "delive_fee": 500,
        "card": 1
      },
      {
        "id": "send_003",
        "delive_date": "2020/01/01 00:00:00",
        "delive_fee": 0,
        "card": 0
      }
    ],
    "total_price": 50000,
    "total_delive_fee": 500,
    "status": 0,
    "cart_in": false,
    "created": "2019/06/27 00:00:00",
    "cart_in_updated": null,
    "updated": "2019/06/27 00:00:00",
    "update_user": {
      "uid": "abcde",
      "name": "テストユーザー"
    },
    "delete_flg": false
  },
  {
    "event": {
      "id": "ev001",
      "name": "古高家挙式"
    },
    "name": "親族用B",
    "items":[
      {
        "id": "item_003",
        "name": "商品C",
        "num": 1,
        "noshi": 3
      },
      {
        "id": "item_004",
        "name": "商品D",
        "num": 1,
        "noshi": 3
      }
    ],
    "sendlist": [
      {
        "id": "send_004",
        "delive_date": "2020/02/01 00:00:00",
        "delive_fee": 500,
        "card": 0
      },
      {
        "id": "send_005",
        "delive_date": "2020/02/02 00:00:00",
        "delive_fee": 500,
        "card": 1
      },
      {
        "id": "send_006",
        "delive_date": "2020/02/01 00:00:00",
        "delive_fee": 0,
        "card": 0
      }
    ],
    "total_price": 80000,
    "total_delive_fee": 1000,
    "status": 0,
    "cart_in": false,
    "created": "2019/06/27 00:00:00",
    "cart_in_updated": null,
    "updated": "2019/06/27 00:00:00",
    "update_user": {
      "uid": "abcde",
      "name": "テストユーザー"
    },
    "delete_flg": false
  },
  {
    "event": {
      "id": "ev001",
      "name": "古高家挙式"
    },
    "name": "友人用A",
    "items":[
      {
        "id": "item_005",
        "name": "商品E",
        "num": 1,
        "noshi": 2
      },
      {
        "id": "item_006",
        "name": "商品F",
        "num": 1,
        "noshi": 2
      }
    ],
    "sendlist": [
      {
        "id": "send_007",
        "delive_date": "2020/01/01 00:00:00",
        "delive_fee": 0,
        "card": 0
      },
      {
        "id": "send_008",
        "delive_date": "2020/01/07 00:00:00",
        "delive_fee": 500,
        "card": 1
      }
    ],
    "total_price": 70000,
    "total_delive_fee": 500,
    "status": 0,
    "cart_in": false,
    "created": "2019/06/27 00:00:00",
    "cart_in_updated": null,
    "updated": "2019/06/27 00:00:00",
    "update_user": {
      "uid": "abcde",
      "name": "テストユーザー"
    },
    "delete_flg": false
  },
  {
    "event": {
      "id": "ev001",
      "name": "古高家挙式"
    },
    "name": "友人用B",
    "items":[
      {
        "id": "item_007",
        "name": "商品G",
        "num": 1,
        "noshi": 0
      },
      {
        "id": "item_008",
        "name": "商品H",
        "num": 1,
        "noshi": 0
      },
      {
        "id": "item_009",
        "name": "商品I",
        "num": 1,
        "noshi": 0
      },
      {
        "id": "item_010",
        "name": "商品J",
        "num": 1,
        "noshi": 0
      }
    ],
    "sendlist": [
      {
        "id": "send_009",
        "delive_date": "2020/01/01 00:00:00",
        "delive_fee": 0,
        "card": 0
      },
      {
        "id": "send_010",
        "delive_date": "2020/01/02 00:00:00",
        "delive_fee": 500,
        "card": 1
      },
      {
        "id": "send_003",
        "delive_date": "2020/01/01 00:00:00",
        "delive_fee": 0,
        "card": 0
      }
    ],
    "total_price": 100000,
    "total_delive_fee": 500,
    "status": 0,
    "cart_in": false,
    "created": "2019/06/27 00:00:00",
    "cart_in_updated": null,
    "updated": "2019/06/27 00:00:00",
    "update_user": {
      "uid": "abcde",
      "name": "テストユーザー"
    },
    "delete_flg": false
  }
]