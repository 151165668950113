export interface IHeaderCount {
  giftbox_cnt: number, // ギフトセット数（顧客画面）
  cart_cnt: number, // カートに追加された数（顧客画面）
  info_not_read_cnt: number, // 味読のお知らせ数（顧客画面、管理画面）
  request_order_cnt: number, // 新規注文数（管理画面）
  change_order_cnt: number // 注文変更数（管理画面）
}
export class HeaderCount implements IHeaderCount {
  // 初期化用クラス
  giftbox_cnt: number = 0;
  cart_cnt: number = 0;
  info_not_read_cnt: number = 0;
  request_order_cnt: number = 0;
  change_order_cnt: number = 0;
}
