import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';

import { ModalMessageComponent } from '../../common/modal-message/modal-message.component';

import { AccountService } from '../../service/account.service';
import { PlaceService } from '../../service/place.service';
import { SessionService } from '../../service/session.service';
import { EventService } from '../../service/event.service';
import { AuthService } from '../../service/auth.service';

import { Account } from '../../models/account';
import { Place } from '../../models/place';
import { Session } from '../../models/session';
import * as constant from '../../models/constant';

import $ from 'jquery';

declare function resetMemberEvent(): void;
declare function showSimpleMsg(msg: string): void;
// declare function toggleCreateArea(): void;
declare const debugLog: any;

@Component({
  selector: 'app-member-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    ModalMessageComponent,
  ],
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent implements OnInit {
  function_category = ["search"];
  private session: Session = new Session();
  private place: Place = new Place();
  private place_id: string = '';
  public members: Account[] = [];
  public in_charge_event: boolean[] = [];
  private message: string = '';
  private _keywords: string[] = [];
  public get keywords(): string[] {
    return this._keywords;
  }
  public set keywords(value: string[]) {
    this._keywords = value;
  }
  private orders: string[] = ['first_name', 'last_name', 'first_kname', 'last_kname'];
  public editData: {id: string, manager_flg: boolean, cross_search_flg: boolean} = {id: '', manager_flg: false, cross_search_flg: false};
  public edit_member_id: string = '';
  private del_target_id: string = '';
  private updated_user: {uid: string, name: string} = {uid: '', name: ''};
  public manager_flg: boolean = false;
  public cross_search_flg: boolean = false;
  private uid: string = '';
  private subscription: Subscription = new Subscription();

  constructor(
    private sv_account: AccountService,
    private sv_place: PlaceService,
    private sv_session: SessionService,
    private sv_event: EventService,
    private sv_auth: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add (
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.sv_account.initialize(session);
          this.manager_flg = session.data.manager_flg;
          this.cross_search_flg = session.data.cross_search_flg;
          this.uid = session.data.uid;
          this.session = session;
        }
      })
    );
    this.sv_session.getPlaceInfo();
    this.subscription.add (
      this.sv_session.placeInfo.subscribe(placeInfo => {
        if (placeInfo) {
          this.place = placeInfo;
          this.place_id = placeInfo.objectID;
          this.getMembers();
        }
      })
    );
    // 編集エリア開閉準備
    if (isPlatformBrowser(this.platformId)) {
      if (!$('body').hasClass('giftbox-open')) {
        $('body').addClass('giftbox-open');
      }
    }
    this.setEditDataDefault();
  }

  private setEditDataDefault(): void {
    this.editData = {
      id: '',
      manager_flg: false,
      cross_search_flg: false,
    };
  }

  private getMembers(): void {
    this.subscription.add(
      this.sv_place.getMembers(this.place.objectID).subscribe(members => {
        if (!members.length) {
          this.members = [];
          this.message = constant.MSG_SELECT_NONE;
          return;
        }
        this.members = members;
        // メンバーの担当挙式の有無を判定
        this.in_charge_event = [];
        members.forEach((member, i) => {
          this.subscription.add(
            this.sv_event.getEventSearchesForMember(this.place_id, member.objectID).subscribe(events => {
              if (events.length) {
                this.in_charge_event[i] = true;
              } else {
                this.in_charge_event[i] = false;
              }
            })
          );
        });
        resetMemberEvent(); // メンバー一覧のイベント設定
      })
    );
  }

  public setDelTargetId(event: any, in_charge: boolean) {
    if (!in_charge) {
      this.del_target_id = $(event.target).data('id');
    } else {
      showSimpleMsg(constant.MSG_ERR_STAFF_EVENT_EXISTS);
    }
  }

  public onModalMsgEvent(msg: boolean) {
    if (!msg) {
      this.del_target_id = '';
      return;
    }
    setTimeout(() => {
      if (this.del_target_id) {
        this.del();
      }
    }, 300);
  }

  public addMyself(): void {
    if (!this.session.data) return;
    this.subscription.add(
      this.sv_account.updatePlace(this.uid, this.place_id, false, this.session.data.current_place).subscribe(data => {
        if (data.result) {
          this.edit_member_id = '';
          // セッションのcurrent_placeも更新
          if (data.result) {
            this.sv_account.getAccount(this.uid).subscribe(mydata => {
              if (!this.session.data?.current_place && mydata) {
                this.sv_auth.setSession(mydata, this.uid);
              }
            });
          }
        }
        showSimpleMsg(data.msg as string);
      })
    );
  }

  private del(): void {
    // アカウント自体の削除ではなく、アカウントの所属会場からこの会場を除外するだけ。
    if (this.del_target_id && this.session.data) {
      this.subscription.add(
        this.sv_account.updatePlace(this.del_target_id, this.place_id, true, this.session.data.current_place).subscribe(data => {
          if (!data.result) {
            showSimpleMsg(data.msg as string);
          }
          this.del_target_id = '';
          // セッションのcurrent_placeも更新
          if (data.result && this.session.data) {
            this.sv_account.getAccount(this.uid).subscribe(mydata => {
              if (this.place_id == this.session.data?.current_place && mydata) {
                this.sv_auth.setSession(mydata, this.uid);
              }
            });
          }
        })
      );
    } else {
      showSimpleMsg(constant.MSG_UPDATE_FAILED);
      this.del_target_id = '';
    }
  }

  public toggleEdit(member: any, ev: any): void {
    let current_target: boolean = this.edit_member_id == member.objectID;
    if (!member) return;

    // 編集対象データをセット
    this.editData = {
      id: member.objectID,
      manager_flg: member ? member.manager_flg : false,
      cross_search_flg: member ? member.cross_search_flg : false,
    };
    // 特定会場の編集エリアの開閉
    if (isPlatformBrowser(this.platformId)) {
      $('.js-listRow').css('background-color', '#fff');
      if (current_target) {
        this.edit_member_id = '';  // close
      } else {
        $(ev.target).parents('.js-listRow').css('background-color', '#e5e5e5');
        this.edit_member_id = member.objectID;  // open
      }
    }
  }

  public updatePermission(): void {
    this.subscription.add(
      this.sv_account.updatePermission(this.editData).subscribe(data => {
        if (data.result) {
          this.edit_member_id = '';
        }
        showSimpleMsg(data.msg as string);
      })
    );
  }

  public setCrossSearchFlg(): void {
    if (!this.editData.manager_flg) {
      this.editData.cross_search_flg = false;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    // 編集エリア開閉の設定を解除
    if (isPlatformBrowser(this.platformId)) {
      if ($('body').hasClass('giftbox-open')) {
        $('body').removeClass('giftbox-open');
      }
    }
  }

}
