import { Injectable, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, Subject, Subscription, of, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FirebaseUISignInSuccessWithAuthResult, FirebaseUISignInFailure, FirebaseuiAngularLibraryService } from 'firebaseui-angular';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { LOCAL_STORAGE, SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { SessionService } from './session.service';
import { CompanyService } from './company.service';

import { Account } from '../models/account';
import { SessionData } from '../models/session_data';
import { Event } from '../models/event';
import { Session } from '../models/session';
import { ProcessResult } from '../models/process_result';
import * as constant from '../models/constant';

import * as environment from '../../environments/environment';
import moment from 'moment';

declare const debugLog: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private accountCollection!: AngularFirestoreCollection<Account>;
  private accountDocument!: AngularFirestoreDocument<Account>;
  private account: Observable<Account | null> = of(null);
  private _user: Observable<firebase.User | null> = of(null);
  private login_message_subject: Subject<string> = new Subject();
  public login_message_result = this.login_message_subject.asObservable();
  public subscription: Subscription = new Subscription();

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private sv_session: SessionService,
    private sv_company: CompanyService,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
    @Inject(SESSION_STORAGE) private session_storage: StorageService,
  ) {}

  signInInit():  Observable<firebase.User | null> {
    const persistencePromise = this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    return from(persistencePromise).pipe(
      switchMap(() => {
        // 永続性が設定された後にユーザーのauthStateを取得
        this._user = this.afAuth.authState;
        return this._user;
      })
    );
  }

  /**
   * 通常ログインからのログイン成功時処理の呼び出し
   *
   * @param {string} email
   * @param {string} password
   * @memberof AuthService
   */
  signIn(email: string, password: string): void {
    this.afs.firestore.enableNetwork()
    .then(() => {
      this.afAuth.signInWithEmailAndPassword(email, password)
      .then(() => {
        this.signInSuccess();
      }).catch(error => {
        switch (error.code){
          case 'auth/invalid-email':
          case 'auth/wrong-password':
            this.login_message_subject.next(constant.MSG_LOGIN_FAILURE_MISMATCH);
            break;
          case 'auth/user-not-found':
            this.login_message_subject.next(constant.MSG_LOGIN_FAILURE_NOT_USER);
            break;
          case 'auth/user-disabled':
            this.login_message_subject.next(constant.MSG_LOGIN_FAILURE_INVALID_USER);
            break;
          default:
            this.login_message_subject.next(constant.MSG_LOGIN_FAILURE_OTHER);
        }
      });
    })
    .catch((error) => {
      debugLog(error);
    })
  }

  /**
   * SNSログインからのログイン成功時処理の呼び出し
   *
   * @memberof AuthService
   */
  callSignInSuccess(): void {
    this.afs.firestore.enableNetwork()
    .then(() => {
      this.signInSuccess();
    })
    .catch((error) => {
      debugLog(error);
    });
  }

  /**
   * ログイン成功時の処理
   * （通常ログイン・SNSログイン共に）
   *
   * @memberof AuthService
   */
  signInSuccess(): void {
    debugLog('signInSuccess');
    this.subscription.add(
      this._user.subscribe(user => {
        // ユーザー情報取得。無ければログアウト。
        this.accountDocument = this.afs.doc<Account>(`account/${user?.uid}`);
        this.account = this.accountDocument.snapshotChanges().pipe(
          map (doc => {
            if (doc.payload.exists === false) {
              return null;
            }
            let obj: Account = {
              objectID: user?.uid ?? '',
              email: doc.payload.data().email,
              default_password: '',
              event_id: doc.payload.data().event_id,
              first_name: doc.payload.data().first_name,
              last_name: doc.payload.data().last_name,
              first_kname: doc.payload.data().first_kname,
              last_kname: doc.payload.data().last_kname,
              zip: doc.payload.data().zip,
              addr1: doc.payload.data().addr1,
              addr2: doc.payload.data().addr2,
              addr3: doc.payload.data().addr3,
              addr4: doc.payload.data().addr4,
              tel: doc.payload.data().tel,
              favorite_items: doc.payload.data().favorite_items,
              company: doc.payload.data().company,
              place: doc.payload.data().place,
              current_company: doc.payload.data().current_company,
              current_place: doc.payload.data().current_place,
              info: doc.payload.data().info,
              role: doc.payload.data().role,
              current_role: doc.payload.data().current_role,
              cust_type: doc.payload.data().cust_type,
              manager_flg: doc.payload.data().manager_flg,
              cross_search_flg: doc.payload.data().cross_search_flg,
              status: doc.payload.data().status,
              created: doc.payload.data().created,
              updated: doc.payload.data().updated,
              updated_user: doc.payload.data().updated_user,
              delete_flg: doc.payload.data().delete_flg
            };
            return obj;
          })
        );
        this.subscription.add(
          this.account.subscribe(account => {
            if (!account || account.delete_flg || account.current_role != constant.ROLE.MNG) {
              this.login_message_subject.next(constant.MSG_LOGIN_FAILURE_NOT_ACCOUNT);
              this.sign0ut();
            } else {
              if (user?.uid) {
                this.setSession(account, user.uid);
                // 規約同意画面の表示設定を有効にしている場合、ログインすれば規約に同意したとみなす
                if (environment.environment.ASK_FOR_CONSENT) {
                  this.local_storage.set(constant.STORAGE_LOCAL_MNG_TERMS_CONSENT, true);
                }
              }
            }
          })
        );
      })
    );
  }

  /**
   * セッションデータをセット
   * （アカウント情報・送料区分）
   *
   * @param {Account} account
   * @param {string} uid
   * @returns {void}
   * @memberof AuthService
   */
  setSession (account: Account, uid: string) :void {
    if (account.current_role == constant.ROLE.MNG) {
      this.subscription.add(
        this.sv_company.getCompanyDeliveFeeType(account.current_company).subscribe(company_delive_fee_type => {
          if (company_delive_fee_type === null) {
            debugLog("送料区分の設定なし"); // 0:送料込み、1:送料別
          } else {
            this.saveSession({
              uid: uid,
              email: account.email,
              first_name: account.first_name,
              last_name: account.last_name,
              first_kname: account.first_kname,
              last_kname: account.last_kname,
              favorite_items: account.favorite_items,
              company: account.company,
              place: account.place,
              current_company: account.current_company,
              current_place: account.current_place,
              info: account.info,
              role: account.role,
              current_role: account.current_role,
              cust_type: account.cust_type,
              manager_flg: account.manager_flg,
              cross_search_flg: account.cross_search_flg,
              status: account.status,
              delete_flg: account.delete_flg,
              event: null,
              delive_fee_type: company_delive_fee_type
            });
          }
        })
      )
    }
  }

  /**
   * セッション情報をストレージへ保存
   *
   * @private
   * @param {SessionData} session_data
   * @memberof AuthService
   */
  private saveSession(session_data: SessionData): void {
    if (!session_data) {
      this.login_message_subject.next(constant.MSG_LOGIN_FAILURE_NOT_ACCOUNT);
      this.sign0ut();
    } else {
      let session: Session = new Session();
      session.login = true;
      session.data = session_data;
      this.local_storage.set(constant.STORAGE_LOCAL_MNG_LOGIN, session);
      this.sv_session.sessionSubject.next(session);
      this.login_message_subject.next(constant.MSG_LOGIN_SUCCESS);
    }
  }

  /**
   * SNSログイン失敗時の処理
   *　
   * @param {FirebaseUISignInFailure} errorData
   * @returns {string} ログインメッセージ
   * @memberof AuthService
   */
  signInFailure(errorData: FirebaseUISignInFailure): string {
    this.sign0ut();
    debugLog(errorData);
    return constant.MSG_LOGIN_FAILURE_MISMATCH;
  }

  /**
   * ログアウト
   * （WdbStorageのデータ破棄）
   *
   * @memberof AuthService
   */
  sign0ut(): void {
    this.afAuth.signOut().then(() => {
      if ($('body').hasClass('is-nav-open')) {
        $('body').removeClass('is-nav-open');
      }
      this.local_storage.remove(constant.STORAGE_LOCAL_MNG_LOGIN);
      this.local_storage.remove(constant.STORAGE_LOCAL_GIFTBOX_INFO);
      this.local_storage.remove(constant.STORAGE_LOCAL_GIFTBOX_AREA_STATUS);
      this.local_storage.remove(constant.STORAGE_LOCAL_GIFTBOX);
      this.local_storage.remove(constant.STORAGE_LOCAL_ORDER_INFO);
      this.local_storage.remove(constant.STORAGE_LOCAL_ORDER_DETAIL);
      this.local_storage.remove(constant.STORAGE_LOCAL_EVENT_ORDER_TOTAL_PRICE);
      this.local_storage.remove(constant.STORAGE_LOCAL_ORDER_DETAIL_GIFTBOX);
      this.local_storage.remove(constant.STORAGE_LOCAL_PLACE_INFO);
      this.local_storage.remove(constant.STORAGE_LOCAL_EVENT_INFO);
      this.local_storage.remove(constant.STORAGE_LOCAL_COMPANY_INFO);

      this.local_storage.remove(constant.STORAGE_LOCAL_ITEM_SEARCH_KEY);
      this.local_storage.remove(constant.STORAGE_LOCAL_ITEM_SEARCH_CATEGORY);
      this.local_storage.remove(constant.STORAGE_LOCAL_ITEM_SEARCH_PRICE_RANGE);
      this.local_storage.remove(constant.STORAGE_LOCAL_ITEM_SEARCH);
      this.local_storage.remove(constant.STORAGE_LOCAL_SCROLL_POINT);

      localStorage.removeItem(constant.STORAGE_LOCAL_MNG_LOGIN);
      localStorage.removeItem(constant.STORAGE_LOCAL_GIFTBOX_INFO);
      localStorage.removeItem(constant.STORAGE_LOCAL_GIFTBOX_AREA_STATUS);
      localStorage.removeItem(constant.STORAGE_LOCAL_GIFTBOX);
      localStorage.removeItem(constant.STORAGE_LOCAL_ORDER_INFO);
      localStorage.removeItem(constant.STORAGE_LOCAL_ORDER_DETAIL);
      localStorage.removeItem(constant.STORAGE_LOCAL_EVENT_ORDER_TOTAL_PRICE);
      localStorage.removeItem(constant.STORAGE_LOCAL_ORDER_DETAIL_GIFTBOX);
      localStorage.removeItem(constant.STORAGE_LOCAL_PLACE_INFO);
      localStorage.removeItem(constant.STORAGE_LOCAL_EVENT_INFO);
      localStorage.removeItem(constant.STORAGE_LOCAL_COMPANY_INFO);

      localStorage.removeItem(constant.STORAGE_LOCAL_ITEM_SEARCH_KEY);
      localStorage.removeItem(constant.STORAGE_LOCAL_ITEM_SEARCH_CATEGORY);
      localStorage.removeItem(constant.STORAGE_LOCAL_ITEM_SEARCH_PRICE_RANGE);
      localStorage.removeItem(constant.STORAGE_LOCAL_ITEM_SEARCH);
      localStorage.removeItem(constant.STORAGE_LOCAL_SCROLL_POINT);

      // 過去に使用していた古いログインセッション（今後は使用しないが残らないよう削除しておく）
      this.local_storage.remove('t-port_session');
      localStorage.removeItem('t-port_session');

      this.afs.firestore.disableNetwork();
    });
  }

  /**
   * パスワード再設定
   *
   * @param {(string | null)} email
   * @memberof AuthService
   */
  passwordReset(email: string | null): void {
    if (email) {
      this.afAuth.sendPasswordResetEmail(email)
      .then(() => {
        alert(constant.MSG_INFO_MAIL_CHANGE_PASSWORD);
      })
      .catch(() => {
        alert(constant.MSG_INFO_MAIL_CHANGE_PASSWORD_FAILED);
      })
    } else {
      this.subscription.add(
        this._user.subscribe(user => {
          if (user!.email) {
            this.afAuth.sendPasswordResetEmail(user!.email)
            .then(() => {
              alert(constant.MSG_INFO_MAIL_CHANGE_PASSWORD);
            })
            .catch(() => {
              alert(constant.MSG_INFO_MAIL_CHANGE_PASSWORD_FAILED);
            })
          }
        })
      );
    }
  }


  /**
   * emailアドレス再設定
   *
   * @param {string} email
   * @returns {Observable<ProcessResult>}
   * @memberof AuthService
   */
  emaildReset(email: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    this.afAuth.currentUser
    .then(user => {
      user!.updateEmail(email)
      .then(() => {
        result_subject.next({result: true, msg: constant.MSG_INFO_MAIL_CHANGE_EMAIL, id: null});
      })
      .catch(err => {
        debugLog(err);
        if (err.code == "auth/requires-recent-login") {
          result_subject.next({result: false, msg: constant.MSG_INFO_MAIL_CHANGE_EMAIL_FAILED2, id: null});
        } else {
          result_subject.next({result: false, msg: constant.MSG_INFO_MAIL_CHANGE_EMAIL_FAILED, id: null});
        }
      });
    });
    return result;
  }

  // public createAccount(user: Observable<firebase.User>): void {
  //   let now = new Date();
  //   this.subscription.add(
  //     user.subscribe(user => {
  //       const obj: any = {
  //         email: user.email,
  //         event_id: '',
  //         first_name: '',
  //         last_name: '',
  //         first_kname: '',
  //         last_kname: '',
  //         zip: '',
  //         addr1: '',
  //         addr2: '',
  //         addr3: '',
  //         addr4: '',
  //         tel: user.phoneNumber,
  //         favorite_items: [],
  //         company: [],
  //         place: [],
  //         current_company: '',
  //         current_place: '',
  //         info: {
  //           info_id: '',
  //           status: 0
  //         },
  //         role: [
  //           1
  //         ],
  //         current_role: 1,
  //         cust_type: 0,
  //         manager_flg: false,
  //         cross_search_flg: false,
  //         status: 1,
  //         created: now,
  //         updated: now,
  //         updated_user: {
  //           uid: '',
  //           name: ''
  //         },
  //         delete_flg: false,
  //       }
  //       this.accountCollection.doc(user.uid).set(obj);
  //     })
  //   );
  // }

  public testCreateAccount(uid: string): void {
    let now = new Date();
    // this.subscription.add(
    //   user.subscribe(user => {
        const obj: any = {
          email: 'maki-takahashi+02@timeless-gift.co.jp',
          event_id: 'cKuntx8VeyzKCYFKYH3V',
          first_name: '',
          last_name: '',
          first_kname: '',
          last_kname: '',
          zip: '',
          addr1: '',
          addr2: '',
          addr3: '',
          addr4: '',
          tel: '',
          favorite_items: [],
          company: [],
          place: [],
          current_company: '',
          current_place: '',
          info: {
            info_id: '',
            status: 0
          },
          role: [
            1
          ],
          current_role: 1,
          cust_type: 0,
          manager_flg: false,
          cross_search_flg: false,
          status: 1,
          created: now,
          updated: now,
          updated_user: {
            uid: '',
            name: ''
          },
          delete_flg: false,
        }
        this.accountCollection.doc(uid).set(obj);
    //   })
    // );
  }

  public get user(): Observable<firebase.User | null> {
    return this._user;
  }

  public set user(user: Observable<firebase.User>) {
    this._user = user;
  }

}
