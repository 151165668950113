import { Component, OnInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { OrderService } from '../../service/order.service';
import { SessionService } from '../../service/session.service';

import { OrderData } from '../../models/order_data';
import { OrderBranch } from '../../models/order_branch';
import * as constant from '../../models/constant';

import moment from 'moment';

declare function resetCommonrEvent(): void;
declare const debugLog: any;

@Component({
  selector: 'app-order-history-list',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
  ],
  templateUrl: './order-history-list.component.html',
  styleUrls: ['./order-history-list.component.scss']
})
export class OrderHistoryListComponent implements OnInit {
  private order_id: string = '';
  public order_base_info: OrderData = new OrderData();
  public order_datas: OrderData[] = [];
  public order_list_type = constant.ORDER_LIST_TYPE;
  public taxable_info: boolean[] = [];  // 税込総額表示の対象（2021/1/29以前に生成された注文データは税額のデータを持っていないため総額表示の対象外とする）
  private taxable_limit: Date = moment(new Date(constant.TAXABLE_DATE).setHours(0,0,0,0)).toDate();
  private subscription: Subscription = new Subscription();
  public rate = constant.TAX_RATE;
  public Math = Math;

  constructor(
    private sv_session: SessionService,
    private sv_order: OrderService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {

  }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.pipe(take(1)).subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.sv_order.initialize(session);
        }
      })
    );
    this.subscription.add(
      this.route.params.subscribe(params => {
        this.order_id = params['id'];
        this.sv_session.getOrderBaseInfo();
        this.sv_session.orderBaseInfo.subscribe(base => {
          this.order_base_info = base;
          this.getOrderHistory();
        });
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント設定
    }
  }

  private getOrderHistory() {
    this.subscription.add(
      this.sv_order.getOrderHistory(this.order_id).subscribe(datas => {
        // タイムレスへ発注された履歴のみ抽出
        let result: OrderData[] = [];
        this.taxable_info = [];
        if (datas) {
          datas.forEach((data, i) => {
            data.order_no = this.order_base_info.order_no;
            data.order_created = this.order_base_info.order_created;
            data.event_id = this.order_base_info.event_id;
            data.event_name = this.order_base_info.event_name;
            data.event_date = this.order_base_info.event_date;
            result[i] = data;
            // 総額表示判定
            if (data.order_branch) {
              this.taxable_info[i] = moment(data.order_branch.order_request_date.toDate()).unix() >= moment(this.taxable_limit).unix();
            }
          });
          this.order_datas = result;
        }
      })
    );
  }

  public selected(index: number): boolean {
    if (!this.order_datas[index].order_branch) return false;
    this.saveOrderBaseInfo(index);
    let branch = this.order_datas[index].order_branch as OrderBranch;
    this.router.navigate([`/order/${branch.objectID}`], {relativeTo: this.route});
    return false;
  }

  private saveOrderBaseInfo(index: number): void {
    if (!this.order_datas[index].order_branch) return;

    // 日付系を変換。（Timestampのobjectをstorageを介して渡すとただのobjectになってしまい、受け側の画面で日付として扱えなくなるため送り側で処理しておく）
    let b: OrderBranch = this.order_datas[index].order_branch as OrderBranch;
    this.order_datas[index].order_branch!.order_request_date = b.order_request_date ? moment(b.order_request_date.toDate()).toDate() : null;
    this.order_datas[index].order_branch!.order_date = b.order_date ? moment(b.order_date.toDate()).toDate() : null;
    this.order_datas[index].order_branch!.order_accept_date = b.order_accept_date ? moment(b.order_accept_date.toDate()).toDate() : null;
    this.order_datas[index].order_branch!.order_cancel_date = b.order_cancel_date ? moment(b.order_cancel_date.toDate()).toDate() : null;

    if (isPlatformBrowser(this.platformId)) {
      this.local_storage.set(constant.STORAGE_LOCAL_ORDER_BASE_INFO, this.order_datas[index]);
      this.sv_session.orderBaseSubject.next(this.order_datas[index]);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
