import { Component, Injector, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet} from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { AuthService } from './service/auth.service';
import { SessionService } from './service/session.service';
import { CommonService } from './service/common.service';

import { SearchComponent } from './common/search/search.component';
import { HeaderComponent } from './common/header/header.component';
import { NaviComponent } from './common/navi/navi.component';

@Component({
  selector: 'my-app',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    SearchComponent,
    HeaderComponent,
    NaviComponent,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild(SearchComponent, {static: false}) search!: SearchComponent;
  @ViewChild(HeaderComponent, {static: false}) header!: HeaderComponent;
  private function_category: string[] = [];
  public componentRef: any;
  public search_flg: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    public router: Router,
    private sv_auth: AuthService,
    private sv_session: SessionService,
    private sv_common: CommonService,
    private injector: Injector
  ){
  }

  ngOnInit() {
    // ログインセッション取得
    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.subscribe(session => {
        if (session && session.data) {
          // 商品画面以外へ遷移したら商品検索結果をクリア
          if (this.router.url != '/items' && this.router.url.indexOf('/item/') == -1) {
            this.sv_common.clearItemSearch();
          }
        }
      })
    );
  }

  onActivate(componentRef: any) {
    this.function_category = [];
    this.componentRef = null;
    if (componentRef.function_category) {
      this.function_category = componentRef.function_category;
      // 現在表示されている画面に従いヘッダー部検索エリアの内容を決定
      if (this.function_category.includes('search')) {
        this.search_flg = true;
      } else {
        this.search_flg = false;
      }
    }
    this.componentRef = componentRef;
  }

  signOut(): void {
    this.sv_auth.sign0ut();
  }

}
