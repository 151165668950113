<!-- <div class="content-block-inner">
    <div class="search-gift-input">
        <form #fm_search="ngForm">
        <input type="text" class="form-gift-search" name="keyword" id="keyword" placeholder="メンバーを検索" size="50"
            [ngModel]="search_word"
            (ngModelChange)="keywords=$event.split(' ')"
            (keyup.enter)="search()"/>
        <button (click)="search()" class="icon-search"></button>
        </form>
    </div>
</div> -->

<div class="header-item-wrap">
    <div class="inner-header">
        <div class="result"><span class="js-itemCount">{{members ? (members.length) : 0}}</span>名</div>
    </div><!--/inner-header-->
</div><!--/header-item-wrap-->

<!-- 自分をメンバーに追加する -->
<div *ngIf="manager_flg cross_search_flg" class="btn-box">
    <button type="button" class="btn-item mgt-style1" (click)="addMyself()"><span>自分をメンバーに追加する</span></button>
</div>

<!-- 検索結果 -->
<div class="list-wrap" id="list">
    <!-- ゲスト情報 -->
    <div class="js-listRow" *ngFor="let member of members; index as i">
        <div class="item-list send-list">

            <div class="lits-cont-box sendlist-add">
                <div class="list-name">
                    <div class="js-listRowName">{{member?.last_name}}　{{member?.first_name}}</div>
                    <div class="ruby">{{member?.last_kname}}　{{member?.first_kname}}</div>
                    <div class="ruby" *ngIf="member?.manager_flg">（マネージャー）</div>
                    <div class="ruby" *ngIf="member?.cross_search_flg">（会場横断検索）</div>
                </div>
            </div><!--/lits-cont-box-->

            <!-- ボタンエリア -->
            <div class="list-even-box sendlist-add js-btnAreaSendListChange">
                <button *ngIf="in_charge_event[i]" type="button" class="btn-staff-change" routerLink="/member-change/{{member?.objectID}}">挙式担当者変更</button>
                <div class="lits-change-box">
                    <button type="button" class="btn-edit js-editBtnSendListChange" (click)="toggleEdit(member, $event)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.8 48.8">
                        <path class="cls-1 icon-color" d="M1,38.05V47.8h9.75L38.5,20.05,28.75,10.3ZM47,11.51a2.6,2.6,0,0,0,0-3.67L41,1.76a2.6,2.6,0,0,0-3.67,0L32.53,6.52l9.75,9.75Z"/>
                        </svg>
                    </button>
                </div>
                <div class="lits-change-box">
                    <button type="button" data-target="modal-message" class="btn-trash"
                        [class.js-trashBtnMember]="!in_charge_event[i]"
                        (click)="setDelTargetId($event, in_charge_event[i])"
                        [attr.data-id]="member?.objectID">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 54" [attr.data-id]="member?.objectID">
                        <path class="icon-color" [attr.data-id]="member?.objectID" d="M50,8h-4h-7.9l-5-8H17.9l-5,8H5H1C0.4,8,0,8.4,0,9s0.4,1,1,1h4v39c0,2.8,2.2,5,5,5h31c2.8,0,5-2.2,5-5V10h4c0.6,0,1-0.4,1-1S50.6,8,50,8z M19.1,2h12.9l3.8,6H15.3L19.1,2z M44,49c0,1.7-1.3,3-3,3H10c-1.7,0-3-1.3-3-3V10h4.7h27.6H44V49z"/>
                        <path class="icon-color" [attr.data-id]="member?.objectID" d="M18.5,18c-0.6,0-1,0.4-1,1v25c0,0.6,0.4,1,1,1s1-0.4,1-1V19C19.5,18.4,19.1,18,18.5,18z"/>
                        <path class="icon-color" [attr.data-id]="member?.objectID" d="M32.5,18c-0.6,0-1,0.4-1,1v25c0,0.6,0.4,1,1,1s1-0.4,1-1V19C33.5,18.4,33.1,18,32.5,18z"/>
                        </svg>
                    </button>
                </div>
            </div>

        </div><!--/item-list-->

        <!-- 編集エリア（変更） -->
        <div *ngIf="edit_member_id == member?.objectID">
            <ng-container *ngTemplateOutlet="editArea; context:member"></ng-container>
        </div>
    </div><!-- js-listRow-->
</div><!--/list-wrap-->

<!-- 編集エリア テンプレート -->
<ng-template #editArea
let-objectID = "objectID"
let-manager_flg = "manager_flg"
let-cross_search_flg = "cross_search_flg">

<form #memberForm="ngForm" (ngSubmit)="updatePermission()" novalidate>
    <div class="gift-make-block js-createArea">
    <div class="item-list-open h-adr item-list-open-hed">

        <div class="input-box-wrap">
            <div class="checkbox-wrap">
                <label>
                <input type="checkbox" [(ngModel)]="editData.manager_flg" name="manager_flg" class="header-checkbox" value=true (change)="setCrossSearchFlg()">
                <span class="item-label">マネージャー</span>
                </label>
            </div>
        </div>

        <div class="input-box-wrap">
            <div class="checkbox-wrap">
                <label>
                <input type="checkbox" [(ngModel)]="editData.cross_search_flg" name="cross_search_flg" class="header-checkbox" value=true [disabled]="!editData.manager_flg">
                <span class="item-label">会場横断検索</span>
                </label>
            </div>
        </div>
        <span class="cap">※会場横断検索を設定する場合はマネージャーの設定も必要です</span>

        <div class="btn-box">
            <div class="flex-box j-content-center">
                <button class="btn-item" type="submit"><span>保存</span></button>
                <button class="btn-item white js-modalCreateSendListCancelBtn" type="reset"><span>クリア</span></button>
            </div>
        </div><!--/input-box-->

    </div><!--/item-list-open-->
    </div><!--/gift-make-block-->

</form>
</ng-template>

<app-modal-message (msg_event)="onModalMsgEvent($event)"></app-modal-message>
