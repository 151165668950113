<div *ngIf="show_item_detail">
    <ng-container *ngTemplateOutlet="modal"></ng-container>
</div>

<div class="content-block content-block-max">
    <div class="content-block-inner">
        <div class="back-link"><a (click)="back()">戻る</a></div>

        <div class="flex-box">
            <div class="title-content mng mg-style2">商品別数量<span class="cap" *ngIf="is_event">（この挙式の全注文の商品）</span></div>
        </div>

        <div class="div">
            <div *ngIf="order_info.order_branch && order_info.order_branch.latest" class="flex-box j-content-end">
                <div *ngIf="limit_over && (order_info.order_branch.status < status.ORDER || order_info.order_branch.status == status.CANCEL_REQUEST)" class="icon-note">
                <div class="text-red" style="margin-right: 10px;">発注期限超過</div>
                </div>
                <div class="state-change mgb0" *ngIf="order_info.order_branch.status == status.REQUEST_AFTER_ORDER || order_info.order_branch.status == status.REQUEST_AFTER_ACCEPT || order_info.order_branch.status == status.CANCEL_REQUEST">
                    注文変更
                    <span class="caption" *ngIf="order_info.order_branch.status == status.CANCEL_REQUEST">（キャンセル依頼）</span>
                </div>
                <div class="state-change black mgb0" *ngIf="order_info.order_branch.status == status.ORDER || order_info.order_branch.status == status.ORDER_AFTER_ACCEPT">発注済み</div>
                <div class="state-change black mgb0" *ngIf="order_info.order_branch.status == status.ACCEPT">受注済み</div>
                <div class="state-change black mgb0" *ngIf="order_info.order_branch.status == status.CANCEL || order_info.order_branch.status == status.CANCEL_BY_ADMIN">注文キャンセル</div>
                <div class="text-frame bg-bk"><span class="cap white">注文最終確定：</span>{{order_info.order_limit | date}}</div>
            </div>
            <div *ngIf="order_info.order_branch && !order_info.order_branch.latest" class="flex-box j-content-end">
                <div class="state-change black mgb0">発注履歴</div>
            </div>
        </div>

        <div class="mg-style2">
            <div class="flex-box">
                <dl class="flex-box j-content-start text-m-style2">
                    <dt class="dl-title">挙式日</dt>
                    <dd>{{order_info.event_date | date}}</dd>
                </dl>
            </div>
            <div class="title-content"><a routerLink="/event-detail/{{order_info.event_id}}">{{order_info.event_name}}</a></div>
        </div>
        <div class="error-box gray" *ngIf="!is_event && !taxable">
            <div class="text-m-style1">このご注文は税込み総額表示の対象期間外にご注文されております。表示価格は全て税抜きとなりますのでご注意ください。</div>
        </div>
        <div class="mg-style2" *ngIf="is_event">
            <div class="error-box gray" *ngIf="total_price?.contain_untaxable_order">
                <div class="text-m-style1">このご注文には税込み総額表示の対象期間外のご注文が含まれております。<br>表示価格は全て税抜きとなりますのでご注意ください。</div>
            </div>
            <div class="flex-box">
                <dl class="flex-box j-content-start">
                    <dt><span>この挙式の合計金額：</span></dt>
                    <dd>{{total_price.price | currency : 'JPY'}}<span class="m10 left">送料{{total_price.deliv_fee | currency : 'JPY'}}</span></dd>
                </dl>
            </div>
        </div>

        <div class="flex-box j-content-start a-items-start text-note-box">
            <div class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg></div>
            <span>この商品の商品別合計数は以下になります。同一商品が複数のギフトセットに含まれる場合は合算されます。</span>
        </div>
    </div><!--/content-block-inner-->

    <div class="inner-header">
        <div class="result">{{order_items.length}}種</div>
    </div>

    <div class="list-wrap">

        <div class="order-item-list" *ngFor="let item of order_items; index as i">
            <div class="order-list-wrap-inner list-size-l">
                <div class="order-item-list-inner giftbox-detail">
                    <div class="order-list-cont-box-odd">
                        <a class="list-img" (click)="detail(item.objectID)">
                            <div class="item-image item-box">
                                <div class="item-box-inner">
                                    <img *ngIf="item.thumb_s.no >= 0 && item.thumb_s.url" src="{{item.thumb_s.url}}" alt="">
                                    <img *ngIf="item.thumb_s.no >= 0 && !item.thumb_s.url" src="{{ dummy_thumb_s_url }}" alt="">
                                    <div class="protect-image"></div>
                                </div>
                            </div>
                        </a>
                        <div class="detail-gift-box">
                            <div class="detail-list-name fz-l"><p>{{item.name}}</p></div>
                            <div *ngIf="taxable && !place_tax_rate?.rule && !total_price?.contain_untaxable_order" class="detail-gift-price">
                                {{item?.reduced_tax_flg ?
                                    ((item.price + Math.floor(item.price * rate.REDUCED_TAX / 100)) | currency : 'JPY') :
                                    ((item.price + Math.floor(item.price * rate.TAX / 100)) | currency : 'JPY')}}
                                <span class="cap blank_l">(税込)</span>
                            </div>
                            <div *ngIf="taxable && place_tax_rate?.rule && !total_price?.contain_untaxable_order" class="detail-gift-price">
                                {{((item.price + Math.floor(item.price * place_tax_rate.rate / 100)) | currency : 'JPY')}}
                                <span class="cap blank_l">(税込)</span>
                            </div>
                            <div *ngIf="!taxable || total_price?.contain_untaxable_order" class="detail-gift-price">
                                {{item.price | currency : 'JPY'}}
                            </div>
                            <div *ngIf="taxable && !place_tax_rate?.rule && !total_price?.contain_untaxable_order && item?.reduced_tax_flg" class="m5 t">
                                <span class="cap blank_l">（※消費税{{rate.REDUCED_TAX}}%対象商品です）</span>
                            </div>
                        </div>
                    </div>

                    <div class="order-list-cont-box-even"><div class="">{{item.num}}個</div></div>

                </div><!--/order-item-list-inner-->
            </div><!--/order-list-wrap-inner-->
        </div><!--/order-item-list-->

    </div><!--/list-wrap-->

    <div class="content-footer-wrap only-pc">
        <div class="content-footer">
            <div class="content-footer-inner flex-box j-content-end a-items-end">
            <div class="box-odd">
                <button type="button" data-target="modal-confirmation" class="btn-pdf js-addCartBtn" [disabled]="order_items.length<1" (click)="printPage()">
                    <i class="material-icons">print</i>
                    <div style="margin-left: 3px;">このページを印刷する</div>
                </button>
            </div>
            </div><!--/content-footer-inner-->
        </div><!--/content-footer-->
    </div><!--/content-footer-wrap-->

</div><!--/content-block-->

<!-- 商品詳細テンプレート -->
<ng-template #modal>
    <app-modal>
        <app-item-detail #modalContents [select_item_key]="select_item_key"></app-item-detail>
    </app-modal>
</ng-template>
