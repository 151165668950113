import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { DateAdapter } from '@angular/material/core';
import { JpDateAdapter } from '../../util/adapter/jp-date-adapter';

import { ModalMessageComponent } from '../../common/modal-message/modal-message.component';

import { EventService } from '../../service/event.service';
import { SessionService } from '../../service/session.service';
import { PlaceService } from '../../service/place.service';
import { ConfigService } from '../../service/config.service';
import { CommonService } from '../../service/common.service';

import { Session } from '../../models/session';
import { Event } from '../../models/event';
import { SearchCondEvent } from '../../models/search_cond_event';
import { EventSearch } from '../../models/event_search';
import { EventSearchResult } from '../../models/event_search_result';
import { InputSelect } from '../../models/input_select';

import moment from 'moment';
import $ from 'jquery';

declare function resetCommonrEvent(): void;
declare function resetEventListEvent(): void;
declare function jsWeddingInfoMemoChange(): void;
declare function jsOtherHallCheckClick(): void;
declare function showSimpleMsg(msg: string): void;
declare function toggleCreateArea(): void;

@Component({
  selector: 'app-event-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    OverlayModule,
    ModalMessageComponent,
  ],
  providers: [
    { provide: DateAdapter, useClass: JpDateAdapter }
  ],
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {
  function_category = ["search"];
  public session: Session = new Session();
  private is_cross_search: boolean = false;
  private uid: string = '';
  public events: EventSearch[] = [];
  public event_search_result: EventSearchResult = new EventSearchResult();
  public order_limit: Date[] = [];
  public pages: number[] = [];
  public select_page_index: number = 0;

  public add_flg = false;
  private edit_event_id: string = '';
  public places: InputSelect[] = [];
  public select_place: string = '';
  public members1: InputSelect[] = [];
  public members2: InputSelect[] = [];
  public select_member: string = '';
  private cond: SearchCondEvent = new SearchCondEvent();
  private saved: boolean = false;
  private subscription: Subscription = new Subscription();
  public editData: Event = new Event();

  public company: any; // AoT対策
  public place: any; // AoT対策

  constructor(
    private sv_session: SessionService,
    private sv_event: EventService,
    private sv_place: PlaceService,
    private sv_config: ConfigService,
    private sv_common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    moment.updateLocale("ja", null);
  }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add (
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        }
        else {
          this.session = session;
          this.uid = session.data.uid;
          this.is_cross_search = session.data.cross_search_flg;
          this.sv_event.initialize(session);
          this.getEvents();
          // 所属企業・会場あり
          if (session.data.current_company && session.data.current_place) {
            this.select_place = session.data.current_place;
            this.getPlaces();
          }
          // 所属企業・会場なし（フリープランナー等）
          else {
            this.getMembers();
          }
        }
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント設定
    }
  }

  private getEvents(): void {
    let cond: SearchCondEvent = new SearchCondEvent();
    cond.staff = this.session.data!.uid;
    if (this.session.data?.current_company && this.session.data.current_place) {
      cond.company_id = this.session.data.current_company;
      cond.place_id = this.session.data.current_place;
    }
    this.search(cond); // algoliaに登録されている事が前提のため、登録直後は検索されない可能性あり。
    resetEventListEvent();
  }

  public search(cond: SearchCondEvent) {
    this.cond = cond;
    if (!this.is_cross_search) {
      cond.place_id = this.session.data!.current_place;
    }
    this.subscription.add(
      this.sv_event.searchEvents(cond, 1).subscribe(res => {
        this.event_search_result = res;
        res.event_search.forEach((ev, i) => {
          ev.date = moment.unix(ev.date).toDate();
        });
        this.events = res.event_search;
        // 注文最終確定日を取得
        this.order_limit = [];
        this.events.forEach((ev, i) => {
          this.order_limit[i] = moment(ev.date).add(-14, 'd').toDate();
          this.sv_config.getOrderLimit(ev.date).subscribe(adjust => {
            this.order_limit[i] = adjust.order_limit;
          });
        });
        // ページ設定
        this.pages = [];
        for(let i = 1; i <= res.nbPages; i++) {
          this.pages.push(i);
        }
      })
    );
  }

  /**
   * 挙式登録直後の一覧表示用検索
   * 挙式登録直後は algoliaへの登録ができていない可能性があるため、DBから検索
   * @private
   */
  private searchEventsAfterRegist(): void {
    // ログインユーザーが担当の未来挙式を挙式日の昇順で取得する
    if (this.session.data?.current_place && this.session.data?.uid) {
      this.subscription.add(
        this.sv_event.getEventSearchesForMember(this.session.data?.current_place, this.session.data?.uid).subscribe(event_searchs => {
          this.events = event_searchs.filter(ev => this.sv_common.convertTimestampToDate(ev.date) >= new Date()); // 未来挙式
          this.events = this.events.reverse(); // 挙式日昇順に並べ替え
          // 注文最終確定日を取得
          this.order_limit = [];
          this.events.forEach((ev, i) => {
            this.order_limit[i] = moment(this.sv_common.convertTimestampToDate(ev.date)).add(-14, 'd').toDate();
            this.sv_config.getOrderLimit(ev.date).subscribe(adjust => {
              this.order_limit[i] = adjust.order_limit;
            });
          });
          // ページ設定
          this.pages = [];
        })
      );
    }
  }

  // TimestampをDateに変換。（テンプレートでの日付表示の際に date directiveが使えない日付データに対しテンプレートから直接使用）
  convertTimestampToDate(timestamp: any): Date {
    return this.sv_common.convertTimestampToDate(timestamp);
  }

  public paging(page: number, index: number): void {
    this.select_page_index = index;
    this.subscription.add(
      this.sv_event.searchEvents(this.cond, page).subscribe(res => {
        this.event_search_result = res;
        res.event_search.forEach(ev => {
          ev.date = moment.unix(ev.date).toDate();
        });
        this.events = res.event_search;
        // 注文最終確定日を取得
        this.order_limit = [];
        this.events.forEach((ev, i) => {
          this.order_limit[i] = moment(ev.date).add(-14, 'd').toDate();
          this.sv_config.getOrderLimit(ev.date).subscribe(adjust => {
            this.order_limit[i] = adjust.order_limit;
          });
        });
      })
    );
  }

  public toggleAddOpen() {
    // 新規追加エリア開閉状態制御
    this.add_flg = !this.add_flg;
    setTimeout(() => {
      if (this.add_flg) {
        jsWeddingInfoMemoChange();
        jsOtherHallCheckClick();
      }
    }, 100);
    return false;
  }

  public selected(index: number): boolean {
    const event_id = this.events[index].objectID;
    this.router.navigate([`/event-detail/${event_id}`], {relativeTo: this.route});
    return false;
  }

  public save(event_key: string | null, ev: any): void {
    if (!this.session.data) return;

    if (this.session.data.current_company) {
      this.editData.company_id = this.session.data.current_company;
    }
    // 顧客登録期限を挙式日1ヶ月前に設定
    this.editData.token_cust = {
      id: this.sv_event.createToken(),
      limit: moment(this.editData.date).add(-1, 'months').toDate()
    };
    // ゲスト登録期限を挙式日1ヶ月前に設定
    this.editData.token_guest = {
      id: this.sv_event.createToken(),
      limit: moment(this.editData.date).add(-1, 'months').toDate()
    };
    this.subscription.add(
      this.sv_event.save(null, this.editData).subscribe(data => {
        this.add_flg = false;
        showSimpleMsg(data.msg as string);
      })
    );
  }

  public onModalSimpleMsgEvent(event: boolean) {
    setTimeout(() => {
      this.searchEventsAfterRegist();
      // this.setEditDataDefault();
      this.editData = new Event();
      toggleCreateArea();
    }, 2000);
  }

  private getPlaces(): void {
    if (!this.session.data) return;

    this.members1 = [];
    this.members2 = [];
    this.subscription.add(
      this.sv_place.getPlaces(this.session.data.current_company).pipe(take(1)).subscribe(places => {
        this.places = [];
        if (this.is_cross_search) {
          places.forEach(place => {
            this.places.push({
              key: place.objectID,
              value: place.name,
              grep_colum: null,
              select: false,
              disabled: false
            });
          });
        }
        else {
          places.forEach(place => {
            if (place && place.objectID == this.session.data?.current_place) {
              this.places.push({
                key: place.objectID,
                value: place.name,
                grep_colum: null,
                select: false,
                disabled: false
              });
              return;
            }
          });
        }
        this.places.unshift({key: '', value: '', grep_colum: null, select: false, disabled: false});
      })
    );

  }

  private getMembers(): void {
    if (!this.session.data) return;

    if (this.session.data.current_place) {
      this.subscription.add(
        this.sv_place.getMembers(this.select_place).subscribe(members => {
          this.members1 = [];
          this.members2 = [];
          members.forEach(member => {
            let mdata = {
              key: member.objectID,
              value: member.last_name + member.first_name,
              grep_colum: '',
              select: false,
              disabled: false
            }
            this.members1.push(mdata);
            this.members2.push(mdata);
          });
          this.members1.unshift({key: '', value: '', grep_colum: null, select: false, disabled: false});
          this.members2.unshift({key: '', value: '', grep_colum: null, select: false, disabled: false});
        })
      );
    }
    else {
      this.members1 = [];
      this.members2 = [];
      let mdata = {
        key: this.session.data.uid,
        value: this.session.data.last_name + this.session.data.first_name,
        grep_colum: '',
        select: false,
        disabled: false
      };
      this.members1.push(mdata);
      this.members2.push(mdata);
    }
  }

  public onChangePlace(): void {
    if (!this.session.data) return;
    if (this.session.data.current_place && !this.select_place) {
      this.members1 = [];
      this.members2 = [];
    } else {
      this.getMembers();
    }
  }

  public setYourself(): void {
    if (!this.session.data) return;
    if (this.session.data.current_place) {
      this.editData.place_id = this.session.data.current_place;
      this.select_place = this.session.data.current_place;
      this.onChangePlace();
    }
    setTimeout(() => {
      if (!this.session.data) return;
      this.editData.staff_ids[0] = this.session.data.uid;
      this.select_member = this.session.data.uid;
    }, 100);
  }

  public onChangeStaff(type: number) {
    if (type == 1) {
      this.members2.forEach((member2, i) => {
        if (member2.key == this.select_member) {
          this.members2[i].disabled = true;
        } else {
          this.members2[i].disabled = false;
        }
      });
    } else if (type == 2) {
      this.members1.forEach((member1, j) => {
        if (member1.key == this.select_member) {
          this.members1[j].disabled = true;
        } else {
          this.members1[j].disabled = false;
        }
      });
    }
    if (this.editData.staff_ids[0] == this.editData.staff_ids[1]) {
      this.editData.staff_ids[1] = '';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
