<div id="pagetop"><a pageScroll [pageScrollDuration]="200" href="#header" class="js-innerJamp"></a></div>
<div class="content-block content-block-max">
  <div class="content-block-inner">
    <div *ngIf="!session_exist" class="back-link">
      <a (click)="back()">戻る</a>
    </div>

    <div class="m30 title-content center">{{title}}</div>
    <div class="terms m10 wrap">{{body}}</div>

  </div>
</div>
<app-spinner *ngIf="!ready"></app-spinner>
