<div class="content-block content-block-max">
  <div class="content-block-inner howto-block">
    <div class="flex-box">
      <div class="title-content howto-tit">操作方法ガイド</div>
    </div>
  </div><!--/content-block-inner-->

  <div class="list-wrap howto-content">
    <div class="howto-title">STEP1：ゲストリストを作りましょう</div>
    <ul class="howto-content">
      <li class="howto-box">
        <div class="howto-text">
          ゲストリストメニューより、ゲストを追加していきましょう。ゲスト情報の登録は、QRコードを使ってゲストに直接していただくこともできます。
        </div>
        <div class="howto-img">
          <img src="assets/img/howto_img_pc1.png" alt="イメージ" class="pc">
          <img src="assets/img/howto_img_sp1.png" alt="イメージ" class="sp">
        </div>
        <div class="howto-text">
          ゲストリストの「グループ」画面から、贈り分けするグループごとにゲストを登録しておくと、その後の設定をスムーズに進めていただけます。
        </div>
        <div class="howto-img">
          <img src="assets/img/howto_img_pc2.png" alt="イメージ" class="pc">
          <img src="assets/img/howto_img_sp2.png" alt="イメージ" class="sp">
        </div>
      </li><!--/howto-box-->
    </ul><!--/howto-content-->
  </div><!--/list-wrap howto-content-->

  <div class="list-wrap howto-content">
    <div class="howto-title">STEP2：ギフトセット作成し、商品を登録しましょう</div>
    <ul class="howto-content">
      <li class="howto-box">
        <div class="howto-text">
          商品カタログ画面の下にある「ギフトセットを作りましょう」をクリックし、贈り分けをするゲストごとのボックスを作成しましょう。
        </div>
        <div class="howto-img">
          <img src="assets/img/howto_img_pc3.png" alt="イメージ" class="pc">
          <img src="assets/img/howto_img_sp3.png" alt="イメージ" class="sp">
        </div>
        <div class="howto-text">
          ギフトセットができたら、ギフトセット一覧から商品を登録するセットを選択。
        </div>
        <div class="howto-img">
          <img src="assets/img/howto_img_pc4.png" alt="イメージ" class="pc">
          <img src="assets/img/howto_img_sp4.png" alt="イメージ" class="sp">
        </div>
        <div class="howto-text">
          各ギフトセットに商品を登録していきましょう。
        </div>
        <div class="howto-img">
          <img src="assets/img/howto_img_pc5.png" alt="イメージ" class="pc">
          <img src="assets/img/howto_img_sp5.png" alt="イメージ" class="sp">
        </div>
      </li><!--/howto-box-->
    </ul><!--/howto-content-->
  </div><!--/list-wrap howto-content-->

  <div class="list-wrap howto-content">
    <div class="howto-title">STEP3：贈る相手を追加しましょう</div>
    <ul class="howto-content">
      <li class="howto-box">
        <div class="howto-text">
          各ギフトセットから贈る相手を追加しましょう。「グループを追加」を押すと、STEP1で作成したグループごとのゲストを一括で追加できます。
        </div>
        <div class="howto-img">
          <img src="assets/img/howto_img_pc6.png" alt="イメージ" class="pc">
          <img src="assets/img/howto_img_sp6.png" alt="イメージ" class="sp">
        </div>
        <div class="howto-text">
          贈る相手一覧から、お届け日／熨斗カードの文面＆お名前を設定します。一括設定はもちろん、ゲストごとにも設定をしていただけます。
        </div>
        <div class="howto-img">
          <img src="assets/img/howto_img_pc7.png" alt="イメージ" class="pc">
          <img src="assets/img/howto_img_sp7.png" alt="イメージ" class="sp">
        </div>
      </li><!--/howto-box-->
    </ul><!--/howto-content-->
  </div><!--/list-wrap howto-content-->

  <div class="list-wrap howto-content">
    <div class="howto-title">STEP4：オーダーリストへ追加し注文をしましょう</div>
    <ul class="howto-content">
      <li class="howto-box">
        <div class="howto-text">
          STEP3まで完了したら「オーダーリストに追加」をクリック。オーダーリスト画面より「注文する」ボタンを押せば、注文完了です。
        </div>
        <div *ngIf="!is_additional" class="howto-img">
          <img src="assets/img/howto_img_pc8-1.png" alt="イメージ" class="pc">
          <img src="assets/img/howto_img_sp8-1.png" alt="イメージ" class="sp">
        </div>
        <div *ngIf="is_additional" class="howto-img">
          <img src="assets/img/howto_img_pc8-2.png" alt="イメージ" class="pc">
          <img src="assets/img/howto_img_sp8-2.png" alt="イメージ" class="sp">
        </div>
      </li><!--/howto-box-->
    </ul><!--/howto-content-->
  </div><!--/list-wrap howto-content-->

</div><!--/content-block-->
