import { Component, OnInit, OnDestroy, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Location } from '@angular/common';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Subscription, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

import { ModalComponent } from '../../common/modal/modal.component';
import { ModalMessageComponent } from '../../common/modal-message/modal-message.component';
import { EventQrComponent } from '../event-qr/event-qr.component';
import { SpinnerComponent } from '../../common/spinner/spinner.component';

import { EventService } from '../../service/event.service';
import { AccountService } from '../../service/account.service';
import { SessionService } from '../../service/session.service';
import { PlaceService } from '../../service/place.service';
import { ModalService } from '../../service/modal.service';
import { ConfigService } from '../../service/config.service';
import { GuestService } from '../../service/guest.service';
import { CommonService } from '../../service/common.service';
import { CompanyService } from '../../service/company.service';

import { Session } from '../../models/session';
import { Event } from '../../models/event';
import { Account } from '../../models/account';
import { Place } from '../../models/place';
import { Company} from '../../models/company';
import * as constant from '../../models/constant';

import { environment } from '../../../environments/environment';

import $ from 'jquery';
import moment from 'moment';

declare function resetCommonrEvent(): void;
declare function jsTrashBtnCustClick(): void;
declare function showModalBack(flg: boolean): void;
declare function showSimpleMsg(msg: string): void;
declare function showConfirmTypeMsg(msg: string, type: string): void;
declare const debugLog: any;

@Component({
  selector: 'app-event-detail',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    ModalComponent,
    ModalMessageComponent,
    EventQrComponent,
    SpinnerComponent,
  ],
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
  @ViewChild(ModalComponent, {static: false}) modal!: ModalComponent;
  private session: Session = new Session();
  private event_type: any = null;
  private event_id: string = '';
  public event: Event = new Event();
  public order_limit: Date = new Date(0);
  private token_cust: {id: string, limit: Date} = {id: '', limit: new Date(0)};
  public custs: Account[] = [];
  private delete_cust_index: number = -1;
  private delete_cust_id: string = '';
  public staffs: Account[] = [];
  public place: Place = new Place();
  private company: Company = new Company();
  public show_qr: boolean = false;
  public url: string = '';
  public event_status = constant.EVENT_STATUS;

  public onew_link: boolean = false;
  public onewBaseKey: string = '';
  public onewCustomerNo: string = '';
  public onewKyotenNo: string = '';
  public onewLoginID: string = '';

  private init_check: boolean = true;
  public list_import_status: number = 0;
  public ready: boolean = true;
  private subscription: Subscription = new Subscription();

  constructor(
    private sv_session: SessionService,
    private sv_event: EventService,
    private sv_account: AccountService,
    private sv_company: CompanyService,
    private sv_place: PlaceService,
    private sv_modal: ModalService,
    private sv_config: ConfigService,
    private sv_guest: GuestService,
    private sv_common: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {

  }

  ngOnInit() {
    this.subscription.add(
      this.sv_modal.close_result$.subscribe(() => this.show_qr = false)
    );

    this.sv_session.loginCheck();
    this.subscription.add (
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.session = session;
          this.sv_common.initialize(session);
          this.sv_event.initialize(session);
          this.sv_guest.initialize(session);
          this.route.params.subscribe(params => {
            this.event_id = params['id'];
            this.getEventAccount();
          });
        }
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント設定
    }
  }

  private getEventAccount(): void {
    this.subscription.add(
      forkJoin([
        this.sv_event.getEvent(this.event_id).pipe(take(1)),
        this.sv_account.getEventCustomer(this.event_id).pipe(take(1))
      ]).subscribe(([event, accounts]) => {
        if (!event || !accounts) return;
        this.event = event;
        this.token_cust = event.token_cust;
        this.custs = accounts;
        if (this.event.company_id != this.session.data?.current_company) {
          this.router.navigate([`/not-found-page`], {relativeTo: this.route});
          return;
        }
        // 注文最終確定日
        this.order_limit = moment(event.date.toDate()).add(-14, 'd').toDate();
        this.sv_config.getOrderLimit(event.date.toDate()).subscribe(adjust => {
          this.order_limit = adjust.order_limit;
        });
        // 担当者
        this.staffs = [];
        event.staff_ids.forEach(staff_id => {
          this.sv_account.getAccount(staff_id).subscribe(staff => {
            if (staff) {
              this.staffs.push(staff);
            }
            jsTrashBtnCustClick();
          });
        });
        // 得意先
        this.sv_company.getCompany(event.company_id).subscribe(company => {
          this.company = company;
          this.onew_link = company.onew_link;
          this.onewBaseKey = company.onew_base_key; // ONE-W共通キー（得意先別）
        });
        // 会場
        this.sv_place.getPlaces(event.company_id).subscribe(places => {
          places.forEach(place => {
            if (place.objectID == event.place_id) {
              this.place = place;
            }
          });
        });
        // ONE-Wゲストリスト登録可否の事前チェック
        this.init_check = true;
        this.guestImportCheck();
      })
    );
  }

  public back(): void {
    this.location.back();
  }

  public showQR(): boolean {
    this.show_qr = true;
    this.createUrl();
    showModalBack(true);
    return false;
  }

  private closeQR(): void {
    this.show_qr = false;
  }

  private createUrl(): void {
    if (!this.urlCheck()) {
      showSimpleMsg(constant.MSG_SET_REGIST_TOKEN_FAILED_FOR_MNG);
      this.show_qr = false;
      return;
    }
    this.url = `${environment.BASE_CUST_URL}/cust-regist/${this.event_id}/${this.token_cust.id}`;
  }

  private urlCheck(): boolean {
    if (!this.event.token_cust.id || !this.event.token_cust.limit) {
      return false;
    }
    let token_cust_limit: Date = moment(this.event.token_cust.limit.toDate()).toDate();
    let today: Date = moment(new Date().setHours(0,0,0,0)).toDate();
    if (token_cust_limit < today) {
      return false;
    } else {
      return true;
    }
  }

  public copy(event: any): void {
    if (!isPlatformBrowser(this.platformId)) return;
    if (!this.url) {
      this.createUrl();
    }
    let clipboard = $('<textarea></textarea>');
    clipboard.addClass('clipboard');
    clipboard.html(this.url);
    $(event.target).append(clipboard);
    clipboard.select();
    document.execCommand('copy');
    clipboard.remove();
  }

  public setDelTarget(index: number, id: string): void {
    this.delete_cust_index = index;
    this.delete_cust_id = id;
    showConfirmTypeMsg("選択した顧客を削除します。<br>この操作は取り消しできません。実行してもよろしいですか？", "del_cust");
  }

  public onModalTypeMsgEvent(event: {msg: boolean, type: any}) {
    this.event_type = event.type;
    switch (event.type) {
      // 顧客削除
      case "del_cust":
        if (!event.msg) {
          this.delete_cust_index = -1;
          this.delete_cust_id = '';
          return;
        }
        setTimeout(() => {
          if (this.delete_cust_index >= 0 && this.delete_cust_id) {
            this.delCust();
          }
        }, 300);
        break;

      // ONE-Wゲストリスト取り込み
      case "onew_guestlist_import":
        if (!event.msg) {
          return;
        }
        setTimeout(() => {
          if (this.onewBaseKey && this.onewCustomerNo && this.onewKyotenNo && this.onewLoginID) {
            this.importGuestList();
          }
        }, 300);
        break;

      default:
        this.event_type = '';
        break;
    }
    return false;
  }

  private delCust(): void {
    if (this.event_type === "del_cust") {
      this.subscription.add (
        this.sv_event.delCust(this.event_id, this.delete_cust_index, this.delete_cust_id).subscribe(data => {
          showSimpleMsg(data.msg as string);
          if (data.result) {
            this.custs.splice(this.delete_cust_index, 1);
          }
          this.delete_cust_index = -1;
          this.delete_cust_id = '';
        })
      );
      this.event_type = "";
    }
  }

  public callGuestImportCheck(): void {
    this.init_check = false;
    this.guestImportCheck();
  }

  private guestImportCheck(): void{
    // 登録済みゲストあり、または挙式が無効の場合はエラーメッセージ表示し処理を中止
    this.subscription.add (
      this.sv_event.getEvent(this.event_id).pipe(take(1)).subscribe(event => {
        if (!event) return;
        if (event.status !== constant.EVENT_STATUS.UNSUBSCRIBED) {
          this.sv_guest.getGuests(this.event_id).subscribe(guests => {
            if (guests && guests.length > 0) {
              // 登録済みのゲストリストあり
              this.list_import_status = 1;
            } else {
              // 取り込み可能
              this.list_import_status = 0;
              if (!this.init_check) {
                showConfirmTypeMsg("ONE-Wゲストリストの一括取り込みを実行します。<br>この操作は取り消しできません。実行してもよろしいですか？", "onew_guestlist_import");
              }
            }
          });
        } else {
          // 挙式ステータスが無効
          this.list_import_status = 1;
        }
      })
    );
  }

  private importGuestList(){
    this.ready = false;
    if (this.event_type === "onew_guestlist_import") {
      this.sv_common.getOneWGuests(this.event_id, this.onewBaseKey, this.onewCustomerNo, this.onewKyotenNo, this.onewLoginID).subscribe(res1 => {
        if (res1.result) {
          debugLog(res1.msg);
          if (res1.guests && res1.guests.length > 0) {
            // 1件以上あれば登録
            // debugLog(res1.guests);
            res1.guests.forEach((guest, g) => {
              setTimeout(() => {
                this.sv_guest.save(this.event_id, null, guest).subscribe(res2 => {
                  if (g === res1.guests!.length -1) {
                    this.ready = true;
                    showSimpleMsg((res1.guests!.length) + '件のゲストを登録しました。');
                  }
                });
              }, g * 200);
            });
          } else {
            // 0件
            this.ready = true;
            showSimpleMsg(constant.MSG_SELECT_NONE);
          }
        } else {
          this.ready = true;
          showSimpleMsg(constant.MSG_SELECT_FAILED);
          debugLog(res1.msg);
        }
      });
      this.event_type = "";
    }
  }

  public guestsOpen() {
    this.local_storage.set(constant.STORAGE_LOCAL_COMPANY_INFO, this.company);
    this.sv_session.companySubject.next(this.company);

    // 挙式日はany型。Storageを経由した後のany型の日付は遷移先画面でtoDate()で変換できなくなるため、先に変換とフォーマットを済ませた状態の値をStorageに入れる。
    this.event.date = moment(this.event.date.toDate()).format('YYYY/MM/DD');
    this.local_storage.set(constant.STORAGE_LOCAL_EVENT_INFO, this.event);
    this.sv_session.eventSubject.next(this.event);

    this.router.navigate([`/event-guests/${this.event_id}`], {relativeTo: this.route});
  }

  ngAfterViewChecked() {
    if (!this.modal) {
      this.show_qr = false;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
