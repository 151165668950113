import { Routes } from '@angular/router';

// landingPage
import { LandingPageComponent } from './common/landing-page/landing-page.component';

// cust
import { FaqComponent } from './cust/faq/faq.component';
import { HowtoComponent } from './cust/howto/howto.component';

// mng
import { SignInComponent } from './common/sign_in/sign_in.component';
import { ItemsComponent } from './common/items/items.component';
import { PwResetComponent } from './common/pw-reset/pw-reset.component';
import { ItemDetailComponent } from './common/item-detail/item-detail.component';
import { OrderListComponent } from './mng/order-list/order-list.component';
import { OrderDetailComponent } from './mng/order-detail/order-detail.component';
import { OrderDetailGiftComponent } from './mng/order-detail-gift/order-detail-gift.component';
import { OrderDetailSendlistComponent } from './mng/order-detail-sendlist/order-detail-sendlist.component';
import { OrderHistoryListComponent } from './mng/order-history-list/order-history-list.component';
import { EventListComponent } from './mng/event-list/event-list.component';
import { EventDetailComponent } from './mng/event-detail/event-detail.component';
import { EventGuestsComponent } from './mng/event-guests/event-guests.component';
import { EventEditComponent } from './mng/event-edit/event-edit.component';
import { PlaceComponent } from './mng/place/place.component';
import { MemberComponent } from './mng/member/member.component';
import { MemberChangeComponent } from './mng/member-change/member-change.component';
import { MemberRegistComponent } from './mng/member-regist/member-regist.component';
import { MemberAccountComponent } from './mng/member-account/member-account.component';
import { RepresentativeRegistComponent } from './mng/representative-regist/representative-regist.component';

// other
import { NotFoundPageComponent } from './common/not-found-page/not-found-page.component';
import { TermsAndPolicyComponent } from './common/terms-and-policy/terms-and-policy.component';

export const routes: Routes = [
  // mng
  { path: '', component: LandingPageComponent },
  { path: 'sign_in', component: SignInComponent },
  { path: 'pw-reset', component: PwResetComponent },
  { path: 'items', component: ItemsComponent },
  { path: 'item', component: ItemDetailComponent },
  { path: 'order-list/:type', component: OrderListComponent},
  { path: 'order/:id', component: OrderDetailComponent},
  { path: 'order-gift/:id/:type', component: OrderDetailGiftComponent},
  { path: 'order-sendlist/:id/:type', component: OrderDetailSendlistComponent},
  { path: 'order-history-list/:id', component: OrderHistoryListComponent},
  { path: 'event-list', component: EventListComponent},
  { path: 'event-detail/:id', component: EventDetailComponent},
  { path: 'event-guests/:id', component: EventGuestsComponent},
  { path: 'event-edit/:id', component: EventEditComponent},
  { path: 'place', component: PlaceComponent},
  { path: 'member/:id', component: MemberComponent},
  { path: 'member-change/:id', component: MemberChangeComponent},
  { path: 'member-regist/:cid/:pid/:token', component: MemberRegistComponent },
  { path: 'member-account', component: MemberAccountComponent},
  { path: 'representative-regist/:cid/:token', component: RepresentativeRegistComponent },
  { path: 'terms-and-policy/:type', component: TermsAndPolicyComponent},
  { path: 'not-found-page', component: NotFoundPageComponent},

  // cust
  { path: 'faq', component:FaqComponent},
  { path: 'howto', component: HowtoComponent},

  // not found
  { path: '**', redirectTo: '/not-found-page' },
];
