import { Component, OnInit, ContentChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from '../../service/modal.service';

declare const hideModal: any;

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  public close: boolean = false;
  // modalContents という変数名で抽象化（呼び出し元のViewでModalのセレクタに対しこの変数名を定義）
  @ContentChild('modalContents', {static: false}) modalContents: any;
  // @ContentChild(GuestSelectComponent) guest_select: GuestSelectComponent;

  constructor(private sv_modal: ModalService) { }

  ngOnInit() {
    this.close = false;
  }

  ngOnDestroy() {
    this.close = true;
  }

  // onReceiveEventFromChild(close: boolean) {
  //   if (close) {
  //     this.sv_modal.close();
  //   }
  // }

  public modal_close(event: any) {
    hideModal();
    this.sv_modal.close();

  }

}
