import { Observable, of } from 'rxjs';
import { OrderGiftbox } from './order_giftbox';
import { OrderGuest } from '../models/order_guest';
import { OrderItem } from '../models/order_item';

export interface IOrderedGiftbox {
    order_giftbox: OrderGiftbox;
    // giftbox_objectID: string;
    order_guests$: Observable<OrderGuest[]>;
    order_items$: Observable<OrderItem[]>;
    order_guests: OrderGuest[];
    order_items: OrderItem[];
}
export class OrderedGiftbox implements IOrderedGiftbox {
    order_giftbox: OrderGiftbox = new OrderGiftbox();
    order_guests$: Observable<OrderGuest[]> = of([]);
    order_items$: Observable<OrderItem[]> = of([]);
    order_guests: OrderGuest[] = [];
    order_items: OrderItem[] = [];
}
