import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable, of, Subject} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CommonService } from './common.service';

import { Config } from '../models/config'
import { ItemAttentionAdjust } from '../models/item_attention_adjust';
import * as constant from '../models/constant';

import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private configDocument!: AngularFirestoreDocument<Config>;
  private order_adjust: Observable<Config | null> = of(null);

  constructor(
    private firestore: AngularFirestore,
    private sv_common: CommonService,
  ) { }

  /**
   * 注文期限調整情報 取得
   *
   * @returns {Observable<Config | null>}
   * @memberof ConfigService
   */
  private getOrderAdjust(): Observable<Config | null> {
    this.configDocument = this.firestore.doc<Config>(`config/${constant.CONFIG_ORDER_ADJUST}`);
    this.order_adjust = this.configDocument.snapshotChanges().pipe(
      map(doc => {
        if (!doc.payload.exists) {
          return null;
        }
        let obj = {
            gw: doc.payload.data().gw,
            summer: doc.payload.data().summer,
            winter: doc.payload.data().winter,
            other: doc.payload.data().other
        };
        return obj;
      })
    );
    return this.order_adjust.pipe(
      catchError(this.handleError<Config | null>('getOrderAdjust'))
    );
  }

  /**
   * 注文期限調整
   * （注文最終確定日、注文アラート日、期限超過判定　を取得）
   *
   * @param {*} event_date
   * @returns {Observable<{order_limit: Date, order_limit_alert: Date, limit_over: boolean}>}
   * @memberof ConfigService
   */
  getOrderLimit(event_date: any): Observable<{order_limit: Date, order_limit_alert: Date, limit_over: boolean}> {
    let subject: Subject<{order_limit: Date, order_limit_alert: Date, limit_over: boolean}> = new Subject();
    let result: Observable<{order_limit: Date, order_limit_alert: Date, limit_over: boolean}> = subject.asObservable();

    this.getOrderAdjust().subscribe(adjust => {
      // let date = moment(event_date).toDate();
      let date = this.sv_common.convertTimestampToDate(event_date);
      let today = new Date().setHours(0,0,0,0);
      let from: Date;
      let to: Date;
      let hit: boolean = false;
      let default_adjust: {order_limit: Date, order_limit_alert: Date, limit_over: boolean} = {
        order_limit: moment(date).add(-14, 'd').toDate(),
        order_limit_alert: moment(date).add(-20, 'd').toDate(),
        limit_over: false
      };

      if (adjust) {
        // GW
        adjust.gw.forEach(gw => {
          from = moment(gw.event_from).toDate();
          to = moment(gw.event_to).toDate();
          if (
            moment(date).isSameOrAfter(from, 'day') &&
            moment(date).isSameOrBefore(to, 'day')
          ) {
            hit = true;
            subject.next({
              order_limit: gw.order_limit,
              order_limit_alert: gw.order_alert,
              limit_over: moment(today).isAfter(moment(gw.order_limit), 'day')
              // limit_over: moment(today).unix() > moment(gw.order_alert).unix()
            });
          }
        });
        // 夏季休業
        adjust.summer.forEach(summer => {
          from = moment(summer.event_from).toDate();
          to = moment(summer.event_to).toDate();
          if (
            moment(date).isSameOrAfter(from, 'day') &&
            moment(date).isSameOrBefore(to, 'day')
          ) {
            hit = true;
            subject.next({
              order_limit: summer.order_limit,
              order_limit_alert: summer.order_alert,
              limit_over: moment(today).isAfter(moment(summer.order_limit), 'day')
            });
          }
        });
        // 年末年始休業
        adjust.winter.forEach(winter => {
          from = moment(winter.event_from).toDate();
          to = moment(winter.event_to).toDate();
          if (
            moment(date).isSameOrAfter(from, 'day') &&
            moment(date).isSameOrBefore(to, 'day')
          ) {
            hit = true;
            subject.next({
              order_limit: winter.order_limit,
              order_limit_alert: winter.order_alert,
              limit_over: moment(today).isAfter(moment(winter.order_limit), 'day')
            });
          }
        });

        if (!hit) {
          subject.next(default_adjust);
        }
      }
    });
    return result;
  }

  /**
   * 挙式日による商品メッセージ表示制御
   *
   * @returns {Observable<ItemAttentionAdjust | null>}
   * @memberof ConfigService
   */
  getItemAttentionAdjust(): Observable<ItemAttentionAdjust | null> {
    let get_result: Subject<ItemAttentionAdjust | null> = new Subject();
    let result = get_result.asObservable();
    result = this.firestore.doc<ItemAttentionAdjust>('config/item_attention_adjust').snapshotChanges().pipe(
      map(doc => {
        if (!doc.payload.exists) {
          return null;
        }
        let obj = {
          vol: doc.payload.data().vol,
          prev_vol: doc.payload.data().prev_vol,
          vol_base_date: doc.payload.data().vol_base_date,
          prev_vol_base_date: doc.payload.data().prev_vol_base_date,
          item_remove_date: doc.payload.data().item_remove_date,
          msg_start: doc.payload.data().msg_start,
          msg_end: doc.payload.data().msg_end,
          price_adjust_msg_start: doc.payload.data().price_adjust_msg_start,
          price_adjust_msg_end: doc.payload.data().price_adjust_msg_end
        };
        return obj;
      })
    );
    return result.pipe(
      catchError(this.handleError<ItemAttentionAdjust | null>(`getItemAttentionAdjust id=item_attention_adjust`))
    );
  }

  /**
   * 失敗したHttp操作を処理します。
   * アプリを持続させます。
   * @param operation - 失敗した操作の名前
   * @param result - observableな結果として返す任意の値
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: リモート上のロギング基盤にエラーを送信する
      console.error(error); // かわりにconsoleに出力

      // TODO: ユーザーへの開示のためにエラーの変換処理を改善する
      // this.log(`${operation} failed: ${error.message}`);

      // 空の結果を返して、アプリを持続可能にする
      return of(result as T);
    };
  }
}
