<div class="content-block content-block-max">
    <div class="content-block-inner">
        <div class="flex-box">
            <div class="title-content mng">注文情報</div>
        </div>
        <div class="flex-box j-content-start mgt10">
            <div class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg></div>
            <a data-target="modal-deskcard" class="modal-open a-style js-modalDeskCard">卓上カードについてご注意</a>
        </div>
    </div><!--/content-block-innerk-->

    <div class="page" *ngIf="pages.length > 1">
        <div class="no" (click)="paging(1, 0)"><<</div>
        <div class="no" *ngFor="let page of pages; index as i" (click)="paging(page, i)" [class.current]="i == select_page_index">{{page}}</div>
        <div class="no" (click)="paging(pages.length, pages.length-1)">>></div>
    </div>

    <div class="inner-header">
        <div class="result" *ngIf="order_datas.length > 0; else noData">
            <span *ngIf="order_datas.length > len; else onePage" class="js-itemCount">
                {{(select_page_index * len) + 1}}〜
                {{ (pages.length - 1 > select_page_index) ? ((select_page_index + 1) * len) : order_datas.length }}件／
                {{order_datas.length ? order_datas.length : 0}}件
            </span>
            <ng-template #onePage><div class="result">{{order_datas.length}}件</div></ng-template>
        </div>
        <ng-template #noData><div class="result">0件</div></ng-template>
    </div><!--/inner-header-->

    <div class="list-wrap">

        <a *ngFor="let order of order_datas | slice: start: start+len; index as i" (click)="selected(start+i)" class="item-list"> <!-- routerLink="/order/{{order?.order_id}}"  -->
            <div class="order-info-box list-fz-size-s">
                <div class="lits-cont-box-odd w-style-s">
                    <div class="orderer-box">
                        <dl class="flex-box j-content-start">
                            <dt class="dl-title">挙式日</dt>
                            <dd>{{order?.event_date | date}}</dd>
                        </dl>
                        <div class="wed-name">
                            <p>{{order?.event_name}}</p>
                            <div *ngIf="order?.event_status == 5"><span class="cap2 s red red-square">挙式無効</span></div>
                        </div>
                    </div>
                    <div class="order-detail-box">
                        <dl class="flex-box j-content-start a-items-start">
                            <dt class="dl-title w5e">注文No</dt>
                            <dd>{{order?.order_no}}</dd>
                        </dl>
                        <dl class="flex-box j-content-start a-items-start">
                            <dt class="dl-title w5e">顧客注文</dt>
                            <dd *ngIf="order?.order_branch?.order_request_date">{{order?.order_branch?.order_request_date.toDate() | date : 'short'}}</dd>
                        </dl>
                        <dl class="flex-box j-content-start a-items-start">
                            <dt class="dl-title w5e">注文者</dt>
                            <dd *ngIf="order?.order_branch?.order_request_user">{{order?.order_branch?.order_request_user?.name}}様</dd>
                        </dl>
                        <dl class="flex-box j-content-start">
                            <dt class="dl-title w5e">発注期限</dt>
                            <dd>{{order?.order_limit | date}}</dd>
                        </dl>
                    </div>
                </div><!--/mng-box-odd-->
                <div class="lits-cont-box-even w-style-l">
                    <div class="state-box">
                        <div *ngIf="order?.order_branch?.status == status.REQUEST_AFTER_ORDER || order?.order_branch?.status == status.REQUEST_AFTER_ACCEPT" class="state-change">
                            変更注文
                        </div>
                        <div *ngIf="order?.order_branch?.status == status.CANCEL_REQUEST">
                            <div class="state-change">変更注文</div>
                            <span class="cap red">　キャンセル依頼</span>
                            <div *ngIf="order?.order_branch?.cancel_request_date">{{order?.order_branch?.cancel_request_date.toDate() | date : 'short'}}</div>
                        </div>
                        <div *ngIf="order?.order_branch?.status == status.ORDER || order?.order_branch?.status == status.ORDER_AFTER_ACCEPT">
                            <div class="state-change black" style="text-align: center;">発注済み</div>
                            <div *ngIf="order?.order_branch?.order_date">{{order?.order_branch?.order_date.toDate() | date : 'short'}}</div>
                        </div>
                        <div *ngIf="order?.order_branch?.status == status.CANCEL_ORDER">
                            <div style="text-align: center;">キャンセル<br>発注済み</div>
                            <div *ngIf="order?.order_branch?.order_cancel_date">{{order?.order_branch?.order_cancel_date.toDate() | date : 'short'}}</div>
                        </div>
                        <div *ngIf="order?.order_branch?.status == status.ACCEPT">
                            <div class="state-change black" style="text-align: center;">受注済み</div>
                            <div *ngIf="order?.order_branch?.order_accept_date">{{order?.order_branch?.order_accept_date.toDate() | date : 'short'}}</div>
                        </div>
                        <div *ngIf="order?.order_branch?.status == status.CANCEL || order?.order_branch?.status == status.CANCEL_BY_ADMIN">
                            <div style="text-align: center;">キャンセル</div>
                            <div *ngIf="order?.order_branch?.cancel_date">{{order?.order_branch?.cancel_date.toDate() | date : 'short'}}</div>
                        </div>
                    </div>
                    <div class="state-box">
                        <div *ngIf="limit_over[i] && order.order_branch && (order.order_branch.status < status.ORDER || order.order_branch.status == status.CANCEL_REQUEST)" class="icon-note">
                            <div class="text-red">発注期限超過</div>
                        </div>
                    </div>
                    <div class="state-box">
                        <div *ngIf="order_history_exists[i] && order.order_branch && (order.order_branch.status >= status.REQUEST_AFTER_ORDER)" class="state-history">
                            <a (click)="selectedHistory(start+i)" class="a-style">発注履歴</a>
                        </div>
                    </div>
                </div><!--/mng-box-even-->
            </div><!--/order-info-box-->
        </a><!--/item-list-->

    </div><!--/list-wrap-->

</div><!--/content-block-->

<app-modal-message></app-modal-message>
