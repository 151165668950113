<header id="header">
	<div class="header-section">
		<div class="header-section-inner">
			<div class="header-cont">

				<h1 class="logo"
					*ngIf="(!cust_regist && !guest_regist && !member_regist && !representative_regist && !terms_and_policy) || (terms_and_policy && session_exist)">
					<a routerLink="/items"><img src="assets/img/logo.svg" alt="THE TIMELESS"></a>
				</h1>
				<h1 class="logo"
					*ngIf="cust_regist || guest_regist || member_regist || representative_regist || (terms_and_policy && !session_exist)">
					<a><img src="assets/img/logo.svg" alt="THE TIMELESS"></a>
				</h1>

				<div class="hamburger-button pc"
					*ngIf="(!cust_regist && !guest_regist && !member_regist && !representative_regist && !landing && !terms_and_policy) || (terms_and_policy && session_exist)">
					<div class="hamburger hamburger--arrow">
						<div class="nav-button-badge"><span *ngIf="!no_info_not_read" class="badge">{{header_count.info_not_read_cnt}}</span></div>
						<div class="hamburger-box">
							<div class="hamburger-inner"></div>
						</div>
					</div>
				</div>

				<div *ngIf="searchable" class="header-search">
					<a class="search-trigger">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.2 42" class="icon-search-header">
						<path class="icon-color" d="M36.7,35.6l-8.9-8.9C30.4,23.9,32,20.1,32,16c0-8.8-7.2-16-16-16S0,7.2,0,16s7.2,16,16,16c4,0,7.6-1.5,10.4-3.9l8.9,8.9c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3C37.1,36.6,37.1,35.9,36.7,35.6z M2,16C2,8.3,8.3,2,16,2s14,6.3,14,14s-6.3,14-14,14S2,23.7,2,16z"/>
						</svg>
					</a>
				</div>

				<div class="header-dev" style="margin-right:93px;"><span>{{syte_type}}</span></div>

				<div *ngIf="!landing && session_exist && user_role==2" class="header-order-change">
					<a routerLink="/order-list/{{order_list_type.CHANGE}}">変更<br>注文</a>
					<span *ngIf="!no_change_order" class="badge-order">{{header_count.change_order_cnt}}</span>
				</div>

				<div *ngIf="!landing && session_exist && user_role==2" class="header-order-new">
					<a routerLink="/order-list/{{order_list_type.NEW}}">新規<br>注文</a>
					<span *ngIf="!no_request_order" class="badge-order">{{header_count.request_order_cnt}}</span>
				</div>

				<div *ngIf="landing || (terms_and_policy && !session_exist)" class="header-order-limit">
					<button type="button" class="btn-item size-s" routerLink="/sign_in">サインイン</button>
				</div>

			</div><!--/header-cont-->
		</div><!--/header-section-inner-->

		<div class="nav-section sp"
			*ngIf="(!cust_regist && !guest_regist && !member_regist && !representative_regist && !landing && !terms_and_policy) || (terms_and_policy && session_exist)">
			<div class="drawer">
				<div class="nav-button-badge"><span *ngIf="!no_info_not_read" class="badge">{{header_count.info_not_read_cnt}}</span></div>
				<div class="nav-button">
					<span class="nav-icon"></span>
					<span class="nav-icon"></span>
					<span class="nav-icon"></span>
				</div>
			</div><!--/drawer-->
			<div class="backlayer"></div>
		</div><!--/nav-section-->

	</div><!--/header-section-->

</header>
