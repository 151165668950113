import { Component, OnInit, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Subscription, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

import { ModalMessageComponent } from '../../common/modal-message/modal-message.component';

import { OrderService } from '../../service/order.service';
import { SessionService } from '../../service/session.service';
import { EventService } from '../../service/event.service';
import { ConfigService } from '../../service/config.service';

import { Session } from '../../models/session';
import { OrderData } from '../../models/order_data';
import { SearchCondOrder } from '../../models/search_cond_order';
import { EventSearch } from '../../models/event_search';
import { OrderBranch } from '../../models/order_branch';
import { OrderCount } from '../../models/order_count';
import * as constant from '../../models/constant';

import moment from 'moment';

declare function resetCommonrEvent(): void;
declare function jsModalDeskCardClick(): void;

@Component({
  selector: 'app-order-list',
  standalone: true,
  imports: [
    CommonModule,
    ModalMessageComponent,
  ],
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {
  function_category = ["search"];
  private order_limit: Date = new Date(0);
  private session: Session = new Session();
  private is_cross_search: boolean = false;
  private uid: string = '';
  public order_datas: OrderData[] = [];
  public order_history_exists: boolean[] = [];
  public limit_over: boolean[] = [];
  private subscription: Subscription = new Subscription();
  private cond: SearchCondOrder = new SearchCondOrder();
  public status = constant.ORDER_STATUS;
  public pages: number[] = [];
  public select_page_index: number = 0;
  public start: number = 0;
  public len: number = constant.LIST_ROW_MAX;
  private order_list_type: number = 0;

  constructor(
    private sv_session: SessionService,
    private sv_order: OrderService,
    private sv_event: EventService,
    private sv_config: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    moment.updateLocale("ja", null);
  }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.pipe(take(1)).subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.session = session;
          this.is_cross_search = session.data.cross_search_flg;
          this.uid = session.data.uid;
          this.sv_event.initialize(session);
          this.sv_order.initialize(session);
          this.route.params.subscribe(params => {
            this.order_list_type = params['type'];
            if (this.order_list_type == constant.ORDER_LIST_TYPE.DEFAULT){
              this.getDefaultOrderList();
            } else {
              this.headerSearchOrder();
            }
          });
        }
      })
    );

    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント設定
      jsModalDeskCardClick(); // 卓上カードの説明表示
    }
  }

  private headerSearchOrder(): void {
    let status_cond = constant.SEARCH_ORDER_STATUS;
    if (this.order_list_type == constant.ORDER_LIST_TYPE.NEW) {
      status_cond = [
        {key: 'request', value: '新規注文', checked: true},
        {key: 'change', value: '注文変更', checked: false},
        {key: 'order', value: '発注', checked: false},
        {key: 'cancel_order', value: 'キャンセル発注', checked: false},
        {key: 'cancel', value: 'キャンセル', checked: false}
      ];
    } else if (this.order_list_type == constant.ORDER_LIST_TYPE.CHANGE) {
      status_cond = [
        {key: 'request', value: '新規注文', checked: false},
        {key: 'change', value: '注文変更', checked: true},
        {key: 'order', value: '発注', checked: false},
        {key: 'cancel_order', value: 'キャンセル発注', checked: false},
        {key: 'cancel', value: 'キャンセル', checked: false}
      ];
    }
    let cond: SearchCondOrder = {
      event: {
        name: '',
        date_from: null,
        date_to: null,
        order_limit_from: null,
        order_limit_to: null,
        company_id: '',
        company_name: '',
        place_id: this.session.data?.current_place ?? '',
        place_name: '',
        staff: this.session.data?.uid ?? '',
        exclude_ck: true
      },
      order_request_date_from: null,
      order_request_date_to: null,
      order_date_from: null,
      order_date_to: null,
      order_status_type: status_cond
    };
    this.search(cond);
  }

  private getDefaultOrderList(): void {
    // デフォルトの注文一覧はヘッダーの注文数を取得した際にlocalStoregeに入れた注文情報を流用。（headerComponent)
    this.subscription.add(
      this.sv_session.orderList.subscribe(datas => {
        if (!datas) return;
        let target: OrderData[] = [];
        datas.forEach((data, j) => {
          // 履歴の有無を確認
          this.order_history_exists[j] = false;
            this.subscription.add(
              this.sv_order.getLatestOrderBranchId(data.order_id).subscribe(branches => {
              if (branches.length) {
                branches.forEach(branch => {
                  if (branch.status >= constant.ORDER_STATUS.ORDER && branch.order_date) {
                    this.order_history_exists[j] = true;
                    // return;
                  }
                });
              }
            })
          );
          // 注文期限超過判定
          this.limit_over = [];
          this.subscription.add(
            this.sv_config.getOrderLimit(data.event_date).subscribe(adjust => {
              if (adjust) {
                datas[j].order_limit = adjust.order_limit;
                this.limit_over[j] = adjust.limit_over;
              }
            })
          );
        });
        this.order_datas = datas;

        // ページ設定
        this.pages = [];
        for(let i = 1; i <= Math.ceil(this.order_datas.length / this.len); i++) {
          this.pages.push(i);
        }
      })
    );
  }

  public selected(index: number): void {
    this.saveOrderBaseInfo(index);
    this.router.navigate([`/order/${this.order_datas[index].order_id}`], {relativeTo: this.route});
  }

  public selectedHistory(index: number): void {
    this.saveOrderBaseInfo(index);
    this.router.navigate([`/order-history-list/${this.order_datas[index].order_id}`], {relativeTo: this.route});
  }

  private saveOrderBaseInfo(index: number): void {
    // 日付系を変換。（Timestampのobjectをstorageを介して渡すとただのobjectになってしまい、受け側の画面で日付として扱えなくなるため送り側で処理しておく）
    if (this.order_datas[index].order_branch) {
      let b: OrderBranch = this.order_datas[index].order_branch as OrderBranch;
      this.order_datas[index].order_branch!.order_request_date = b.order_request_date ? moment(b.order_request_date.toDate()).toDate() : null;
      this.order_datas[index].order_branch!.order_date = b.order_date ? moment(b.order_date.toDate()).toDate() : null;
      this.order_datas[index].order_branch!.order_accept_date = b.order_accept_date ? moment(b.order_accept_date.toDate()).toDate() : null;
      this.order_datas[index].order_branch!.cancel_request_date = b.cancel_request_date ? moment(b.cancel_request_date.toDate()).toDate() : null;
      this.order_datas[index].order_branch!.order_cancel_date = b.order_cancel_date ? moment(b.order_cancel_date.toDate()).toDate() : null;
      this.order_datas[index].order_branch!.cancel_date = b.cancel_date ? moment(b.cancel_date.toDate()).toDate() : null;

      this.local_storage.set(constant.STORAGE_LOCAL_ORDER_BASE_INFO, this.order_datas[index]);
      this.sv_session.orderBaseSubject.next(this.order_datas[index]);
    }
  }

  public search(cond: SearchCondOrder) {
    if (!this.session.data) return;
    this.cond = cond;
    if (!this.is_cross_search) {
      cond.event.place_id = this.session.data.current_place;
    }

    this.subscription.add(
      forkJoin([
        this.sv_event.searchEvents(cond.event, null).pipe(take(1)), // 担当施行
        this.sv_order.getOrders(cond.event.staff).pipe(take(1)), // 担当注文
        this.sv_order.searchOrdersFromRequestDatas(cond, true).pipe(take(1)), // 注文依頼日で検索
        this.sv_order.searchOrdersFromOrderDate(cond, true).pipe(take(1)) // 注文日で検索
      ]).subscribe(([events, orders1, orders2, orders3]) => {
        // event, orders1, orders2, orders3 の積集合を抽出
        this.order_datas = this.setSearchData(events.event_search, orders1, orders2, orders3, false);
        // 注文期限超過判定
        this.limit_over = [];
        this.order_datas.forEach((order, i) => {
          this.sv_config.getOrderLimit(order.event_date).subscribe(adjust => {
            this.order_datas[i].order_limit = adjust.order_limit;
            this.limit_over[i] = adjust.limit_over;
          });
        });
        // ページ設定
        this.pages = [];
        for(let i = 1; i <= Math.ceil(this.order_datas.length / this.len); i++) {
          this.pages.push(i);
        }
      })
    );
  }

  public paging(page: number, index: number): void {
    this.select_page_index = index;
    this.start = this.len * index;
  }

  private setSearchData(events: EventSearch[], orders1: OrderData[], orders2: OrderData[], orders3: OrderData[], init: boolean): OrderData[] {
    // キャンセルの注文を除外（ただし一度でもタイムレスへ発注された注文は残す）
    orders2.forEach((val2, i) => {
      if (val2.order_branch?.status == constant.ORDER_STATUS.CANCEL && !val2.order_branch.order_date) {
        orders2.splice(i, 1);
      }
    });
    if (!init) { // 検索時
      orders3.forEach((val3, j) => {
        if (val3.order_branch?.status == constant.ORDER_STATUS.CANCEL && !val3.order_branch.order_date) {
          orders3.splice(j, 1);
        }
      });
    }
    // event, orders1, orders2, orders3 の積集合を抽出
    //【A】→ event, orders1
    let data1: OrderData[] = [];
    orders1.forEach(val1 => {
      events.forEach(ev => {
        if (val1.event_id == ev.objectID) {
          let merge: OrderData = {
            order_id: val1.order_id,
            order_no: val1.order_no,
            order_created: val1.order_created,
            event_id: val1.event_id,
            event_name: ev.name,
            event_status: ev.status,
            event_date: moment.unix(ev.date).toDate(),
            order_limit: moment.unix(ev.date).add(-14, 'd').toDate(),
            order_branch: null
          };
          data1.push(merge);
        }
      })
    });
    //【B】→ orders2, orders3
    let data2: OrderData[] = [];
    if (!init) { // 検索時
      orders2.forEach(val2 => {
        orders3.forEach(val3 => {
          if (val2.order_id == val3.order_id) data2.push(val2);
        });
      });
    } else { // 初期表示時
      data2 = orders2;
    }
    // 【A】【B】
    let result: OrderData[] = [];
    data2.forEach(d2 => {
      data1.forEach(d1 => {
        if (d2.order_id == d1.order_id) {
          let merge_result: OrderData = {
            order_id: d1.order_id,
            order_no: d1.order_no,
            order_created: d1.order_created,
            event_id: d1.event_id,
            event_name: d1.event_name,
            event_date: d1.event_date,
            event_status: d1.event_status,
            order_limit: d1.order_limit,
            order_branch: d2.order_branch
          };
          result.push(merge_result);
        }
      });
    });

    // 絞り込み
    if (!init) {
      // 発注期限日
      if (this.cond.event.order_limit_from && this.cond.event.order_limit_to) {
        result = result.filter(d => d.order_limit! >= this.cond.event.order_limit_from! && d.order_limit! <= this.cond.event.order_limit_to!);
      } else if (this.cond.event.order_limit_from) {
        result = result.filter(d => d.order_limit! >= this.cond.event.order_limit_from!);
      } else if (this.cond.event.order_limit_to) {
        result = result.filter(d => d.order_limit! <= this.cond.event.order_limit_to!);
      }

      // ステータスチェックあり
      if (this.cond.order_status_type.filter(status => status.checked).length) {
        let status_filter: OrderData[] = [];
        this.cond.order_status_type.forEach(status => {
          if (status.checked) {
            switch(status.key) {
              case 'request':
                status_filter = status_filter.concat(result.filter(d => d.order_branch?.status == constant.ORDER_STATUS.REQUEST));
                break;
              case 'change':
                status_filter = status_filter.concat(result.filter(d => d.order_branch!.status == constant.ORDER_STATUS.REQUEST_AFTER_ORDER || d.order_branch?.status == constant.ORDER_STATUS.REQUEST_AFTER_ACCEPT || d.order_branch!.status == constant.ORDER_STATUS.CANCEL_REQUEST));
                break;
              case 'order':
                status_filter = status_filter.concat(result.filter(d => d.order_branch?.status == constant.ORDER_STATUS.ORDER || d.order_branch?.status == constant.ORDER_STATUS.ORDER_AFTER_ACCEPT));
                break;
              case 'accept':
                status_filter = status_filter.concat(result.filter(d => d.order_branch?.status == constant.ORDER_STATUS.ACCEPT));
                break;
              case 'cancel_order':
                status_filter = status_filter.concat(result.filter(d => d.order_branch?.status == constant.ORDER_STATUS.CANCEL_ORDER));
                break;
              case 'cancel':
                status_filter = status_filter.concat(result.filter(d => d.order_branch?.status == constant.ORDER_STATUS.CANCEL || d.order_branch?.status == constant.ORDER_STATUS.CANCEL_BY_ADMIN));
                break;
            };
          }
        });
        result = status_filter;
      }

      result.forEach((data, j) => {
        // 履歴の有無を確認
        this.order_history_exists[j] = false;
        this.sv_order.getLatestOrderBranchId(data.order_id).subscribe(branches => {
          if (branches) {
            branches.forEach(branch => {
              if (branch.status >= constant.ORDER_STATUS.ORDER && branch.order_date) {
                this.order_history_exists[j] = true;
                // return true;
              }
            });
          }
        });
      });
    }

    return result;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
