import { SessionData } from './session_data';

import { Observable } from 'rxjs';

export class Session { // 追加
    login: boolean;
    data: SessionData | null;

    constructor() {
      this.login = false;
      this.data = null;
    }

    // reset(): Session { // 追加
    //   this.login = false;
    //   return this;
    // }
}
