<div *ngIf="show_item_detail">
  <ng-container *ngTemplateOutlet="modal"></ng-container>
</div>

<div id="pagetop"><a pageScroll [pageScrollDuration]="200" href="#header" class="js-innerJamp"></a></div>
<div class="content-block">
  <div class="product-header">
    <div class="result">{{items.length}}件</div>
      <div class="view-wrap">
        <div class="clear-search-cond" (click)="clearSearchCond()">検索条件クリア</div>
        <div class="view-box">
          <button type="button" value="" class="view-column js-multiColumnButton active"></button>
          <button type="button" value="" class="view-single js-singleColumnButton"></button>
        </div>
        <!-- <div class="select-wrap">
          <select name="" id="" class="form-select">
            <option value="">新着順</option>
            <option value="">おすすめ順</option>
            <option value="">人気順</option>
          </select>
        </div> -->
      </div><!--/view-wrap-->
  </div><!--/product-header-->

  <!-- 検索結果 -->
  <div class="product-block-multi js-productList">
      <ul class="product-box">
        <li class="box" *ngFor="let item of items; index as i">
          <div class="item-image item-box not-select js-itemImage" [attr.data-item_id]="item?.objectID"
          (click)="detail(item.objectID)">
            <div class="item-box-inner">
              <img *ngIf="image_url[i]" src="{{image_url[i]}}" alt="">
              <img *ngIf="!image_url[i]" src="{{dummy_img_url}}" alt="">
              <div class="protect-image"></div>
              <div *ngIf="item?.status===status.SOLDOUT" class="soldout list">SOLDOUT</div>
              <div *ngIf="items_attention[i] && items_attention[i].type" class="attention" [class.blue]="items_attention[i].type==='prev_vol'">{{items_attention[i].msg}}</div>
            </div>
          </div>
          <div class="product-info js-product-info" (click)="detail(item.objectID)">
            <div class="item-name"><p>{{item?.name}}</p></div>
            <div class="info-inner">
              <div *ngIf="!place_tax_rate?.rule" class="item-price">
                {{item?.reduced_tax_flg ?
                  ((item.price + Math.floor(item.price * rate.REDUCED_TAX / 100)) | currency : 'JPY') :
                  ((item.price + Math.floor(item.price * rate.TAX / 100)) | currency : 'JPY')}}
                <span class="cap blank_l">(税込)</span>
              </div>
              <div *ngIf="place_tax_rate?.rule" class="item-price">
                {{((item.price + Math.floor(item.price * place_tax_rate.rate / 100)) | currency : 'JPY')}}
                <span class="cap blank_l">(税込)</span>
              </div>
              <button *ngIf="role_cust" type="button" class="favorite-btn js-favariteButton"
                click-stop-propagation2
                [class.favorite-item]="item?.favorite"
                [attr.data-current_favorite]="item?.favorite"
                [attr.data-item_objectid]="item?.objectID"
                (click)="updateFavorite($event)"
              ></button>
            </div>
          </div>
        </li>
        <!-- </virtual-scroller> -->
        <!-- </cdk-virtual-scroll-viewport> -->
      </ul>
  </div><!--/product-block-->
</div><!--/content-block-->

<app-modal-message></app-modal-message>
<!-- 商品詳細テンプレート -->
<ng-template #modal>
  <app-modal>
    <app-item-detail #modalContents [select_item_key]="select_item_key"></app-item-detail>
  </app-modal>
</ng-template>
