import { Injectable } from '@angular/core';

import { TabModel } from '../models/tab-model';

@Injectable({
  providedIn: 'root'
})
export class SwitchTabService {

  private _tabs: TabModel[] = [];

  constructor() { }

  /**
   *  表示中のコンテンツ
   *
   * @returns {*}
   * @memberof SwitchTabService
   */
  getCurrentContents(): any {
    for(let i = 0; i < this._tabs.length; i++) {
      const target = this._tabs[i];
      if (target.current) {
        return target.contents;
      }
    }
  }

  /**
   * 表示コンテンツ切り替え
   *
   * @param {string} name クリックされたタブ名
   * @returns {*} 切り替え先コンテンツ
   * @memberof SwitchTabService
   */
  changeCurrentContents(name: string): any {
    let contents: any;
    for(let i = 0; i < this._tabs.length; i++) {
      const target = this._tabs[i];
      target.current = false;
      if (target.name == name) {
        target.current = true;
        contents = target.contents;
      }
    }
    return contents;
  }

  public get tabs(): TabModel[] {
    return this._tabs;
  }

  public set tabs(value: TabModel[]) {
    this._tabs = value;
  }

}
