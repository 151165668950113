/**************************************/
//  離島郵便番号一覧（沖縄離島も含む）
/**************************************/
export const RITO_ZIP_LIST = [
  // 沖縄の離島
  '9070000',
  '9070024',
  '9070023',
  '9070332',
  '9070022',
  '9070001',
  '9070453',
  '9070452',
  '9070242',
  '9070014',
  '9070241',
  '9070004',
  '9070021',
  '9070333',
  '9070013',
  '9070003',
  '9070331',
  '9070451',
  '9070002',
  '9070012',
  '9070243',
  '9070244',
  '9070011',
  '9010200',
  '9010232',
  '9010243',
  '9010223',
  '9010202',
  '9010231',
  '9010204',
  '9010244',
  '9010221',
  '9010233',
  '9010212',
  '9010213',
  '9010242',
  '9010234',
  '9010215',
  '9010222',
  '9010241',
  '9010225',
  '9010235',
  '9010203',
  '9010205',
  '9010211',
  '9010214',
  '9010201',
  '9010224',
  '9060000',
  '9060502',
  '9060503',
  '9060505',
  '9060507',
  '9060504',
  '9060506',
  '9060501',
  '9060204',
  '9060202',
  '9060201',
  '9060203',
  '9060102',
  '9060108',
  '9060109',
  '9060107',
  '9060105',
  '9060106',
  '9060104',
  '9060103',
  '9060101',
  '9060304',
  '9060302',
  '9060301',
  '9060306',
  '9060303',
  '9060305',
  '9060421',
  '9060004',
  '9060001',
  '9060002',
  '9060015',
  '9060003',
  '9060013',
  '9060008',
  '9060012',
  '9060006',
  '9060005',
  '9060007',
  '9060011',
  '9060422',
  '9060014',
  '9041300',
  '9041304',
  '9041302',
  '9041303',
  '9041301',
  '9041200',
  '9041202',
  '9041201',
  '9041203',
  '9050500',
  '9050503',
  '9050505',
  '9050504',
  '9050501',
  '9050502',
  '9013500',
  '9013502',
  '9013501',
  '9013400',
  '9013311',
  '9013401',
  '9013403',
  '9013312',
  '9013402',
  '9013601',
  '9013800',
  '9013806',
  '9013801',
  '9013803',
  '9013805',
  '9013802',
  '9013804',
  '9013900',
  '9013902',
  '9013903',
  '9013901',
  '9013100',
  '9013103',
  '9013101',
  '9013138',
  '9013105',
  '9013106',
  '9013123',
  '9013132',
  '9013121',
  '9013122',
  '9013133',
  '9013115',
  '9013134',
  '9013114',
  '9013107',
  '9013113',
  '9013125',
  '9013136',
  '9013124',
  '9013135',
  '9013131',
  '9013108',
  '9013102',
  '9013112',
  '9013104',
  '9013111',
  '9013137',
  '9060600',
  '9060601',
  '9060602',
  '9060603',
  '9070000',
  '9071435',
  '9071542',
  '9071541',
  '9071311',
  '9071221',
  '9071432',
  '9071543',
  '9071431',
  '9071101',
  '9071434',
  '9071433',
  '9071751',
  '9071544',
  '9071800',
  '9071801',
  // 沖縄以外の離島
  '0431405',
  '0431401',
  '0431407',
  '0431404',
  '0431403',
  '0431410',
  '0431408',
  '0431402',
  '0431406',
  '0431412',
  '0431409',
  '0431411',
  '0784100',
  '0783871',
  '0431417',
  '0431416',
  '0431414',
  '0431415',
  '0971201',
  '0971111',
  '0971202',
  '0285131',
  '0285134',
  '0285305',
  '0285222',
  '0285304',
  '0285302',
  '0285221',
  '0285132',
  '0285303',
  '0285133',
  '4703505',
  '4703504',
  '4440416',
  '7993470',
  '7983358',
  '7980212',
  '7980099',
  '7980098',
  '7980097',
  '7980096',
  '7942410',
  '7942504',
  '7942506',
  '7942592',
  '7942510',
  '7942501',
  '7942520',
  '7942512',
  '7942508',
  '7942513',
  '7942502',
  '7942509',
  '7942507',
  '7942511',
  '7942530',
  '7942505',
  '7942500',
  '7942540',
  '7942550',
  '7942503',
  '7942541',
  '7942542',
  '7942593',
  '7941101',
  '7941102',
  '7941103',
  '7942118',
  '7942111',
  '7942104',
  '7942117',
  '7942119',
  '7942101',
  '7942115',
  '7942102',
  '7942110',
  '7942192',
  '7942103',
  '7942112',
  '7942113',
  '7942114',
  '7942116',
  '7942203',
  '7920080',
  '7942201',
  '7942204',
  '7942202',
  '7941402',
  '7941403',
  '7941404',
  '7941401',
  '7941308',
  '7941304',
  '7941393',
  '7941307',
  '7941309',
  '7941305',
  '7941302',
  '7941301',
  '7941303',
  '7941306',
  '7942303',
  '7942302',
  '7942304',
  '7942305',
  '7942392',
  '7942395',
  '7942301',
  '7992433',
  '7914506',
  '7914508',
  '7914504',
  '7914507',
  '7914321',
  '7914502',
  '7914322',
  '7914505',
  '7914511',
  '7914501',
  '7914592',
  '7914503',
  '7914324',
  '7914323',
  '7918093',
  '7914510',
  '7914431',
  '7918091',
  '7914432',
  '7918092',
  '7914509',
  '7920891',
  '7968060',
  '7060306',
  '7048153',
  '7140035',
  '7140037',
  '7140036',
  '7140302',
  '7140301',
  '7140038',
  '7014302',
  '7014392',
  '7014394',
  '7014398',
  '7013203',
  '7013204',
  '7013292',
  '7013293',
  '8820096',
  '9850194',
  '9850192',
  '9850191',
  '9850193',
  '9850195',
  '9862212',
  '9862211',
  '9880606',
  '9880605',
  '9880632',
  '9880604',
  '9880603',
  '9880601',
  '9880607',
  '9880633',
  '9880613',
  '9880631',
  '9880623',
  '9880612',
  '9880602',
  '9880622',
  '9880621',
  '9880611',
  '9880634',
  '9862523',
  '9862525',
  '9860023',
  '9862526',
  '8693711',
  '8660303',
  '8660313',
  '8660334',
  '7390607',
  '7340017',
  '7340303',
  '7340304',
  '7340302',
  '7340301',
  '7340103',
  '7340102',
  '7340101',
  '7340017',
  '7230022',
  '7230021',
  '7390501',
  '7390502',
  '7390503',
  '7390504',
  '7390505',
  '7390506',
  '7390507',
  '7390511',
  '7390512',
  '7390513',
  '7390514',
  '7390515',
  '7390516',
  '7390517',
  '7390518',
  '7390521',
  '7390522',
  '7390523',
  '7390524',
  '7390525',
  '7390531',
  '7390532',
  '7390533',
  '7390534',
  '7390535',
  '7390536',
  '7390541',
  '7390542',
  '7390543',
  '7390550',
  '7390551',
  '7390552',
  '7390553',
  '7390554',
  '7390555',
  '7390556',
  '7390557',
  '7390558',
  '7390559',
  '7390588',
  '7390592',
  '7390595',
  '7222432',
  '7222431',
  '7222403',
  '7222402',
  '7222401',
  '7222412',
  '7222414',
  '7222404',
  '7222411',
  '7222492',
  '7222413',
  '7222493',
  '7222494',
  '7222415',
  '7222405',
  '7222417',
  '7222416',
  '7220061',
  '7200204',
  '7200204',
  '7222631',
  '7222632',
  '7222633',
  '7222641',
  '7250402',
  '7250302',
  '7250303',
  '7250301',
  '7250231',
  '7250403',
  '7250401',
  '7680071',
  '7630231',
  '7630103',
  '7630102',
  '7630104',
  '7630106',
  '7630108',
  '7630105',
  '7630107',
  '7630101',
  '7630111',
  '7630223',
  '7630221',
  '7630222',
  '7630224',
  '7630226',
  '7630225',
  '7630228',
  '7630227',
  '7613110',
  '7600092',
  '7600091',
  '7620072',
  '7620071',
  '7620073',
  '7691108',
  '7691109',
  '7614411',
  '7614492',
  '7614302',
  '7614307',
  '7614404',
  '7614401',
  '7614306',
  '7614405',
  '7614422',
  '7614425',
  '7614304',
  '7614308',
  '7614433',
  '7614434',
  '7614432',
  '7614301',
  '7614388',
  '7614155',
  '7614303',
  '7614424',
  '7614403',
  '7614305',
  '7614426',
  '7614421',
  '7614402',
  '7614431',
  '7614423',
  '7614412',
  '7614392',
  '7614393',
  '7614395',
  '7614396',
  '7614397',
  '7614398',
  '7614100',
  '7614131',
  '7614664',
  '7614142',
  '7614154',
  '7614143',
  '7614192',
  '7614193',
  '7614152',
  '7614144',
  '7614132',
  '7614153',
  '7614146',
  '7614122',
  '7614145',
  '7614195',
  '7614134',
  '7614133',
  '7614141',
  '7614151',
  '7614121',
  '7614194',
  '7614661',
  '7614663',
  '7614662',
  '7614101',
  '7614102',
  '7614103',
  '7614104',
  '7614105',
  '7614106',
  '7614111',
  '7614112',
  '7614113',
  '7614114',
  '7614115',
  '7640050',
  '7640040',
  '7880679',
  '7880678',
  '7880677',
  '8470027',
  '8470306',
  '8470131',
  '8470317',
  '8470406',
  '8470405',
  '8471524',
  '5170205',
  '5170703',
  '5170005',
  '5170001',
  '5170004',
  '5170003',
  '5170002',
  '9980281',
  '7500095',
  '7596542',
  '7500095',
  '7400051',
  '7421401',
  '7421404',
  '7421516',
  '7421114',
  '7430063',
  '7430063',
  '7430003',
  '7450057',
  '7422601',
  '7422108',
  '7422301',
  '7422801',
  '7580701',
  '7580001',
  '7580003',
  '7470832',
  '7420041',
  '8940501',
  '8940626',
  '8940513',
  '8940622',
  '8940507',
  '8940627',
  '8940506',
  '8940624',
  '8940504',
  '8940625',
  '8940512',
  '8940595',
  '8940505',
  '8940623',
  '8940502',
  '8940621',
  '8940508',
  '8940511',
  '8940503',
  '8941113',
  '8941204',
  '8941304',
  '8941321',
  '8941111',
  '8941203',
  '8941116',
  '8941202',
  '8941292',
  '8941201',
  '8941115',
  '8941114',
  '8941205',
  '8941112',
  '8940011',
  '8948588',
  '8940021',
  '8940024',
  '8940068',
  '8940023',
  '8948501',
  '8948507',
  '8948655',
  '8940035',
  '8940022',
  '8940031',
  '8940016',
  '8948567',
  '8940025',
  '8948555',
  '8940026',
  '8948558',
  '8948666',
  '8940005',
  '8940013',
  '8940006',
  '8948686',
  '8948688',
  '8940012',
  '8940015',
  '8940017',
  '8940351',
  '8940774',
  '8940008',
  '8940108',
  '8940048',
  '8940776',
  '8940046',
  '8940771',
  '8940772',
  '8940001',
  '8940047',
  '8940003',
  '8940773',
  '8940043',
  '8940775',
  '8940002',
  '8940352',
  '8940063',
  '8940041',
  '8940042',
  '8940061',
  '8940036',
  '8948601',
  '8948677',
  '8940034',
  '8948511',
  '8940004',
  '8940044',
  '8940045',
  '8940014',
  '8940027',
  '8948585',
  '8940033',
  '8940032',
  '8940062',
  '8940007',
  '8914208',
  '8914311',
  '8914409',
  '8914403',
  '8914405',
  '8914312',
  '8914408',
  '8914407',
  '8914401',
  '8914402',
  '8914404',
  '8914406',
  '8914203',
  '8914201',
  '8914202',
  '8914205',
  '8914292',
  '8914204',
  '8914207',
  '8914206',
  '8913606',
  '8913603',
  '8913607',
  '8913601',
  '8913602',
  '8913604',
  '8913692',
  '8913693',
  '8913605',
  '8913703',
  '8913793',
  '8913705',
  '8913704',
  '8913701',
  '8913792',
  '8913706',
  '8913702',
  '8961601',
  '8961696',
  '8961412',
  '8961512',
  '8961521',
  '8961411',
  '8961602',
  '8961301',
  '8961392',
  '8961206',
  '8961203',
  '8961204',
  '8961205',
  '8961201',
  '8961202',
  '8961281',
  '8961101',
  '8961192',
  '8900902',
  '8900903',
  '8900901',
  '8915204',
  '8915101',
  '8915205',
  '8915203',
  '8915201',
  '8915202',
  '8915301',
  '8991501',
  '8913432',
  '8913102',
  '8913202',
  '8913221',
  '8913112',
  '8913116',
  '8913103',
  '8913431',
  '8913222',
  '8913117',
  '8913104',
  '8913111',
  '8913101',
  '8913192',
  '8913193',
  '8913194',
  '8913195',
  '8913196',
  '8913198',
  '8913114',
  '8913115',
  '8913113',
  '8913118',
  '8918322',
  '8918221',
  '8918321',
  '8918201',
  '8918293',
  '8918111',
  '8918116',
  '8918216',
  '8918327',
  '8918115',
  '8918112',
  '8918328',
  '8918324',
  '8918329',
  '8918117',
  '8918326',
  '8918325',
  '8918114',
  '8918323',
  '8918113',
  '8943632',
  '8943302',
  '8943411',
  '8943633',
  '8943412',
  '8943522',
  '8943304',
  '8943413',
  '8943303',
  '8943301',
  '8943305',
  '8943392',
  '8943523',
  '8943631',
  '8943521',
  '8916162',
  '8916217',
  '8916141',
  '8916226',
  '8916228',
  '8916229',
  '8916151',
  '8916161',
  '8916163',
  '8916164',
  '8916231',
  '8916144',
  '8916216',
  '8916225',
  '8916143',
  '8916232',
  '8916142',
  '8916233',
  '8916224',
  '8916214',
  '8916201',
  '8916227',
  '8916152',
  '8916213',
  '8916223',
  '8916221',
  '8916211',
  '8916212',
  '8916215',
  '8916203',
  '8916153',
  '8916222',
  '8916154',
  '8916202',
  '8916292',
  '8941742',
  '8942411',
  '8941744',
  '8941511',
  '8942236',
  '8941524',
  '8942235',
  '8942231',
  '8941522',
  '8941515',
  '8942413',
  '8941853',
  '8942237',
  '8941854',
  '8941746',
  '8941851',
  '8941852',
  '8941501',
  '8941592',
  '8942402',
  '8942414',
  '8942403',
  '8941741',
  '8942404',
  '8941531',
  '8942234',
  '8942144',
  '8942141',
  '8941512',
  '8942232',
  '8941743',
  '8942412',
  '8942322',
  '8942401',
  '8941521',
  '8942142',
  '8942321',
  '8941855',
  '8942502',
  '8941514',
  '8941523',
  '8942501',
  '8942143',
  '8942323',
  '8942415',
  '8942416',
  '8941513',
  '8942417',
  '8942233',
  '8941745',
  '8942601',
  '8941502',
  '8941503',
  '8941504',
  '8941505',
  '8941506',
  '8941507',
  '8941508',
  '8943211',
  '8943101',
  '8943214',
  '8943213',
  '8943104',
  '8943107',
  '8943106',
  '8943105',
  '8943192',
  '8943103',
  '8943102',
  '8943212',
  '8919211',
  '8919215',
  '8919202',
  '8919233',
  '8919203',
  '8919207',
  '8919205',
  '8919212',
  '8919223',
  '8919234',
  '8919204',
  '8919292',
  '8919232',
  '8919213',
  '8919294',
  '8919296',
  '8919224',
  '8919206',
  '8919221',
  '8919214',
  '8919295',
  '8919231',
  '8919222',
  '8919201',
  '8919293',
  '8917603',
  '8917604',
  '8917621',
  '8917602',
  '8917623',
  '8917731',
  '8917605',
  '8917622',
  '8917611',
  '8917624',
  '8917612',
  '8917601',
  '8917114',
  '8917116',
  '8917115',
  '8917425',
  '8917101',
  '8917102',
  '8917422',
  '8917424',
  '8917423',
  '8917421',
  '8917112',
  '8917113',
  '8917111',
  '8917103',
  '8917105',
  '8917104',
  '8917426',
  '8919307',
  '8919301',
  '8919304',
  '8919308',
  '8919302',
  '8919303',
  '8919309',
  '8940412',
  '8940323',
  '8940104',
  '8940192',
  '8940324',
  '8940101',
  '8940331',
  '8940332',
  '8940322',
  '8940107',
  '8940333',
  '8940102',
  '8940103',
  '8940411',
  '8940105',
  '8940106',
  '8940321',
  '8919115',
  '8919135',
  '8919123',
  '8919102',
  '8919122',
  '8919114',
  '8919124',
  '8919132',
  '8919101',
  '8919121',
  '8919111',
  '8919116',
  '8919103',
  '8919134',
  '8919136',
  '8919104',
  '8919125',
  '8919133',
  '8919131',
  '8919113',
  '8919112',
  '8919192',
  '9580061',
  '9520303',
  '9520022',
  '9521201',
  '9520626',
  '9521643',
  '9520004',
  '9523434',
  '9520422',
  '9520505',
  '9520421',
  '9520514',
  '9520515',
  '9520513',
  '9520512',
  '9520502',
  '9520511',
  '9520501',
  '9520503',
  '9520504',
  '9523114',
  '9520215',
  '9520025',
  '9523424',
  '9520322',
  '9520114',
  '9520701',
  '9521501',
  '9521304',
  '9520028',
  '9523113',
  '9521315',
  '9521314',
  '9521393',
  '9523425',
  '9521207',
  '9520703',
  '9520855',
  '9520023',
  '9522203',
  '9520824',
  '9520857',
  '9522201',
  '9523203',
  '9521203',
  '9521202',
  '9520312',
  '9521644',
  '9523423',
  '9520212',
  '9523119',
  '9520606',
  '9521208',
  '9520307',
  '9521325',
  '9520202',
  '9523543',
  '9520614',
  '9523206',
  '9520016',
  '9528502',
  '9522132',
  '9522131',
  '9522202',
  '9523421',
  '9522223',
  '9520622',
  '9521645',
  '9522214',
  '9520304',
  '9523112',
  '9520216',
  '9521204',
  '9520705',
  '9521321',
  '9520306',
  '9521302',
  '9520207',
  '9520021',
  '9520015',
  '9520612',
  '9520006',
  '9522136',
  '9520205',
  '9522206',
  '9520602',
  '9520605',
  '9520613',
  '9520611',
  '9520625',
  '9520604',
  '9520601',
  '9520603',
  '9522212',
  '9520821',
  '9520024',
  '9520108',
  '9520702',
  '9521305',
  '9521303',
  '9523422',
  '9520116',
  '9520107',
  '9520106',
  '9520113',
  '9520103',
  '9520112',
  '9520105',
  '9520102',
  '9520109',
  '9520101',
  '9520104',
  '9520111',
  '9520115',
  '9520615',
  '9520712',
  '9521301',
  '9523201',
  '9523431',
  '9520313',
  '9520318',
  '9520301',
  '9523541',
  '9521205',
  '9520715',
  '9520434',
  '9521326',
  '9520321',
  '9522224',
  '9521322',
  '9522211',
  '9520852',
  '9520711',
  '9521209',
  '9521292',
  '9521212',
  '9521557',
  '9521556',
  '9521552',
  '9521572',
  '9521548',
  '9521511',
  '9521542',
  '9521592',
  '9521536',
  '9521582',
  '9521581',
  '9521584',
  '9521573',
  '9521575',
  '9521571',
  '9521504',
  '9521551',
  '9521537',
  '9521574',
  '9521553',
  '9521531',
  '9521547',
  '9521527',
  '9521518',
  '9521505',
  '9521563',
  '9521561',
  '9521593',
  '9521562',
  '9521538',
  '9521564',
  '9521567',
  '9521566',
  '9521507',
  '9521583',
  '9521503',
  '9521514',
  '9521522',
  '9521523',
  '9521541',
  '9521515',
  '9521568',
  '9521525',
  '9521502',
  '9521516',
  '9521517',
  '9521646',
  '9521508',
  '9521524',
  '9521534',
  '9521513',
  '9521506',
  '9521535',
  '9521526',
  '9521543',
  '9521546',
  '9521558',
  '9521554',
  '9521555',
  '9521565',
  '9521545',
  '9521512',
  '9521533',
  '9521539',
  '9521544',
  '9521532',
  '9521521',
  '9521528',
  '9520822',
  '9520214',
  '9520431',
  '9520315',
  '9520716',
  '9522205',
  '9520432',
  '9521206',
  '9520314',
  '9521435',
  '9521432',
  '9521431',
  '9521434',
  '9521433',
  '9520621',
  '9522135',
  '9521323',
  '9520302',
  '9521211',
  '9521324',
  '9523111',
  '9520027',
  '9520305',
  '9520204',
  '9521306',
  '9523432',
  '9520003',
  '9520435',
  '9520433',
  '9520623',
  '9520856',
  '9523545',
  '9521307',
  '9523546',
  '9520706',
  '9520714',
  '9522226',
  '9521308',
  '9521641',
  '9522215',
  '9523117',
  '9520316',
  '9520005',
  '9520001',
  '9520206',
  '9521311',
  '9521312',
  '9521313',
  '9520213',
  '9522134',
  '9520823',
  '9523115',
  '9521213',
  '9521642',
  '9523542',
  '9520854',
  '9520317',
  '9520203',
  '9523202',
  '9520002',
  '9523207',
  '9523116',
  '9522222',
  '9522213',
  '9522225',
  '9522221',
  '9522133',
  '9528501',
  '9520311',
  '9520624',
  '9520201',
  '9523544',
  '9522204',
  '9520713',
  '9520853',
  '9520026',
  '9520011',
  '9520012',
  '9523435',
  '9520013',
  '9520014',
  '9523433',
  '9523118',
  '9523204',
  '9523205',
  '9520211',
  '9520704',
  '9520851',
  '4130004',
  '9280072',
  '8760003',
  '8760007',
  '8760004',
  '8760001',
  '8760006',
  '8760005',
  '8760002',
  '8760008',
  '8761313',
  '8792501',
  '8721501',
  '8115301',
  '8115733',
  '8115732',
  '8115731',
  '8115734',
  '8115744',
  '8115741',
  '8115743',
  '8115742',
  '8115316',
  '8115313',
  '8115314',
  '8115312',
  '8115315',
  '8115311',
  '8115321',
  '8115322',
  '8115324',
  '8115325',
  '8115323',
  '8115326',
  '8115461',
  '8115757',
  '8115751',
  '8115752',
  '8115756',
  '8115755',
  '8115754',
  '8115753',
  '8115467',
  '8115468',
  '8115462',
  '8115464',
  '8115463',
  '8115465',
  '8115466',
  '8115131',
  '8115113',
  '8115135',
  '8115163',
  '8115125',
  '8115124',
  '8115141',
  '8115143',
  '8115144',
  '8115105',
  '8115106',
  '8115134',
  '8115103',
  '8115112',
  '8115122',
  '8115161',
  '8115162',
  '8115102',
  '8115101',
  '8115142',
  '8115123',
  '8115117',
  '8115151',
  '8115153',
  '8115154',
  '8115152',
  '8115132',
  '8115155',
  '8115111',
  '8115116',
  '8115121',
  '8115136',
  '8115133',
  '8115192',
  '8115193',
  '8115115',
  '8115114',
  '8115107',
  '8115104',
  '8115531',
  '8115501',
  '8115543',
  '8115533',
  '8115522',
  '8115521',
  '8115532',
  '8115511',
  '8115512',
  '8115551',
  '8115552',
  '8115544',
  '8115523',
  '8115513',
  '8115541',
  '8115546',
  '8115542',
  '8115545',
  '8115556',
  '8115554',
  '8115553',
  '8115555',
  '8115214',
  '8115223',
  '8115201',
  '8115215',
  '8115211',
  '8115224',
  '8115222',
  '8115221',
  '8115225',
  '8115226',
  '8115204',
  '8115202',
  '8115203',
  '8115213',
  '8115212',
  '8530000',
  '8532481',
  '8530001',
  '8530000',
  '8530053',
  '8530061',
  '8530021',
  '8530011',
  '8530704',
  '8530701',
  '8530705',
  '8530313',
  '8530702',
  '8530312',
  '8530706',
  '8530703',
  '8530311',
  '8530031',
  '8530042',
  '8532171',
  '8530502',
  '8530411',
  '8530501',
  '8530503',
  '8530506',
  '8530413',
  '8530504',
  '8530505',
  '8530507',
  '8530508',
  '8530412',
  '8530003',
  '8530054',
  '8530022',
  '8530004',
  '8530006',
  '8530043',
  '8530016',
  '8530065',
  '8530606',
  '8530613',
  '8530604',
  '8530612',
  '8530611',
  '8530602',
  '8530608',
  '8530603',
  '8530607',
  '8530609',
  '8530601',
  '8530692',
  '8530605',
  '8530064',
  '8530014',
  '8530025',
  '8530052',
  '8530023',
  '8530013',
  '8530063',
  '8530062',
  '8530027',
  '8530066',
  '8530032',
  '8530018',
  '8530002',
  '8532174',
  '8530012',
  '8530044',
  '8532173',
  '8530015',
  '8532201',
  '8532292',
  '8532204',
  '8532202',
  '8532203',
  '8530026',
  '8530213',
  '8530211',
  '8530208',
  '8530212',
  '8530206',
  '8530205',
  '8530202',
  '8530215',
  '8530207',
  '8530203',
  '8530214',
  '8530204',
  '8530201',
  '8530017',
  '8530007',
  '8538501',
  '8538502',
  '8538601',
  '8530051',
  '8532482',
  '8530005',
  '8530033',
  '8530024',
  '8530041',
  '8532172',
  '8574816',
  '8574812',
  '8574811',
  '8574903',
  '8574815',
  '8574813',
  '8574901',
  '8574814',
  '8574904',
  '8574902',
  '8573271',
  '8594521',
  '8594741',
  '8594301',
  '8594302',
  '8594306',
  '8594309',
  '8594307',
  '8594303',
  '8594305',
  '8594304',
  '8594308',
  '8594500',
  '8594522',
  '8594523',
  '8594524',
  '8594525',
  '8594526',
  '8594527',
  '8594528',
  '8594529',
  '8594742',
  '8594743',
  '8594744',
  '8594745',
  '8572532',
  '8170241',
  '8170034',
  '8170242',
  '8170243',
  '8170245',
  '8170244',
  '8170032',
  '8170031',
  '8170035',
  '8170011',
  '8178520',
  '8170002',
  '8170022',
  '8178510',
  '8170021',
  '8170156',
  '8170005',
  '8170001',
  '8170248',
  '8170246',
  '8170015',
  '8170153',
  '8170024',
  '8170013',
  '8170247',
  '8170014',
  '8170023',
  '8170016',
  '8178517',
  '8170154',
  '8170155',
  '8170152',
  '8170157',
  '8170003',
  '8170012',
  '8170033',
  '8170006',
  '8170151',
  '8171532',
  '8171531',
  '8171522',
  '8171512',
  '8171524',
  '8171525',
  '8171603',
  '8171602',
  '8171692',
  '8171533',
  '8171521',
  '8171511',
  '8171513',
  '8171523',
  '8171601',
  '8172243',
  '8172242',
  '8171721',
  '8172331',
  '8171714',
  '8171702',
  '8172332',
  '8172333',
  '8172241',
  '8171703',
  '8171725',
  '8171722',
  '8171715',
  '8171712',
  '8171701',
  '8171713',
  '8171711',
  '8171724',
  '8171704',
  '8171723',
  '8171797',
  '8171102',
  '8170433',
  '8171103',
  '8171107',
  '8170324',
  '8170322',
  '8171106',
  '8170512',
  '8170432',
  '8170326',
  '8170321',
  '8170325',
  '8171101',
  '8170434',
  '8171105',
  '8170323',
  '8170511',
  '8170513',
  '8170514',
  '8171104',
  '8170431',
  '8170435',
  '8171307',
  '8171306',
  '8171411',
  '8171412',
  '8171301',
  '8171413',
  '8171302',
  '8171304',
  '8171305',
  '8171303',
  '8171253',
  '8171223',
  '8171246',
  '8171241',
  '8171233',
  '8171234',
  '8171254',
  '8171232',
  '8171255',
  '8171231',
  '8171256',
  '8171201',
  '8171292',
  '8171213',
  '8171212',
  '8171252',
  '8171251',
  '8171245',
  '8171257',
  '8171214',
  '8171202',
  '8511201',
  '8511315',
  '8570071',
  '8533322',
  '8574511',
  '8574512',
  '8574592',
  '8532315',
  '8574513',
  '8533102',
  '8532314',
  '8532302',
  '8574103',
  '8532304',
  '8574413',
  '8532311',
  '8574412',
  '8574514',
  '8574214',
  '8532301',
  '8532392',
  '8532303',
  '8574212',
  '8574601',
  '8532305',
  '8574404',
  '8574495',
  '8574101',
  '8574405',
  '8574602',
  '8574411',
  '8574415',
  '8574213',
  '8533321',
  '8574604',
  '8533323',
  '8574416',
  '8574402',
  '8533101',
  '8532312',
  '8574414',
  '8574401',
  '8574102',
  '8574211',
  '8532313',
  '8574603',
  '8595801',
  '8595803',
  '8595802',
  '8595804',
  '8595806',
  '8595805',
  '8595101',
  '8574706',
  '8574702',
  '8574708',
  '8574703',
  '8574701',
  '8574711',
  '8574707',
  '8574705',
  '8574709',
  '8574704',
  '8574712',
  '6850306',
  '6850431',
  '6850413',
  '6850011',
  '6850017',
  '6850024',
  '6850002',
  '6850305',
  '6850311',
  '6850003',
  '6850435',
  '6850027',
  '6850015',
  '6858601',
  '6850022',
  '6850314',
  '6850312',
  '6850026',
  '6850016',
  '6858585',
  '6858666',
  '6850432',
  '6850014',
  '6850023',
  '6850101',
  '6850304',
  '6850001',
  '6850106',
  '6850007',
  '6850434',
  '6850013',
  '6850105',
  '6850104',
  '6850005',
  '6858555',
  '6850012',
  '6850103',
  '6850313',
  '6850303',
  '6850004',
  '6850411',
  '6850302',
  '6850412',
  '6850025',
  '6850301',
  '6850021',
  '6850433',
  '6850102',
  '6850006',
  '6858512',
  '6840401',
  '6840403',
  '6840412',
  '6840413',
  '6840411',
  '6840404',
  '6840402',
  '6840301',
  '6840211',
  '6840303',
  '6840302',
  '6840102',
  '6840107',
  '6840105',
  '6840103',
  '6840101',
  '6840104',
  '6840106',
  '1001301',
  '1001212',
  '1001103',
  '1001102',
  '1001101',
  '1001211',
  '1001213',
  '1002101',
  '1002211',
  '1000400',
  '1000511',
  '1000401',
  '1000402',
  '1000601',
  '1001701',
  '1000102',
  '1000101',
  '1000211',
  '1000103',
  '1000212',
  '1000104',
  '1001621',
  '1001511',
  '1001401',
  '1001623',
  '1001622',
  '1000301',
  '7741760',
  '7750010',
  '7750013',
  '7750011',
  '7750012',
  '7750013',
  '8191336',
  '8150000',
  '8150000',
  '8150000',
  '8113701',
  '8113511',
  '8020092',
  '8020091',
  '6720102',
  '6720101',
  '6720103',
];

/********************/
//  沖縄本島
/********************/
export const HONTO_OKINAWA_ZIP_LIST = [
  '9000000',
  '9010154',
  '9000002',
  '9020067',
  '9000029',
  '9010143',
  '9000003',
  '9000005',
  '9000021',
  '9000011',
  '9010153',
  '9020073',
  '9000026',
  '9010148',
  '9000006',
  '9010152',
  '9010142',
  '9000028',
  '9010155',
  '9010151',
  '9010146',
  '9000033',
  '9000015',
  '9020075',
  '9000024',
  '9020078',
  '9030813',
  '9030811',
  '9030824',
  '9030804',
  '9030823',
  '9030802',
  '9030815',
  '9030821',
  '9030807',
  '9030814',
  '9030826',
  '9030801',
  '9030803',
  '9030806',
  '9030812',
  '9030822',
  '9030805',
  '9030816',
  '9030825',
  '9010141',
  '9000023',
  '9020066',
  '9010145',
  '9010156',
  '9000037',
  '9000025',
  '9020065',
  '9010144',
  '9000012',
  '9000035',
  '9020074',
  '9020077',
  '9000036',
  '9020071',
  '9000034',
  '9000022',
  '9020061',
  '9020072',
  '9000016',
  '9020068',
  '9000013',
  '9000014',
  '9020062',
  '9020069',
  '9000032',
  '9000001',
  '9020063',
  '9010147',
  '9000004',
  '9000027',
  '9020076',
  '9020064',
  '9000031',
  '9012200',
  '9012206',
  '9012205',
  '9012201',
  '9012221',
  '9012204',
  '9012227',
  '9012225',
  '9012223',
  '9012226',
  '9012214',
  '9012207',
  '9012211',
  '9012222',
  '9012216',
  '9012213',
  '9012212',
  '9012203',
  '9012202',
  '9012215',
  '9012224',
  '9012100',
  '9012114',
  '9012132',
  '9012128',
  '9012123',
  '9012121',
  '9012113',
  '9012111',
  '9012133',
  '9012124',
  '9012122',
  '9012112',
  '9012104',
  '9012103',
  '9012125',
  '9012101',
  '9012102',
  '9012131',
  '9012134',
  '9012126',
  '9012127',
  '9050000',
  '9050021',
  '9050003',
  '9052263',
  '9050001',
  '9051152',
  '9051142',
  '9050006',
  '9050009',
  '9051634',
  '9052267',
  '9052268',
  '9050019',
  '9050017',
  '9050018',
  '9050016',
  '9050015',
  '9051146',
  '9050002',
  '9051633',
  '9051155',
  '9052262',
  '9051145',
  '9050026',
  '9050024',
  '9052173',
  '9050013',
  '9051141',
  '9050025',
  '9051154',
  '9051156',
  '9050023',
  '9051635',
  '9052266',
  '9051147',
  '9052265',
  '9052261',
  '9052172',
  '9051153',
  '9051144',
  '9050004',
  '9050012',
  '9050005',
  '9052269',
  '9051151',
  '9052171',
  '9051143',
  '9050014',
  '9052264',
  '9050011',
  '9051631',
  '9050007',
  '9050008',
  '9050022',
  '9051632',
  '9010300',
  '9010301',
  '9010323',
  '9010363',
  '9010342',
  '9010361',
  '9010344',
  '9010332',
  '9010325',
  '9010334',
  '9010313',
  '9010303',
  '9010312',
  '9010354',
  '9010324',
  '9010341',
  '9010335',
  '9010314',
  '9010364',
  '9010302',
  '9010311',
  '9010353',
  '9010315',
  '9010321',
  '9010351',
  '9010304',
  '9010305',
  '9010306',
  '9010345',
  '9010362',
  '9010331',
  '9010336',
  '9010333',
  '9010343',
  '9010352',
  '9010322',
  '9040000',
  '9042152',
  '9040012',
  '9042172',
  '9042141',
  '9040031',
  '9042163',
  '9042162',
  '9042162',
  '9040005',
  '9040023',
  '9042145',
  '9042161',
  '9042166',
  '9040001',
  '9040021',
  '9042175',
  '9042144',
  '9040002',
  '9040003',
  '9040022',
  '9042171',
  '9042143',
  '9040004',
  '9040011',
  '9042164',
  '9040014',
  '9042142',
  '9042154',
  '9042173',
  '9042151',
  '9042153',
  '9042156',
  '9040035',
  '9042155',
  '9042165',
  '9040013',
  '9040032',
  '9040006',
  '9040034',
  '9040033',
  '9042174',
  '9042200',
  '9042212',
  '9042245',
  '9042214',
  '9041106',
  '9041103',
  '9041102',
  '9041101',
  '9041107',
  '9041104',
  '9041115',
  '9041114',
  '9041105',
  '9041112',
  '9041111',
  '9041108',
  '9041113',
  '9042204',
  '9042222',
  '9042211',
  '9042244',
  '9042205',
  '9042224',
  '9042313',
  '9042317',
  '9042311',
  '9042315',
  '9042316',
  '9042314',
  '9042312',
  '9042241',
  '9042203',
  '9042232',
  '9042236',
  '9042225',
  '9042223',
  '9042201',
  '9042231',
  '9042234',
  '9042221',
  '9042242',
  '9042213',
  '9042202',
  '9042233',
  '9042226',
  '9042235',
  '9042215',
  '9042243',
  '9042303',
  '9042306',
  '9042421',
  '9042422',
  '9042424',
  '9042305',
  '9042301',
  '9042425',
  '9042302',
  '9042307',
  '9042428',
  '9042426',
  '9042423',
  '9042304',
  '9042427',
  '9011400',
  '9011204',
  '9011202',
  '9011203',
  '9011208',
  '9011205',
  '9011206',
  '9011207',
  '9011201',
  '9011401',
  '9011413',
  '9011411',
  '9011403',
  '9011415',
  '9011412',
  '9011414',
  '9011402',
  '9011405',
  '9011404',
  '9011406',
  '9010617',
  '9010606',
  '9010614',
  '9010608',
  '9010601',
  '9010607',
  '9010613',
  '9010604',
  '9010612',
  '9010605',
  '9010602',
  '9010603',
  '9010611',
  '9010618',
  '9010615',
  '9010616',
  '9010619',
  '9011502',
  '9011504',
  '9011514',
  '9011501',
  '9011511',
  '9011505',
  '9011516',
  '9011503',
  '9011513',
  '9011515',
  '9011512',
  '9011407',
  '9051400',
  '9051503',
  '9051504',
  '9051428',
  '9051423',
  '9051429',
  '9051501',
  '9051412',
  '9051416',
  '9051422',
  '9051425',
  '9051426',
  '9051502',
  '9051417',
  '9051415',
  '9051414',
  '9051413',
  '9051421',
  '9051424',
  '9051411',
  '9051427',
  '9051300',
  '9051308',
  '9051317',
  '9051305',
  '9051306',
  '9051313',
  '9051303',
  '9051311',
  '9051302',
  '9051316',
  '9051315',
  '9051301',
  '9051314',
  '9051318',
  '9051304',
  '9051307',
  '9051319',
  '9051312',
  '9051200',
  '9051206',
  '9051203',
  '9051205',
  '9051204',
  '9051201',
  '9051202',
  '9050400',
  '9050411',
  '9050428',
  '9050403',
  '9050427',
  '9050404',
  '9050406',
  '9050421',
  '9050413',
  '9050422',
  '9050414',
  '9050426',
  '9050405',
  '9050415',
  '9050402',
  '9050424',
  '9050401',
  '9050423',
  '9050425',
  '9050412',
  '9050200',
  '9050206',
  '9050221',
  '9050228',
  '9050229',
  '9050217',
  '9050223',
  '9050212',
  '9050202',
  '9050209',
  '9050201',
  '9050226',
  '9050225',
  '9050203',
  '9050208',
  '9050227',
  '9050213',
  '9050214',
  '9050204',
  '9050222',
  '9050215',
  '9050216',
  '9050211',
  '9050207',
  '9050218',
  '9050224',
  '9050205',
  '9050219',
  '9040400',
  '9040402',
  '9040411',
  '9040403',
  '9040404',
  '9040412',
  '9040415',
  '9040401',
  '9040413',
  '9040414',
  '9040417',
  '9040416',
  '9040300',
  '9040303',
  '9040321',
  '9040328',
  '9040316',
  '9040313',
  '9040302',
  '9040327',
  '9040301',
  '9040325',
  '9040304',
  '9040323',
  '9040315',
  '9040326',
  '9040305',
  '9040324',
  '9040322',
  '9040311',
  '9040312',
  '9040314',
  '9040200',
  '9040203',
  '9040205',
  '9040201',
  '9040204',
  '9040202',
  '9040100',
  '9040102',
  '9040107',
  '9040101',
  '9040117',
  '9040103',
  '9040111',
  '9040106',
  '9040116',
  '9040104',
  '9040112',
  '9040114',
  '9040115',
  '9040113',
  '9040105',
  '9012300',
  '9012316',
  '9012313',
  '9012314',
  '9012315',
  '9012311',
  '9012301',
  '9012317',
  '9012303',
  '9012302',
  '9012305',
  '9012321',
  '9012304',
  '9012312',
  '9012400',
  '9012407',
  '9012422',
  '9012403',
  '9012417',
  '9012412',
  '9012423',
  '9012414',
  '9012401',
  '9012404',
  '9012413',
  '9012406',
  '9012402',
  '9012421',
  '9012411',
  '9012424',
  '9012415',
  '9012405',
  '9012416',
  '9030100',
  '9030105',
  '9030113',
  '9030115',
  '9030125',
  '9030121',
  '9030117',
  '9030103',
  '9030101',
  '9030112',
  '9030102',
  '9030104',
  '9030116',
  '9030122',
  '9030118',
  '9030124',
  '9030129',
  '9030126',
  '9030123',
  '9030114',
  '9030127',
  '9030128',
  '9030111',
  '9011300',
  '9011304',
  '9011301',
  '9011302',
  '9011303',
  '9011100',
  '9011105',
  '9011101',
  '9011111',
  '9011114',
  '9011113',
  '9011117',
  '9011116',
  '9011102',
  '9011104',
  '9011112',
  '9011115',
  '9011103',
  '9013700',
  '9013703',
  '9013701',
  '9013702',
  '9050700',
  '9050703',
  '9050704',
  '9050701',
  '9050705',
  '9050702',
  '9050600',
  '9050604',
  '9050601',
  '9050602',
  '9050605',
  '9050603',
  '9010400',
  '9010514',
  '9010503',
  '9010405',
  '9010412',
  '9010502',
  '9010416',
  '9010512',
  '9010415',
  '9010504',
  '9010401',
  '9010413',
  '9010404',
  '9010414',
  '9010411',
  '9010402',
  '9010516',
  '9010501',
  '9010513',
  '9010417',
  '9010511',
  '9010406',
  '9010515',
  '9010403',
];

/********************/
//  沖縄離島
/********************/
export const RITO_OKINAWA_ZIP_LIST = [
  '9070000',
  '9070024',
  '9070023',
  '9070332',
  '9070022',
  '9070001',
  '9070453',
  '9070452',
  '9070242',
  '9070014',
  '9070241',
  '9070004',
  '9070021',
  '9070333',
  '9070013',
  '9070003',
  '9070331',
  '9070451',
  '9070002',
  '9070012',
  '9070243',
  '9070244',
  '9070011',
  '9010200',
  '9010232',
  '9010243',
  '9010223',
  '9010202',
  '9010231',
  '9010204',
  '9010244',
  '9010221',
  '9010233',
  '9010212',
  '9010213',
  '9010242',
  '9010234',
  '9010215',
  '9010222',
  '9010241',
  '9010225',
  '9010235',
  '9010203',
  '9010205',
  '9010211',
  '9010214',
  '9010201',
  '9010224',
  '9060000',
  '9060502',
  '9060503',
  '9060505',
  '9060507',
  '9060504',
  '9060506',
  '9060501',
  '9060204',
  '9060202',
  '9060201',
  '9060203',
  '9060102',
  '9060108',
  '9060109',
  '9060107',
  '9060105',
  '9060106',
  '9060104',
  '9060103',
  '9060101',
  '9060304',
  '9060302',
  '9060301',
  '9060306',
  '9060303',
  '9060305',
  '9060421',
  '9060004',
  '9060001',
  '9060002',
  '9060015',
  '9060003',
  '9060013',
  '9060008',
  '9060012',
  '9060006',
  '9060005',
  '9060007',
  '9060011',
  '9060422',
  '9060014',
  '9041300',
  '9041304',
  '9041302',
  '9041303',
  '9041301',
  '9041200',
  '9041202',
  '9041201',
  '9041203',
  '9050500',
  '9050503',
  '9050505',
  '9050504',
  '9050501',
  '9050502',
  '9013500',
  '9013502',
  '9013501',
  '9013400',
  '9013311',
  '9013401',
  '9013403',
  '9013312',
  '9013402',
  '9013601',
  '9013800',
  '9013806',
  '9013801',
  '9013803',
  '9013805',
  '9013802',
  '9013804',
  '9013900',
  '9013902',
  '9013903',
  '9013901',
  '9013100',
  '9013103',
  '9013101',
  '9013138',
  '9013105',
  '9013106',
  '9013123',
  '9013132',
  '9013121',
  '9013122',
  '9013133',
  '9013115',
  '9013134',
  '9013114',
  '9013107',
  '9013113',
  '9013125',
  '9013136',
  '9013124',
  '9013135',
  '9013131',
  '9013108',
  '9013102',
  '9013112',
  '9013104',
  '9013111',
  '9013137',
  '9060600',
  '9060601',
  '9060602',
  '9060603',
  '9070000',
  '9071435',
  '9071542',
  '9071541',
  '9071311',
  '9071221',
  '9071432',
  '9071543',
  '9071431',
  '9071101',
  '9071434',
  '9071433',
  '9071751',
  '9071544',
  '9071800',
  '9071801',
];