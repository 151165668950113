<div class="content-block content-block-max">
    <div class="content-block-inner">
        <div class="back-link"><a routerLink="/place">会場一覧へ</a></div>
        <div class="title-content mg-style2">{{place.name}}</div>
        <div class="giftbox-detail-text">
            <div class="flex-box j-content-start text-m-style2">
                <div class="content-even">
                    <dl class="flex-box j-content-start">
                        <!-- <dt>メンバー：</dt> -->
                        <!-- <dd><span>{{num}}</span>名</dd> -->
                    </dl>
                </div>
            </div><!--/flex-box-->
        </div><!--/giftbox-detail-text-->
    </div><!-- content-block-inner-->
    <app-switch-tab></app-switch-tab>
</div><!--/content-block-->
