<div class="content-block content-block-max">

    <div class="content-block-inner">
        <div class="flex-box">
            <div class="title-content mng">会場情報</div>
        </div>
        <div class="flex-box j-content-start a-items-start text-note-box">
            <div class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg></div>
            <span>
                「熨斗カード校正紙」と「確認書」は、こちらのFAX番号にお送りいたします。<br>
                「卓上カード」は、原則としてこちらのご住所へのお届けとなりますが、各挙式情報にて個別に指定いただくことも可能です。
            </span>
        </div>
    </div><!--/content-block-inner-->

    <div class="page" *ngIf="pages.length > 1">
        <div class="no" (click)="paging(1, 0)"><<</div>
        <div class="no" *ngFor="let page of pages; index as i" (click)="paging(page, i)" [class.current]="i == select_page_index">{{page}}</div>
        <div class="no" (click)="paging(pages.length, pages.length-1)">>></div>
    </div>

    <div class="header-item-wrap">
        <div class="inner-header">
            <div class="count-info">
            <div class="result" *ngIf="places; else noData">
                <span class="js-itemCount">
                {{(select_page_index * len) + 1}}〜
                {{ (pages.length - 1 > select_page_index) ? ((select_page_index + 1) * len) : places.length }}件／
                {{places.length}}
                </span>件
            </div>
            <ng-template #noData><div class="result">0件</div></ng-template>
            </div><!-- count-info-->
            <div class="giftbox-make-btn js-createWeddingInfoBtn" (click)="toggleAddOpen()">
            <div class="icon-giftplus">
                <div class="icon-plus-l"></div>会場を追加
            </div>
            </div>
        </div><!--/inner-header-->
    </div><!--/header-item-wrap-->


    <!-- 検索結果 -->
    <div class="list-wrap" id="list">
        <!-- 編集エリア（新規追加） -->
        <div class="js-listRow" id="addRow" *ngIf="add_flg">
            <ng-container *ngTemplateOutlet="editArea"></ng-container>
        </div>

        <!-- 会場情報 -->
        <div class="js-listRow" *ngFor="let place of places | slice: start: start+len; index as i">
            <div class="item-list send-list">

                <div class="lits-cont-box sendlist-add">
                    <div class="list-name">
                        <div class="js-listRowName">{{place?.name}}</div>
                        <div class="ruby">{{place?.kname}}</div>
                    </div>
                </div><!--/lits-cont-box-->

                <!-- ボタンエリア -->
                <div class="list-even-box sendlist-add js-btnAreaSendListChange">
                    <div class="lits-change-box">
                    <button type="button" class="btn-edit js-editBtnSendListChange" routerLink="/member/{{place?.objectID}}">
                        <i class="material-icons" style="opacity: 0.7;">person_add</i>
                    </button>
                    </div>
                    <div class="lits-change-box">
                    <button type="button" class="btn-edit js-editBtnSendListChange" (click)="toggleEdit(place, $event)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.8 48.8">
                        <path class="cls-1 icon-color" d="M1,38.05V47.8h9.75L38.5,20.05,28.75,10.3ZM47,11.51a2.6,2.6,0,0,0,0-3.67L41,1.76a2.6,2.6,0,0,0-3.67,0L32.53,6.52l9.75,9.75Z"/>
                        </svg>
                    </button>
                    </div>
                    <div class="lits-change-box">
                    <button type="button" data-target="modal-message" class="btn-trash js-trashBtnSendListChange"
                        (click)="setDelTargetId($event)"
                        [attr.data-id]="place?.objectID">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 54" [attr.data-id]="place?.objectID">
                            <path class="icon-color" [attr.data-id]="place?.objectID" d="M50,8h-4h-7.9l-5-8H17.9l-5,8H5H1C0.4,8,0,8.4,0,9s0.4,1,1,1h4v39c0,2.8,2.2,5,5,5h31c2.8,0,5-2.2,5-5V10h4c0.6,0,1-0.4,1-1S50.6,8,50,8z M19.1,2h12.9l3.8,6H15.3L19.1,2z M44,49c0,1.7-1.3,3-3,3H10c-1.7,0-3-1.3-3-3V10h4.7h27.6H44V49z"/>
                            <path class="icon-color" [attr.data-id]="place?.objectID" d="M18.5,18c-0.6,0-1,0.4-1,1v25c0,0.6,0.4,1,1,1s1-0.4,1-1V19C19.5,18.4,19.1,18,18.5,18z"/>
                            <path class="icon-color" [attr.data-id]="place?.objectID" d="M32.5,18c-0.6,0-1,0.4-1,1v25c0,0.6,0.4,1,1,1s1-0.4,1-1V19C33.5,18.4,33.1,18,32.5,18z"/>
                        </svg>
                    </button>
                    </div>
                </div>
            </div><!--/item-list-->

            <!-- 編集エリア（変更） -->
            <div *ngIf="edit_place_id == place?.objectID">
                <ng-container *ngTemplateOutlet="editArea; context:place"></ng-container>
            </div>
        </div><!-- js-listRow-->
    </div><!--/list-wrap-->


    <!-- 編集エリア テンプレート -->
    <ng-template #editArea
    let-objectID = "objectID"
    let-name = "name"
    let-kname = "kname"
    let-zip = "zip"
    let-addr1 = "addr1"
    let-addr2 = "addr2"
    let-addr3 = "addr3"
    let-addr4 = "addr4"
    let-tel = "tel"
    let-fax = "fax"
    let-email = "email"
    let-created = "created">

    <form #placeForm="ngForm" (ngSubmit)="save(objectID, $event)" novalidate>
        <div class="gift-make-block js-createArea">
        <div class="item-list-open h-adr item-list-open-hed">

            <div class="input-box-wrap">
            <div class="input-box-title">
                <div class="title-required"><span>会場名</span></div>
                <div class="text-frame-s">必須</div>
            </div>
            <div class="input-box">
                <div>
                <div *ngIf="name.errors?.required && (name.dirty || name.touched)" class="error-text input-error-text">※会場名は必須です</div>
                </div>
                <div class="flex-box j-content-start">
                <input class="input-column input-size-m" type="text" required maxlength="100" #name="ngModel" placeholder="会場名" name="name" [(ngModel)]="editData!.name" [class.error-item]="name.errors && (name.dirty || name.touched)"/>
                </div>
            </div>
            </div><!--/input-box-wrap-->

            <div class="input-box-wrap">
            <div class="input-box-title">
                <div class="title-required"><span>会場名カナ</span></div>
                <div class="text-frame-s">必須</div>
            </div>
            <div class="input-box">
                <div>
                <div *ngIf="kname.errors?.required && (kname.dirty || kname.touched)" class="error-text input-error-text">※会場名カナは必須です</div>
                <div *ngIf="kname.errors?.pattern" class="error-text input-error-text">※会場名カナは全角カタカナで入力してください</div>
                </div>
                <div class="flex-box j-content-start">
                <input class="input-column input-size-m" type="text" required pattern="{{pattern.KANA}}" maxlength="100" #kname="ngModel" placeholder="会場名カナ" name="kname" [(ngModel)]="editData!.kname" [class.error-item]="kname.errors && (kname.dirty || kname.touched)"/>
                </div>
            </div>
            </div><!--/input-box-wrap-->

            <div class="input-box-wrap">
            <div class="input-box-title">
                <div class="title-required"><span>郵便番号</span></div>
                <div class="text-frame-s">必須</div>
                <div class="cap m5 l sp">※ハイフンなし</div>
            </div>
            <div class="flex-box j-content-start flow-style">
                <div *ngIf="zip.errors?.required && (zip.dirty || zip.touched)" class="error-text input-error-text">※郵便番号は必須です</div>
                <div *ngIf="zip.errors?.pattern" class="error-text input-error-text">※郵便番号の形式が不正です</div>
                <div class="input-box input-size-s">
                    <input type="text" required pattern="{{pattern.ZIP}}" maxlength="7" #zip="ngModel" placeholder="郵便番号" class="input-column p-postal-code js-postalCode" name="zip" [(ngModel)]="editData!.zip" [class.error-item]="zip.errors && (zip.dirty || zip.touched)"/>
                </div>
                <!-- <button class="btn-item size-s tight m5 l" type="button" (click)="address.complete()">住所検索</button> -->
                <span class="cap m5 l pc">※ハイフンなし</span>
            </div>
            </div><!--/input-box-wrap-->

            <div class="input-box-wrap">
            <div class="input-box-title">
                <div class="title-required"><span>住所</span></div>
                <div class="text-frame-s">必須</div>
            </div>
            <div class="flex-box j-content-start flow-style w100p">
                <div>
                <div *ngIf="addr1.errors?.required && (addr1.dirty || addr1.touched)" class="error-text input-error-text">※都道府県は必須です</div>
                </div>
                <div class="input-box">
                <div class="input-size-s mgtb2">
                    <input type="text" required maxlength="10" #addr1="ngModel" placeholder="都道府県" class="input-column p-region-id" name="addr1" size="50"
                    [(ngModel)]="editData!.addr1"
                    (change)="changeZipCode()"
                    [class.error-item]="addr1.errors && (addr1.dirty || addr1.touched)"/>
                </div>
                </div>
            </div>
            </div><!--/input-box-wrap-->

            <div class="input-box-wrap">
            <div class="input-box-title">
                <div class="title-required"></div>
                <div class="text-frame-s">必須</div>
            </div>
            <div class="flex-box j-content-start flow-style w100p">
                <div>
                <div *ngIf="addr2.errors?.required && (addr2.dirty || addr2.touched)" class="error-text input-error-text">※市区町村は必須です</div>
                </div>
                <div class="input-box">
                <div class="mgtb2">
                    <input type="text" required maxlength="100" #addr2="ngModel" placeholder="市区町村" class="input-column p-locality" name="addr2" size="50" [(ngModel)]="editData!.addr2" [class.error-item]="addr2.errors && (addr2.dirty || addr2.touched)"/>
                </div>
                </div>
            </div>
            </div><!--/input-box-wrap-->

            <div class="input-box-wrap">
            <div class="input-box-title">
                <div class="title-required"></div>
                <div class="text-frame-s">必須</div>
            </div>
            <div class="flex-box j-content-start flow-style w100p">
                <div>
                <div *ngIf="addr3.errors?.required && (addr3.dirty || addr3.touched)" class="error-text input-error-text">※町名・番地は必須です</div>
                </div>
                <div class="input-box">
                <div class="mgtb2">
                    <input type="text" required maxlength="100" #addr3="ngModel" placeholder="町名・番地" class="input-column p-street-address" name="addr3" size="50" [(ngModel)]="editData!.addr3" [class.error-item]="addr3.errors && (addr3.dirty || addr3.touched)"/>
                </div>
                </div>
            </div>
            </div><!--/input-box-wrap-->

            <div class="input-box-wrap">
            <div class="input-box-title">
                <div class="title-required"></div>
            </div>
            <div class="flex-box j-content-start flow-style w100p">
                <div class="input-box">
                <div class="mgtb2">
                    <input type="text" maxlength="100" #addr4="ngModel" placeholder="マンション名・建物名" class="input-column p-extended-address" name="addr4" size="50" [(ngModel)]="editData!.addr4" />
                </div>
                </div>
            </div>
            </div><!--/input-box-wrap-->

            <div class="input-box-wrap">
            <div class="input-box-title">
                <div class="title-required"><span>電話</span></div>
                <div class="text-frame-s">必須</div>
                <div class="cap m5 l sp">※ハイフンなし</div>
            </div>
            <div class="flex-box j-content-start flow-style">
                <div *ngIf="tel.errors?.required && (tel.dirty || tel.touched)" class="error-text input-error-text">※電話は必須です</div>
                <div *ngIf="tel.errors?.pattern" class="error-text input-error-text">※電話の形式が不正です</div>
                <div class="input-box input-size-s">
                    <input class="input-column" type="text" required pattern="{{pattern.TEL}}" maxlength="20" #tel="ngModel" placeholder="0311112222" name="tel" [(ngModel)]="editData!.tel" [class.error-item]="tel.errors && (tel.dirty || tel.touched)"/>
                </div>
                <span class="cap m5 l pc">※ハイフンなし</span>
            </div>
            </div><!--/input-box-wrap-->

            <div class="input-box-wrap">
                <div class="input-box-title">
                    <div class="title-required"><span>FAX</span></div>
                    <div class="cap m5 l sp">※ハイフンなし</div>
                </div>
                <div class="flex-box j-content-start flow-style">
                    <div *ngIf="fax.errors?.pattern" class="error-text input-error-text">※FAXの形式が不正です</div>
                    <div class="input-box input-size-s">
                        <input class="input-column" type="text" pattern="{{pattern.TEL}}" maxlength="20" #fax="ngModel" placeholder="0311112222" name="fax" [(ngModel)]="editData!.fax" [class.error-item]="fax.errors && (fax.dirty || fax.touched)"/>
                    </div>
                    <span class="cap m5 l pc">※ハイフンなし</span>
                </div>
            </div><!--/input-box-wrap-->

            <div class="input-box-wrap">
                <div class="input-box-title">
                    <div class="title-required"><span>メールアドレス</span></div>
                </div>
                <div class="input-box">
                    <div>
                    <div *ngIf="email.errors?.email" class="error-text input-error-text">※メールアドレスの形式が不正です</div>
                    </div>
                    <div class="j-content-start">
                    <input class="input-column" type="email" email #email="ngModel" placeholder="メールアドレス" name="email" [(ngModel)]="editData!.email" [class.error-item]="email.errors && (email.dirty || email.touched)"/>
                    </div>
                </div>
            </div><!--/input-box-wrap-->

            <div class="btn-box">
            <div class="flex-box j-content-center">
                <button click-stop-propagation2 class="btn-item" type="submit" [disabled]="placeForm.invalid" [class.disabled]="placeForm.invalid"><span>保存</span></button>
                <button click-stop-propagation2 class="btn-item white js-modalCreateSendListCancelBtn" type="reset"><span>クリア</span></button>
                <input type="hidden" name="created" value="created" [(ngModel)]="editData!.created" />
            </div>
            </div><!--/input-box-->


        </div><!--/item-list-open-->
        </div><!--/gift-make-block-->

    </form>
    </ng-template>

</div><!--/content-block-->

<app-modal-message (msg_event)="onModalMsgEvent($event)"></app-modal-message>
