import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { ItemsComponent } from '../../common/items/items.component';
import { OrderListComponent } from '../../mng/order-list/order-list.component';
import { EventListComponent } from '../../mng/event-list/event-list.component';
import { SearchOrderComponent } from '../../mng/search-order/search-order.component';
import { SearchEventComponent } from '../../mng/search-event/search-event.component';

import { SessionService } from '../../service/session.service';

import { SearchCondOrder } from '../../models/search_cond_order';
import * as constant from '../../models/constant';
import { SearchCondEvent } from '../../models/search_cond_event';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SearchOrderComponent,
    SearchEventComponent,
  ],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() componentRef: any;
  public search_type: number = -1;
  public search_types = constant.SEARCH_TYPES;
  public mng: boolean = false;
  public placeholder: string = '';
  public keywords: string[] = [];
  public search_word: string = '';
  public category_type = constant.ITEM_CATEGORY;
  public price_range = constant.ITEM_PRICE_RANGE;
  private isBrowser: boolean;

  constructor(
    private sv_session: SessionService,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (this.componentRef instanceof ItemsComponent) {
      this.sv_session.getItemSearchKeyword();
      this.sv_session.itemSearchKeyword.subscribe(item_keyword => {
        this.keywords = item_keyword ? item_keyword : [];
        this.search_word = (this.keywords) ? this.keywords.join(' ') : '';
      });
      this.sv_session.getItemSearchCategory();
      this.sv_session.itemSearchCategory.subscribe(category_type => {
        if (category_type) {
          this.category_type = category_type;
        }
      });
      this.sv_session.getItemSearchPriceRange();
      this.sv_session.itemSearchPriceRange.subscribe(price_range => {
        if (price_range) {
          this.price_range = price_range;
        }
      });
    }
  }

  ngOnChanges(){
    if (!this.isBrowser) return;
    /////// 商品検索
    if (this.componentRef instanceof ItemsComponent) {
      this.search_type = constant.SEARCH_TYPES.ITEM;
      this.placeholder = "商品を検索";
      this.mng = false;
      // カテゴリーチェックを初期化
      if (this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_CATEGORY)) {
        this.category_type = this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_CATEGORY);
        let categories: string[] = [];
        this.category_type.forEach((cate, i) => {
          this.category_type[i].checked = cate.checked;
          if (cate.checked) {
            categories.push(cate.key.toString());
          }
        });
      }
      else {
        let categories: string[] = [];
        if (this.category_type) {
          this.category_type.forEach((cate, i) => {
            this.category_type[i].checked = false;
            categories.push(cate.key.toString());
          });
        }
      }
      // 価格帯チェックを初期化
      if (this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_PRICE_RANGE)) {
        this.price_range = this.local_storage.get(constant.STORAGE_LOCAL_ITEM_SEARCH_PRICE_RANGE);
        let price_ranges: string[] = [];
        this.price_range.forEach((range, r) => {
          this.price_range[r].checked = false;
          if (range.checked) {
            price_ranges.push(range.key.toString());
          }
        });
      }
      else {
        let price_ranges: string[] = [];
        if (this.price_range) {
          this.price_range.forEach((range, r) => {
            this.price_range[r].checked = false;
            price_ranges.push(range.key.toString());
          });
        }
      }
      if (this.isBrowser) {
        if(!$('.search-input').hasClass('wide')) {
          $('.search-input').addClass('wide');
        };
      }
    }
    // 注文検索
    else if (this.componentRef instanceof OrderListComponent) {
      this.search_type = constant.SEARCH_TYPES.ORDER;
      this.placeholder = "注文を検索";
      this.mng = true;
      if (this.isBrowser) {
        $('.search-input').removeClass('wide');
      }
    }
    // 挙式検索
    else if (this.componentRef instanceof EventListComponent) {
      this.search_type = constant.SEARCH_TYPES.EVENT;
      this.placeholder = "挙式を検索";
      this.mng = true;
      if (this.isBrowser) {
        $('.search-input').removeClass('wide');
      }
    }
    else {
      this.search_type = -1;
    }
  }

  public search() {
    // 検索カテゴリーと検索キーワードを各Componentに渡す（検索の実処理は各コンポーネント側で管理）
    if (this.search_type == constant.SEARCH_TYPES.ITEM) {
      // カテゴリー
      let categories: string[] = [];
      this.category_type.forEach(category => {
        if (category.checked) {
          categories.push(category.key.toString());
        }
      });
      if (categories.length < 1) {
        this.category_type.forEach(category => {
          categories.push(category.key.toString());
        });
      }
      this.componentRef.categories = categories;

      // 価格帯
      let price_ranges: string[] = [];
      this.price_range.forEach(range => {
        if (range.checked) {
          price_ranges.push(range.key.toString());
        }
      });
      if (price_ranges.length < 1) {
        this.price_range.forEach(range => {
          price_ranges.push(range.key.toString());
        });
      }
      this.componentRef.price_ranges = price_ranges;
    }
    this.componentRef.keywords = this.keywords;
    this.componentRef.search();
  }

  public clearItemSearchCond(): void {
    this.keywords = [];
    this.category_type = constant.ITEM_CATEGORY;
    this.price_range = constant.ITEM_PRICE_RANGE;
    this.category_type.forEach(cate => cate.checked = false);
    this.price_range.forEach(price => price.checked = false);
    this.search();
  }

  public onSearchOrder(cond: SearchCondOrder) {
    this.componentRef.search(cond);
  }

  public onSearchEvent(cond: SearchCondEvent) {
    this.componentRef.search(cond);
  }


}
