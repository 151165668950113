import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SwitchTabService } from '../../service/switch-tab.service';

import { TabModel } from '../../models/tab-model';

@Component({
  selector: 'app-switch-tab',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './switch-tab.component.html',
  styleUrls: ['./switch-tab.component.scss']
})
export class SwitchTabComponent implements OnInit {
  @Input() id: string = '';

  private _currentTab: any;
  private _tabs: TabModel[] = [];

  constructor(
    private sv_switchTab: SwitchTabService
  ) { }

  ngOnInit() {
    this._tabs = this.sv_switchTab.tabs;
    this._currentTab = this.sv_switchTab.getCurrentContents();
  }

  tabClick(event: any) {
    this._currentTab = this.sv_switchTab.changeCurrentContents(event.target.innerHTML);
  }

  public get currentTab(): any {
    return this._currentTab;
  }

  public set currentTab(value: any) {
    this._currentTab = value;
  }

  public get tabs(): TabModel[] {
    return this._tabs;
  }

  public set tabs(value: TabModel[]) {
    this._tabs = value;
  }
}
