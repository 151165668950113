import { OrderBranch } from './order_branch';

export interface IOrderData {
  order_id: string;
  order_no: string;
  order_created: any;
  event_id: string;
  event_name: string;
  event_date: Date | null;
  event_status: number;
  order_limit: Date | null;
  order_branch: OrderBranch | null;
}
export class OrderData implements IOrderData {
  order_id: string = '';
  order_no: string = '';
  order_created: any = null;
  event_id: string = '';
  event_name: string = '';
  event_date: Date | null= null;
  event_status: number = 0;
  order_limit: Date | null= null;
  order_branch: OrderBranch | null= null;
}
