import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable, of, Subject} from 'rxjs';
import { catchError, map, filter } from 'rxjs/operators';

import { SessionService } from './session.service';

import { Session } from '../models/session';
import { Event } from '../models/event';
import { EventSearch } from '../models/event_search';
import { EventSearchResult } from '../models/event_search_result';
import { ProcessResult } from '../models/process_result';
import { SearchCondEvent } from '../models/search_cond_event';
import { Account } from '../models/account';
import * as constant from '../models/constant';

import { environment } from '../../environments/environment';
import algoliasearch from 'algoliasearch';
import moment from 'moment';

declare const debugLog: any;

const ALGOLIA_ID = environment.ALGOLIA_APP_ID;
const ALGOLIA_SEARCH_KEY = environment.ALGOLIA_SEARCH_KEY
const ALGOLIA_INDEX_EVENT_SEARCH = environment.ALGOLIA_INDEX_EVENT_SEARCH;

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private eventCollection!: AngularFirestoreCollection<Event>;
  private eventDocument!: AngularFirestoreDocument<Event>;
  private event: Observable<Event | null> = of(null);
  private events: Observable<Event[]> = of([]);
  private eventSearchCollection!: AngularFirestoreCollection<EventSearch>;
  private eventSearchDocument!: AngularFirestoreDocument<EventSearch>;
  private event_search: Observable<EventSearch | null> = of(null);
  private event_searches: Observable<EventSearch[]> = of([]);
  private session: Session = new Session();
  private update_user: {uid: string, name: string} = {uid: '', name:''};

  constructor(
    private firestore: AngularFirestore,
    private sv_session: SessionService,
  ) {
  }

  /**
   * 更新ユーザー設定
   *
   * @param {Session} session
   * @memberof EventService
   */
  initialize(session: Session): void {
    if (!session || !session.data) return;
    this.session = session;
    this.update_user = {
      uid: session.data.uid,
      name: session.data.last_name + session.data.first_name
    };
  }

  /**
   * ゲスト・顧客登録用URLトークン作成
   *
   * @returns {string}
   * @memberof EventService
   */
  createToken(): string {
    const len = 15;
    const str = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_$+-";
    let result: string = "";
    for (var i = 0; i < len; i++) {
      result += str[Math.floor(Math.random() * str.length)];
    }
    return result;
  }

  /**
   * 担当挙式情報を取得（EventSearchから検索）
   *　（過去施行は除く）
   * @returns {Observable<EventSearch[]>}
   * @memberof EventService
   */
  getEventSearches(): Observable<EventSearch[]> {
    const today = moment(new Date().setHours(0,0,0,0)).toDate();
    const current_company = this.session.data?.current_company;
    const current_place = this.session.data?.current_place;

    if (current_company && current_place) {
      this.eventSearchCollection = this.firestore.collection<EventSearch>('event_search', ref => ref
        .where('company_id', '==', current_company)
        .where('place_id', '==', current_place)
        .where('staff_ids', 'array-contains', this.session.data?.uid)
        .where('date', '>=', today)
        .where('delete_flg', '==', false)
        .orderBy('date', 'desc')
      );
    } else {
      this.eventSearchCollection = this.firestore.collection<EventSearch>('event_search', ref => ref
        .where('staff_ids', 'array-contains', this.session.data?.uid)
        .where('date', '>=', today)
        .where('delete_flg', '==', false)
        .orderBy('date', 'desc')
      );
    }

    this.event_searches = this.eventSearchCollection.snapshotChanges().pipe(
      map(docs =>
        docs.map(doc => ({
          objectID: doc.payload.doc.id,
          name: doc.payload.doc.data().name,
          date: doc.payload.doc.data().date,
          company_id: doc.payload.doc.data().company_id,
          company_name: doc.payload.doc.data().company_name,
          place_id: doc.payload.doc.data().place_id,
          place_name: doc.payload.doc.data().place_name,
          cust1_id: doc.payload.doc.data().cust1_id,
          cust2_id: doc.payload.doc.data().cust2_id,
          staff_ids: doc.payload.doc.data().staff_ids,
          memo: doc.payload.doc.data().memo,
          status: doc.payload.doc.data().status,
          created: doc.payload.doc.data().created,
          updated: doc.payload.doc.data().updated,
          delete_flg: doc.payload.doc.data().delete_flg
        }))
      )
    );
    return this.event_searches.pipe(
      catchError(this.handleError<EventSearch[]>('getEventSearches', []))
    );
  }

  /**
   * 担当挙式情報を取得（挙式担当変更用）
   *
   * @param {string} place_key
   * @param {string} staff_key
   * @returns {Observable<EventSearch[]>}
   * @memberof EventService
   */
  getEventSearchesForMember(place_key: string, staff_key: string): Observable<EventSearch[]> {
    this.eventSearchCollection = this.firestore.collection<EventSearch>('event_search', ref => ref
      .where('place_id', '==', place_key)
      .where('staff_ids', 'array-contains', staff_key)
      .where('delete_flg', '==', false)
      .orderBy('date', 'desc')
    );
    this.event_searches = this.eventSearchCollection.snapshotChanges().pipe(
      map(docs =>
        docs.map(doc => ({
          objectID: doc.payload.doc.id,
          name: doc.payload.doc.data().name,
          date: doc.payload.doc.data().date,
          company_id: doc.payload.doc.data().company_id,
          company_name: doc.payload.doc.data().company_name,
          place_id: doc.payload.doc.data().place_id,
          place_name: doc.payload.doc.data().place_name,
          cust1_id: doc.payload.doc.data().cust1_id,
          cust2_id: doc.payload.doc.data().cust2_id,
          staff_ids: doc.payload.doc.data().staff_ids,
          memo: doc.payload.doc.data().memo,
          status: doc.payload.doc.data().status,
          created: doc.payload.doc.data().created,
          updated: doc.payload.doc.data().updated,
          delete_flg: doc.payload.doc.data().delete_flg
        }))
      )
    );
    return this.event_searches.pipe(
      catchError(this.handleError<EventSearch[]>('getEventSearches', []))
    );
  }

  /**
   * 挙式検索
   *　（Algolia）
   * @param {SearchCondEvent} cond
   * @returns {Observable<EventSearch[]>}
   * @memberof EventService
   */
  searchEvents(cond: SearchCondEvent, page: number | null): Observable<EventSearchResult> {
    const client = algoliasearch(ALGOLIA_ID, ALGOLIA_SEARCH_KEY);
    const index = client.initIndex(ALGOLIA_INDEX_EVENT_SEARCH);
    const oldest_from = moment(new Date().setHours(0,0,0,0)).add(-1, 'y').unix();
    let filters = [];

    if (this.session.data?.current_company) {
      filters.push(`company_id: ${this.session.data.current_company}`); // ユーザーの所属企業を超える検索はできない
    }
    if (cond.company_name) { // 所属を持たないフリープランナー等の場合は企業名・会場名での検索が可能
      filters.push(`company_name: ${cond.company_name}`);
    }
    // algolia上で挙式日はUnixTimestampで保存しているため検索日付もUnix変換
    if (cond.exclude_ck) {
      let today = moment(new Date().setHours(0,0,0,0)).unix();
      filters.push(`date >= ${today}`);
    }
    else {
      if (cond.date_from) {
        let from = moment(cond.date_from).unix();
        if (from < oldest_from) {
          from = oldest_from
        }
        filters.push(`date >= ${from}`);
      }
      else { // fromは1年以内
        filters.push(`date >= ${oldest_from}`);
      }
    }
    if (cond.date_to) {
      let to = moment(cond.date_to).unix();
      filters.push(`date <= ${to}`);
    }
    if (cond.place_id) {
      filters.push(`place_id: ${cond.place_id}`);
    }
    if (cond.place_name) { // 所属を持たないフリープランナー等の場合は企業名・会場名での検索が可能
      filters.push(`place_name: ${cond.place_name}`);
    }
    if (cond.staff) {
      filters.push(`staff_ids: ${cond.staff}`);
    }
    filters.push(`delete_flg: false`);

    let search_filters = filters.join(' AND ');
    let search_keyword = cond.name ? cond.name : '';
    let search_subject: Subject<EventSearchResult> = new Subject();
    let search_result = search_subject.asObservable();
    let param: any;

    if (page) { // ページ指定あり
      let page_index = page ? page -1 : 0;
      param = {
        // query: search_keyword,
        filters: search_filters,
        page: page_index,
        hitsPerPage: 20
      };
    } else { // ページ指定なし
      param = {
        // query: search_keyword,
        filters: search_filters,
        hitsPerPage: 1000
      };
    }

    index.search(search_keyword, param)
    .then(function(responses) {
      let res: EventSearchResult = {
        page: responses.page,
        nbHits: responses.nbHits,
        nbPages: responses.nbPages,
        hitsPerPage: responses.hitsPerPage,
        exhaustiveNbHits: responses.exhaustiveNbHits,
        event_search: responses.hits as EventSearch[]
      };
      search_subject.next(res);
    });

    return search_result.pipe(
      catchError(this.handleError<EventSearchResult>('searchEvents', undefined))
    );
  }

  /**
   * 担当挙式情報とその顧客を取得
   * （過去施行は除く）
   * @returns {Observable<Event[]>}
   * @memberof EventService
   */
  getEvents(): Observable<Event[]> {
    const today = moment(new Date().setHours(0,0,0,0)).toDate();
    const current_company = this.session.data?.current_company;
    const current_place = this.session.data?.current_place;

    if (current_company && current_place) {
      this.eventCollection = this.firestore.collection<Event>('event', ref => ref
        .where('company_id', '==', this.session.data?.current_company)
        .where('place_id', '==', this.session.data?.current_place)
        .where('staff_ids', 'array-contains', this.session.data?.uid)
        .where('date', '>=', today)
        .where('delete_flg', '==', false)
        .orderBy('date', 'desc')
      );
    }
    else {
      this.eventCollection = this.firestore.collection<Event>('event', ref => ref
        .where('staff_ids', 'array-contains', this.session.data?.uid)
        .where('date', '>=', today)
        .where('delete_flg', '==', false)
        .orderBy('date', 'desc')
      );
    }
    this.events = this.eventCollection.snapshotChanges().pipe(
      map(docs =>
        docs.map(doc => ({
          objectID: doc.payload.doc.id,
          name: doc.payload.doc.data().name,
          date: doc.payload.doc.data().date,
          memo: doc.payload.doc.data().memo,
          company_id: doc.payload.doc.data().company_id,
          company_name: doc.payload.doc.data().company_name,
          place_id: doc.payload.doc.data().place_id,
          place_name: doc.payload.doc.data().place_name,
          cust1_id: doc.payload.doc.data().cust1_id,
          cust2_id: doc.payload.doc.data().cust2_id,
          status: doc.payload.doc.data().status,
          token_guest: doc.payload.doc.data().token_guest,
          token_cust: doc.payload.doc.data().token_cust,
          transfer_first_name: doc.payload.doc.data().transfer_first_name,
          transfer_last_name: doc.payload.doc.data().transfer_last_name,
          transfer_first_kname: doc.payload.doc.data().transfer_first_kname,
          transfer_last_kname: doc.payload.doc.data().transfer_last_kname,
          transfer_zip: doc.payload.doc.data().transfer_zip,
          transfer_addr1: doc.payload.doc.data().transfer_addr1,
          transfer_addr2: doc.payload.doc.data().transfer_addr2,
          transfer_addr3: doc.payload.doc.data().transfer_addr3,
          transfer_addr4: doc.payload.doc.data().transfer_addr4,
          transfer_tel: doc.payload.doc.data().transfer_tel,
          table_card_zip: doc.payload.doc.data().table_card_zip,
          table_card_addr1: doc.payload.doc.data().table_card_addr1,
          table_card_addr2: doc.payload.doc.data().table_card_addr2,
          table_card_addr3: doc.payload.doc.data().table_card_addr3,
          table_card_addr4: doc.payload.doc.data().table_card_addr4,
          table_card_tel: doc.payload.doc.data().table_card_tel,
          staff_ids: doc.payload.doc.data().staff_ids,
          created: doc.payload.doc.data().created,
          updated: doc.payload.doc.data().updated,
          updated_user: doc.payload.doc.data().updated_user,
          delete_flg: doc.payload.doc.data().delete_flg
        }))
      )
    );
    return this.events.pipe(
      catchError(this.handleError<Event[]>('getEventsWithAccount', []))
    );
  }

  /**
   * イベント情報取得
   *
   * @param {string} key
   * @returns {Observable<Event | null>}
   * @memberof EventService
   */
  getEvent(key: string): Observable<Event | null>{
    this.eventDocument = this.firestore.doc<Event>(`event/${key}`);
    this.event = this.eventDocument.snapshotChanges().pipe(
      map(doc => {
        if (!doc.payload.exists){
          return null;
        }
        let obj: Event = {
          objectID: doc.payload.id,
          name: doc.payload.data().name,
          date: doc.payload.data().date,
          memo: doc.payload.data().memo,
          company_id: doc.payload.data().company_id,
          company_name: doc.payload.data().company_name,
          place_id: doc.payload.data().place_id,
          place_name: doc.payload.data().place_name,
          cust1_id: doc.payload.data().cust1_id,
          cust2_id: doc.payload.data().cust2_id,
          staff_ids: doc.payload.data().staff_ids,
          status: doc.payload.data().status,
          token_guest: doc.payload.data().token_guest,
          token_cust: doc.payload.data().token_cust,
          transfer_first_name: doc.payload.data().transfer_first_name,
          transfer_last_name: doc.payload.data().transfer_last_name,
          transfer_first_kname: doc.payload.data().transfer_first_kname,
          transfer_last_kname: doc.payload.data().transfer_last_kname,
          transfer_zip: doc.payload.data().transfer_zip,
          transfer_addr1: doc.payload.data().transfer_addr1,
          transfer_addr2: doc.payload.data().transfer_addr2,
          transfer_addr3: doc.payload.data().transfer_addr3,
          transfer_addr4: doc.payload.data().transfer_addr4,
          transfer_tel: doc.payload.data().transfer_tel,
          table_card_zip: doc.payload.data().table_card_zip,
          table_card_addr1: doc.payload.data().table_card_addr1,
          table_card_addr2: doc.payload.data().table_card_addr2,
          table_card_addr3: doc.payload.data().table_card_addr3,
          table_card_addr4: doc.payload.data().table_card_addr4,
          table_card_tel: doc.payload.data().table_card_tel,
          created: doc.payload.data().created,
          updated: doc.payload.data().updated,
          updated_user: doc.payload.data().updated_user,
          delete_flg: doc.payload.data().delete_flg
        };
        return obj;
      })
    );

    return this.event.pipe(
      catchError(this.handleError<Event>(`getEvent id=${key}`))
    );
  }

  /**
   * イベント登録・編集
   *
   * @param {string} key
   * @param {Event} event 編集データ
   * @returns {Observable<ProcessResult>}
   * @memberof EventService
   */
  save(key: string | null, event: Event): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    // 新規登録時
    if (!key) {
      key = this.firestore.createId();
    }

    const event_ref = this.firestore.doc<Event>(`event/${key}`).ref;
    const event_search_ref = this.firestore.doc<EventSearch>(`event_search/${key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await Promise.all([
        transaction.get(event_ref),
        transaction.get(event_search_ref),
      ])
      .then(() => {
        transaction.set(event_ref, {
          'name': event.name,
          'date': event.date,
          'memo': event.memo,
          'company_id': event.company_id,
          'company_name': event.company_name,
          'place_id': event.place_id,
          'place_name': event.place_name,
          'cust1_id': event.cust1_id,
          'cust2_id': event.cust2_id,
          'staff_ids': event.staff_ids,
          'status': event.status,
          'token_guest': event.token_guest,
          'token_cust': event.token_cust,
          'transfer_first_name': event.transfer_first_name,
          'transfer_last_name': event.transfer_last_name,
          'transfer_first_kname': event.transfer_first_kname,
          'transfer_last_kname': event.transfer_last_kname,
          'transfer_zip': event.transfer_zip,
          'transfer_addr1': event.transfer_addr1,
          'transfer_addr2': event.transfer_addr2,
          'transfer_addr3': event.transfer_addr3,
          'transfer_addr4': event.transfer_addr4,
          'transfer_tel': event.transfer_tel,
          'table_card_zip': event.table_card_zip,
          'table_card_addr1': event.table_card_addr1,
          'table_card_addr2': event.table_card_addr2,
          'table_card_addr3': event.table_card_addr3,
          'table_card_addr4': event.table_card_addr4,
          'table_card_tel': event.table_card_tel,
          'created': event.created ? event.created : new Date(),
          'updated': new Date(),
          'updated_user': event.updated_user ? event.updated_user : this.update_user,
          'delete_flg': false
        }, {'merge': true});
      })
      .then(() => {
        transaction.set(event_search_ref, {
          'name': event.name,
          'date': event.date,
          'company_id': event.company_id,
          'company_name': event.company_name,
          'place_id': event.place_id,
          'place_name': event.place_name,
          'cust1_id': event.cust1_id,
          'cust2_id': event.cust2_id,
          'staff_ids': event.staff_ids,
          'status': event.status,
          'memo': event.memo ? true : false,
          'created': event.created ? event.created : new Date(),
          'updated': new Date(),
          'delete_flg': false
        }, {'merge': true});
      })
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * 不在時転送先更新
   *
   * @param {string} key
   * @param {Event} event
   * @returns {Observable<ProcessResult>}
   * @memberof EventService
   */
  updateTransfer(key: string, event: Event): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const event_ref = this.firestore.doc<Event>(`event/${key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(event_ref)
      .then(() => {
        transaction.update(event_ref, {
          'transfer_first_name': event.transfer_first_name,
          'transfer_last_name': event.transfer_last_name,
          'transfer_first_kname': event.transfer_first_kname,
          'transfer_last_kname': event.transfer_last_kname,
          'transfer_zip': event.transfer_zip,
          'transfer_addr1': event.transfer_addr1,
          'transfer_addr2': event.transfer_addr2,
          'transfer_addr3': event.transfer_addr3,
          'transfer_addr4': event.transfer_addr4,
          'transfer_tel': event.transfer_tel,
          'updated': new Date(),
          'updated_user': event.updated_user ? event.updated_user : this.update_user
        });
      })
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * 顧客ID更新
   *　（顧客登録時に挙式データの顧客IDをセットするための処理）
   * @param {string} key
   * @param {Account} cust
   * @returns {Observable<ProcessResult>}
   * @memberof EventService
   */
  updateCust(key: string, cust: Account): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();

    const event_ref = this.firestore.doc<Event>(`event/${key}`).ref;
    const event_search_ref = this.firestore.doc<EventSearch>(`event_search/${key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await Promise.all([
        transaction.get(event_ref),
        transaction.get(event_search_ref),
      ])
      .then(() => {
        if (cust.cust_type == constant.CUST_TYPE.GROOM) {
          transaction.update(event_ref, {
            'cust1_id': cust.objectID,
            'updated': new Date(),
            'updated_user': cust.updated_user
          });
        } else {
          transaction.update(event_ref, {
            'cust2_id': cust.objectID,
            'updated': new Date(),
            'updated_user': cust.updated_user
          });
        }
      })
      .then(() => {
        if (cust.cust_type == constant.CUST_TYPE.GROOM) {
          transaction.update(event_search_ref, {
            'cust1_id': cust.objectID,
            'updated': new Date(),
            'updated_user': cust.updated_user
          });
        } else {
          transaction.update(event_search_ref, {
            'cust2_id': cust.objectID,
            'updated': new Date(),
            'updated_user': cust.updated_user
          });
        }
      })
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * 挙式情報の顧客を削除
   *　(accountのdelete_flgをtrueで更新時はfirebaseユーザーも削除される。※functions)
   * @param {string} key
   * @param {number} cust_index
   * @param {string} cust_key
   * @returns {Observable<ProcessResult>}
   * @memberof EventService
   */
  delCust(key: string, cust_index: number, cust_key: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const event_ref = this.firestore.doc<Event>(`event/${key}`).ref;
    const event_search_ref = this.firestore.doc<EventSearch>(`event_search/${key}`).ref;
    const account_ref = this.firestore.doc<Account>(`account/${cust_key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await Promise.all([
        transaction.get(event_ref),
        transaction.get(event_search_ref),
      ])
      .then(() => {
        if (cust_index == 0) {
          transaction.update(event_ref, {
            'cust1_id': null,
            'updated': new Date(),
            'updated_user': this.update_user,
          });
        } else {
          transaction.update(event_ref, {
            'cust2_id': null,
            'updated': new Date(),
            'updated_user': this.update_user,
          });
        }
      })
      .then(() => {
        if (cust_index == 0) {
          transaction.update(event_search_ref, {
            'cust1_id': null,
            'updated': new Date(),
            'updated_user': this.update_user,
          });
        } else {
          transaction.update(event_search_ref, {
            'cust2_id': null,
            'updated': new Date(),
            'updated_user': this.update_user,
          });
        }
      })
      .then(() => {
        transaction.update(account_ref, {
          'event_id': null,
          'delete_flg': true,
          'updated': new Date(),
          'updated_user': this.update_user,
        });
      })
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * 挙式担当変更
   *
   * @param {string} key
   * @param {string[]} staff_ids
   * @param {uid: string, name: string} update_user
   * @returns {Observable<ProcessResult>}
   * @memberof EventService
   */
  updateStaff(key: string, staff_ids: string[], update_user: {uid: string, name: string}): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();

    const event_ref = this.firestore.doc<Event>(`event/${key}`).ref;
    const event_search_ref = this.firestore.doc<EventSearch>(`event_search/${key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await Promise.all([
        transaction.get(event_ref),
        transaction.get(event_search_ref),
      ])
      .then(() => {
        transaction.update(event_ref, {
          'staff_ids': staff_ids,
          'updated': new Date(),
          'updated_user': update_user
        });
      })
      .then(() => {
        transaction.update(event_search_ref, {
          'staff_ids': staff_ids,
          'updated': new Date(),
          'updated_user': update_user
        });
      })
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * 失敗したHttp操作を処理します。
   * アプリを持続させます。
   * @param operation - 失敗した操作の名前
   * @param result - observableな結果として返す任意の値
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: リモート上のロギング基盤にエラーを送信する
      console.error(error); // かわりにconsoleに出力

      // TODO: ユーザーへの開示のためにエラーの変換処理を改善する
      // this.log(`${operation} failed: ${error.message}`);

      // 空の結果を返して、アプリを持続可能にする
      return of(result as T);
    };
  }

}
