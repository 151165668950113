import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()

export class JpDateAdapter extends NativeDateAdapter {
    override getDateNames(): string[] {
        return Array.from(Array(31), (v,k) => `${k+1}`);
    }
}
