<div class="search-block" [class.mng]="mng" [class.item]="search_type===search_types.ITEM">
  <div class="search-block-inner">
    <!--ITEM-->
    <div *ngIf="search_type===search_types.ITEM" class="search-input">
      <input class="form-search" type="text"
        [placeholder]="placeholder"
        [(ngModel)]="search_word"
        (ngModelChange)="keywords=$event.split(' ')"
        (keyup.enter)="search()">
      <button type="button" class="icon-search" (click)="search()"></button>
    </div>

    <!--ITEM-->
    <div *ngIf="search_type===search_types.ITEM" class="search-block-inner item">
      <div class="checkbox-block item">
        <div class="search-type">カテゴリーで検索</div>
        <ng-container *ngFor="let cate of category_type; index as i">
          <div class="checkbox-wrap">
            <label>
              <input type="checkbox" class="header-checkbox" name="cate{{i}}" id="cate{{i}}"
                [(ngModel)]="category_type[i].checked"
                [value]="cate.key"
                (change)="search()">
              <span class="item-label search">{{cate.value}}</span>
            </label>
          </div>
        </ng-container>
      </div>
      <div class="checkbox-block item">
        <div class="search-type">金額で検索（※税抜き価格）</div>
        <ng-container *ngFor="let range of price_range; index as r">
          <div class="checkbox-wrap block">
            <label>
              <input type="checkbox" class="header-checkbox" name="range{{r}}" id="range{{r}}"
                [(ngModel)]="price_range[r].checked"
                [value]="range.key"
                (change)="search()">
              <span class="item-label search">{{range.value}}</span>
            </label>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="search_type===search_types.ITEM" class="clear-search-cond in" (click)="clearItemSearchCond()">検索条件クリア</div>

    <!--ORDER-->
    <div *ngIf="search_type===search_types.ORDER">
      <app-search-order (search_order_event)="onSearchOrder($event)"></app-search-order>
    </div>

    <!--EVENT-->
    <div *ngIf="search_type===search_types.EVENT">
      <app-search-event (search_event_event)="onSearchEvent($event)"></app-search-event>
    </div>

  </div><!--/search-block-inner-->
</div><!--/search-block-->
