<div class="wrapper-login">
	<div class="main-login">
		<h1 class="logo"><img src="assets/img/logo.svg" alt="THE TIMELESS"></h1>
      
        <div class="about-text">お探しのページが見つかりませんでした。</div>
        <div class="about-text">
            <button type="button" class="btn-item" routerLink="/sign_in">トップページへ</button>
        </div>

        <footer class="footer-login">
            <div class="footer-inner">
                &copy; TIMELESS CO./All Rights Reserved.
            </div>
        </footer><!--/footer-login-->
</div><!--/wrapper-login-->
