import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription, of } from 'rxjs';

import { SessionService } from '../../service/session.service';
import { EventService } from '../../service/event.service';

import { Session } from '../../models/session';
import { OrderData } from '../../models/order_data';
import { OrderedGiftbox } from '../../models/ordered_giftbox';
import { OrderGiftbox } from '../../models/order_giftbox';
import { OrderGuest } from '../../models/order_guest';
import { Event } from '../../models/event';
import * as constant from '../../models/constant';

import moment from 'moment';

declare function resetCommonrEvent(): void;
declare const debugLog: any;

@Component({
  selector: 'app-order-detail-sendlist',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
  ],
  templateUrl: './order-detail-sendlist.component.html',
  styleUrls: ['./order-detail-sendlist.component.scss']
})
export class OrderDetailSendlistComponent implements OnInit {

  private session: Session = new Session();
  public order_info: OrderData = new OrderData();
  public event: Event = new Event();
  public order_detail_giftbox: OrderedGiftbox = new OrderedGiftbox();
  public order_detail_giftboxes: OrderedGiftbox[] = [];
  public guests: OrderGuest[] = [];
  public gb_names: string[] = [];
  public guest_changes: boolean[] = [];
  public noshis:{[key: number]: string} = constant.NOSHI_OBJ;
  public cards:{[key: number]: string} = constant.CARD_MESSAGE_OBJ;
  public status = constant.ORDER_STATUS;
  private today: Date = moment(new Date().setHours(0,0,0,0)).toDate(); // 現在日付（発注期限超過判定のため時間は考慮しない）
  public limit_over: boolean = false;
  public is_event: boolean = false;
  private subscription: Subscription = new Subscription();

  constructor(
    private sv_session: SessionService,
    private sv_event: EventService,
    private route: ActivatedRoute,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.session = session;
        }
      })
    );

    this.subscription.add(
      this.route.params.subscribe(params => {
        this.is_event = params['type'] == 'event';
        this.sv_session.getOrderInfo();
        this.sv_session.orderInfo.subscribe(order_info => {
          this.order_info = order_info;
          if (order_info) {
            this.limit_over = moment(this.order_info.order_limit).unix() < moment(this.today).unix();
            this.sv_event.getEvent(order_info.event_id).subscribe(ev => {
              if (ev) {
                this.event = ev;
              }
            });
          }
        });

        this.sv_session.getOrderDetailGiftbox();
        this.sv_session.orderDetailGiftbox.subscribe(order_detail_giftbox => {
          this.guests = [];
          this.order_detail_giftbox = order_detail_giftbox;
          if (order_detail_giftbox) {
            this.guests = order_detail_giftbox.order_guests;
          }
        });
        this.sv_session.getOrderDetailGuestChange();
        this.sv_session.orderDetailGuestChange.subscribe(guest_changes => {
          this.guest_changes = guest_changes;
        });

        if (this.is_event) {
          this.sv_session.getOrderDetail();
          this.sv_session.orderDetail.subscribe(order_detail => {
            this.order_detail_giftboxes = order_detail;
            this.guests = [];
            this.gb_names = [];
            order_detail.forEach(gb => {
              gb.order_guests.forEach(guest => {
                this.guests.push(guest);
                this.gb_names.push(gb.order_giftbox.name);
              })
            })
          });
        }
      })
    );

    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント設定
    }
  }

  public back(): void {
    this.location.back();
  }

  public printPage(): void {
    try {
      document.execCommand('print', false, '');  // for Safari
    } catch {
      window.print();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
