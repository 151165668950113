<div class="content-block content-block-max">
  <div class="content-block-inner">

    <div *ngIf="show_qr">
      <ng-container *ngTemplateOutlet="modal"></ng-container>
    </div>

    <div class="back-link"><a (click)="back()">戻る</a></div>
    <div class="mg-style2">
      <div class="flex-box">
        <dl class="flex-box j-content-start text-m-style2">
          <dt class="dl-title w4e">挙式日</dt>
          <dd>{{event.date?.toDate() | date}}</dd>
        </dl>
      </div>
      <div class="title-content mg-style2">{{event.name}}</div>
      <div *ngIf="event?.status == event_status.UNSUBSCRIBED"><span class="red red-square">無効</span></div>
    </div>
    <dl class="flex-box j-content-start text-m-style2">
      <dt class="dl-title w4e">会場名</dt>
      <dd>{{place.name}}</dd>
    </dl>
    <dl class="flex-box j-content-start text-m-style2">
      <dt class="dl-title w4e">担当者</dt>
      <dd *ngFor="let staff of staffs">{{staff?.last_name}}{{staff?.first_name}}　</dd>
    </dl>
    <dl *ngIf="event?.memo" class="flex-box j-content-start a-items-start text-m-style2">
      <dt class="dl-title w4e">メモ</dt>
      <dd>{{event.memo}}</dd>
    </dl>
    <dl class="flex-box j-content-start a-items-start text-m-style2">
      <dt class="dl-title w8e">注文最終確定</dt>
      <dd>{{order_limit | date}}</dd>
    </dl>
    <dl class="flex-box j-content-start a-items-start text-m-style2">
      <dt class="dl-title w8e">顧客登録期限</dt>
      <dd>{{event.token_cust.limit?.toDate() | date}}</dd>
    </dl>
    <dl class="flex-box j-content-start a-items-start text-m-style2">
      <dt class="dl-title w8e">ゲスト登録期限</dt>
      <dd>{{event.token_guest.limit?.toDate() | date}}</dd>
    </dl>

    <div class="input-box mgt20">
      <div class="flex-box j-content-center">
        <a routerLink="/event-edit/{{event.objectID}}" class="btn-item"><span>変更</span></a>
      </div>
    </div>

    <!-- 登録QRコードボタン表示 -->
    <div *ngIf="custs.length < 2" class="list-wrap-border-t">
      <div class="text-l-style1 mg-style2">顧客登録</div>
      <div class="text-m-style3 mg-style2">
        ・新郎新婦様にQRコードをスキャンしていただくかURLを送付し、顧客登録が完了すると上記の挙式の顧客として登録されます。<br>
        ・ご注文の際は、新郎新婦様それぞれの顧客登録が必須となります。<br>
        ・挙式ステータスが「無効」になっている場合は、以下の「QRコードを表示」「URLをコピー」が使えません。
      </div>
      <div class="qrcode-wrap mgt0">
        <div class="flex-box j-content-center">
          <button type="button" class="btn-item" (click)="showQR()" [disabled]="event.status==event_status.UNSUBSCRIBED"><span>QRコードを表示</span></button>
          <button type="button" class="btn-item url-copy size-l" (click)=copy($event) [disabled]="event.status==event_status.UNSUBSCRIBED"><span>URLをコピー</span></button>
        </div>
      </div>
    </div><!--/list-wrap-border-t-->

  </div><!--/content-block-inner-->

  <div *ngIf="custs?.length" class="list-wrap-border-t">

    <div *ngFor="let cust of custs; index as i" class="order-item-list event_detail js-listRow">
      <div class="lits-cont-box sendlist">
        <dl class="flex-box j-content-start a-items-start">
          <dt class="dl-title w4e">顧客{{i+1}}</dt>
          <dd class="js-listRowName">{{cust?.last_name}}{{cust?.first_name}}（{{cust?.last_kname}}{{cust?.first_kname}}）</dd>
        </dl>
        <dl class="flex-box j-content-start a-items-start">
          <dt class="dl-title w4e">メール</dt>
          <dd>{{cust?.email}}</dd>
        </dl>
        <dl class="flex-box j-content-start">
          <dt class="dl-title w4e">電話</dt>
          <dd>{{cust?.tel}}</dd>
        </dl>
        <dl class="flex-box j-content-start a-items-start">
          <dt class="dl-title w4e">住所</dt>
          <dd>〒{{cust?.zip}}<br>
          {{cust?.addr1}}{{cust?.addr2}}{{cust?.addr3}}<br>
          {{cust?.addr4}}</dd>
        </dl>
      </div><!--/lits-cont-box-->

      <div class="list-even-box sendlist event_detail" style="align-self: center; margin-right: 10px;">
        <div class="lits-change-box">
          <button type="button" data-target="modal-message" class="btn-trash js-trashBtnCust"
            (click)="setDelTarget(i, cust.objectID)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 54">
              <path class="icon-color" d="M50,8h-4h-7.9l-5-8H17.9l-5,8H5H1C0.4,8,0,8.4,0,9s0.4,1,1,1h4v39c0,2.8,2.2,5,5,5h31c2.8,0,5-2.2,5-5V10h4c0.6,0,1-0.4,1-1S50.6,8,50,8z M19.1,2h12.9l3.8,6H15.3L19.1,2z M44,49c0,1.7-1.3,3-3,3H10c-1.7,0-3-1.3-3-3V10h4.7h27.6H44V49z"/>
              <path class="icon-color" d="M18.5,18c-0.6,0-1,0.4-1,1v25c0,0.6,0.4,1,1,1s1-0.4,1-1V19C19.5,18.4,19.1,18,18.5,18z"/>
              <path class="icon-color" d="M32.5,18c-0.6,0-1,0.4-1,1v25c0,0.6,0.4,1,1,1s1-0.4,1-1V19C33.5,18.4,33.1,18,32.5,18z"/>
            </svg>
          </button>
        </div>
      </div><!--/list-even-box-->

    </div><!--/order-item-list-->

  </div>


  <!-- ONE-Wゲストリスト連携 -->
  <div *ngIf="onew_link && onewBaseKey" style="padding-top: 100px;">
    <div style="font-weight: 600; padding: 10px;">ONE-Wをご利用のお客様はこちらからONE-Wに登録済みのゲストリストをこの挙式情報に取り込むことができます。</div>
    <div style="margin: 10px 15px; padding: 15px; border: 1px solid #ccc; border-radius: 5px;">
      <div class="lits-cont-box sendlist item_center p10">

        <dl class="flex-box j-content-start a-items-end">
          <dt class="dl-title w18e">
            <div class="input-box-title">
              <div class="title-required">
                <span>ONE-W 顧客No.</span>
                <span class="text-frame-s">必須</span>
              </div>
            </div>
          </dt>
          <dd class="js-listRowName">
            <div class="input-box" style="max-width: 150px;">
              <input class="input-column input-size-s" type="text" placeholder="0000001" name="onewCustomerNo" size="20"
                maxlength="100" [(ngModel)]="onewCustomerNo"/>
            </div>
          </dd>
        </dl>

        <dl class="flex-box j-content-start a-items-end" style="margin: 15px 0;">
          <dt class="dl-title w18e">
            <div class="input-box-title">
              <div class="title-required">
                <span>ONE-W 拠点No.</span>
                <span class="text-frame-s">必須</span>
              </div>
            </div>
          </dt>
          <dd class="js-listRowName">
            <div class="input-box" style="max-width: 150px;">
              <input class="input-column input-size-s" type="text" placeholder="0000001" name="onewKyotenNo" size="20"
                maxlength="100" [(ngModel)]="onewKyotenNo"/>
            </div>
          </dd>
        </dl>

        <dl class="flex-box j-content-start a-items-end">
          <dt class="dl-title w18e">
            <div class="input-box-title">
              <div class="title-required">
                <span>ONE-W 新郎新婦様ログインID</span>
                <span class="text-frame-s">必須</span>
              </div>
            </div>
          </dt>
          <dd class="js-listRowName">
            <div class="input-box" style="max-width: 150px;">
              <input class="input-column input-size-s" type="text" placeholder="0000001" name="onewLoginID" size="20"
                maxlength="100" [(ngModel)]="onewLoginID"/>
            </div>
          </dd>
        </dl>

      </div>

      <div style="margin-top: 60px;">
        <div class="flex-box j-content-center">
          <button type="button" class="btn-item" (click)="callGuestImportCheck()"
            [disabled]="list_import_status===1 || event.status===event_status.UNSUBSCRIBED || !onewCustomerNo || !onewKyotenNo || !onewLoginID" >
              <span>ONE-Wゲストリストを取り込む</span>
          </button>
          <button type="button" class="btn-item url-copy size-l"
            [class.m10]="event.status!=event_status.UNSUBSCRIBED" [class.right]="event.status!=event_status.UNSUBSCRIBED"
            (click)="onewCustomerNo='';onewKyotenNo='';onewLoginID='';"><span>クリア</span></button>
          <div *ngIf="event?.status!=event_status.UNSUBSCRIBED" class="m10 left" style="text-align: center;">
            <a (click)="guestsOpen()" class="a-style"><span>ゲストリストを確認する</span></a>
          </div>
        </div>
        <div style="padding: 15px 5px; text-align: center;">
          <span *ngIf="list_import_status===1 || event?.status===event_status.UNSUBSCRIBED" class="cap red">
            こちらの挙式はT-PORTで挙式ステータスが無効、または既にゲストリストが1件以上存在するため<br>ONE-Wゲストリスト取り込みをご利用いただけません。<br><br>
          </span>
          <span *ngIf="list_import_status===1 || event?.status===event_status.UNSUBSCRIBED" class="cap black">
            挙式が有効でゲストリストが未登録であることをご確認の上、再度実行してください。<br>
            （登録済みのゲストリストの削除は新郎新婦様のみ実行可能です）
          </span>
          <span *ngIf="list_import_status===2" class="cap">取り込みが完了しました。</span>
          <span *ngIf="list_import_status===3" class="cap red">取り込みが失敗しました。運営会社までお問い合わせください。</span>
        </div>
      </div>

    </div>
  </div>

</div><!--/content-block-->

<!-- モーダルエリア（QRコード） テンプレート -->
<ng-template #modal>
  <app-modal>
    <div class="modal_header">
      <div class='title'>【{{event.date.toDate() | date}}】{{event.name}}</div>
    </div>
    <app-event-qr [url]="url"></app-event-qr>
  </app-modal>
</ng-template>

<app-modal-message (type_msg_event)="onModalTypeMsgEvent($event)"></app-modal-message>
<app-spinner *ngIf="!ready"></app-spinner>
