import { EventSearch } from './event_search';

export interface IEventSearchResult {
  page: number;
  nbHits: number;
  nbPages: number;
  hitsPerPage: number;
  exhaustiveNbHits: boolean;
  event_search: EventSearch[];
}
export class EventSearchResult implements IEventSearchResult {
  page: number = 0;
  nbHits: number = 0;
  nbPages: number = 0;
  hitsPerPage: number = 0;
  exhaustiveNbHits: boolean = false;
  event_search: EventSearch[] = [];
}
