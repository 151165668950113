import { Inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LOCAL_STORAGE,  StorageService } from 'ngx-webstorage-service';

import { Workbook } from 'exceljs';
import fs from 'file-saver';
import firebase from 'firebase/compat/app';
import 'firebase/functions';

import { SessionService } from './session.service';

import { ProcessResult } from '../models/process_result';
import { ProcessResultGuests } from '../models/process_result_guests';
import { Mail } from '../models/mail';
import { Guest } from '../models/guest';
import { TermsAndPolicy } from '../models/terms_and_policy';
import { Session } from '../models/session';
import * as constant from '../models/constant';

import moment from 'moment';

declare const debugLog: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private session: Session = new Session();
  private updated_user: {uid: string, name: string} = {uid: '', name: ''};

  constructor(
    private sv_session: SessionService,
    private firestore: AngularFirestore,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
  ) {

  }

  /**
   * 更新ユーザー設定
   *
   * @param {Session} session
   * @memberof CommonService
   */
  initialize(session: Session): void {
    if (!session || !session.data) return;
    this.session = session;
    this.updated_user = {
      uid: session.data.uid,
      name: session.data.last_name + session.data.first_name
    };
  }

  /**
   * メール送信
   *
   * @param {Mail} mail
   * @returns {Observable<ProcessResult>}
   * @memberof CommonService
   */
  sendMail(mail:Mail): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const func = firebase.app().functions('asia-northeast1').httpsCallable('sendEmailMessageV2');

    func({
      subject: mail.subject,
      title: mail.title,
      name: mail.name,
      email: mail.email,
      message: mail.message,
      bcc: mail.bcc
    })
    .then(() => {
      result_subject.next({result: true, msg: constant.MSG_MAIL_SUCCESSED, id: null});
      debugLog('完了メール送信 成功');
    })
    .catch(error => {
      result_subject.next({result: false, msg: constant.MSG_MAIL_FAILED, id: null});
      debugLog('完了メール送信 失敗 ', error);
    });
    return result;
  }

  /**
   * ゲストリストCSVダウンロード
   *　(UTF-8)
   * @param {Guest[]} guests
   * @param {string} event_name
   * @returns {boolean}
   * @memberof CommonService
   */
  // guestsDownload(guests: Guest[], event_name: string): void {
  //   let csv = 'last_name, first_name, last_kname, first_kname, zip, addr1, addr2, addr3, addr4, tel, email, \r\n';
  //   guests.forEach(guest => {
  //     csv +=
  //       '"' + guest.last_name + '",' +
  //       '"' + guest.first_name + '",' +
  //       '"' + guest.last_kname + '",' +
  //       '"' + guest.first_kname + '",' +
  //       '"' + guest.zip + '",' +
  //       '"' + guest.addr1 + '",' +
  //       '"' + guest.addr2 + '",' +
  //       '"' + guest.addr3 + '",' +
  //       '"' + guest.addr4 + '",' +
  //       '"' + guest.tel + '",' +
  //       '"' + guest.email + '",' +
  //       '\r\n';
  //   });
  //   let bom = "\uFEFF";
  //   let blob = new Blob([bom, csv], {"type": "text/csv"});
  //   const name = event_name + "_ゲストリスト";
  //   const anchor: any = document.createElement('a');

  //   // IE
  //   if (window.navigator.msSaveBlob) {
  //     window.navigator.msSaveBlob(blob, name);
  //   }
  //   // Chrome, Firefox
  //   else if (window.URL && anchor.download !== undefined) {
  //     anchor.download = name;
  //     anchor.href = window.URL.createObjectURL(blob);
  //     document.body.appendChild(anchor);
  //     anchor.click();
  //     anchor.parentNode.removeChild(anchor);
  //   }
  //   // その他
  //   else {
  //     window.location.href = 'data:attachment/csv;charset=utf-8,' + encodeURIComponent(bom);
  //   }
  // }

  /**
   * ゲストリストダウンロード
   * (Excel)
   * @param {Guest[]} guests
   * @param {string} event_name
   * @returns {boolean}
   * @memberof CommonService
   */
  guestsDownloadExcel(guests: Guest[], event_name: string): void {
    const file_name = event_name + "_ゲストリスト.xlsx";
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('ゲストリスト');
    // let header: string[] = ["last_name", "first_name", "last_kname", "first_kname", "zip", "addr1", "addr2", "addr3", "addr4", "tel", "email"];
    let header: string[] = ["姓", "名", "姓カナ", "名カナ", "郵便番号", "住所", "電話番号", "メールアドレス"];
    worksheet.addRow(header);
    let data: string[][] = [];
    guests.forEach(guest => {
      let guest_data: string[] = [
        guest.last_name,
        guest.first_name,
        guest.last_kname,
        guest.first_kname,
        guest.zip,
        `${guest.addr1}${guest.addr2}${guest.addr3} ${guest.addr4}`,
        guest.tel,
        guest.email
      ];
      data.push(guest_data);
      worksheet.addRow(guest_data);
    });
    // セル幅調整
    header.forEach((col: string, i: number) => {
      if (i == 5) {
        worksheet.getColumn(i+1).width = 50; // 住所
      } else if (i == 6 || i == 7) {
        worksheet.getColumn(i+1).width = 20; // 電話番号、メールアドレス
      }else {
        worksheet.getColumn(i+1).width = 10; // その他
      }
    });
    // 出力
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      fs.saveAs(blob, file_name);
    });
  }

  /**
   * 商品検索条件・結果を削除
   *
   * @memberof CommonService
   */
  clearItemSearch(): void {
    this.local_storage.remove(constant.STORAGE_LOCAL_ITEM_SEARCH_KEY);
    this.local_storage.remove(constant.STORAGE_LOCAL_ITEM_SEARCH_CATEGORY);
    this.local_storage.remove(constant.STORAGE_LOCAL_ITEM_SEARCH_PRICE_RANGE);
    this.local_storage.remove(constant.STORAGE_LOCAL_ITEM_SEARCH);
    this.sv_session.getItemSearchKeyword();
    this.sv_session.getItemSearchCategory();
    this.sv_session.getItemSearchPriceRange();
    this.sv_session.getItemSearchList();
  }

  /**
   * URL文字列をリンクに変換
   *　（商品説明本文内のリンク文字列を機能するリンクに変換して表示する）
   * @param {string} str
   * @returns {string}
   * @memberof CommonService
   */
  getActiveLink(str: string): string {
    // 下記はSafariで何も表示できなくなる。ログイン画面すら出なくなるので注意。Safariは正規表現の後読み(Lookbehind)を許容せずエラーになるから。
    // const exp = /((?<!href="|href='|src="|src=')(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return str.replace(exp, "<a href='$1' target='_blank' rel='noopener noreferrer'>$1</a>");
  }

  /**
   * プライバシーポリシー 取得
   *
   * @returns {Observable<TermsAndPolicy | null>}
   * @memberof CompanyService
   */
  getPolicy(): Observable<TermsAndPolicy | null> {
    const terms = this.firestore.doc<TermsAndPolicy>('terms/policy').snapshotChanges().pipe(
      map(doc => {
        if (!doc.payload.exists) {
          return null;
        }
        let obj = {
          objectID: doc.payload.id,
          body: doc.payload.data().body
        }
        return obj;
      })
    );
    return terms.pipe(
      catchError(this.handleError<TermsAndPolicy | null>(`getPolicy`, null))
    )
  }

  /**
   * ONE-Wゲストリスト取得

   * @param {string} event_key
   * @param {string} baseKey // ONE-W共通キー（PIEMにて得意先別に発行しタイムレスに共有される。それをDBで管理）
   * @param {string} customerNo // ONE-W顧客No（得意先No)
   * @param {string} kyotenNo // ONE-W拠点No（会場No)
   * @param {string} loginID // ONE-WログインID（新郎新婦ログインID）
   * @returns {Observable<ProcessResultGuests>}
   * @memberof CommonService
   */
  getOneWGuests(event_key: string, baseKey: string, customerNo: string, kyotenNo: string, loginID: string): Observable<ProcessResultGuests> {
    let result_subject: Subject<ProcessResultGuests> = new Subject();
    let result: Observable<ProcessResultGuests> = result_subject.asObservable();
    const func = firebase.app().functions('asia-northeast1').httpsCallable('getOneWGuestsV2');

    func({
      baseKey: baseKey,
      customerNo: customerNo,
      kyotenNo: kyotenNo,
      loginID: loginID
    })
    .then(response => {
      let guest_list: Guest[] = [];
      let res = JSON.parse(response.data); // メモ：戻り値はJSONオブジェクトの想定（function側でstringify）
      debugLog("===============");
      debugLog(res);

      if (res.result === "failed") { // APIリクエストまでの処理で失敗
        throw new Error(res.msg + res.data);
      } else if (res.result === "failed_api") { // APIレスポンスでエラー返却（※リクエスト自体は成功しstatusも200で戻るが、APIから「内部エラー」等のエラーメッセージが返された場合）
        throw new Error(res.msg + res.data.errordetail);
      }

      res.data.guest.forEach((
        guest: {last_name: string, first_name: string, last_kname: string, first_kname: string, zip: string, addr1: string, addr2: string, tel: string},
        g: number
      ) => {
        // ONE-Wの住所1、住所2を変換
        //   住所1は都道府県のみ、住所2は都道府県以降の住所とする。都道府県が特定できない場合、住所1は空、住所2に全ての住所を入れる。
        let addr1: string = this.getPrefName(guest.addr1);
        let addr2: string = "";
        if (addr1.length) {
          addr2 = guest.addr1.replace(addr1, "") + guest.addr2;
        } else {
          addr1 = "";
          addr2 = guest.addr1 + guest.addr2;
        }

        let obj: Guest = {
          objectID: '',
          first_name: guest.first_name ? guest.first_name : '',
          last_name: guest.last_name ? guest.last_name : '',
          first_kname: guest.first_kname ? guest.first_kname.replace(/[\u3042-\u3093]/g, s => String.fromCharCode(s.charCodeAt(0) + 96)) : '', // カナ変換
          last_kname: guest.last_kname ? guest.last_kname.replace(/[\u3042-\u3093]/g, s => String.fromCharCode(s.charCodeAt(0) + 96)) : '', // カナ変換
          zip: guest.zip ? guest.zip.replace(/-/g, '') : '', // ハイフン除去 : '',
          addr1: addr1, // 都道府県
          addr2: addr2, // 都道府県以降の住所
          addr3: '',
          addr4: '',
          tel: guest.tel ? guest.tel.replace(/-/g, '') : '', // ハイフン除去
          email: '',
          event_id: event_key,
          giftbox_ids: [],
          group_id: '',
          deliv_date: new Date(0),
          deliv_fee: 0,
          card: 0,
          noshi: 0,
          created: new Date(),
          updated: new Date(),
          update_user: this.updated_user,
          group_updated: new Date(0),
          giftbox_updated: new Date(0),
          delete_flg: false,
          giftbox$: [],
          group_name$: of('')
        }
        guest_list[g] = obj;

        if (g === res.data.guest.length -1) {
          result_subject.next({result: true, msg: `${res.data.guest.length}件のデータを取得しました。`, id: null, guests: guest_list});
        }
      });
    })
    .catch(error => {
      result_subject.next({result: false, msg: error, id: null, guests: null});
    });
    return result;
  }

  public getPrefName(str: string): string {
    let result = "";
    let tmp =  constant.PREF_LIST.filter(pref => str.indexOf(pref) != -1);
    if (tmp.length) {
      result = tmp[0];
    }
    return result;
  }

  /**
   * Firestoreから取得したTimestamp型の日付データをDate型に変換
   *
   * @param {*} timestamp
   * @returns {Date}
   */
  convertTimestampToDate(timestamp: any): Date {
    return timestamp?.toDate ? timestamp.toDate() : timestamp;
  }

  /**
   * 失敗したHttp操作を処理します。
   * アプリを持続させます。
   * @param operation - 失敗した操作の名前
   * @param result - observableな結果として返す任意の値
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: リモート上のロギング基盤にエラーを送信する
      console.error(error); // かわりにconsoleに出力

      // TODO: ユーザーへの開示のためにエラーの変換処理を改善する
      // this.log(`${operation} failed: ${error.message}`);

      // 空の結果を返して、アプリを持続可能にする
      return of(result as T);
    };
  }

}
