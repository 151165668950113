//////////////////
//   定数クラス　
//////////////////

/****************/
//  ストレージキー
/****************/

// local
export const STORAGE_LOCAL_MNG_LOGIN: string = 't-port-mng_session'; // ログインセッション（アカウント情報）
export const STORAGE_LOCAL_MNG_TERMS_CONSENT: string = 't-port_mng_terms_consent'; // 個人情報保護法に基づく規約同意
// export const STORAGE_LOCAL_MNG_REGIST_TERMS_CONSENT: string = 't-port_mng_regist_terms_consent'; // 個人情報保護法に基づく規約同意（プランナー登録）
// export const STORAGE_LOCAL_REP_REGIST_TERMS_CONSENT: string = 't-port_rep_regist_terms_consent'; // 個人情報保護法に基づく規約同意（代表者登録）
export const STORAGE_LOCAL_GIFTBOX: string = 'selected_giftbox'; // 選択中のギフトセットID
export const STORAGE_LOCAL_GIFTBOX_AREA_STATUS: string = 'giftbox_area_status'; // ギフトセットエリア開閉状態
export const STORAGE_LOCAL_GIFTBOX_INFO: string = 'giftbox_info'; // 選択しているギフトセット情報（名称・商品数・贈る相手数）
export const STORAGE_LOCAL_PLACE_INFO: string = 'place_info'; // 選択している会場情報（名称・メンバー数）
export const STORAGE_LOCAL_EVENT_INFO: string = 'event_info'; // 選択している挙式情報
export const STORAGE_LOCAL_COMPANY_INFO: string = 'company_info'; // 選択している得意先

export const STORAGE_LOCAL_ORDER_LIST: string = 'order_list'; // 注文情報（注文一覧画面のデフォルトの値。ログインユーザーが担当の注文情報一覧）
export const STORAGE_LOCAL_ORDER_BASE_INFO: string = 'order_base_info'; // 注文基本情報（注文一覧から選択した個別の注文情報。）
export const STORAGE_LOCAL_ORDER_INFO: string = 'order_info'; // 注文基本情報（注文一覧から選択した個別の注文情報）
export const STORAGE_LOCAL_ORDER_DETAIL: string = 'order_detail'; // 注文詳細情報（注文一覧から選択した個別の注文情報詳細。注文内の全てのギフトセット）
export const STORAGE_LOCAL_EVENT_ORDER_TOTAL_PRICE: string = 'event_order_total_price'; // 施行の全注文の総額（商品金額、送料）
export const STORAGE_LOCAL_ORDER_DETAIL_GIFTBOX: string = 'order_detail_giftbox'; // 注文詳細情報（注文一覧から選択した個別の注文情報詳細。注文内の選択されたギフトセット）
export const STORAGE_LOCAL_ORDER_DETAIL_GUEST_CHANGE: string = 'order_detail_guest_change'; // 注文情報ゲスト差分（直近でタイムレスに発注された内容との差分）

// session
export const STORAGE_LOCAL_ITEM_SEARCH_KEY: string = 'search_key_items'; // 商品検索キーワード
export const STORAGE_LOCAL_ITEM_SEARCH_CATEGORY: string = 'search_category'; // 商品検索カテゴリー
export const STORAGE_LOCAL_ITEM_SEARCH_PRICE_RANGE: string = 'search_price_range'; // 商品検索 価格帯
export const STORAGE_LOCAL_ITEM_SEARCH: string = 'search_items'; // 商品検索結果
export const STORAGE_LOCAL_SCROLL_POINT: string = 'scroll_point'; // 画面のスクロールポイント

/****************/
//  メッセージ系
/****************/

// ログイン・ログアウト
export const MSG_LOGIN_SUCCESS: string = "ログインしました。"; // メッセージは非表示
export const MSG_LOGOUT: string = "ログアウトしました。";
export const MSG_LOGIN_FAILURE_MISMATCH: string = "ログインできませんでした。IDまたはパスワードが一致しません。";
export const MSG_LOGIN_FAILURE_NOT_USER: string = "ログインできませんでした。ユーザーが存在しません。";
export const MSG_LOGIN_FAILURE_INVALID_USER: string = "ログインできませんでした。無効なユーザーです。";
export const MSG_LOGIN_FAILURE_NOT_ACCOUNT: string = "ログインできませんでした。\n有効なアカウント情報が存在しません。";
export const MSG_LOGIN_FAILURE_NOT_EVENT: string = "ログインできませんでした。挙式情報がありません。";
export const MSG_LOGIN_FAILURE_OTHER: string = "ログインできませんでした。";
// 登録・更新
export const MSG_SET_SUCCESSED: string = "登録が完了しました。";
export const MSG_SET_FAILED: string = "登録が失敗しました。";
export const MSG_ORDER_SUCCESSED: string = "発注が完了しました。";
export const MSG_ORDER_FAILED: string = "発注が失敗しました。";
export const MSG_SET_FAILED_LIMIT_OVER: string = "注文期限が超過しているため登録を中止しました。";
export const MSG_SET_FAILED_NOT_TRANSFER_ADDR: string = "不在時転送先が未登録です。処理を中止しました。";
export const MSG_SET_FAILED_NOT_CUST_REGIST: string = "新郎様または新婦様のいずれかが未登録です。処理を中止しました。";
export const MSG_PART_OF_SET_FAILED: string = "一部のデータの登録が失敗しました。";
export const MSG_UPDATE_SUCCESSED: string = "更新が完了しました。";
export const MSG_UPDATE_FAILED: string = "更新が失敗しました。";
export const MSG_PART_OF_UPDATE_FAILED: string = "一部のデータの更新が失敗しました。";
export const MSG_PROCESS_FAILED: string = "問題が発生したため処理を中止しました。";
// 取得
export const MSG_SELECT_FAILED: string = "情報の取得に失敗しました。";
export const MSG_SELECT_NONE: string = "該当する情報がありませんでした。";
// ゲスト自身によるゲスト登録処理用（QRコードからアクセス）
export const MSG_SET_REGIST_GUEST_SUCCESSED: string = "列席者の登録が完了いたしました。ありがとうございました。";
export const MSG_SET_REGIST_GUEST_FAILED: string = "列席者を登録できませんでした。大変申し訳ございませんがしばらくたってから再度ご登録をお願いいたします。";
export const MSG_SET_REGIST_SUCCESSED: string = "登録が完了いたしました。ありがとうございました。";
export const MSG_SET_REGIST_FAILED: string = "大変申し訳ございません。登録が失敗しました。";
export const MSG_SET_REGIST_FAILED_EMAIL_DUPLICATE: string = "大変申し訳ございません。このメールアドレスは既に存在するため登録できません。";
export const MSG_SET_REGIST_CUST_EXIST_FAILED: string = "既に登録されています。登録内容の変更はT-PORTへログインし「アカウント」から行ってください。";
export const MSG_SET_REGIST_TOKEN_FAILED: string = "このURLは正しくありません。\nURLをお確かめの上、再度アクセスしてください。";
export const MSG_SET_REGIST_TOKEN_FAILED_FOR_MNG: string = "このURLは正しくないか有効期限が切れています。\n顧客登録期限を設定しなおしてください。";
export const MSG_SET_REGIST_TOKEN_FAILED_FOR_CUST: string = "QRコードおよびURLは、正しくないか有効期限が切れているため表示できません。担当プランナーまでお問い合わせください。";
export const MSG_SET_REGIST_FAILED_NO_EVENT: string = "対象の挙式情報が見つかりません。登録処理を中止しました。";
export const MSG_SET_STAFF_REGIST_FAILED: string = "対象の会場情報が見つかりません。登録処理を中止しました。";
export const MSG_SET_REGIST_FAILED_TIMEOUT: string = "一定時間が経過したため処理を中止しました。更新ボタンを押してください。";
// ギフトセット カート追加前チェック
export const MSG_GIFTBOX_CART_IN_CHECK_FAILED_GUEST = "贈る相手の納品日・カード・名前は必須です。";
export const MSG_GIFTBOX_CART_IN_CHECK_FAILED_KEEPSAKE: string = "ギフトセットに記念品が含まれていません。記念品は1品以上必ず選んでください。";
export const MSG_GIFTBOX_CART_IN_CHECK_FAILED_NUM: string = "ギフトセットの商品数が0点、または贈る相手が0名のためカートに追加できません。";
export const MSG_GIFTBOX_CART_IN_CHECK_CONFIRM = "ギフトセットの商品合計額（税抜）が5000円未満のため、お届け1箇所につき別途送料800円（税抜）がかかります。<br/>このまま処理を続けてもよろしいですか。";
export const MSG_GIFTBOX_CART_IN_SUCCESS: string = "ギフトセットをカートに追加しました。";
export const MSG_GIFTBOX_CART_IN_FAILED: string = "ギフトセットをカートに追加できませんでした。";
// パスワード変更・メールアドレス変更後のメール通知の案内
export const MSG_INFO_MAIL_CHANGE_EMAIL: string = "メールアドレスを変更しました。";
export const MSG_INFO_MAIL_CHANGE_PASSWORD: string = "現在ご登録のメールアドレスにメールアドレス変更のご案内を送信しました。案内に従い変更の手続きを行って下さい。";
export const MSG_INFO_MAIL_CHANGE_EMAIL_FAILED: string = "メールアドレス変更の処理を開始できませんでした。運営事務局までお問い合わせ下さい。";
export const MSG_INFO_MAIL_CHANGE_EMAIL_FAILED2: string = "ログインID（メールアドレス）を安全に変更するには、本人確認のために再度ログインしてから操作を行ってください。";
export const MSG_INFO_MAIL_CHANGE_PASSWORD_FAILED: string = "パスワード変更の処理を開始できませんでした。運営事務局までお問い合わせ下さい。";
// 注文（顧客）
export const MSG_ORDER_CONFIRM: string = "選択したギフトセットを注文します。よろしいですか。";
export const MSG_ERR_ORDER_CONFIRM: string = "注文するギフトセットを1つ以上選択してから再度実行してください。";
export const MSG_ERR_ORDER_CONFIRM_INVALID_DATA: string = "商品数か贈る相手数が0件のギフトセットが含まれているため処理を中止しました。";
export const MSG_CONFIRM_ORDER_GUEST_DEL: string = " を削除すると、ギフトセットの再注文（または注文キャンセル）が自動で行われます。<br>削除してもよろしいですか。";
export const MSG_ORDER_GUEST_CHANGE: string = "このゲストに割り当てられたギフトセットは変更内容を反映して再注文されました。";
// 注文（会場）
export const MSG_ORDER_CONFIR_MNG: string = "この情報で発注します。よろしいですか。";
// 商品メッセージ
export const ITEM_ATTENTION_MSG_SHORT = { // 商品一覧用
  'vol': '以降の挙式限定',
  'prev_vol': 'より前の挙式限定'
};
export const ITEM_ATTENTION_MSG = { // 商品詳細用
  'vol': '以降の挙式のお客様限定の商品となります。',
  'prev_vol': 'より前の挙式のお客様限定の商品となります。'
};
// その他
export const MSG_ERR_GIFTBOX_SELECT: string = "ギフトセットを選択してから再度実行してください。";
export const MSG_ERR_GIFTBOX_CREATE: string = "同じ名前のギフトセットが既に存在します。<br>別の名前で作成してください。";
export const MSG_ERR_GUEST_SELECT: string = "対象となるお客様を1名以上選択してから再度実行してください。";
export const MSG_ERR_ORDERED_GUEST_SELECT: string = "既に注文済みのギフトセットに割り当てられているお客様をこのギフトセットに割り当てることはできません。<br/>先に注文済みのギフトセットを変更し再注文を行ってください。";
export const MSG_ERR_GIFTBOX_EXISTS: string = "この商品は既に登録されています。";
export const MSG_ERR_STAFF_EVENT_EXISTS: string = "担当している挙式があるためこのユーザーを削除できません。<br/>このユーザーを削除するには挙式担当者変更より担当を変更してください。";
export const MSG_CONFIRM_EVENT_UNSUBSCRIBE: string = "無効に設定し「保存」ボタンを押すとこの挙式で発注済みの注文は自動でキャンセルされます。無効に設定してもよろしいですか。<br/>なお、再び有効にしても注文の自動キャンセルは取り消せません。";

/****************/
//  メール系
/****************/
export const MSG_MAIL_SUCCESSED: string = "メール送信が完了しました。";
export const MSG_MAIL_FAILED: string = "メール送信が失敗しました。";

export const MAIL_SUBJECT_REGIST: string = "T-PORTのお客さま登録が完了いたしました";
export const MAIL_BODY_REGIST: string = "T-PORTにご登録いただき誠にありがとうございます。<br>下記のURLよりT-PORTにログインいただけます。<br>";

export const MAIL_SUBJECT_REGIST_INVI: string = "T-PORTへのご登録をお願いいたします";
export const MAIL_BODY_REGIST_INVI: string = "下記のURLよりT-PORTへのご登録をお願いいたします。<br>ご登録が完了次第、登録完了メールを送信させていただきますので<br>メールに記載のURLよりT-PORTにログインいただけます。";

// 新規発注・注文変更 通知 （プランナー・タイムレスギフトセンター向け）
export const MAIL_SUBJECT_ORDER: string = "新しい注文が入りました";
export const MAIL_BODY_ORDER: string = "下記の挙式のお客様から新しい注文が入りました。<br>詳細はT-PORTよりご確認ください。";
export const MAIL_SUBJECT_REORDER: string = "注文内容が変更されました";
export const MAIL_BODY_REORDER: string = "下記の挙式のお客様が注文内容を変更されました。<br>詳細はT-PORTよりご確認ください。";

// T-PORT 代表メール
export const MAIL_TPORT: string = 't-port-info@timeless-gift.co.jp';

/****************/
//  ユーザー系
/****************/

// ユーザー権限
export const ROLE = {
  'SYSTEM': 0, // システム
  'CUST': 1, // 顧客（新郎新婦）
  'MNG': 2, // 管理（会場）
  'ADMIN': 3  // 運営（タイムレス）
};
// 新郎新婦区分
export const CUST_TYPE = {
  'NONE': 0, // 設定なし（デフォルト）
  'GROOM': 1, // 新郎
  'BRIDE': 2 // 新婦
};
// 新郎新婦区分（選択メニュー用）
export const CUST_TYPE_SELECT = [
  {key: CUST_TYPE.GROOM, value: 'ご新郎', checked: false},
  {key: CUST_TYPE.BRIDE, value: 'ご新婦', checked: false}
];

/****************/
//  ステータス系
/****************/

// ユーザーステータス
export const USER_STATUS = {
  'NONE': 0, // 設定なし（デフォルト）
  'AVAILABLE': 1, // 利用可
  'NOT_AVAILABLE': 2 // 利用不可
};
// 注文情報リストのタイプ（注文一覧に表示されるリストは3つのタイプでデフォルト表示できる）
export const ORDER_LIST_TYPE = {
  'DEFAULT': 0,
  'NEW': 1, // 新規注文
  'CHANGE': 2, // 注文変更
};
// 挙式ステータス
export const EVENT_STATUS = {
  'DEFAULT': 0, // デフォルト
  'REGISTING': 1, // 顧客登録中
  'REGISTED': 2, // 顧客登録済み
  'ORDERED': 3, // 初回注文済み
  'FINISHED': 4, // 挙式済み
  'UNSUBSCRIBED': 5, // 退会済み（無効）
};
// 注文ステータス
// export const ORDER_STATUS2 = {
//   'DEFAULT': 0, // 顧客が注文する前の状態
//   'REQUEST': 1, // 発注依頼（顧客 → 会場）
//   'REQUEST_AGAIN': 2, // 発注修正（顧客 → 会場）
//   'ORDER': 3, // 発注（会場 → タイムレス）
//   'ORDER_ACCEPT': 4, // 受注（タイムレス）
//   'CANCEL_REQUEST': 5, // 注文キャンセル依頼（顧客 → 会場）
//   'ORDER_CANCEL': 6, // キャンセル発注（会場 → タイムレス）
//   'CANCEL': 7 // 注文キャンセル（タイムレス）
// };
export const ORDER_STATUS = {
  'DEFAULT': 0, // 顧客が注文する前の状態
  'REQUEST': 1, // 発注依頼（顧客 → 会場）
  'REQUEST_AFTER_ORDER': 2, // 発注修正（顧客 → 会場）※タイムレス発注後に変更
  'REQUEST_AFTER_ACCEPT': 3, // 発注修正（顧客 → 会場）※タイムレス受注後に変更
  'ORDER': 4, // 発注（会場 → タイムレス）
  'ORDER_AFTER_ACCEPT': 5, // 発注（会場 → タイムレス）※タイムレス受注後に変更されたものを再発注
  'ACCEPT': 6, // 受注（タイムレス）
  'CANCEL_REQUEST': 7, // 注文キャンセル依頼（顧客 → 会場）※タイムレス受注後に顧客がキャンセルした場合は会場にキャンセル依頼
  'CANCEL_ORDER': 8, // キャンセル発注（会場 → タイムレス）
  'CANCEL': 9, // 注文キャンセル（顧客がキャンセル）※タイムレス発注前のため会場を介さず顧客がキャンセル
  'CANCEL_BY_ADMIN': 10 // 注文キャンセル（タイムレスがキャンセル）※タイムレス発注後のため会場からのキャンセル発注を経てタイムレスがキャンセル
};

// 【顧客画面】　ギフトセットステータス表示名
export const CUST_GIFTBOX_STATUS_NAME = {
  'DEFAULT': '',
  'CART_IN': 'カート追加済み',
  'ORDERED': '注文済み',
  'CANCEL': '注文キャンセル'
};
// 【管理画面】 注文検索 注文ステータス
export const SEARCH_ORDER_STATUS = [
  {key: 'request', value: '新規注文', checked: false},
  {key: 'change', value: '変更注文', checked: false},
  {key: 'order', value: '発注', checked: false},
  {key: 'accept', value: '受注', checked: false},
  {key: 'cancel_order', value: 'キャンセル発注', checked: false},
  {key: 'cancel', value: 'キャンセル', checked: false}
];
// 商品ステータス
export const ITEM_STATUS = {
  'PRIVATE': 0, // 非公開
  'COMMINGSOON': 1, // 近日公開予定,
  'PUBLIC': 2, // 公開
  'SOLDOUT': 3, // ソールドアウト
  'DISCONTINUED': 4 // 廃番
};
// ゲスト選択
export const GUEST_SELECT = {
  'GROUP': 0,
  'GIFTBOX': 1,
};
// ギフトセットエリア開閉状態
export const GIFTBOX_AREA_STATUS = {
  'CLOSE': 0,
  'OPEN': 1,
};
// 検索タイプ（ヘッダー部検索エリアの種類）
export const SEARCH_TYPES = {
  'ITEM': 0,
  'GIFTBOX': 1,
  'GUEST': 2,
  'ORDER': 3,
  'EVENT': 4,
};

/****************/
//  商品系
/****************/
// 商品カテゴリータイプ
export const ITEM_CATEGORY_TYPE = {
  'KEEPSAKE': 'keepsake',
  'CATALOG': 'catalog',
  'SWEETS': 'sweets',
  'PLUS_ONE_ITEM': 'plusOneItem'
};
// 商品カテゴリー（検索チェック用）
export const ITEM_CATEGORY = [
  {key: 'keepsake', value: '記念品', checked: false},
  {key: 'catalog', value: 'カタログ', checked: false},
  {key: 'sweets', value: '引菓子', checked: false},
  {key: 'plusOneItem', value: 'プラスワンアイテム', checked: false}
];
export const ITEM_CATEGORY_KEYS = [
  'keepsake','catalog', 'sweets', 'plusOneItem'
];
// 商品価格帯（検索チェック用）
export const ITEM_PRICE_RANGE = [
  {key: 'range1', value: '〜 ¥2,999', checked: false},
  {key: 'range2', value: '¥3,000 〜 ¥4,999', checked: false},
  {key: 'range3', value: '¥5,000 〜 ¥9,999', checked: false},
  {key: 'range4', value: '¥10,000 〜', checked: false}
];
// 税率
export const TAX_RATE = {
  'REDUCED_TAX': 8,
  'TAX': 10
};
// 熨斗カード 名前 プルダウン用
export const NOSHI = [
  {key: 0, value: 'なし'},
  {key: 1, value: '新郎姓＋名前連名'},
  {key: 2, value: '新婦姓＋名前連名'},
  {key: 3, value: '両家の姓＋名前連名'},
  {key: 4, value: '名前のみ連名'}
];
// 熨斗カード 名前
export const NOSHI_OBJ = {
  0: 'なし',
  1: '新郎姓＋名前連名',
  2: '新婦姓＋名前連名',
  3: '両家の姓＋名前連名',
  4: '名前のみ連名'
};
// 熨斗カード メッセージ種類 プルダウン用
export const CARD_MESSAGE = [
  {key: 0, value: 'なし'},
  {key: 1, value: 'A'},
  {key: 2, value: 'B'}
];
// 熨斗カード メッセージ種類
export const CARD_MESSAGE_OBJ = {
  0: 'なし',
  1: 'A',
  2: 'B',
};

/****************/
//  入力パターン
/****************/
export const INPUT_PATTERN = {
  'PW': '^([a-zA-Z0-9!-/:-@¥[-`{-~]{6,})$',
  'KANA': '^[ァ-ンヴー・]*$',
  'ZIP': '^[0-9]{7}$',
  'TEL': '^[0-9\+]+$'
};

/****************************************/
//  configコレクションの固定ドキュメントID
/****************************************/
export const CONFIG_ORDER_ADJUST: string = 'order_adjust';  // 注文期限の調整

/****************/
//  その他
/****************/

//////// 送料 /////////
// 改訂前送料
export const DELIVE_FEE: number = 800; // 通常送料（送料込み）
export const DELIVE_FEE_ADDITIONAL: number = 850; // 送料別。商品額に関係なく一律850円
// 改訂後送料
export const NEW_DELIVE_FEE: number = 900; // 通常送料（送料込み）
export const NEW_DELIVE_FEE_ADDITIONAL: number = 900; // 送料別。商品額に関係なく一律900円
// 送料改定基準挙式日（この挙式日以降の挙式は新送料を適用）
export const NEW_DELIVE_FEE_DATE: string = '2023/12/01 00:00:00';

export const DELIVE_FEE_OKINAWA: number = 1500;
export const DELIVE_FEE_LIMIT_PRICE: number = 5000;

export const CAMPANY_DELIVE_FEE_TYPE = {
  'NOMAL': 0,
  'ADDITIONAL': 1
}
// 送料区分（選択メニュー用）
export const CAMPANY_DELIVE_FEE_TYPE_SELECT = [
  {key: CAMPANY_DELIVE_FEE_TYPE.NOMAL, value: '送料込み', checked: false},
  {key: CAMPANY_DELIVE_FEE_TYPE.ADDITIONAL, value: '送料別', checked: false}
];
// 税込み総額表示開始
export const TAXABLE_DATE: string = '2021/01/29';
// 一覧系画面（ページングあり）のページあたりの最大表示件数
export const LIST_ROW_MAX: number = 20;

// 都道府県リスト（ONE-Wゲストリスト連携時、ONE-Wの住所から都道府県を割り出すため使用）
export const PREF_LIST = [
  '北海道',
  '青森県',
  '岩手県',
  '秋田県',
  '宮城県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
  ];
