<div class="content-block content-block-max">
  <div class="content-block-inner">
    <div class="back-link"><a (click)="back()">戻る</a></div>

    <div class="flex-box">
      <div class="title-content mng mg-style2">贈る相手一覧<span class="cap" *ngIf="is_event">（この挙式の全注文の贈る相手）</span></div>
    </div>

    <div class="div">
      <div *ngIf="order_info.order_branch?.latest" class="flex-box j-content-end">
        <div *ngIf="limit_over && order_info.order_branch && (order_info.order_branch.status < status.ORDER || order_info.order_branch.status == status.CANCEL_REQUEST)" class="icon-note">
          <div class="text-red" style="margin-right: 10px;">発注期限超過</div>
        </div>
        <div class="state-change mgb0" *ngIf="order_info.order_branch?.status == status.REQUEST_AFTER_ORDER || order_info.order_branch?.status == status.REQUEST_AFTER_ACCEPT || order_info.order_branch?.status == status.CANCEL_REQUEST">
          注文変更
          <span class="caption" *ngIf="order_info.order_branch?.status == status.CANCEL_REQUEST">（キャンセル依頼）</span>
        </div>
        <div class="state-change black mgb0" *ngIf="order_info.order_branch?.status == status.ORDER || order_info.order_branch?.status == status.ORDER_AFTER_ACCEPT">発注済み</div>
        <div class="state-change black mgb0" *ngIf="order_info.order_branch?.status == status.ACCEPT">受注済み</div>
        <div class="state-change black mgb0" *ngIf="order_info.order_branch?.status == status.CANCEL || order_info.order_branch?.status == status.CANCEL_BY_ADMIN">注文キャンセル</div>
        <div class="text-frame bg-bk"><span class="cap white">注文最終確定：</span>{{order_info.order_limit | date}}</div>
      </div>
      <div *ngIf="!order_info.order_branch?.latest" class="flex-box j-content-end">
        <div class="state-change black mgb0">発注履歴</div>
      </div>
    </div>

    <div class="mg-style2">
      <div class="flex-box">
        <dl class="flex-box j-content-start text-m-style2">
          <dt class="dl-title">挙式日</dt>
          <dd>{{order_info.event_date | date}}</dd>
        </dl>
      </div>
      <div class="title-content"><a routerLink="/event-detail/{{order_info.event_id}}">{{order_info.event_name}}</a></div>
    </div>
    <div *ngIf="!is_event" class="order-list-name fz-l">{{order_detail_giftbox.order_giftbox.name}}</div>
  </div><!--/content-block-inner-->

  <!-- <div class="inner-header">
    <div class="result">贈り主</div>
  </div>
  <div class="list-wrap">
    <div class="order-item-list pd-style list-wrap-border-b">
      <div class="list-ind-box">
        <div class="list-ind-box-inner">
          <div class="order-list-name-wrap">
            <div class="order-list-name-ch">たいむ 太郎<span>（タイム タロウ）</span></div>
            <div class="order-list-add"><span>〒103-0001</span>東京都中央区日本橋小伝馬町1-1-1 小伝馬町ビル303</div>
            <div class="order-list-tel">090-9999-9999</div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="inner-header">
    <div class="result">不在時転送先</div>
  </div>
  <div class="list-wrap">
    <div class="order-item-list pd-style list-wrap-border-b">
      <div class="list-ind-box"><!-- 変更色class change-item -->
        <div class="list-ind-box-inner">
          <div class="order-list-name-wrap">
            <div class="order-list-name-ch">{{event.transfer_last_name}} {{event.transfer_first_name}}<span>（{{event.transfer_last_kname}} {{event.transfer_first_kname}}）</span></div>
            <div class="order-list-add"><span>〒{{event.transfer_zip}}</span>{{event.transfer_addr1}}{{event.transfer_addr2}}{{event.transfer_addr3}} {{event.transfer_addr4}}</div>
            <div class="order-list-tel">{{event.transfer_tel}}</div>
          </div>
        </div>
      </div>
    </div><!--/item-list-->
  </div><!--/list-wrap-->

  <div class="inner-header j-content-start">
    <div class="result">贈る相手：{{guests.length}}名</div>
    <div *ngIf="is_event" class="result">&nbsp;／ ギフトセット：{{order_detail_giftboxes.length}}種</div>
  </div>

  <div class="list-wrap">

    <div class="order-item-list pd-style" *ngFor="let guest of guests; index as i">
      <div class="list-ind-box" [class.change-item]="!is_event && guest_changes.length && guest_changes[i]">
        <div class="list-ind-box-inner">
          <div class="order-list-name-wrap">
            <div class="order-list-name-ch">{{guest?.last_name}} {{guest?.first_name}}<span>（{{guest?.last_kname}} {{guest?.first_kname}}）</span></div>
            <div class="order-list-add"><span>〒{{guest?.zip}}</span>{{guest?.addr1}}{{guest?.addr2}}{{guest?.addr3}}{{guest?.addr4}}</div>
            <div class="order-list-tel">{{guest?.tel}}</div>
          </div>

          <div>
            <div *ngIf="is_event" class="order-list-name-wrap">
              <div class="order-list-gb">ギフトセット：{{gb_names[i]}}</div>
            </div>
            <div>
              <div class="order-list-date">
                <dl class="flex-box j-content-start a-items-start">
                  <dt class="dl-title w4e display-sp">納品日</dt>
                  <dd>{{guest?.deliv_date | date}}</dd>
                </dl>
              </div>
              <div class="order-list-card">
                <dl class="flex-box j-content-start a-items-start">
                  <dt class="dl-title w4e display-sp">カード</dt>
                  <dd>{{cards[guest.card]}}</dd>
                </dl>
              </div>
              <div class="order-list-name-ch-d">
                <dl class="flex-box j-content-start a-items-start">
                  <dt class="dl-title w4e display-sp">名前</dt>
                  <dd>{{noshis[guest.noshi]}}</dd>
                </dl>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div><!--/item-list-->

  </div><!--/list-wrap-->

  <div class="content-footer-wrap only-pc">
    <div class="content-footer">
        <div class="content-footer-inner flex-box j-content-end a-items-end">
        <div class="box-odd">
            <button type="button" data-target="modal-confirmation" class="btn-pdf js-addCartBtn" [disabled]="guests.length<1" (click)="printPage()">
                <i class="material-icons">print</i>
                <div style="margin-left: 3px;">このページを印刷する</div>
            </button>
        </div>
        </div><!--/content-footer-inner-->
    </div><!--/content-footer-->
  </div><!--/content-footer-wrap-->

</div><!--/content-block-->
