<div class="content-block product-detail">
  <div class="product-block m50 t">
    <div class="product-box column-box">

      <!-- slick -->
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slide_conf">
        @if (!no_img_ready) {
          @for (img of images; track trackByImg) {
            <div ngxSlickItem class="slide">
              @if (img.url) {
                <img src="{{ img.url }}" alt="">
              } @else {
                <img src="{{ dummy_img_url }}" alt="">
              }
            </div>
          }
        } @else {
          <div ngxSlickItem class="slide">
            <img src="{{ dummy_img_url }}" alt="">
          </div>
        }
      </ngx-slick-carousel>

      <!-- slick -->
      <!-- <ngx-slick-carousel class="slide" #slickModal="slick-carousel" [config]="slide_conf">
        <div ngxSlickItem *ngFor="let img of images; trackBy: trackByImg" class="slide">
          <img *ngIf="img.url" src="{{ img.url }}" alt="">
          <img *ngIf="!img.url" src="{{ dummy_img_url }}" alt="">
        </div>
        <div ngxSlickItem *ngIf="no_img_ready" class="slide">
          <img src="{{ dummy_img_url }}" alt="">
        </div>
      </ngx-slick-carousel> -->

      <div class="product-info-wrap">
        <div class="product-info">
          <div class="item-name detail font-a bold">{{item.name}}</div>
          <div *ngIf="item?.status===status.SOLDOUT" class="soldout m10 b">SOLDOUT</div>
          <div *ngIf="item?.status===status.DISCONTINUED" class="soldout">現在お取扱なし</div>
          <div *ngIf="item_attention && item_attention.type" class="attention_msg" [class.blue]="item_attention.type==='prev_vol'">{{item_attention.msg}}</div>
          <div class="info-inner">
            <div *ngIf="!place_tax_rate?.rule" class="item-price" [class.no_giftbox_bt]="!role_cust">
              {{item.reduced_tax_flg ?
                ((item.price + Math.floor(item.price * rate.REDUCED_TAX / 100)) | currency : 'JPY') :
                ((item.price + Math.floor(item.price * rate.TAX / 100)) | currency : 'JPY')}}
              <span class="cap blank_l">(税込)</span>
            </div>
            <div *ngIf="place_tax_rate?.rule" class="item-price" [class.no_giftbox_bt]="!role_cust">
              {{((item.price + Math.floor(item.price * place_tax_rate.rate / 100)) | currency : 'JPY')}}
              <span class="cap blank_l">(税込)</span>
            </div>
            <button type="button" *ngIf="role_cust" class="favorite-btn detail js-favariteButton"
              [class.favorite-item]="item.favorite"
              [attr.data-current_favorite]="item.favorite"
              [attr.data-item_objectid]="item.objectID"
              (click)="updateFavorite($event)"
            ></button>
          </div>
          <div *ngIf="!place_tax_rate?.rule && item?.reduced_tax_flg" class="m5 t"><span class="cap blank_l">（※消費税{{rate.REDUCED_TAX}}%対象商品です）</span></div>
        </div><!--/product-info-->
        <div class="item-description text-m wrap">{{item.description}}</div>
        <dl class="model-block text-s">
          <dt>サイズ</dt>
          <dd>
            <span *ngIf="item?.size_w">縦 {{item.size_w}}</span>
            <span *ngIf="item?.size_d"><span *ngIf="item?.size_w"> × </span>横 {{item.size_d}}</span>
            <span *ngIf="item?.size_h"><span *ngIf="item?.size_d"> × </span>高さ {{item.size_h}}</span><span *ngIf="item?.size_w || item?.size_d || item?.size_h"> mm</span>
          </dd>
          <dt *ngIf="item?.size_diameter">直径</dt>
          <dd *ngIf="item?.size_diameter">{{item.size_diameter}} mm</dd>
          <dt *ngIf="item?.size_other">サイズ 他</dt>
          <dd *ngIf="item?.size_other">{{item.size_other}}</dd>
          <dt *ngIf="item?.set">セット内容</dt>
          <dd *ngIf="item?.set" class="item-set wrap">{{item.set}}</dd>
          <dt>BOX</dt>
          <dd>
            <span *ngIf="item?.box_size_w">縦 {{item.box_size_w}}</span>
            <span *ngIf="item?.box_size_d"><span *ngIf="item?.box_size_w"> × </span>横 {{item.box_size_d}}</span>
            <span *ngIf="item?.box_size_h"><span *ngIf="item?.box_size_d"> × </span>高さ {{item.box_size_h}}</span><span *ngIf="item?.size_w || item?.size_d || item?.size_h"> mm</span>
          </dd>
          <dt *ngIf="item?.expire_date">賞味期限</dt>
          <dd *ngIf="item?.expire_date">{{item.expire_date}}</dd>
          <dt *ngIf="item?.allergy">アレルギー</dt>
          <dd *ngIf="item?.allergy">{{item.allergy}}</dd>
          <dt>商品番号</dt>
          <dd>No.{{item.beare_code}}</dd>
          <dt *ngIf="item.brand.brand_name">ブランド名</dt>
          <!-- <dd><a data-target="modal-brand1" class="modal-open a-style js-modalBrandLink">{{item?.brand.brand_name}}</a></dd> -->
          <dd *ngIf="item.brand.brand_name">{{item.brand.brand_name}}</dd>
          <dt *ngIf="item?.note">備考</dt>
          <dd>{{item.note}}</dd>
        </dl><!--/model-block-->

      </div><!--/product-info-wrap-->

    </div><!--/product-box-->
  </div><!--/product-block-->
</div><!--/content-block-->

<app-modal-message></app-modal-message>
