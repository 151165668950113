export interface IOrderGiftbox {
    objectID: string;
    name: string;
    price: number; // 上代合計
    deliv_fee: number; // 送料合計
    tax_target_price: number; // 通常税率対象の上代合計
    reduced_tax_target_price: number; // 軽減税率対象の上代合計
    tax: number; // 通常税率消費税
    reduced_tax: number; // 軽減税率消費税
    deliv_fee_tax: number; // 送料消費税
    unit_price: number; // 上代単価（１名あたりのギフトセット金額）
    unit_tax: number; // 1名あたりの通常税率消費税
    unit_reduced_tax: number; // 1名あたりの軽減税率消費税
    created: Date;
    updated: Date;
    delete_flg: boolean;
}
export class OrderGiftbox implements IOrderGiftbox {
    objectID: string = '';
    name: string = '';
    price: number = 0; // 上代合計
    deliv_fee: number = 0; // 送料合計
    tax_target_price: number = 0; // 通常税率対象の上代合計
    reduced_tax_target_price: number = 0;; // 軽減税率対象の上代合計
    tax: number = 0;; // 通常税率消費税
    reduced_tax: number = 0;; // 軽減税率消費税
    deliv_fee_tax: number = 0;; // 送料消費税
    unit_price: number = 0;; // 上代単価（１名あたりのギフトセット金額）
    unit_tax: number = 0;; // 1名あたりの通常税率消費税
    unit_reduced_tax: number = 0;; // 1名あたりの軽減税率消費税
    created: Date = new Date();
    updated: Date = new Date();
    delete_flg: boolean = false;
}
