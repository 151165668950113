import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, Subscription } from 'rxjs';

import { ModalComponent } from '../../common/modal/modal.component';
import { ItemDetailComponent } from '../../common/item-detail/item-detail.component';

import { SessionService } from '../../service/session.service';
import { ItemService } from '../../service/item.service';
import { ModalService } from '../../service/modal.service';
import { PlaceService } from '../../service/place.service';

import { Session } from '../../models/session';
import { OrderData } from '../../models/order_data';
import { OrderedGiftbox } from '../../models/ordered_giftbox';
import { OrderItem } from '../../models/order_item';
import * as constant from '../../models/constant';

import moment from 'moment';
import { environment } from '../../../environments/environment';

declare function resetCommonrEvent(): void;
declare const debugLog: any;

@Component({
  selector: 'app-order-detail-gift',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ModalComponent,
    ItemDetailComponent,
  ],
  templateUrl: './order-detail-gift.component.html',
  styleUrls: ['./order-detail-gift.component.scss']
})
export class OrderDetailGiftComponent implements OnInit {

  private session: Session = new Session();
  public order_info: OrderData = new OrderData();
  public status = constant.ORDER_STATUS;
  private order_detail: OrderedGiftbox[] = [];
  public total_price: {price: number, deliv_fee: number, contain_untaxable_order: boolean} = {price: 0, deliv_fee: 0, contain_untaxable_order: false};
  public order_items: OrderItem[] = [];
  private today: Date = moment(new Date().setHours(0,0,0,0)).toDate(); // 現在日付（発注期限超過判定のため時間は考慮しない）
  public limit_over: boolean = false;
  public taxable: boolean = true;  // 税込総額表示の対象（2021/1/29以前に生成された注文データは税額のデータを持っていないため総額表示の対象外とする）
  private taxable_limit: Date = moment(new Date(constant.TAXABLE_DATE).setHours(0,0,0,0)).toDate();
  public is_event: boolean = false;
  public dummy_thumb_s_url = environment.NO_IMAGE_THUMB_S;
  public rate = constant.TAX_RATE;
  public Math = Math;
  public show_item_detail: boolean = false;
  public select_item_key: string = '';
  public place_tax_rate: {rule: boolean, rate: number} = {rule: false, rate: 0};
  private subscription: Subscription = new Subscription();

  constructor(
    private sv_session: SessionService,
    private sv_item: ItemService,
    private sv_modal: ModalService,
    private sv_place: PlaceService,
    private route: ActivatedRoute,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.sv_modal.close_result$.subscribe(() => {
        this.show_item_detail = false;
      })
    );
    // ログインセッション取得
    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.session = session;
          if (session.data.current_place) {
            this.sv_place.getPlace(session.data.current_company, session.data.current_place).subscribe(place => {
              if (place) {
                this.place_tax_rate = place.place_tax_rate;
              }
            });
          }
        }
      })
    );

    this.subscription.add(
      this.route.params.subscribe(params => {
        this.is_event = params['type'] == 'event';
        this.sv_session.getOrderInfo();
        this.sv_session.orderInfo.subscribe(order_info => {
          this.order_info = order_info;
          if (order_info) {
            // 注文期限判定
            this.limit_over = moment(this.order_info.order_limit).unix() < moment(this.today).unix();
            // 総額表示判定
            if (this.order_info.order_branch) {
              this.taxable = moment(this.order_info.order_branch.order_request_date).unix() >= moment(this.taxable_limit).unix();
            }
          }
        });

        this.sv_session.getOrderDetail();
        this.sv_session.orderDetail.subscribe(order_detail => {
          this.order_detail = order_detail;
          this.setItemData(); // 商品リスト作成（重複する商品の数量は合算）
        });
        if (this.is_event) {
          this.sv_session.getEventOrderTotalPrice();
          this.sv_session.eventOrderTotalPrice.subscribe(total_price => {
            this.total_price = total_price;
          });
        }
      })
    );

    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント設定
    }
  }

  public back(): void {
    this.location.back();
  }

  private setItemData(): void {
    let tmp: OrderItem[] = [];
    let keys: string[] = [];
    this.order_detail.forEach(gb => {
      gb.order_items.forEach(item => {
        tmp.push({
          objectID: item.objectID,
          beare_code: item.beare_code,
          name: item.name,
          num: item.num * gb.order_guests.length,
          price: item.price,
          reduced_tax_flg: item.reduced_tax_flg,
          thumb_s: item.thumb_s,
          brand: item.brand,
          created: item.created,
          updated: item.updated,
          delete_flg: item.delete_flg
        });
        keys.push(item.objectID);
      });
    });
    Array.from(new Set(keys)).forEach(key => {
      this.order_items.push({
        objectID: key,
        beare_code: '',
        name: '',
        num: 0,
        price: 0,
        reduced_tax_flg: false,
        thumb_s: {url: '', no: -1},
        brand: {brand_id: '', brand_name: ''},
        created: new Date(),
        updated: new Date(),
        delete_flg: false
      });
    });
    this.order_items.forEach((item, i) => {
      tmp.forEach(a => {
        if (item.objectID == a.objectID) {
          item.name = a.name;
          item.num += a.num;
          item.price = a.price;
          item.reduced_tax_flg = a.reduced_tax_flg;
          item.thumb_s = a.thumb_s;
        }
      });
    });
  }

  public printPage(): void {
    try {
      document.execCommand('print', false, '');  // for Safari
    } catch {
      window.print();
    }
  }

  public detail(item_key: string) {
    this.select_item_key = item_key;
    this.show_item_detail = true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
