import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';

import { SessionService } from '../../service/session.service';

import * as constant from '../../models/constant';

declare function resetCommonrEvent(): void;
declare function accordion(): void;

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  public is_additional: boolean = false; // 送料区分判定（true:送料別、false:送料込み）
  public delive_fee: number = constant.NEW_DELIVE_FEE;
  public delive_fee_additional: number = constant.NEW_DELIVE_FEE_ADDITIONAL;
  public delive_fee_okinawa: number = constant.DELIVE_FEE_OKINAWA;
  public rate = constant.TAX_RATE;
  public Math = Math;
  private subscription: Subscription = new Subscription();

  constructor(
    private sv_session: SessionService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add (
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.is_additional = session.data.delive_fee_type == constant.CAMPANY_DELIVE_FEE_TYPE.ADDITIONAL;
        }
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent();
      accordion();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
