<div class="content-block content-block-max">
    <div *ngIf="message" class="content-block-inner">
        <div>
        <div class="title-content">{{company.name}}　スタッフ様ご登録</div>
        </div>
        <div class="j-content-start a-items-start text-note-box">
        <span>{{message}}</span>
        <div *ngIf="success" class="input-box mgt20">
            <div class="flex-box">
            <a routerLink="/sign_in" class="btn-item"><span>T-PORTへログインする</span></a>
            </div>
        </div>
        <div *ngIf="time_out" class="input-box mgt20">
            <div class="flex-box">
            <a (click)="reload()" class="btn-item"><span>ページを更新</span></a>
            </div>
        </div>
        </div>
    </div>

    <div *ngIf="ready && !saved" class="content-block-inner">
        <div class="flex-box">
        <div class="title-content">{{company.name}}<br>スタッフ様ご登録</div>
        </div>
        <div class="flex-box j-content-start a-items-start text-note-box">
        <div class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg></div>
        <span>T-PORTご利用にあたりスタッフアカウントのご登録をお願いいたします。<br>登録が完了されましたらT-PORTへログインいただき、会場・メンバー情報のご登録へお進みください。</span>
        </div>
    </div><!--/content-block-inner-->

    <form *ngIf="ready && !saved" #memberForm="ngForm" (ngSubmit)="regist()" novalidate ybAddress>
        <div class="gift-make-block js-createArea">
        <div class="item-list-open h-adr item-list-open-hed" style="border-bottom: none;">

            <div class="input-box-wrap">
                <div class="input-box-title">
                <div class="title-required"><span>お名前</span></div>
                <div class="text-frame-s">必須</div>
                </div>
                <div class="input-box">
                <div>
                    <div *ngIf="(last_name.errors?.['required'] && (last_name.dirty || last_name.touched)) || (first_name.errors?.['required'] && (first_name.dirty || first_name.touched))" class="error-text input-error-text">※お名前は必須です</div>
                </div>
                <div class="flex-box j-content-start">
                    <input class="input-column input-size-m" type="text" required maxlength="100" #last_name="ngModel" placeholder="姓" name="last_name" [(ngModel)]="member.last_name" [class.error-item]="last_name.errors && (last_name.dirty || last_name.touched)"/>
                    <input class="input-column input-size-m" type="text" required maxlength="100" #first_name="ngModel" placeholder="名" name="first_name" [(ngModel)]="member.first_name" [class.error-item]="first_name.errors && (first_name.dirty || first_name.touched)"/>
                </div>
                </div>
            </div><!--/input-box-wrap-->

            <div class="input-box-wrap">
                <div class="input-box-title">
                <div class="title-required"><span>フリガナ</span></div>
                <div class="text-frame-s">必須</div>
                </div>
                <div class="input-box">
                <div>
                    <div *ngIf="(last_kname.errors?.['required'] && (last_kname.dirty || last_kname.touched)) || (first_kname.errors?.['required'] && (first_kname.dirty || first_kname.touched))" class="error-text input-error-text">※フリガナは必須です</div>
                    <div *ngIf="last_kname.errors?.['pattern'] || first_kname.errors?.['pattern']" class="error-text input-error-text">※フリガナは全角カタカナで入力してください</div>
                </div>
                <div class="flex-box j-content-start">
                    <input class="input-column input-size-m" type="text" required maxlength="100" pattern="{{pattern.KANA}}" #last_kname="ngModel" placeholder="セイ" name="last_kname" [(ngModel)]="member.last_kname" [class.error-item]="last_kname.errors && (last_kname.dirty || last_kname.touched)"/>
                    <input class="input-column input-size-m" type="text" required maxlength="100" pattern="{{pattern.KANA}}" #first_kname="ngModel" placeholder="メイ" name="first_kname" [(ngModel)]="member.first_kname" [class.error-item]="first_kname.errors && (first_kname.dirty || first_kname.touched)"/>
                </div>
                </div>
            </div><!--/input-box-wrap-->

            <div class="input-box-wrap">
                <div class="input-box-title">
                <div class="title-required"><span>メールアドレス</span></div>
                <div class="text-frame-s">必須</div>
                </div>
                <div class="input-box">
                <div>
                    <div *ngIf="email.errors?.['required'] && (email.dirty || email.touched)" class="error-text input-error-text">※メールアドレスは必須です</div>
                    <div *ngIf="email.errors?.['email']" class="error-text input-error-text">※メールアドレスの形式が不正です</div>
                </div>
                <div class="j-content-start">
                    <input class="input-column" type="email" required email #email="ngModel" placeholder="メールアドレス" name="email" [(ngModel)]="member.email" [class.error-item]="email.errors && (email.dirty || email.touched)"/>
                </div>
                </div>
            </div><!--/input-box-wrap-->

            <div class="input-box-wrap">
                <div class="input-box-title">
                <div class="title-required"><span>パスワード<br>（6文字以上）</span></div>
                <div class="text-frame-s">必須</div>
                </div>
                <div class="input-box">
                <div>
                    <div *ngIf="password.errors?.['required'] && (password.dirty || password.touched)" class="error-text input-error-text">※パスワードは必須です</div>
                    <div *ngIf="password.errors?.['pattern']" class="error-text input-error-text">※パスワードの形式が不正か使用できない文字が含まれています</div>
                </div>
                <div class="j-content-start">
                    <input class="input-column" type="password" autocomplete="current-password" required pattern="{{pattern.PW}}" #password="ngModel" placeholder="パスワード" id="password" name="password" [(ngModel)]="member.default_password" [class.error-item]="password.errors && (password.dirty || password.touched)"/>
                    <div class="checkbox-wrap flex-box" style="margin-top: 10px;">
                    <label>
                    <input type="checkbox" id="password_check" class="header-checkbox" name="password_check" (change)="passwordCheck()">
                    <span class="item-label search">パスワードを表示する</span>
                    </label>
                    </div>
                </div>
                </div>
            </div><!--/input-box-wrap-->

            <div class="btn-box">
                <div class="flex-box j-content-center">
                <button class="btn-item" type="submit" [disabled]="memberForm.invalid"><span>保存</span></button>
                <button class="btn-item white js-modalCreateSendListCancelBtn" type="reset" [disabled]="memberForm.invalid"><span>クリア</span></button>
                </div>
            </div><!--/input-box-->

        </div><!--/item-list-open-->
        </div><!--/gift-make-block-->
    </form>

</div><!--/content-block-->

<app-modal-message (simple_msg_event)="onModalSimpleMsgEvent($event)"></app-modal-message>
<app-spinner *ngIf="!ready && !message"></app-spinner>
