import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ReOrderEvent } from '../../models/re_order_event';
import { ReOrderCancelEvent } from '../../models/re_order_cancel_event';
import { OrderCancelEvent } from '../../models/order_cancel_event';
import { CartInEvent } from '../../models/cart_in_event';
import { OrderEvent } from '../../models/order_event';

declare function hideModal(): void;

@Component({
  selector: 'app-modal-message',
  standalone: true,
  imports: [],
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent implements OnInit {
  @Output() simple_msg_event = new EventEmitter<boolean>();
  @Output() no_transfer_simple_msg_event = new EventEmitter<boolean>();
  @Output() msg_event = new EventEmitter<boolean>();
  @Output() type_msg_event = new EventEmitter<{msg:boolean, type:any}>();
  @Output() re_order_event = new EventEmitter<ReOrderEvent>();
  @Output() re_order_cancel_event = new EventEmitter<ReOrderCancelEvent>();
  @Output() order_cancel_event = new EventEmitter<OrderCancelEvent>();
  @Output() cart_in_event = new EventEmitter<CartInEvent>();
  @Output() order_event = new EventEmitter<OrderEvent>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  public ok_click(): void {
    this.simple_msg_event.emit(true);
  }

  public no_transfer_ok_click(): void {
    this.no_transfer_simple_msg_event.emit(true);
  }

  public msg_click(flg: boolean): void {
    this.msg_event.emit(flg);
  }

  public type_msg_click(ev: any, flg: boolean): void {
    let type_name: any = $(ev.target).closest('button').siblings('.type_name').val();
    this.type_msg_event.emit({msg: flg, type: type_name});
  }

  public re_order_click(flg: boolean): void {
    let event: ReOrderEvent = {
      re_order: flg
    };
    this.re_order_event.emit(event);
  }

  public order_cancel_click(flg: boolean): void {
    let event: OrderCancelEvent = {
      order_cancel: flg
    };
    this.order_cancel_event.emit(event);
  }

  public re_order_cancel_click(flg: boolean): void {
    let event: ReOrderCancelEvent = {
      re_order_cancel: flg
    };
    this.re_order_cancel_event.emit(event);
  }

  public cart_in_click(flg: boolean): void {
    let event: CartInEvent = {
      cart_in: flg
    };
    this.cart_in_event.emit(event);
  }

  public order_click(flg: boolean): void {
    let event: OrderEvent = {
      order: flg
    };
    this.order_event.emit(event);
  }

  public checkPrivacyPolicy(): void {
    hideModal();
    setTimeout(() => this.router.navigate(['/terms-and-policy/policy'], {relativeTo: this.route}), 100);
  }


}
