import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

import { SessionService } from '../../service/session.service';
import { AuthService } from '../../service/auth.service';

import { HeaderCount } from '../../models/header_count';
import * as constant from '../../models/constant';

declare function menuDispControll(): void;
declare function navButtonClick(): void;

@Component({
  selector: 'app-navi',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
  ],
  templateUrl: './navi.component.html',
  styleUrls: ['./navi.component.scss']
})
export class NaviComponent implements OnInit {
  public user_name: string = '';
  public user_role: number = -1;
  public is_manager: boolean = false;
  public header_count: HeaderCount = new HeaderCount();
  public order_list_type = constant.ORDER_LIST_TYPE;
  private subscription: Subscription = new Subscription();

  public no_giftbox: boolean = false;
  public no_cart: boolean = false;
  public no_info_not_read: boolean = false;

  constructor(
    public router: Router,
    private sv_session: SessionService,
    private sv_auth: AuthService
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.sv_session.sessionState.subscribe(session => {
        if (session && session.data) {
          this.user_name = `${session.data.last_name} ${session.data.first_name}`;
          this.user_role = session.data.current_role;
          this.is_manager = session.data.manager_flg;
          if (this.user_role == constant.ROLE.CUST) {
            if (session.data.event) {
              this.sv_session.countInfoCust(session.data.event.objectID);
            }
          } else if (session.data.current_role == constant.ROLE.MNG) {

          }
          this.sv_session.header_count.subscribe(header_cnts => {
            this.header_count = header_cnts;
            this.dispControll();
          });
        }
      })
    );
    // menuDispControll();
  }

  private dispControll(): void {
    this.no_giftbox = this.header_count.giftbox_cnt < 1;
    this.no_cart = this.header_count.cart_cnt < 1;
    this.no_info_not_read = this.header_count.info_not_read_cnt < 1;
  }

  public signOut(): void {
    this.sv_auth.sign0ut();
  }

  public closeMenu(): void {
    if (matchMedia('(max-width: 798px)').matches) {
      navButtonClick();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
