export interface ISearchCondEvent {
    name: string;
    date_from: Date | null;
    date_to: Date | null;
    order_limit_from: Date | null;
    order_limit_to: Date | null;
    company_id: string;
    company_name: string;
    place_id: string;
    place_name: string;
    staff: string;
    exclude_ck: boolean; // true:過去挙式を除く  false:過去挙式を含む
}
export class SearchCondEvent implements ISearchCondEvent {
    name: string = '';
    date_from: Date | null = null;
    date_to: Date | null = null;
    order_limit_from: Date | null = null;
    order_limit_to: Date | null = null;
    company_id: string = '';
    company_name: string = '';
    place_id: string = '';
    place_name: string = '';
    staff: string = '';
    exclude_ck: boolean = true;
}
