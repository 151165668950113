
<form #fm_search_event="ngForm" (ngSubmit)="search($event)">
	<div class="input-box-wrap flex-box">
		<div class="search-title">挙式名</div>
		<div class="input-box-wrap-inner">
			<div class="search-input">
				<input [(ngModel)]="cond.name" name="event_name" class="form-search mng-style" type="text" placeholder="">
			</div>
		</div>
	</div>

	<div class="input-box-wrap flex-box">
		<div class="search-title">挙式日</div>
		<div class="input-box-wrap-inner" style="display:block;">
			<div class="input-box">
				<div class="flex-box j-content-start">
					<div class="calendar-input">
						<mat-form-field>
						<input matInput [(ngModel)]="cond.date_from" name="event_date_from" [min]="oldest_date" [matDatepicker]="picker_event_date_from" readonly>
						<mat-datepicker-toggle matSuffix [for]="picker_event_date_from"></mat-datepicker-toggle>
						<mat-datepicker #picker_event_date_from ></mat-datepicker>
						</mat-form-field>
					</div>
					<div class="unit">～</div>
					<div class="calendar-input">
						<mat-form-field>
						<input matInput [(ngModel)]="cond.date_to" name="event_date_to" [min]="oldest_date" [matDatepicker]="picker_event_date_to" readonly>
						<mat-datepicker-toggle matSuffix [for]="picker_event_date_to"></mat-datepicker-toggle>
						<mat-datepicker #picker_event_date_to ></mat-datepicker>
						</mat-form-field>
					</div>
				</div>
			</div>
			<div class="checkbox-wrap">
				<label>
				<input type="checkbox" [(ngModel)]="cond.exclude_ck" name="exclude_ck" class="header-checkbox" value="1">
				<span class="item-label">完了した挙式の注文を除く</span>
				</label>
			</div>
		</div>
	</div>

	<div *ngIf="!session.data?.current_company || !session.data?.current_place" class="input-box-wrap flex-box">
		<div class="search-title">企業</div>
		<div class="input-box-wrap-inner">
			<div class="input-box">
				<input class="input-column" type="text" name="company_name" placeholder="企業名を入力してください" [(ngModel)]="cond.company_name">
			</div>
		</div>
	</div>

	<div *ngIf="!session.data?.current_company || !session.data?.current_place" class="input-box-wrap flex-box">
		<div class="search-title">会場</div>
		<div class="input-box-wrap-inner">
			<div class="input-box">
				<input class="input-column" type="text" name="place_name" placeholder="会場名を入力してください" [(ngModel)]="cond.place_name">
			</div>
		</div>
	</div>

	<div *ngIf="is_cross_search" class="input-box-wrap flex-box">
		<div class="search-title">会場</div>
		<div class="input-box-wrap-inner">
			<div class="input-box">
				<div class="select-wrap mgtb2">
					<select data-ng-options="v as k for (k, v) in places" name="place" class="p-region-id"
						[(ngModel)]="cond.place_id"
						(ngModelChange)="select_place=$event"
						(change)="onChangePlace()">
						<option *ngFor="let place of places" [ngValue]="place.key">{{place.value}}</option>
					</select>
				</div>
			</div>
	</div>
	</div>

	<div class="input-box-wrap flex-box">
		<div class="search-title">担当者</div>
		<div class="input-box-wrap-inner">
			<div class="input-box">
				<div class="select-wrap mgtb2">
					<select data-ng-options="v as k for (k, v) in members" name="staff" class="p-region-id"
						[(ngModel)]="cond.staff"
						(ngModelChange)="select_member=$event"
						[disabled]="!members">
						<option *ngFor="let member of members" [ngValue]="member.key">{{member.value}}</option>
					</select>
				</div>
			</div>
			<div><a (click)="setYourself()">担当者に自分を設定</a></div>
		</div>
	</div>

	<div class="btn-box" style="margin-top:10px;">
		<div class="flex-box j-content-center">
			<button class="btn-item" type="submit"><span>検索</span></button>
			<button class="btn-item white js-modalCreateSendListCancelBtn" type="reset"><span>クリア</span></button>
		</div>
	</div>
</form>
