<div class="content-block content-block-max">
    <div class="content-block-inner">
        <div class="back-link"><a (click)="back()">戻る</a></div>
        <div class="flex-box">
            <div class="title-content mng mg-style2">挙式担当者変更</div>
        </div>
        <div class="title-content">{{staff?.last_name}} {{staff?.first_name}}（{{staff?.last_kname}} {{staff?.first_kname}}）</div>
        <div class="text-m-style3">{{staff?.email}}</div>
        <div class="flex-box j-content-end">
            <div class="mgr20">担当者一括変更</div>
            <div class="view-wrap mgr-style1">
                <div class="select-wrap">
                    <select data-ng-options="v as k for (k, v) in members" id="all_select" class="form-select w140"
                        [ngModel]="staff_id"
                        (ngModelChange)="select_member=$event"
                        (change)="changeStaffAll()">
                        <option *ngFor="let member of members" [ngValue]="member.key">{{member.value}}</option>
                    </select>
                </div>
            </div><!--/view-wrap-->
        </div><!--/flex-box-->
    </div><!--/content-block-inner-->

    <div class="page" *ngIf="pages.length > 1">
        <div class="no" (click)="paging(1, 0)"><<</div>
        <div class="no" *ngFor="let page of pages; index as i" (click)="paging(page, i)" [class.current]="i == select_page_index">{{page}}</div>
        <div class="no" (click)="paging(pages.length, pages.length-1)">>></div>
    </div>

    <div class="inner-header">
        <div class="count-info">
        <div class="result" *ngIf="events; else noData">
            <span class="js-itemCount">
            {{(select_page_index * len) + 1}}〜
            {{ (pages.length - 1 > select_page_index) ? ((select_page_index + 1) * len) : events.length }}件／
            {{events.length}}
            </span>件
        </div>
        <ng-template #noData><div class="result">0件</div></ng-template>
        </div><!-- count-info-->
    </div>

    <div class="list-wrap" *ngFor="let event of events | slice: start: start+len; index as i">
        <div class="item-list send-list">
            <div class="order-info-box order-info-style list-fz-size-s">
                <div class="lits-cont-box-odd">
                    <div class="orderer-box">
                        <div class="order-number">
                            挙式日：{{event?.date.toDate() | date}}
                        </div>
                        <div class="wed-name">
                            <p>{{event?.name}}</p>
                        </div>
                    </div>
                </div><!--/mng-box-odd-->
                <div class="lits-cont-box-even even-style">
                    <dl class="select-dl-style select-dl-column-style">
                        <dt>担当者</dt>
                        <dd>
                            <div class="select-wrap">
                                <select data-ng-options="v as k for (k, v) in members" id="select_{{i}}" class="form-select"
                                    [ngModel]="staff_id"
                                    (ngModelChange)="select_member=$event"
                                    (change)="changeStaff(start+i, $event)">
                                    <option *ngFor="let member of members" [ngValue]="member.key">{{member.value}}</option>
                                </select>
                            </div>
                        </dd>
                    </dl>
                </div><!--/mng-box-even-->
            </div><!--/order-info-box-->
        </div><!--/item-list-->
    </div><!--/list-wrap-->

</div><!--/content-block-->

<app-modal-message (msg_event)="onModalMsgEvent($event)"></app-modal-message>
