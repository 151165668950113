import { Code } from './code';
import { Image } from './image';
import { Thumb } from './thumb';
import { Brand } from './brand';

export interface IItem {
  objectID: string;
  name: string;
  code: Code[];
  beare_code: string;
  place_limited: string[];
  place_without: string[];
  price: number;
  reduced_tax_flg: boolean; // 軽減税対象
  image:Image[];
  thumb_l:Thumb[];
  thumb_s:Thumb[];
  size_w: number;
  size_h: number;
  size_d: number;
  size_diameter: string; //場所や単位も含めて文字列で。
  size_other: string; //場所や単位も含めて文字列で。
  volume: string;　 //場所や単位も含めて文字列で。
  box_size_w: number;
  box_size_h: number;
  box_size_d: number;
  color: string[];
  dishwasher: boolean;
  microwave: boolean;
  allergy: string;
  accessories: string;
  set: string;
  expire_date: string;
  wrapping: boolean;
  brand: Brand;
  description: string;
  category: string[];
  tag: string[];
  status: number;
  level: number;
  note: string;
  vol_no: number[];
  created: Date;
  updated: Date;
  delete_flg: boolean;
  favorite: boolean;
}
export class Item implements IItem {
  objectID: string = '';
  name: string = '';
  code: Code[] = [];
  beare_code: string = '';
  place_limited: string[] = [];
  place_without: string[] = [];
  price: number = 0;
  reduced_tax_flg: boolean = false; // 軽減税対象
  image:Image[] = [];
  thumb_l:Thumb[] = [];
  thumb_s:Thumb[]= [];
  size_w: number = 0;
  size_h: number = 0;
  size_d: number = 0;
  size_diameter: string = ''; //場所や単位も含めて文字列で。
  size_other: string = ''; //場所や単位も含めて文字列で。
  volume: string = ''; //場所や単位も含めて文字列で。
  box_size_w: number = 0;
  box_size_h: number = 0;
  box_size_d: number = 0;
  color: string[]= [];
  dishwasher: boolean = false;
  microwave: boolean = false;
  allergy: string = '';
  accessories: string = '';
  set: string = '';
  expire_date: string = '';
  wrapping: boolean = false;
  brand: Brand = {
    brand_id: '',
    brand_name: ''
  };
  description: string = '';
  category: string[]= [];
  tag: string[]= [];
  status: number = -1;
  level: number = 0;
  note: string = '';
  vol_no: number[]= [];
  created: Date = new Date();
  updated: Date = new Date();
  delete_flg: boolean = false;
  favorite: boolean = false;
}
