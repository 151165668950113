<div *ngIf="show_item_detail">
  <ng-container *ngTemplateOutlet="modal"></ng-container>
</div>

<div class="content-block content-block-max">
  <div class="content-block-inner">
    <div *ngIf="order_info?.order_branch?.latest" class="back-link">
      <a routerLink="/order-list/{{order_list_type.DEFAULT}}">注文一覧へ戻る</a>
    </div>
    <div *ngIf="!order_info?.order_branch?.latest" class="back-link">
      <a routerLink="/order-history-list/{{order_info.order_id}}">発注履歴一覧へ戻る</a>
    </div>

    <div class="mg-style2">
      <div *ngIf="order_info?.order_branch?.latest" class="flex-box j-content-end">
        <div class="state-change mgb0" *ngIf="order_info?.order_branch?.status == status.REQUEST_AFTER_ORDER || order_info?.order_branch?.status == status.REQUEST_AFTER_ACCEPT || order_info?.order_branch?.status == status.CANCEL_REQUEST">
          変更注文
          <span class="caption" *ngIf="order_info?.order_branch?.status == status.CANCEL_REQUEST">（キャンセル依頼）</span>
        </div>
        <div class="state-change black mgb0" *ngIf="order_info?.order_branch?.status == status.ORDER || order_info?.order_branch?.status == status.ORDER_AFTER_ACCEPT">発注済み</div>
        <div class="state-change black mgb0" *ngIf="order_info?.order_branch?.status == status.ACCEPT">受注済み</div>
        <div class="state-change black mgb0" *ngIf="order_info?.order_branch?.status == status.CANCEL_ORDER">キャンセル発注済み</div>
        <div class="state-change black mgb0" *ngIf="order_info?.order_branch?.status == status.CANCEL || order_info?.order_branch?.status == status.CANCEL_BY_ADMIN">キャンセル</div>
        <div class="text-frame bg-bk"><span class="cap white">注文最終確定：</span>{{order_info.order_limit | date}}</div>
      </div>
      <div *ngIf="!order_info?.order_branch?.latest" class="flex-box j-content-end">
        <div class="state-change black mgb0">発注履歴</div>
      </div>
      <div class="flex-box mgt10">
        <dl class="flex-box j-content-start text-m-style2">
          <dt class="dl-title">挙式日</dt>
          <dd>{{order_info.event_date | date}}</dd>
        </dl>
      </div>
      <div class="title-content"><a routerLink="/event-detail/{{order_info.event_id}}">{{order_info.event_name}}</a></div>
      <div *ngIf="order_info?.event_status == 5" class="m5"><span class="cap2 red red-square">挙式無効</span></div>
    </div>

    <div class="error-box gray" *ngIf="!taxable">
      <div class="text-m-style1">このご注文は税込み総額表示の対象期間外にご注文されております。表示価格は全て税抜きとなりますのでご注意ください。</div>
    </div>

    <dl class="flex-box j-content-start text-m-style2">
      <dt class="dl-title w5e">注文No</dt>
      <dd>{{order_info.order_no}}</dd>
    </dl>
    <dl class="flex-box j-content-start a-items-start text-m-style2">
      <dt class="dl-title w5e">顧客注文</dt>
      <dd>{{order_info.order_branch?.order_request_date | date : 'short'}}（{{order_info.order_branch?.order_request_user?.name}}様）</dd>
    </dl>
    <dl class="flex-box j-content-start text-m-style2">
      <dt class="dl-title w5e">会場発注</dt>
      <dd *ngIf="order_info.order_branch?.order_date">{{order_info.order_branch?.order_date | date : 'short'}}（{{order_info.order_branch?.order_user?.name}}様）</dd>
    </dl>
    <dl *ngIf="order_info.order_branch && order_info.order_branch.status >= status.CANCEL_REQUEST" class="flex-box j-content-start text-m-style2">
      <dt class="dl-title">キャンセル依頼</dt>
      <dd *ngIf="order_info.order_branch?.cancel_request_date">{{order_info.order_branch.cancel_request_date | date : 'short'}}（{{order_info.order_branch.cancel_request_user.name}}様）</dd>
    </dl>
    <dl *ngIf="order_info.order_branch && order_info.order_branch.status >= status.CANCEL_ORDER" class="flex-box j-content-start text-m-style2">
      <dt class="dl-title">キャンセル発注</dt>
      <dd *ngIf="order_info.order_branch?.order_cancel_date">{{order_info.order_branch.order_cancel_date | date : 'short'}}（{{order_info.order_branch.order_cancel_user.name}}様）</dd>
    </dl>
    <dl class="flex-box j-content-start text-m-style2">
      <dt class="dl-title w5e">受注</dt>
      <dd *ngIf="order_info.order_branch && order_info.order_branch.order_accept_date">{{order_info.order_branch.order_accept_date | date : 'short'}}（タイムレス）</dd>
    </dl>
    <dl *ngIf="(order_info.order_branch && order_info.order_branch.status == status.CANCEL) || (order_info.order_branch && order_info.order_branch.status == status.CANCEL_BY_ADMIN)" class="flex-box j-content-start text-m-style2">
        <dt class="dl-title">キャンセル</dt>
        <dd *ngIf="order_info.order_branch.cancel_date">{{order_info.order_branch.cancel_date | date : 'short'}}（{{order_info.order_branch.cancel_user.name}}様）</dd>
      </dl>
    <div class="flex-box">
      <dl class="flex-box j-content-start text-m-style2" style="align-self: flex-start;">
        <dt class="dl-title w5e">合計金額</dt>
        <dd>
          <div *ngIf="!taxable" class="order-price">
            {{order_info.order_branch?.price | currency : 'JPY'}}
            <span class="m10 left">送料計{{order_info.order_branch?.deliv_fee | currency : 'JPY'}}</span>
          </div>
          <div *ngIf="taxable && order_info.order_branch" class="order-price">
            {{(order_info.order_branch.price + order_info.order_branch.tax + order_info.order_branch.reduced_tax) | currency : 'JPY'}}<span class="cap2 s blank_l m10 right">(税込)</span>
            送料計{{(order_info.order_branch.deliv_fee + order_info.order_branch.deliv_fee_tax) | currency : 'JPY'}}<span class="cap2 s blank_l">(税込)</span>
          </div>
        </dd>
      </dl>
      <div class="j-content-start">
        <div class="order-check"><a (click)="setDetail(0)" class="a-style">この発注の商品別数量</a></div>
        <div class="order-check"><a (click)="setDetail(1)" class="a-style">この挙式の全ての商品別数量</a></div>
        <div class="order-check"><a (click)="setDetail(2)" class="a-style">この挙式の全ての贈る相手</a></div>
      </div>
    </div>

    <div class="flex-box j-content-start a-items-start" style="margin-top: 10px;">
      <div class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg></div>
      <span *ngIf="taxable">
        <span *ngIf="!is_additional" style="align-self: center;">
          1配送あたりの税抜き商品額合計が{{delive_fee_limit_price | currency : 'JPY'}}未満の場合は別途送料{{(delive_fee + Math.floor(delive_fee * rate.TAX / 100)) | currency : 'JPY'}}<span class="cap2 s blank_l">(税込)</span>です。<br>
          沖縄本島へのお届けの場合は商品額合計に関わらず別途送料{{(delive_fee_okinawa + Math.floor(delive_fee_okinawa * rate.TAX / 100)) | currency : 'JPY'}}<span class="cap2 s blank_l">(税込)</span>です。(送料計にのみ反映)
        </span>
        <span *ngIf="is_additional" style="align-self: center;">
          1配送あたり別途送料{{(delive_fee_additional + Math.floor(delive_fee_additional * rate.TAX / 100)) | currency : 'JPY'}}<span class="cap2 s blank_l">(税込)</span>となります。<br>
          沖縄本島へのお届けの場合は別途送料{{(delive_fee_okinawa + Math.floor(delive_fee_okinawa * rate.TAX /100)) | currency : 'JPY'}}<span class="cap2 s blank_l">(税込)</span>となります。(送料計にのみ反映)
        </span>
      </span>
      <span *ngIf="!taxable">
        <span *ngIf="!is_additional" style="align-self: center;">
          1配送あたりの税抜き商品額合計が{{delive_fee_limit_price | currency : 'JPY'}}未満の場合は別途送料{{delive_fee | currency : 'JPY'}}です。<br>
          沖縄本島へのお届けの場合は商品額合計に関わらず別途送料{{delive_fee_okinawa | currency : 'JPY'}}です。(送料計にのみ反映)
        </span>
        <span *ngIf="is_additional" style="align-self: center;">
          1配送あたり別途送料{{delive_fee_additional | currency : 'JPY'}}となります。<br>
          沖縄本島へのお届けの場合は別途送料{{delive_fee_okinawa | currency : 'JPY'}}となります。(送料計にのみ反映)
        </span>
      </span>
    </div>

  </div><!--/content-block-inner-->

  <div class="error-box" *ngIf="limit_over && order_info.order_branch &&(order_info.order_branch.status < status.ORDER || order_info.order_branch.status == status.CANCEL_REQUEST)">
    <p class="icon-note text-red text-bold">この注文は発注期限を超過しているため発注できません</p>
  </div><!--/error-box-->

  <div class="inner-header">
    <div class="result">ギフトセット {{ordered_giftboxes.length}}件</div>
  </div>

  <div class="list-wrap" *ngIf="ready">
    <div class="order-item-list line-none" *ngFor="let data of ordered_giftboxes; index as g">
      <div class="order-list-name fz-l">{{data.order_giftbox.name}}</div>
      <div class="flex-box j-content-start text-m-style2">
        <div class="content-odd">
          <dl class="flex-box j-content-start">
            <dt>贈る相手：</dt>
            <dd>
              <!-- <span [class.red]="guest_change_exists">{{data?.order_guests.length}}名</span> -->
              <span *ngIf="guest_change_exists[g]" class="red">{{latest_ordered_gb_guest_count[g]}}名 → {{data.order_guests.length}}名</span>
              <span *ngIf="!guest_change_exists[g]">{{data.order_guests.length}}名</span>
            </dd>
          </dl>
        </div>
        <div class="content-even">
          <a (click)="setDetailGiftbox(g)" class="a-style"><span [class.red]="guest_change_exists[g]">贈る相手を確認</span></a>
        </div>
      </div><!--/flex-box-->
      <dl class="flex-box j-content-start">
        <dt><span>1名金額：</span></dt>
        <dd *ngIf="taxable">
          {{(data.order_giftbox.unit_price + data.order_giftbox.unit_tax + data.order_giftbox.unit_reduced_tax) | currency : 'JPY'}}<span class="cap2 s blank_l">(税込)</span>
          <span *ngIf="!is_additional" class="m10 left">送料{{ ((data.order_giftbox.unit_price < delive_fee_limit_price) ? (delive_fee + Math.floor(delive_fee * rate.TAX / 100)) : 0) | currency : 'JPY' }}<span class="cap2 s blank_l">(税込)</span></span>
          <span *ngIf="is_additional" class="m10 left">送料{{ (delive_fee_additional + Math.floor(delive_fee_additional * rate.TAX / 100)) | currency : 'JPY' }}<span class="cap2 s blank_l">(税込)</span></span>
        </dd>
        <dd *ngIf="!taxable">
          {{data.order_giftbox.unit_price | currency : 'JPY'}}
          <span *ngIf="!is_additional" class="m10 left">送料{{ ((data.order_giftbox.unit_price < delive_fee_limit_price) ? delive_fee : 0) | currency : 'JPY' }}</span>
          <span *ngIf="is_additional" class="m10 left">送料{{ delive_fee_additional | currency : 'JPY' }}</span>
        </dd>
      </dl>
      <dl class="flex-box j-content-start">
        <dt><span>合計金額：</span></dt>
        <dd *ngIf="taxable">
          {{(data.order_giftbox.price + data.order_giftbox.tax + data.order_giftbox.reduced_tax) | currency : 'JPY'}}<span class="cap2 s blank_l">(税込)</span>
          <span class="m10 left">送料計{{ (data.order_giftbox.deliv_fee + data.order_giftbox.deliv_fee_tax) | currency : 'JPY' }}<span class="cap2 s blank_l">(税込)</span></span>
        </dd>
        <dd *ngIf="!taxable">
          {{data.order_giftbox.price | currency : 'JPY'}}
          <span class="m10 left">送料計{{ data.order_giftbox.deliv_fee | currency : 'JPY' }}</span>
        </dd>
      </dl>

      <div class="order-list-wrap-inner order-list-style">
        <div class="order-item-list-inner giftbox-detail" *ngFor="let item of data.order_items; index as i" [class.list-deadline]="item_changes.length && item_changes[g][i]">
          <div class="order-list-cont-box-odd">
            <a class="list-img" (click)="detail(item.objectID)">
              <div class="item-image item-box">
                <div class="item-box-inner">
                  <img *ngIf="item.thumb_s.url" src="{{item.thumb_s.url}}" alt="">
                  <img *ngIf="!item.thumb_s.url" src="{{ dummy_thumb_s_url }}" alt="">
                  <div class="protect-image"></div>
                </div>
              </div>
            </a>

            <div class="detail-gift-box">
              <div class="detail-list-name fz-l"><p>{{item?.name}}</p></div>
              <div *ngIf="(item_status_arr[i] | async) === item_status.SOLDOUT">
                <div class="soldout m5 t">SOLDOUT</div>
                <span class="cap2 s red">このギフトセットでの発注は可能です。</span>
              </div>
              <div *ngIf="(item_status_arr[i] | async) === item_status.DISCONTINUED">
                <div class="soldout m5 t">現在お取扱なし</div>
                <span class="cap2 s red">このギフトセットでの発注は可能です。</span>
              </div>
              <div *ngIf="taxable && !place_tax_rate?.rule" class="detail-gift-price">
                {{(item.reduced_tax_flg ?
                  item.price + Math.floor(item.price * rate.REDUCED_TAX / 100) :
                  item.price + Math.floor(item.price * rate.TAX / 100)) | currency : 'JPY'}}
                <span class="cap2 s blank_l">(税込)</span>
              </div>
              <div *ngIf="taxable && place_tax_rate.rule && place_tax_rate.rate" class="detail-gift-price">
                {{(item.price + Math.floor(item.price * place_tax_rate.rate / 100)) | currency : 'JPY'}}
                <span class="cap2 s blank_l">(税込)</span>
              </div>
              <div *ngIf="!taxable" class="detail-gift-price">{{item?.price | currency : 'JPY'}}</div>
              <div *ngIf="taxable && !place_tax_rate?.rule && item?.reduced_tax_flg" class="m5 t"><span class="cap blank_l">（※消費税{{rate.REDUCED_TAX}}%対象商品です）</span></div>
            </div>
          </div>

          <div class="order-list-cont-box-even"> <div class="">{{item?.num}}個</div></div>

        </div><!--/order-item-list-inner-->
      </div><!--/order-list-wrap-inner-->
    </div><!--/order-item-list-->
  </div><!--/list-wrap-->

  <div class="content-footer-wrap">
    <div class="content-footer">
      <div *ngIf="order_info.order_branch && order_info.order_branch.status < status.CANCEL_REQUEST" class="content-footer-inner flex-box j-content-end a-items-end">
        <div class="box-odd only-pc">
          <button type="button" class="btn-pdf" (click)="printPage()">
            <i class="material-icons">print</i>
            <div style="margin-left: 3px;">このページを印刷する</div>
          </button>
        </div>
        <div class="box-even">
          <button type="button" class="btn-item w-style2 size-l btn-order"
            (click)="order_msg('ORDER')"
            [class.disabled]="!order_info.order_branch.latest || limit_over || (order_info.order_branch.status == status.ORDER || order_info.order_branch.status == status.ORDER_AFTER_ACCEPT || order_info.order_branch.status == status.ACCEPT)"
            [disabled]="!order_info.order_branch.latest || limit_over || (order_info.order_branch.status == status.ORDER || order_info.order_branch.status == status.ORDER_AFTER_ACCEPT || order_info.order_branch.status == status.ACCEPT)"
          ><span>発注する</span></button>
        </div>
      </div><!--/content-footer-inner-->

      <div *ngIf="order_info.order_branch && order_info.order_branch.status >= status.CANCEL_REQUEST" class="content-footer-inner flex-box j-content-end a-items-end">
        <div class="box-odd only-pc">
          <button type="button" class="btn-pdf" (click)="printPage()">
            <i class="material-icons">print</i>
            <div style="margin-left: 3px;">このページを印刷する</div>
          </button>
        </div>
        <div class="box-even">
          <button type="button" class="btn-item w-style2 size-l btn-order"
            (click)="order_msg('CANCEL_ORDER')"
            [class.disabled]="!order_info.order_branch.latest || limit_over || order_info.order_branch.status >= status.CANCEL_ORDER"
            [disabled]="!order_info.order_branch.latest || limit_over || order_info.order_branch.status >= status.CANCEL_ORDER"
          ><span>発注をキャンセルする</span></button>
        </div>
      </div><!--/content-footer-inner-->

    </div><!--/content-footer-->
  </div><!--/content-footer-wrap-->

  <app-modal-message (msg_event)="onModalMsgEvent($event)"></app-modal-message>

</div><!--/content-block-->

<!-- 商品詳細テンプレート -->
<ng-template #modal>
  <app-modal>
      <app-item-detail #modalContents [select_item_key]="select_item_key"></app-item-detail>
  </app-modal>
</ng-template>
