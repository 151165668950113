import { Component, OnInit, OnDestroy, Inject, Input, PLATFORM_ID, TrackByFunction } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { forkJoin, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { ModalMessageComponent } from '../modal-message/modal-message.component';

import { SessionService } from '../../service/session.service';
import { ItemService } from '../../service/item.service';
import { AccountService } from '../../service/account.service';
import { GiftboxService } from '../../service/giftbox.service';
import { CompanyService } from '../../service/company.service';
import { CommonService } from '../../service/common.service';
import { ConfigService } from '../../service/config.service';
import { PlaceService } from '../../service/place.service';

import { Item } from '../../models/item';
import { ItemPriceAdjust } from '../../models/item_place_adjust';
import { Image } from '../../models/image';
import { Giftbox } from '../../models/giftbox';
import { Session } from '../../models/session';
import * as constant from '../../models/constant';

import moment from 'moment';
import { environment } from '../../../environments/environment';

declare function resetCommonrEvent(): void;
declare function resetItemEvent(): void;
declare function showSimpleMsg(msg: string): void;
declare const debugLog: any;

@Component({
  selector: 'app-item-detail',
  standalone: true,
  imports: [
    CommonModule,
    SlickCarouselModule,
    ModalMessageComponent,
  ],
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss']
})
export class ItemDetailComponent implements OnInit {
  @Input() select_item_key: string = '';
  function_category = ["item"];
  public item: Item = new Item();
  private init: boolean = false;
  public item_attention: {type: string, msg: string} = {type: '', msg: ''};
  private item_attention_vol_use: boolean = true;
  private item_attention_prev_vol_use: boolean = true;
  private vol_base_date: any = null;
  private prev_vol_base_date: any = null;
  private vol: number = 0;
  private prev_vol: number = 0;
  private place_id: string = '';
  private place_ids: string[] = []; // 会場横断権限ありのプランナーが使用する会場ID（所属企業内の全ての会場ID）
  private company_id: string = '';
  public place_tax_rate: {rule: boolean, rate: number} = {rule: false, rate: 0};
  private is_cross_search: boolean = false;
  public images: Image[] = [];
  public no_img_ready: boolean = false;
  private giftboxes: Giftbox[] = [];
  private selected_giftbox: string = '';
  public role_cust: boolean = false;
  private session:Session = new Session();
  public slide_conf: any;
  public dummy_img_url = environment.NO_IMAGE_MAIN;
  public dummy_thumb_l_url = environment.NO_IMAGE_THUMB_L;
  public dummy_thumb_s_url = environment.NO_IMAGE_THUMB_S;
  private subscription: Subscription = new Subscription();
  public rate = constant.TAX_RATE;
  public status = constant.ITEM_STATUS;
  public Math = Math;
  private isBrowser: boolean;
  public trackByImg: TrackByFunction<string> = (i: number, url: string): string => this.images[i].url;

  constructor(
    private sv_session: SessionService,
    private sv_item: ItemService,
    private sv_account: AccountService,
    private sv_giftbox: GiftboxService,
    private sv_company: CompanyService,
    private sv_common: CommonService,
    private sv_config: ConfigService,
    private sv_place: PlaceService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    // ログインセッション取得
    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        }
        else {
          this.session = session;
          this.role_cust = constant.ROLE.CUST == session.data.current_role;
          this.is_cross_search = session.data.cross_search_flg;
          this.place_id = session.data.current_place;
          this.company_id = session.data.current_company;
          this.sv_giftbox.initialize(session);
          if (this.is_cross_search) {
            this.subscription.add(
              this.sv_company.getPlaceIds(this.company_id).subscribe(place_ids => {
                this.place_ids = place_ids ? place_ids : [];
              })
            );
          }

          if (this.place_id) {
            this.subscription.add(this.sv_place.getPlace(this.company_id, this.place_id).subscribe(place => {
              if (place) {
                this.item_attention_vol_use = place.item_attention_vol_use;
                this.item_attention_prev_vol_use = place.item_attention_prev_vol_use;
                this.vol_base_date = place.vol_base_date ? place.vol_base_date: null;
                this.prev_vol_base_date = place.prev_vol_base_date ? place.prev_vol_base_date : null;
                this.vol = place.vol ? place.vol : 0;
                this.prev_vol = place.prev_vol ? place.prev_vol : 0;
                this.place_tax_rate = place.place_tax_rate ? place.place_tax_rate : {rule: false, rate: 0};
              }
              this.getItem();
              resetItemEvent();
            }));
          } else {
            this.getItem();
            resetItemEvent();
          }

          this.sv_account.initialize(session);
          this.sv_item.initialize(session);
          if (session.data.current_role == constant.ROLE.CUST) {
            this.getGiftboxes();
          }
        }
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent(); // ヘッダーエリアのイベント再設定
    }
  }

  private getGiftboxes(): void {
    if (!this.session.data?.event) return;
    this.subscription.add(
      this.sv_giftbox.getGiftboxes(this.session.data.event.objectID).subscribe(giftboxes => {
        giftboxes.forEach(giftbox => {
          if (giftbox.selected) {
            this.selected_giftbox = giftbox.objectID;
          }
        });
        this.giftboxes = giftboxes;
      })
    );
  }

  private getItem(): void {
    if (this.init) return;

    this.subscription.add(
      forkJoin([
        this.sv_item.getItem(this.select_item_key).pipe(take(1)),
        this.sv_config.getItemAttentionAdjust().pipe(take(1))
      ]).subscribe(([item, item_attention_adjust]) => {
        if (item) {
          if (!item.image || item.image.length < 1) {
            this.no_img_ready = true;
          }

          // メッセージ出し分け
          let vol_base_date_str: string = this.vol_base_date ? moment(this.vol_base_date).format('YYYY/MM/DD') : moment(item_attention_adjust?.vol_base_date).format('YYYY/MM/DD');
          let prev_vol_base_date_str: string = this.prev_vol_base_date ? moment(this.prev_vol_base_date).format('YYYY/MM/DD') : moment(item_attention_adjust?.prev_vol_base_date).format('YYYY/MM/DD');
          let vol: number = this.vol ? this.vol : item_attention_adjust!.vol;
          let prev_vol: number = this.prev_vol ? this.prev_vol : item_attention_adjust!.prev_vol;
          let apply_item_price_adjusts: ItemPriceAdjust[];
          let no_apply_item_price_adjusts: ItemPriceAdjust[];
          // 新旧メッセージ表示期限の判定
          let today: Date = moment(new Date().setHours(0,0,0,0)).toDate();
          let msg_end: Date = moment(item_attention_adjust?.msg_end).toDate();
          let price_adjust_msg_end: Date = moment(item_attention_adjust?.price_adjust_msg_end).toDate();

          this.subscription.add(this.sv_item.getItemPriceAdjusts().subscribe(price_adjusts => {
            apply_item_price_adjusts = [];
            no_apply_item_price_adjusts = [];
            this.item_attention = {type: '', msg: ''};

            if (price_adjusts) {
              if (!this.is_cross_search) { // 一般プランナー
                apply_item_price_adjusts = price_adjusts.filter(p =>
                  p.apply_beare_code === item.beare_code &&
                  p.base_date &&
                  moment(p.base_date).toDate() > today &&
                  !p.place_without.includes(this.place_id) &&
                  (p.place_limited.length < 1 || p.place_limited.includes(this.place_id))
                );
                no_apply_item_price_adjusts = price_adjusts.filter(p =>
                  p.no_apply_beare_code === item.beare_code &&
                  p.base_date &&
                  moment(p.base_date).toDate() > today &&
                  !p.place_without.includes(this.place_id) &&
                  (p.place_limited.length < 1 || p.place_limited.includes(this.place_id))
                );
              }
              else { // 会場横断権限ありのプランナー
                apply_item_price_adjusts = price_adjusts.filter(p =>
                  p.apply_beare_code === item.beare_code &&
                  p.base_date &&
                  moment(p.base_date).toDate() > today &&
                  !this.place_ids.every(id => p.place_without.includes(id)) &&
                  (p.place_limited.length < 1 || this.place_ids.some(id => p.place_limited.includes(id)))
                );
                no_apply_item_price_adjusts = price_adjusts.filter(p =>
                  p.no_apply_beare_code === item.beare_code &&
                  p.base_date &&
                  moment(p.base_date).toDate() > today &&
                  !this.place_ids.every(id => p.place_without.includes(id)) &&
                  (p.place_limited.length < 1 || this.place_ids.some(id => p.place_limited.includes(id)))
                );
              }
            }

            // 価格改定商品の場合は価格改定メッセージ制御
            let price_adjust_date_str: string;
            if (apply_item_price_adjusts.filter(Boolean).length && !no_apply_item_price_adjusts.filter(Boolean).length) {
              // 挙式日が基準日より前
              price_adjust_date_str = moment(apply_item_price_adjusts[0].base_date).format('YYYY/MM/DD');
              this.item_attention = {type: 'vol', msg: price_adjust_date_str + constant.ITEM_ATTENTION_MSG.vol};
            }
            else if (no_apply_item_price_adjusts.filter(Boolean).length) {
              // 挙式日が基準日より前
              price_adjust_date_str = moment(no_apply_item_price_adjusts[0].base_date).format('YYYY/MM/DD');
              this.item_attention = {type: 'prev_vol', msg: price_adjust_date_str + constant.ITEM_ATTENTION_MSG.prev_vol};
            }
            // 価格改定商品でない場合は新旧商品メッセージ制御
            else {
              if (msg_end > today) {
                if (!item.vol_no || item.vol_no.length < 1) {
                  // 継続商品扱いで常に注文可能
                  this.item_attention = {type: '', msg: ''};;
                } else {
                  if (this.item_attention_vol_use && item.vol_no.length && item.vol_no.includes(vol) && !item.vol_no.includes(prev_vol)) {
                    // 新規商品
                    this.item_attention = {type: 'vol', msg: prev_vol_base_date_str + constant.ITEM_ATTENTION_MSG.vol};
                  } else if (this.item_attention_prev_vol_use && item.vol_no.length && !item.vol_no.includes(vol)) {
                    // 旧商品
                    this.item_attention = {type: 'prev_vol', msg: vol_base_date_str + constant.ITEM_ATTENTION_MSG.prev_vol};
                  } else {
                    // 継続商品
                    this.item_attention = {type: '', msg: ''};;
                  }
                }
              }
            }
          }));

          // 会場横断権限ありのプランナー
          if (this.is_cross_search) {
            if (!this.place_ids.every(id => item.place_without.includes(id)) &&
                (item.place_limited.length < 1 || this.place_ids.some(id => item.place_limited.includes(id)))
            ) {
              this.item = item;
              this.setItem();
            } else {
              this.router.navigate(['/not-found-page'], {relativeTo: this.route});
            }
          }
          // 一般プランナー
          else {
            if (!item.place_without.includes(this.place_id) && (item.place_limited.length < 1 || item.place_limited.includes(this.place_id)) ){
              this.item = item;
              this.setItem();
            } else {
              this.router.navigate(['/not-found-page'], {relativeTo: this.route});
            }
          }

        }
        else {
          this.router.navigate(['/not-found-page'], {relativeTo: this.route});
        }
        this.init = true;
      })
    );
  }

  private setItem(): void {
    this.sortImages(this.item);
    this.slide_conf = {
      "slidesToShow": 1,
      "slidesToScroll": 1,
      "nextArrow": "<div class='nav-btn next-slide'></div>",
      "prevArrow": "<div class='nav-btn prev-slide'></div>",
      "dots": true,
      "infinite": false
    }
    let description: string = this.sv_common.getActiveLink(this.item.description);
    $('.item-description').html(description);
    let set: string = this.sv_common.getActiveLink(this.item.set);
    $('.item-set').html(set);
  }

  private sortImages(item: Item): void {
    this.images = item.image;
    if (this.images) {
      this.images.sort((a, b) => {
        if (a.no > b.no) {
          return 1;
        } else {
          return -1;
        }
      });
    } else {
      this.images = [];
    }
  }

  public updateFavorite(event: any): void {
    // DBを更新
    let item_key: string = $(event.target).data('item_objectid');
    let favorite_in: boolean = !$(event.target).data('current_favorite');
    this.sv_account.updateFavorite(item_key, favorite_in);
    // 表示を更新
    $(event.target).data('current_favorite', favorite_in);
    $(event.target).toggleClass('favorite-item');
  }

  public addGiftboxItem(event: any): void {
    if (!this.isBrowser) return;
    let giftbox_status = this.local_storage.get(constant.STORAGE_LOCAL_GIFTBOX_AREA_STATUS);
    this.selected_giftbox = this.local_storage.get(constant.STORAGE_LOCAL_GIFTBOX);
    if (giftbox_status != constant.GIFTBOX_AREA_STATUS.OPEN || !this.selected_giftbox) {
      showSimpleMsg(constant.MSG_ERR_GIFTBOX_SELECT);
      return;
    }

    let item_objectID = $(event.target).data('item_objectid');
    this.subscription.add(
      this.sv_giftbox.addGiftboxItem(this.selected_giftbox, item_objectID).subscribe(data => {
        if (!data.result) { // 成功の場合メッセージは出さない。（商品がギフトセットエリアに表示されるだけ）
          showSimpleMsg(data.msg as string);
        }
      })
    );
  }

  // private saveSelectedGiftbox(): void {
  //   this.sv_giftbox.saveSelectedGiftbox(this.selected_giftbox);
  // }
  back(): void {
    window.history.back();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    // this.sv_item.subscription.unsubscribe();
    // this.sv_giftbox.subscription.unsubscribe();
  }

}
