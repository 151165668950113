import { Component, OnInit, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { SpinnerComponent } from '../../common/spinner/spinner.component';
import { ModalMessageComponent } from '../../common/modal-message/modal-message.component';

import { CommonService } from '../../service/common.service';
import { SessionService } from '../../service/session.service';
import { PlaceService } from '../../service/place.service';
import { AccountService } from '../../service/account.service';
import { AuthService } from '../../service/auth.service';

import { Account } from '../../models/account';
import { Place } from '../../models/place';
import { Mail } from '../../models/mail';
import * as constant from '../../models/constant';

import { environment } from '../../../environments/environment';

import moment from 'moment';
import $ from 'jquery';

declare function toggleCreateArea(): void;
declare function showSimpleMsg(msg: string): void;
declare function hideModal(): void;
declare const debugLog: any;

@Component({
  selector: 'app-member-regist',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    ModalMessageComponent,
    SpinnerComponent,
  ],
  templateUrl: './member-regist.component.html',
  styleUrls: ['./member-regist.component.scss']
})
export class MemberRegistComponent implements OnInit {
  private company_id: string = '';
  private place_id: string = '';
  public place: Place = new Place();
  private token: string = '';
  public event_date: Date = new Date(0);
  public pattern = constant.INPUT_PATTERN;
  public message: string = '';
  private is_anonymous: boolean = false;
  private anonymous_user: firebase.User | null = null;
  public ready: boolean = false;
  public saved: boolean = false;
  public regist_process: boolean = false;
  private user_data: firebase.auth.UserCredential | null = null;
  public time_out: boolean = false;
  private subscription: Subscription = new Subscription();
  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event: any) { // ブラウザを閉じる前
    if (this.regist_process && this.user_data) {
      this.user_data.user?.delete()
      .then(() => debugLog("登録ユーザーを削除しました：", new Date(), this.user_data?.user?.uid))
      .catch(() => debugLog("登録ユーザー削除失敗：", new Date(), this.user_data?.user?.uid));
    }
    this.removeAnonymousUser();
  }
  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event: any) { // ページ移動前
    this.removeAnonymousUser();
  }
  public success = false;
  public cust_types = constant.CUST_TYPE_SELECT;
  public member: Account = {
    objectID: '',
    email: '',
    default_password: '',
    event_id: '',
    first_name: '',
    last_name: '',
    first_kname: '',
    last_kname: '',
    zip: '',
    addr1: '',
    addr2: '',
    addr3: '',
    addr4: '',
    tel: '',
    favorite_items: [],
    company: [],
    place: [],
    current_company: '',
    current_place: '',
    info: null,
    role: [constant.ROLE.MNG],
    current_role: constant.ROLE.MNG,
    cust_type: constant.CUST_TYPE.NONE,
    manager_flg: false,
    cross_search_flg: false,
    status: constant.USER_STATUS.AVAILABLE,
    created: new Date(),
    updated: new Date(),
    updated_user: {
      uid: '',
      name: "[mng-regist]"
    },
    delete_flg: false
  };

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private act_route: ActivatedRoute,
    private sv_session: SessionService,
    private sv_place: PlaceService,
    private sv_account: AccountService,
    private sv_common: CommonService,
    private sv_auth: AuthService,
  ) {
    moment.updateLocale("ja", { weekdaysShort: ["日","月","火","水","木","金","土"] });
  }

  ngOnInit() {
    // 匿名ユーザーのみ有効。登録済みユーザーがログイン中にアクセスした場合はログアウトさせてから処理を続行。
    this.sv_session.loginCheck();
    this.subscription.add(
      this.sv_session.sessionState.pipe(take(1)).subscribe(session => {
        if (session) {
          this.sv_auth.sign0ut();
          setTimeout(() => this.afs.firestore.enableNetwork(), 500);
        }
        setTimeout(() => this.signInAnonymousUser(), 500);
      })
    );
  }

  public reload(): void {
    this.time_out = false;
    this.afs.firestore.enableNetwork().then(() => {
      setTimeout(() => this.signInAnonymousUser(), 500);
      toggleCreateArea();
    }).catch(error => {
      this.message = constant.MSG_SET_REGIST_FAILED;
    });
  }

  private signInAnonymousUser(): void {
    this.removeAnonymousUser();
    if (!this.anonymous_user) {
      this.afAuth.signInAnonymously()
      .then(() => {
        this.setupAuthSubscription();
        this.subscription.add(
          this.act_route.params.subscribe(params => {
            this.company_id = params['cid'];
            this.place_id = params['pid'];
            this.token = params['token'];
            this.member.company = [this.company_id]
            this.member.current_company = this.company_id;
            this.member.place = [this.place_id];
            this.member.current_place = this.place_id;
            this.getPlace();

            if ((!this.company_id || !this.place_id) && !this.saved) {
              this.message = constant.MSG_SET_STAFF_REGIST_FAILED;
            }
          })
        );
        toggleCreateArea();
      })
      .catch(error => {
        debugLog("匿名ユーザーでログインに失敗：", error.code, error.message);
        this.message = constant.MSG_PROCESS_FAILED;
        return;
      });
    }
  }

  async setupAuthSubscription() {
    try {
      const unsubscribe = await this.afAuth.onAuthStateChanged(user => {
        if (user) {
          const isAnonymous = user.isAnonymous;
          this.is_anonymous = isAnonymous;
          if (isAnonymous) {
            this.anonymous_user = user;
            debugLog("匿名ユーザーでログインしました：", new Date(), user.uid, isAnonymous, user.metadata.creationTime);
            // 生成後1時間以上経過した匿名ユーザーは無効とする
            const creationTime: moment.Moment = moment(user.metadata.creationTime);
            const diff: number = Math.abs(creationTime.diff(moment(new Date())) / 3600 / 1000);
            if (diff > 1) {
              this.time_out = true;
              this.message = constant.MSG_SET_REGIST_FAILED_TIMEOUT;
              this.removeAnonymousUser();
              return;
            }
          }
        } else {
          debugLog("匿名ユーザーアカウントなし：");
          return;
        }
      });

      this.subscription.add(unsubscribe);
    } catch (error) {
      debugLog("認証サブスクリプション設定中のエラー:", error);
    }
  }

  private getPlace(): void {
    this.subscription.add(
      this.sv_place.getPlace(this.company_id, this.place_id).pipe(take(1)).subscribe(place => {
        if (place) {
          // token 不正
          if (place.token != this.token) {
            this.message = constant.MSG_SET_REGIST_TOKEN_FAILED;
            this.ready = false;
            showSimpleMsg(this.message);
            return;
          }
          // token 正常
          else {
            this.message = '';
            this.place = place;
            this.ready = true;
          }
        } else {
          if (!this.saved) {
            if (this.time_out) {
              this.message = constant.MSG_SET_REGIST_FAILED_TIMEOUT;
            } else {
              this.message = constant.MSG_SET_STAFF_REGIST_FAILED;
            }
          }
        }
      })
    );
  }

  public regist(): void {
    this.regist_process = true;
    this.afAuth.createUserWithEmailAndPassword(this.member.email, this.member.default_password)
    .then((user_data) => {
      if (user_data.user) {
        const isAnonymous = user_data.user.isAnonymous;
        this.user_data = user_data;
        debugLog("登録ユーザーでログインしました：", new Date(), user_data.user.uid, isAnonymous);
        this.is_anonymous = isAnonymous;
        this.member.updated_user = {uid: user_data.user.uid, name: this.member.last_name + this.member.first_name};
        // メンバー新規登録
        debugLog('ACCOUNT SAVE');
        this.sv_account.save(user_data.user.uid, this.member).subscribe(data => {
          if (data && data.result) {
            this.message = constant.MSG_SET_REGIST_SUCCESSED;
            this.success = true;
            this.regist_process = false;
          }
          else {
            this.message = constant.MSG_SET_REGIST_FAILED;
            // accountデータ削除
            this.sv_account.del(this.member.objectID, this.member.updated_user).subscribe(account_del => {
              // firebaseユーザー削除
              user_data.user?.delete()
              .then(() => debugLog("登録ユーザーを削除しました：", new Date(), user_data.user?.uid, isAnonymous))
              .catch(() => debugLog("登録ユーザー削除失敗：", new Date(), user_data.user?.uid, isAnonymous));
              this.regist_process = false;
              this.user_data = null;
            });
          }
          showSimpleMsg(this.message);
        });
      }
      else {
        debugLog("登録ユーザーアカウントなし：");
        this.regist_process = false;
        this.user_data = null;
      }
    })
    .catch(error => {
      this.message = constant.MSG_SET_REGIST_FAILED + "(UserCreateError)";
      let err: string = error;
      let pattern: any = /The email address is already in use by another account/;
      if (pattern.test(error)) {
        this.message = constant.MSG_SET_REGIST_FAILED_EMAIL_DUPLICATE;
      }
      this.regist_process = false;
      this.user_data = null;
      showSimpleMsg(this.message);
      debugLog('ユーザー作成失敗：' + error);
    });
  }

  public onModalSimpleMsgEvent(event: boolean): void {
    if (event) {
      this.saved = true;
      this.removeAnonymousUser();
      $('#modal-error').hide();
      hideModal();
      if (this.success) {
        let mail: Mail = {
          subject: constant.MAIL_SUBJECT_REGIST,
          title: constant.MAIL_SUBJECT_REGIST,
          name: `${this.member.last_name} ${this.member.first_name}`,
          email: this.member.email,
          message: constant.MAIL_BODY_REGIST + `${environment.BASE_URL}/sign_in`,
          bcc: ''
        };
        this.subscription.add(
          this.sv_common.sendMail(mail).subscribe(send => {
            debugLog(send.msg);
            this.afAuth.signOut().then(() => {
              this.sv_auth.sign0ut();
              this.afs.firestore.disableNetwork()
            });
          })
        );
      }
      this.afAuth.signOut().then(() => {
        this.sv_auth.sign0ut();
        this.afs.firestore.disableNetwork();
      });
    }
  }

  private removeAnonymousUser(): boolean {
    if (!this.anonymous_user) {
      return false;
    }

    this.anonymous_user.delete()
    .then(() => {
      debugLog('匿名ユーザーを削除しました：' + this.anonymous_user?.uid);
      this.anonymous_user = null;
    })
    .catch(error => {
      debugLog('匿名ユーザーの削除失敗：' + this.anonymous_user?.uid);
    });
    this.afs.firestore.disableNetwork();
    return false;
  }

  public passwordCheck(): void {
    if ($('#password_check').prop('checked')) {
      $('#password').attr('type', 'text');
    } else {
      $('#password').attr('type', 'password');
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.removeAnonymousUser();
  }

}
