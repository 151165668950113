<div class="content-block content-block-max">
  <div class="content-block-inner faq-block">
    <div class="flex-box">
      <div class="title-content faq-tit">よくあるご質問</div>
    </div>
  </div><!--/content-block-inner-->

  <div class="list-wrap faq-content">
    <div class="faq-title">サービスについて</div>
    <ul class="faq-content">
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">ネットワーク環境について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">
                    【パソコン】<br>
                    ・Firefox 最新版<br>
                    ・Google Chrome最新版<br>
                    ・Microsoft Edge最新版（Windows10の場合）<br>
                    <br>
                    【スマートフォン】<br>
                    ■iPhone<br>
                    ・OS：iOS 11.0 以上<br>
                    ・ブラウザ：Safari 最新<br>
                    <br>
                    ■Android<br>
                    ・OS：Android 6.0 以上<br>
                    ・ブラウザ：標準ブラウザ または Chrome最新<br>

                  </div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <!-- <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">旧字体・外字について</div>
          </div>
        </div>
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">確認中</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li> -->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">披露宴当日の引出物の案内方法について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">ご結婚式当日、ゲストのテーブルにはご自宅配送をお知らせる「卓上カード」をご用意いたします。<br>
                  卓上カードは挙式2日前に会場にお届けいたします。
                  <p><img src="assets/img/faq_img_1.png" alt="卓上カード イメージ"></p></div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <!-- <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">ゲスト宅へお届け後に破損等があった場合について</div>
          </div>
        </div>
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">ゲスト宅へ引出物をお届けの際は、「サポートカード？」をお入れしていおります。<br>
                  万一、破損があった場合にも応いたしますのでご安心ください。
                  <p><img src="assets/img/faq_img_2.png" alt="サポートカード イメージ"></p></div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li> -->
      <!-- <li class="faq-box cont-last">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">退会方法について</div>
          </div>
        </div>
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">確認中</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </li> -->
    </ul><!--/faq-content-->
  </div><!--/list-wrap faq-content-->

  <div class="list-wrap faq-content">
    <div class="faq-title">注文について</div>
    <ul class="faq-content">
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">ご注文方法について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">
                    <ul>
                      <li>STEP1：ゲストリストを作成。</li>
                      <li>STEP2：ギフトセットを作りに商品を入れましょう。</li>
                      <li>STEP3：ギフトセットとゲストを紐づけましょう。</li>
                      <li>STEP4：カートへ追加し注文。</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->

      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">注文可能数について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">1ギフトセットにつき、記念品またはカタログ式ギフトを1点からご注文いただけます。<br>
                  引菓子とプラスワンアイテムのみのご注文はお承りできませんので、予めご了承ください。</div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">熨斗について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">
                    商品ごとへの熨斗はお付けできかねます代わりに、熨斗カードを同梱してお届けいたします。<br>
                    熨斗カードに印字するお名前は、下記よりお選びいただけます。<br><br>
                    ・新郎姓+名前連名<br>
                    ・新婦姓+名前連名<br>
                    ・両家の姓+名前連名<br>
                    ・名前のみ連名
                    <div class="column-box">
                      <div class="img-left img-left-1">
                        <img src="assets/img/faq_img_3.png" alt="表面のデザイン">
                        <div class="column-text">表面のデザイン</div>
                      </div>
                      <div class="img-right img-right-1">
                        <img src="assets/img/faq_img_4-1.png" alt="裏面の文面パターン">
                        <div class="column-text">裏面の文面パターン A<br>※名前は、両家の姓＋名前連名の場合。</div>
                      </div>
                      <div class="img-right img-right-1">
                        <img src="assets/img/faq_img_4-2.png" alt="裏面の文面パターン">
                        <div class="column-text">裏面の文面パターン B<br>※名前は、両家の姓＋名前連名の場合。</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">贈る相手を連名にする場合について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">ゲストリストを連名にしてご登録ください。
                  <p><img src="assets/img/faq_img_5.png" alt="連盟にする場合の方法"></p></div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">贈る相手の電話番号について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">お届け先様の電話番号は必須項目となりますので、もしわからない場合は、新郎新婦様のお電話番号を入力してください。<br>
                    お届けに際し、住所不明等が発生した場合、ご住所の確認等のご連絡をさせていただく場合がございます。</div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">お届け日の指定について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">
                    お届け日は挙式日の翌日が設定をされています。<br>
                    一括でお届け日を変更される場合は、下記画面の①よりご指定ください。<br>
                    お届け先様ごとにご変更される場合は、下記画面の②よりご指定ください。<br>
                    なお、交通機関の不具合や悪天候等の不可抗力が生じた場合にはご希望にそえない可能性もございますので、予めご了承くださいませ。
                    <div class="column-box">
                      <div class="img-left img-left-2">
                        <img src="assets/img/faq_img_6.png" alt="お届け日の指定　イメージ" class="pc">
                      </div>
                      <div class="img-right img-right-2">
                        <img src="assets/img/faq_img_7.png" alt="お届け日の指定　イメージ" class="sp">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">配送時間の指定について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">配送時間のご指定は出来かねますので、予めご了承くださいませ。</div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">配送料について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div *ngIf="!is_additional" class="faq-content-text">
                    ギフトボックス内の商品合計額(税抜)が5000円未満の場合、1配送につき別途送料{{(delive_fee + Math.floor(delive_fee * rate.TAX / 100)) | currency : 'JPY'}}<span class="cap blank_l">(税込)</span>がかかります。<br>
                    沖縄県本島へお届けの場合は、商品合計額に関わらず1配送につき別途送料{{(delive_fee_okinawa + Math.floor(delive_fee_okinawa * rate.TAX / 100)) | currency : 'JPY'}}<span class="cap blank_l">(税込)</span>がかかります。
                  </div>
                  <div *ngIf="is_additional" class="faq-content-text">
                    1配送につき送料{{(delive_fee_additional + Math.floor(delive_fee_additional * rate.TAX / 100)) | currency : 'JPY'}}<span class="cap blank_l">(税込)</span>となります。<br>
                    沖縄県本島へお届けの場合は、1配送につき送料{{(delive_fee_okinawa + Math.floor(delive_fee_okinawa * rate.TAX / 100)) | currency : 'JPY'}}<span class="cap blank_l">(税込)</span>となります。
                  </div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">注文の期日について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">商品選定の決定期日は挙式日の20日前までとなります。(年末年始・大型連休などは除く)<br>
                  会場ごとに期日が異なる場合がございますので、詳細は担当プランナーにお問い合わせください。</div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">注文の最終確定期日について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">商品確定の最終期日は挙式日の14日前までとなります。(年末年始・大型連休などは除く)<br>
                  期日を過ぎてからのご変更はできませので、予めご了承ください。<br>
                  会場ごとに期日が異なる場合がございますので、詳細は担当プランナーにお問い合わせください。</div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">式の直前に欠席者が出た場合について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">商品確定の最終期日をもって数を確定させて頂いております。<br>
                  その後に、キャンセルになった場合は新郎新婦さまのご自宅へお届させて頂きます。</div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box cont-last">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">その他情報の変更について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">商品確定の最終期日をもってデータの更新ができなくなりますので、予めご了承ください。</div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
    </ul><!--/faq-content-->
  </div><!--/list-wrap faq-content-->

  <div class="list-wrap faq-content">
    <div class="faq-title">配送について</div>
    <ul class="faq-content">
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">お届けスタイルについて</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">
                    お選びいただいたギフトに、文面指定をいただいた熨斗カードを同梱してお届けします。
                    <!-- <p><img src="assets/img/faq_img_8.png" alt="お届けスタイルについて"></p> -->
                  </div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">配送エリアについて</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">
                    配送エリアは国内のみとさせていただきます。(※離島を除く)<br>
                    海外配送のお取り扱いはございません。
                  </div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">宅配ボックスへのお届けについて</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">原則、ドライバーからゲスト様へ手渡しでのお届けをしておりますが、お届け先様がご不在で宅配ボックスが設置されている場合は、宅配ボックスへお入れいたします。（※ギフトセットに食品が含まれない場合のみ）<br>
                    ただし、宅配ボックスへお届けした場合、弊社及び配送業者は紛失等の責任は負いかねますので、予めご了承ください。</div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
      <li class="faq-box">
        <div class="faq">
          <div class="faq-q faq-accordion">
            <div class="faq-content-title">Q</div>
            <div class="faq-content-text">お届け先が不在の場合について</div>
          </div>
        </div><!--/faq-->
        <ul class="faq-content">
          <li>
            <div class="faq">
              <div class="faq-a">
                <div class="faq-accordion">
                  <div class="faq-content-title text-red">A</div>
                  <div class="faq-content-text">お届け先様がご不在等でお受け取りになれない場合、お届け指定日から1週間は配送業者での保管となります。<br>
                    保管期日の1週間を経過しても、何らかの理由により再配達依頼等もなく、お受け取りいただけない場合はご注文者様宛に転送させていただきます。<br>
                    尚、お届け先様のご不在等でお荷物をお申込み時の住所から別の住所へ転送する場合は、お受取り人様にて受取り時に別途送料が実費でかかる場合がございます。何卒ご了承ください。
                  </div>
                </div>
              </div>
            </div><!--/faq-->
          </li>
        </ul><!--/faq-content-->
      </li><!--/faq-box-->
    </ul><!--/faq-content-->
  </div><!--/list-wrap faq-content-->

</div><!--/content-block-->
