import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, of, Subject} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Company } from '../models/company';
import { Place } from '../models/place';
import { Account } from '../models/account';
import { ProcessResult } from '../models/process_result';

import * as constant from '../models/constant';

declare const debugLog: any;

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private companyDocument!: AngularFirestoreDocument<Company>;
  private placeDocument!: AngularFirestoreDocument<Place>;
  private accountCollection!: AngularFirestoreCollection<Account>;
  private company!: Observable<Company>
  private place!: Observable<Place>
  private placeis!: Observable<Place[]>
  private accounts!: Observable<Account[]>;

  constructor(
    private firestore: AngularFirestore,
  ) { }

  /**
   * 企業情報を取得
   *
   * @param {string} key
   * @returns {Observable<Company>}
   * @memberof CompanyService
   */
  getCompany(key: string): Observable<Company>{
    this.companyDocument = this.firestore.doc<Company>(`company/${key}`);
    this.company = this.companyDocument.snapshotChanges().pipe(
      map(doc => ({
        objectID: doc.payload.id,
        name: doc.payload.data()?.name ?? '',
        kname: doc.payload.data()?.kname ?? '',
        zip: doc.payload.data()?.zip ?? '',
        addr1: doc.payload.data()?.addr1 ?? '',
        addr2: doc.payload.data()?.addr2 ?? '',
        addr3: doc.payload.data()?.addr3 ?? '',
        addr4: doc.payload.data()?.addr4 ?? '',
        tel: doc.payload.data()?.tel ?? '',
        fax: doc.payload.data()?.fax ?? '',
        email: doc.payload.data()?.email ?? '',
        represent_user_uid: doc.payload.data()?.represent_user_uid ?? '',
        represent_user_email: doc.payload.data()?.represent_user_email ?? '',
        token: doc.payload.data()?.token ?? '',
        slf_company_code: doc.payload.data()?.slf_company_code ?? '',
        slf_place_code: doc.payload.data()?.slf_place_code ?? '',
        slf_id: doc.payload.data()?.slf_id ?? '',
        delive_fee_type: doc.payload.data()?.delive_fee_type ?? 0,
        onew_link: doc.payload.data()?.onew_link ?? false,
        onew_base_key: doc.payload.data()?.onew_base_key ?? '',
        created: doc.payload.data()?.created ?? new Date(0),
        updated: doc.payload.data()?.updated ?? new Date(0),
        updated_user: doc.payload.data()?.updated_user ?? {uid: '', name: ''},
        delete_flg: doc.payload.data()?.delete_flg ?? false
      }))
    );

    return this.company.pipe(
      catchError(this.handleError<Company>(`getCompany id=${key}`))
    );
  }

  /**
   * 取引先の送料区分を取得
   *
   * @param {string} key
   * @returns {Observable<number>}
   * @memberof CompanyService
   */
  getCompanyDeliveFeeType(key: string): Observable<number | null>{
    this.companyDocument = this.firestore.doc<Company>(`company/${key}`);
    const delive_fee_type = this.companyDocument.snapshotChanges().pipe(
      map(doc => {
        if (!doc.payload.exists) {
          return null;
        } else {
          let obj: number = doc.payload.data()!.delive_fee_type;
          return obj;
        }
      })
    );
    return delive_fee_type.pipe(
      catchError(this.handleError<number | null>(`getCompanyDeliveFeeType id=${key}`))
    );
  }

  /**
   * 企業のメンバーを取得
   *
   * @param {string} company_key
   * @returns {Observable<Account[]>}
   * @memberof PlaceService
   */
  getMembers(company_key: string): Observable<Account[]> {
    this.accountCollection = this.firestore.collection<Account>('account', ref => ref
      .where('company', 'array-contains', company_key)
      .where('delete_flg', '==', false)
      .orderBy('last_kname')
      .orderBy('first_kname')
    );
    this.accounts = this.accountCollection.snapshotChanges().pipe(
      map(docs =>
        docs.map(doc => ({
          objectID: doc.payload.doc.id,
          email: doc.payload.doc.data().email,
          default_password: '',
          event_id: doc.payload.doc.data().event_id,
          first_name: doc.payload.doc.data().first_name,
          last_name: doc.payload.doc.data().last_name,
          first_kname: doc.payload.doc.data().first_kname,
          last_kname: doc.payload.doc.data().last_kname,
          zip: doc.payload.doc.data().zip,
          addr1: doc.payload.doc.data().addr1,
          addr2: doc.payload.doc.data().addr2,
          addr3: doc.payload.doc.data().addr3,
          addr4: doc.payload.doc.data().addr4,
          tel: doc.payload.doc.data().tel,
          favorite_items: doc.payload.doc.data().favorite_items,
          company: doc.payload.doc.data().company,
          place:doc.payload.doc.data().place,
          current_company: doc.payload.doc.data().current_company,
          current_place: doc.payload.doc.data().current_place,
          info: doc.payload.doc.data().info,
          role: doc.payload.doc.data().role,
          current_role: doc.payload.doc.data().current_role,
          cust_type: doc.payload.doc.data().cust_type,
          manager_flg: doc.payload.doc.data().manager_flg,
          cross_search_flg: doc.payload.doc.data().cross_search_flg,
          status: doc.payload.doc.data().status,
          created: doc.payload.doc.data().created,
          updated: doc.payload.doc.data().updated,
          updated_user: doc.payload.doc.data().updated_user,
          delete_flg: doc.payload.doc.data().delete_flg
      })))
    );
    return this.accounts.pipe(
      catchError(this.handleError<Account[]>('getMembers', []))
    );
  }

  /**
   * 会場情報を取得
   *
   * @param {string} key
   * @returns {Observable<Place>}
   * @memberof CompanyService
   */
  getPlace(company_key: string, place_key: string): Observable<Place>{
    this.companyDocument = this.firestore.doc<Company>(`company/${company_key}`);
    this.place = this.companyDocument.collection<Place>('place').doc<Place>(place_key).snapshotChanges().pipe(
      map(doc => ({
        objectID: doc.payload.id,
        name: doc.payload.data()?.name ?? '',
        kname: doc.payload.data()?.kname ?? '',
        zip: doc.payload.data()?.zip ?? '',
        addr1: doc.payload.data()?.addr1 ?? '',
        addr2: doc.payload.data()?.addr2 ?? '',
        addr3: doc.payload.data()?.addr3 ?? '',
        addr4: doc.payload.data()?.addr4 ?? '',
        tel: doc.payload.data()?.tel ?? '',
        fax: doc.payload.data()?.fax ?? '',
        email: doc.payload.data()?.email ?? '',
        token: doc.payload.data()?.token ?? '',
        item_attention_vol_use: doc.payload.data()?.item_attention_vol_use ?? true,
        item_attention_prev_vol_use: doc.payload.data()?.item_attention_prev_vol_use ?? true,
        vol_base_date: doc.payload.data()?.vol_base_date ?? null,
        prev_vol_base_date: doc.payload.data()?.prev_vol_base_date ?? null,
        vol: doc.payload.data()?.vol ?? 0,
        prev_vol: doc.payload.data()?.prev_vol ?? 0,
        place_tax_rate: doc.payload.data()?.place_tax_rate ?? {rule: false, rate: 0},
        created: doc.payload.data()?.created ?? new Date(0),
        updated: doc.payload.data()?.updated ?? new Date(0),
        updated_user: doc.payload.data()?.updated_user ?? {uid: '', name: ''},
        delete_flg: doc.payload.data()?.delete_flg ?? false
      }))
    );

    return this.place.pipe(
      catchError(this.handleError<Place>(`getPlace id=${place_key}`))
    );
  }

  /**
   * 会場情報一覧を取得
   *　（指定企業の会場を全て取得）
   * @param {string} company_key
   * @returns {Observable<Place[]>}
   * @memberof CompanyService
   */
  getPlaces(company_key: string): Observable<Place[]>{
    this.companyDocument = this.firestore.doc<Company>(`company/${company_key}`);
    this.placeis = this.companyDocument.collection<Place>('place', ref => ref.where('delete_flg', '==', false))
    .snapshotChanges().pipe(
      map(datas =>
        datas.map(data => ({
          objectID: data.payload.doc.id,
          name: data.payload.doc.data().name,
          kname: data.payload.doc.data().kname,
          zip: data.payload.doc.data().zip,
          addr1: data.payload.doc.data().addr1,
          addr2: data.payload.doc.data().addr2,
          addr3: data.payload.doc.data().addr3,
          addr4: data.payload.doc.data().addr4,
          tel: data.payload.doc.data().tel,
          fax: data.payload.doc.data().fax,
          email: data.payload.doc.data().email,
          token: data.payload.doc.data().token,
          item_attention_vol_use: data.payload.doc.data().item_attention_vol_use,
          item_attention_prev_vol_use: data.payload.doc.data().item_attention_prev_vol_use,
          vol_base_date: data.payload.doc.data().vol_base_date,
          prev_vol_base_date: data.payload.doc.data().prev_vol_base_date,
          vol: data.payload.doc.data().vol,
          prev_vol: data.payload.doc.data().prev_vol,
          place_tax_rate: data.payload.doc.data().place_tax_rate,
          created: data.payload.doc.data().created,
          updated: data.payload.doc.data().updated,
          updated_user: data.payload.doc.data().updated_user,
          delete_flg: data.payload.doc.data().delete_flg
        }))
      )
    );

    return this.placeis.pipe(
      catchError(this.handleError<Place[]>(`getPlaceis`, []))
    );
  }

  /**
   * 会場ID一覧を取得
   *　（指定企業の会場IDを全て取得）
   * @param {string} company_key
   * @returns {Observable<string[]>}
   * @memberof CompanyService
   */
  getPlaceIds(company_key: string): Observable<string[]> {
    this.companyDocument = this.firestore.doc<Company>(`company/${company_key}`);
    const ids: Observable<string[]> = this.companyDocument.collection<Place>('place', ref => ref.where('delete_flg', '==', false))
    .snapshotChanges().pipe(
      map(docs =>
        docs.map(doc =>
          doc.payload.doc.id
        )
      )
    );

    return ids.pipe(
      catchError(this.handleError<string[]>(`getPlaceIds`, []))
    );
  }

  /**
   * 代表者情報の更新
   *
   * @param {string} key
   * @param {string} uid
   * @param {string} email
   * @returns {Observable<ProcessResult>}
   * @memberof CompanyService
   */
  update_representative(key: string, uid: string, email: string): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const company_ref = this.firestore.doc<Company>(`company/${key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(company_ref);
      transaction.update(company_ref, {
        'represent_user_uid': uid,
        'represent_user_email': email
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * 失敗したHttp操作を処理します。
   * アプリを持続させます。
   * @param operation - 失敗した操作の名前
   * @param result - observableな結果として返す任意の値
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: リモート上のロギング基盤にエラーを送信する
      console.error(error); // かわりにconsoleに出力

      // TODO: ユーザーへの開示のためにエラーの変換処理を改善する
      // this.log(`${operation} failed: ${error.message}`);

      // 空の結果を返して、アプリを持続可能にする
      return of(result as T);
    };
  }

}
