<div class="content-block content-block-max">
  <div class="content-block-inner">
    <div class="flex-box">
      <div class="title-content mng">挙式情報</div>
    </div>
  </div><!--/content-block-inner-->

  <div class="page" *ngIf="pages.length > 1">
    <div class="no" (click)="paging(1, 0)"><<</div>
    <div class="no" *ngFor="let page of pages; index as i" (click)="paging(page, i)" [class.current]="i == select_page_index">{{page}}</div>
    <div class="no" (click)="paging(pages.length, pages.length - 1)">>></div>
  </div>

  <div class="header-item-wrap">
    <div class="inner-header">
      <div class="count-info">
        <div class="result" *ngIf="event_search_result?.nbHits; else noData">
          <span *ngIf="event_search_result.nbHits > event_search_result.hitsPerPage; else onePage" class="js-itemCount">
            {{(select_page_index * event_search_result.hitsPerPage) + 1}}〜
            {{ (pages.length - 1 > select_page_index) ? ((select_page_index + 1) * event_search_result.hitsPerPage) : event_search_result.nbHits }}件／
            {{event_search_result.nbHits}}件
          </span>
          <ng-template #onePage><div class="result">{{event_search_result.nbHits}}件</div></ng-template>
        </div>
        <ng-template #noData><div class="result">0件</div></ng-template>
      </div><!-- count-info-->
      <div class="giftbox-make-btn js-createWeddingInfoBtn" (click)="toggleAddOpen()">
        <div class="icon-giftplus">
          <div class="icon-plus-l"></div>挙式を追加
        </div>
      </div>
    </div><!--/inner-header-->
  </div><!--/header-item-wrap-->

  <div class="list-wrap">
    <!-- 新規登録エリア -->
    <div class="js-listRow" id="addRow" *ngIf="add_flg">
      <ng-container *ngTemplateOutlet="editArea"></ng-container>
    </div>

    <a *ngFor="let event of events; index as i" (click)="selected(i)" class="item-list js-listRow">
      <div class="order-info-box list-fz-size-s">
        <div class="lits-cont-box-odd">
          <div class="orderer-box">
            <div class="order-number">
              挙式日：{{convertTimestampToDate(event?.date) | date}}
            </div>
            <div class="order-number">
              注文最終確定：{{order_limit[i] | date}}
            </div>
            <div class="wed-name">
              <p>{{event?.name}}</p>
            </div>
          </div>
        </div><!--/mng-box-odd-->
        <div class="lits-cont-box-even">
          <div *ngIf="!event?.cust1_id || !event?.cust2_id" class="state-box text-red">顧客登録未完了</div>
          <div *ngIf="event?.memo" class="state-box">※メモあり</div>
          <div *ngIf="event?.status==5"><span class="red red-square">無効</span></div>
        </div><!--/mng-box-even-->
      </div><!--/order-info-box-->
    </a><!--/item-list-->

  </div><!--/list-wrap-->
</div><!--/content-block-->

<!-- 新規登録エリア テンプレート -->
<ng-template #editArea
  let-objectID = "objectID"
  let-name = "name"
  let-date = "date"
  let-memo = "memo"
  let-company_id = "company_id"
  let-place_id = "place_id"
  let-staff_ids = "staff_ids"
  let-created = "created">

  <form #eventForm="ngForm" (ngSubmit)="save(objectID, $event)" novalidate>
    <div class="gift-make-block js-createArea">
      <div class="item-list-open h-adr item-list-open-hed">

      <div class="input-box-wrap">
        <div class="input-box-title">
          <div class="title-required"><span>挙式日</span></div>
          <div class="text-frame-s">必須</div>
        </div>
        <div class="flex-box j-content-start flow-style">
          <div class="input-box">
            <div *ngIf="date?.errors.required" class="error-text input-error-text">※挙式日は必須です</div>
            <div class="calendar-input w110">
              <mat-form-field [class.error-item]="date?.errors && (date?.dirty || date?.touched)">
                <input matInput [matDatepicker]="picker" name="date" readonly
                  #date="ngModel" required
                  [(ngModel)]="editData.date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div><!--/input-box-wrap-->

      <div class="input-box-wrap">
        <div class="input-box-title">
          <div class="title-required"><span>挙式名</span></div>
            <div class="text-frame-s">必須</div>
        </div>
        <div class="flex-box j-content-start flow-style">
          <div class="input-box">
            <div *ngIf="name?.errors.required && (name?.dirty || name?.touched)" class="error-text input-error-text">※挙式名は必須です</div>
            <input class="input-column input-size-m-style2" type="text" placeholder="例：山田 太郎・花子様" name="name"
              required maxlength="100" #name="ngModel"
              [(ngModel)]="editData.name"
              [class.error-item]="name?.errors && (name?.dirty || name?.touched)"/>
          </div>
        </div>
      </div><!--/input-box-wrap-->

      <div *ngIf="!session.data?.current_company" class="input-box-wrap">
        <div class="input-box-title">
          <div class="title-required"><span>企業名</span></div>
        </div>
        <div class="input-box">
          <div *ngIf="company.errors?.['maxlength']" class="error-text input-error-text">※企業名は100文字までです</div>
          <input class="input-column" type="text" name="company_name" placeholder="企業名を入力してください" value=""
            #company="ngModel" required maxlength="100"
            [(ngModel)]="editData.company_name">
        </div>
      </div><!--/input-box-wrap-->

      <div class="input-box-wrap">
        <div class="input-box-title">
          <div class="title-required"><span>会場</span></div>
            <div class="text-frame-s">必須</div>
        </div>
        <div class="input-box">
          <div class="flex-box j-content-start flow-style">
            <div class="input-box">

              <div *ngIf="session.data?.current_place">
                <div *ngIf="place?.errors?.['required'] && (place?.dirty || place?.touched)" class="error-text input-error-text">※会場は必須です</div>
                <div class="select-wrap mgtb2">
                    <select data-ng-options="v as k for (k, v) in places" name="place" class="p-region-id"
                      #place="ngModel" required
                      [(ngModel)]="editData.place_id"
                      (ngModelChange)="select_place=$event"
                      (change)="onChangePlace()"
                      [class.error-item]="place.errors && (place.dirty || place.touched)">
                      <option *ngFor="let place of places" [ngValue]="place.key">{{place.value}}</option>
                    </select>
                </div>
              </div>

              <div *ngIf="!session.data?.current_place">
                <div *ngIf="place?.errors?.['required'] && (place?.dirty || place?.touched)" class="error-text input-error-text">※会場は必須です</div>
                <div *ngIf="place?.errors?.['maxlength']" class="error-text input-error-text">※会場名は100文字までです</div>
                <div class="input-box js-OtherHall">
                  <input class="input-column" type="text" name="place_name" placeholder="会場名を入力してください" value=""
                    #place="ngModel" required maxlength="100"
                    [(ngModel)]="editData.place_name"
                    [class.error-item]="place.errors && (place.dirty || place.touched)">
                </div>
              </div>

            </div>
          </div>
        </div><!--/input-box-->
      </div><!--/input-box-wrap-->

      <div class="input-box-wrap">
        <div class="input-box-title">
          <div class="title-required"><span>担当者1</span></div>
            <div class="text-frame-s">必須</div>
        </div>
        <div class="input-box">
          <div class="flex-box j-content-start flow-style">
            <div class="input-box">
              <div *ngIf="staff1?.errors?.['required'] && (staff1?.dirty || staff1?.touched)" class="error-text input-error-text">※担当者1は必須です</div>
              <div class="select-wrap input-size-m">
                <div class="select-wrap mgtb2">
                  <select data-ng-options="v as k for (k, v) in members1" name="staff1" class="p-region-id"
                    #staff1="ngModel" required
                    [(ngModel)]=editData.staff_ids[0]
                    (ngModelChange)="select_member=$event"
                    (change)="onChangeStaff(1)"
                    [disabled]="!members1"
                    [class.error-item]="(staff1?.errors && (staff1?.dirty || staff1?.touched)) || !members1">
                    <option *ngFor="let member of members1" [ngValue]="member.key" [disabled]="member.disabled">{{member.value}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div><a (click)="setYourself()">担当者に自分を設定</a></div>
          </div>
        </div><!--/input-box-->
      </div><!--/input-box-wrap-->

      <div class="input-box-wrap">
        <div class="input-box-title">
          <div class="title-required"><span>担当者2</span></div>
        </div>
        <div class="input-box">
          <div class="input-box">
            <div class="select-wrap input-size-m">
              <select data-ng-options="v as k for (k, v) in members2" name="staff2" class="p-region-id"
                #staff2="ngModel"
                [(ngModel)]=editData.staff_ids[1]
                (ngModelChange)="select_member=$event"
                (change)="onChangeStaff(2)"
                [disabled]="!members2">
                <option *ngFor="let member of members2" [ngValue]="member.key" [disabled]="member.disabled">{{member.value}}</option>
              </select>
            </div>
          </div>
        </div><!--/input-box-->
      </div><!--/input-box-wrap-->

      <div class="input-box-wrap">
        <div class="input-box-title input-box-title-style2">
          <div class="title-required"><span>メモ</span></div>
        </div>
        <div class="input-box">
          <div *ngIf="memo?.errors.maxlength" class="error-text input-error-text">※メモは300文字までです</div>
          <textarea class="input-size-h js-weddingInfoMemo mgt-style2" name="memo" [(ngModel)]="editData.memo"
            #memo="ngModel" maxlength="300"
            [class.error-item]="memo?.errors">
          </textarea>
        </div><!--/input-box-->
        <div class="js-wordCount" style="width:50px;height:30px;"></div>
      </div><!--/input-box-wrap-->

      <div class="btn-box">
        <div class="flex-box j-content-center">
          <button click-stop-propagation2 class="btn-item" type="submit"
            [disabled]="date?.errors || name?.errors || company?.errors || place?.errors || staff1?.errors">
            <span>保存</span>
          </button>
          <button click-stop-propagation2 class="btn-item white js-modalCreateSendListCancelBtn" type="reset"><span>クリア</span></button>
        </div>
      </div><!--/input-box-->


      </div><!-- /item-list-open-->
    </div><!-- /gift-make-block-->

  </form>
</ng-template>

<app-modal-message (simple_msg_event)="onModalSimpleMsgEvent($event)"></app-modal-message>
