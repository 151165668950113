<div class="content-block content-block-max">
    <div class="content-block-inner">
        <!-- <div class="flex-box"> -->
        <div class="title-content">アカウント情報</div>
        <!-- 退会方法検討中につき非表示 -->
        <div class="flex-box j-content-start hidden-wrap">
            <div class="icon-info">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>
                </svg>
            </div>
            <a href="#" class="a-style">退会はこちら</a>
        </div>
    </div><!--/content-block-inner-->

    <!-- <button type="button" (click)="test_add()">テストデータ作成</button> -->

    <div class="list-wrap">
        <!-- ログイン情報 -->
        <article class="info">
        <div class="title">ログイン情報</div>
        <form *ngIf="ready" #loginInfoForm="ngForm" novalidate>
            <div class="item-list item-list-open-wrap list-wrap-border-b">
            <div class="item-list-open account-info h-adr">

                <div class="input-box-wrap">
                <div class="input-box-title">
                    <div class="title-required"><span>メールアドレス</span></div>
                    <div class="text-frame-s">必須</div>
                </div>
                <div class="input-box">
                    <div>
                    <div *ngIf="mail.errors?.['required']" class="error-text input-error-text">メールアドレスは必須です</div>
                    <div *ngIf="mail.errors?.['email']" class="error-text input-error-text">メールアドレスの形式が不正です</div>
                    </div>
                    <div class="flex-box j-content-start">
                    <input class="input-column input-size-l" type="text" required maxlength="100" #mail="ngModel" placeholder="メールアドレス" name="email" email id="edit_email" [(ngModel)]="email" [class.error-item]="mail.errors"/>
                    </div>
                    <span class="cap">※メールアドレスはログインIDとして使用します</span>
                </div>
                </div><!--/input-box-wrap-->

                <div class="btn-box">
                <div class="flex-box j-content-center">
                    <button click-stop-propagation2 class="btn-item" type="button" (click)="emailReset()" [disabled]="!email"><span>メールアドレスを保存</span></button>
                    <button click-stop-propagation2 class="btn-item" type="button" (click)="passwordReset()" [disabled]="!email"><span>パスワードを変更</span></button>
                </div>
                </div><!--/btn-box-->

            </div><!--/item-list-open-->
            </div><!--/item-list-open-wrap-->
        </form>
        </article>

        <!-- 基本情報 -->
        <article class="info">
        <div class="title">基本情報</div>
        <form *ngIf="ready && account" id="accountForm" #accountForm="ngForm" (ngSubmit)="save()" novalidate ybAddress>
            <div class="item-list item-list-open-wrap list-wrap-border-b">
            <div class="item-list-open account-info h-adr">
                <span class="p-country-name" style="display:none;">Japan</span>
                <div class="input-box-wrap">
                <div class="input-box-title">
                    <div class="title-required"><span>お名前</span></div>
                    <div class="text-frame-s">必須</div>
                </div>
                <div class="input-box">
                    <div>
                        <div *ngIf="(last_name.errors?.['required'] && (last_name.dirty || last_name.touched)) || (first_name.errors?.['required'] && (first_name.dirty || first_name.touched))" class="error-text input-error-text">※お名前は必須です</div>
                    </div>
                    <div class="flex-box j-content-start flow-style">
                        <input class="input-column input-size-m" type="text" required maxlength="100" #last_name="ngModel" placeholder="姓" name="last_name" [(ngModel)]="account.last_name" [class.error-item]="last_name.errors && (last_name.dirty || last_name.touched)"/>
                        <input class="input-column input-size-m" type="text" required maxlength="100" #first_name="ngModel" placeholder="名" name="first_name" [(ngModel)]="account.first_name" [class.error-item]="first_name.errors && (first_name.dirty || first_name.touched)"/>
                    </div>
                </div>
                </div><!--/input-box-wrap-->

                <div class="input-box-wrap">
                <div class="input-box-title">
                    <div class="title-required"><span>フリガナ</span></div>
                    <div class="text-frame-s">必須</div>
                </div>
                <div class="input-box">
                    <div>
                        <div *ngIf="(last_kname.errors?.['required'] && (last_kname.dirty || last_kname.touched)) || (first_kname.errors?.['required'] && (first_kname.dirty || first_kname.touched))" class="error-text input-error-text">※フリガナは必須です</div>
                        <div *ngIf="last_kname.errors?.['pattern'] || first_kname.errors?.['pattern']" class="error-text input-error-text">※フリガナは全角カタカナで入力してください</div>
                    </div>
                    <div class="flex-box j-content-start">
                        <input class="input-column input-size-m" type="text" required maxlength="100" pattern="{{pattern.KANA}}" #last_kname="ngModel" placeholder="セイ" name="last_kname" [(ngModel)]="account.last_kname" [class.error-item]="last_kname.errors && (last_kname.dirty || last_kname.touched)"/>
                        <input class="input-column input-size-m" type="text" required maxlength="100" pattern="{{pattern.KANA}}" #first_kname="ngModel" placeholder="メイ" name="first_kname" [(ngModel)]="account.first_kname" [class.error-item]="first_kname.errors && (first_kname.dirty || first_kname.touched)"/>
                    </div>
                </div>
                </div><!--/input-box-wrap-->

                <div class="input-box-wrap">
                    <div class="input-box-title">
                        <div class="title-required"><span>権限</span></div>
                    </div>
                    <div class="flex-box" style="margin-top: 10px;">
                        <div class="checkbox-wrap">
                            <label>
                            <input type="checkbox" [ngModel]="account.manager_flg" name="manager_flg" class="header-checkbox" disabled>
                            <span class="item-label disabled">マネージャー</span>
                            </label>
                        </div>
                        <div class="checkbox-wrap">
                            <label>
                            <input type="checkbox" [ngModel]="account.cross_search_flg" name="cross_search_flg" class="header-checkbox" disabled>
                            <span class="item-label disabled">会場横断検索</span>
                            </label>
                        </div>
                    </div>
                    <div style="margin-top: 10px;"><span class="cap">※権限の変更は会場・メンバー情報よりマネージャーが行います</span></div>
                </div><!--/input-box-wrap-->

                <div class="input-box-wrap">
                    <div class="input-box-title">
                        <div class="title-required"><span>所属</span></div>
                    </div>
                    <div>
                        <div class="affiliation_info" *ngFor="let af of affiliation">
                            {{af?.company}}
                            <ul *ngFor="let place of af?.places">
                                <li>（{{place}}）</li>
                            </ul>
                        </div>
                    </div>
                </div><!--/input-box-wrap-->

                <div class="btn-box">
                <div class="flex-box j-content-center">
                    <button click-stop-propagation2 class="btn-item" type="submit" [disabled]="accountForm.invalid" [class.disabled]="accountForm.invalid"><span>基本情報を保存</span></button>
                    <button click-stop-propagation2 class="btn-item white js-modalCreateSendListCancelBtn" type="button" (click)="clearMsg()"><span>クリア</span></button>
                </div>
                </div><!--/input-box-->

            </div><!--/item-list-open-->
            </div><!--/item-list-open-wrap-->
        </form>
        </article>

    </div><!--/list-wrap-->

</div><!--/content-block-->
