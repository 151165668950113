import { Component, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AuthService } from '../../service/auth.service';
import { AccountService } from '../../service/account.service';
import { SessionService } from '../../service/session.service';
import { CompanyService } from '../../service/company.service';
import { PlaceService } from '../../service/place.service';

import { Session } from '../../models/session';
import { Account } from '../../models/account';
import * as constant from '../../models/constant';

declare function resetCommonrEvent(): void;
declare const debugLog: any;

@Component({
  selector: 'app-member-account',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
  ],
  templateUrl: './member-account.component.html',
  styleUrls: ['./member-account.component.scss']
})
export class MemberAccountComponent implements OnInit {

  private session: Session = new Session();
  public account: Account | null = null;
  public pattern = constant.INPUT_PATTERN;
  public ready: boolean = false;
  private message: string = '';
  public email: string = '';
  public affiliation: {company: string, places: string[]}[] = [];
  private subscription: Subscription = new Subscription();

  constructor(
    private sv_account: AccountService,
    private sv_session: SessionService,
    private sv_auth: AuthService,
    private sv_company: CompanyService,
    private sv_place: PlaceService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit() {
    this.sv_session.loginCheck();
    this.subscription.add (
      this.sv_session.sessionState.subscribe(session => {
        if (!session || !session.data) {
          this.sv_session.logout();
        } else {
          this.session = session;
          this.subscription.add (
            this.sv_account.getAccount(session.data.uid).subscribe(account => {
              if (account) {
                this.account = account;
                this.email = account.email;
                this.getAffiliation();
                this.ready = true;
              }
            })
          );
        }
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      resetCommonrEvent();
    }
  }

  private getAffiliation(): void {
    if (!this.account) return;

    this.account.company.forEach((company_key: string, index: number) => {
      let data: {company: string, places: string[]} = {company: "", places: []};
      // 企業
      this.subscription.add(
        this.sv_company.getCompany(company_key).subscribe(company => {
          data.company = company.name;
          // 会場
          this.subscription.add(
            this.sv_place.getPlaces(company_key).subscribe(places => {
              data.places = [];
              places.forEach(p => {
                if (this.session.data && this.session.data.place.includes(p.objectID)) {
                  data.places.push(p.name);
                }
              });
              this.affiliation[index] = data;
            })
          );

        })
      );
    });
  }

  public passwordReset() {
    if (!this.account) return;
    this.sv_auth.passwordReset(this.account.email);
    return false;
  }

  public emailReset() {
    if (!this.account) return;
    this.sv_auth.emaildReset(this.email).subscribe(data => {
      // アカウント情報も更新
      if (data.result && this.account) {
        this.account.email = this.email;
        this.save();
      } else {
        alert(data.msg);
      }
    });
    return false;
  }

  public save(): void {
    if (!this.session.data || !this.account) return;
    this.sv_account.save(this.session.data.uid, this.account).subscribe(data => {
      this.message = data.msg ? data.msg : '';
      alert(this.message);
      // セッションのイベント情報も更新
      if (data.result && this.account && this.session.data) {
        this.sv_auth.setSession(this.account, this.session.data.uid)
      }
    });
  }

  public clearMsg(): void {
    this.message = "";
    $('#accountForm')
        .find("input, select, textarea")
        .val("")
    ;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
