<div class="wrapper-login">
	<div class="main-login">
			<h1 class="logo"><img src="assets/img/logo.svg" alt="THE TIMELESS"></h1>
      <h1 class="screen_name">会場管理サイト</h1>
      <div class="site_type">{{site_type}}</div>

      <div *ngIf="(user | async)?.uid && role; else dispSignIn">
        <!-- 認証されている場合 -->
        <!-- <div class="about-text">T-PORTをご利用いただけます</div>
        <div class="about-text">
          <button type="button" class="btn-item" (click)="toTop()">トップページへ</button>
          <button type="button" class="btn-item gray" (click)="signOut()">サインアウト</button>
        </div>
        <div class="about-text"><a (click)="passwordReset()">パスワードをリセット</a></div> -->
        <app-spinner></app-spinner>
      </div>

      <ng-template #dispSignIn>
        <div class="login-box">
          <div class="login_msg">{{login_message}}</div>
          <input id="email" class="id" type="text" placeholder="ID" (keyup.enter)="signIn()">
          <input id="password" class="password" type="password" placeholder="パスワード" autocomplete="on" (keyup.enter)="signIn()">
          <button type="button" class="add-btn" (click)="signIn()">サインイン</button>
        </div>
        <div class="text_s"><a routerLink="/pw-reset">パスワードをお忘れの方はこちら</a></div>
        <div class="text_s"><a routerLink="/">T-PORTとは？</a></div>

        <!-- <button type="button" (click)="delConsent()">同意セッション削除</button> -->

        <!-- <div class="sns-box">
          <firebase-ui
            (signInSuccessWithAuthResult)="successCallback($event)"
            (signInFailure)="errorCallback($event)">
          </firebase-ui>
        </div> -->
      </ng-template>

  </div><!--/main-login-->

	<footer class="footer-login">
		<div class="footer-inner">
			&copy; TIMELESS CO./All Rights Reserved.
		</div>
	</footer><!--/footer-login-->
</div><!--/wrapper-login-->

<!-- <firebase-ui
  (signInSuccessWithAuthResult)="successCallback($event)"
  (signInFailure)="errorCallback($event)">
</firebase-ui> -->

<app-modal-message (msg_event)="onModalConsentMsgEvent($event)"></app-modal-message>
