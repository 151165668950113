import { Injectable, Inject } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable, of, Subject} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as firestore from 'firebase/firestore';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { SessionService } from './session.service';

import { Session } from '../models/session';
import { Account } from '../models/account';
import { ProcessResult } from '../models/process_result';
import * as constant from '../models/constant';

declare const debugLog: any;

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private accountCollection!: AngularFirestoreCollection<Account>;
  private accountDocument!: AngularFirestoreDocument<Account>;
  private accounts: Observable<Account[]> = of([]);;
  private account: Observable<Account | null> = of(null);
  private session: Session = new Session();
  private update_user: {uid: string; name: string} = {uid: '', name: ''};
  private favorite_update_subject: Subject<boolean> = new Subject();
  public favorite_update_result: Observable<boolean> = this.favorite_update_subject.asObservable();

  constructor(
    private firestore: AngularFirestore,
    private sv_session: SessionService,
    @Inject(LOCAL_STORAGE) private local_storage: StorageService,
  ) { }

  initialize(session: Session): void {
    if (!session || !session.data) return;
    this.session = session;
    this.update_user = {
      uid: session.data.uid,
      name: session.data.first_name + session.data.last_name
    };
  }

  /**
   * 挙式情報に紐付く顧客（新郎・新婦）を取得
   *
   * @param {string} event_id
   * @returns {Observable<Account[]>}
   * @memberof AccountService
   */
  getEventCustomer(event_id: string): Observable<Account[]> {
    this.accountCollection = this.firestore.collection('account', ref => ref
      .where('event_id', "==", event_id)
      .where('delete_flg', "==", false)
      .orderBy('cust_type')
      .limit(2)
    );
    this.accounts = this.accountCollection.snapshotChanges().pipe(
      map (datas =>
        datas.map(doc => ({
          objectID: doc.payload.doc.id,
          email: doc.payload.doc.data().email,
          default_password: '',
          event_id: event_id,
          first_name: doc.payload.doc.data().first_name,
          last_name: doc.payload.doc.data().last_name,
          first_kname: doc.payload.doc.data().first_kname,
          last_kname: doc.payload.doc.data().last_kname,
          zip: doc.payload.doc.data().zip,
          addr1: doc.payload.doc.data().addr1,
          addr2: doc.payload.doc.data().addr2,
          addr3: doc.payload.doc.data().addr3,
          addr4: doc.payload.doc.data().addr4,
          tel: doc.payload.doc.data().tel,
          favorite_items: doc.payload.doc.data().favorite_items,
          company: doc.payload.doc.data().company,
          place:doc.payload.doc.data().place,
          current_company: doc.payload.doc.data().current_company,
          current_place: doc.payload.doc.data().current_place,
          info: doc.payload.doc.data().info,
          role: doc.payload.doc.data().role,
          current_role: doc.payload.doc.data().current_role,
          cust_type: doc.payload.doc.data().cust_type,
          manager_flg: doc.payload.doc.data().manager_flg,
          cross_search_flg: doc.payload.doc.data().cross_search_flg,
          status: doc.payload.doc.data().status,
          created: doc.payload.doc.data().created,
          updated: doc.payload.doc.data().updated,
          updated_user: doc.payload.doc.data().updated_user,
          delete_flg: doc.payload.doc.data().delete_flg
        }))
      )
    );
    return this.accounts.pipe(
      catchError(this.handleError<Account[]>('getEventAccounts', []))
    );
  }

  /**
   * アカウント取得
   *
   * @param {string} key
   * @returns {Observable<Account>}
   * @memberof AccountService
   */
  getAccount(key: string): Observable<Account | null> {
    this.accountDocument = this.firestore.doc(`account/${key}`);
    this.account = this.accountDocument.snapshotChanges().pipe(
      map (doc => {
        if (!doc.payload.exists) { // ドキュメントが存在しない場合の処理
          return null;
        }
        let obj: Account = {
          objectID: doc.payload.id,
          email: doc.payload.data().email,
          default_password: '',
          event_id: doc.payload.data().event_id,
          first_name: doc.payload.data().first_name,
          last_name: doc.payload.data().last_name,
          first_kname: doc.payload.data().first_kname,
          last_kname: doc.payload.data().last_kname,
          zip: doc.payload.data().zip,
          addr1: doc.payload.data().addr1,
          addr2: doc.payload.data().addr2,
          addr3: doc.payload.data().addr3,
          addr4: doc.payload.data().addr4,
          tel: doc.payload.data().tel,
          favorite_items: doc.payload.data().favorite_items,
          company: doc.payload.data().company,
          place:doc.payload.data().place,
          current_company: doc.payload.data().current_company,
          current_place: doc.payload.data().current_place,
          info: doc.payload.data().info,
          role: doc.payload.data().role,
          current_role: doc.payload.data().current_role,
          cust_type: doc.payload.data().cust_type,
          manager_flg: doc.payload.data().manager_flg,
          cross_search_flg: doc.payload.data().cross_search_flg,
          status: doc.payload.data().status,
          created: doc.payload.data().created,
          updated: doc.payload.data().updated,
          updated_user: doc.payload.data().updated_user,
          delete_flg: doc.payload.data().delete_flg
        };
        return obj;
      })
    );
    return this.account.pipe(
      catchError(this.handleError<Account | null>(`getAccount id=${key}`, null))
    );
  }

  /**
   * アカウントを検索（メールアドレス、企業IDから）
   *
   * @param {string} email
   * @param {string} company_id
   * @returns {Observable<Account[]>}
   * @memberof AccountService
   */
  searchAccountByEmail(email: string, company_id: string): Observable<Account[]> {
    this.accountCollection = this.firestore.collection('account', ref => ref
      .where('email', "==", email)
      .where('company', "array-contains", company_id)
      .where('delete_flg', "==", false)
    );
    this.accounts = this.accountCollection.snapshotChanges().pipe(
      map (datas =>
        datas.map(doc => ({
          objectID: doc.payload.doc.id,
          email: doc.payload.doc.data().email,
          default_password: '',
          event_id: doc.payload.doc.data().event_id,
          first_name: doc.payload.doc.data().first_name,
          last_name: doc.payload.doc.data().last_name,
          first_kname: doc.payload.doc.data().first_kname,
          last_kname: doc.payload.doc.data().last_kname,
          zip: doc.payload.doc.data().zip,
          addr1: doc.payload.doc.data().addr1,
          addr2: doc.payload.doc.data().addr2,
          addr3: doc.payload.doc.data().addr3,
          addr4: doc.payload.doc.data().addr4,
          tel: doc.payload.doc.data().tel,
          favorite_items: doc.payload.doc.data().favorite_items,
          company: doc.payload.doc.data().company,
          place:doc.payload.doc.data().place,
          current_company: doc.payload.doc.data().current_company,
          current_place: doc.payload.doc.data().current_place,
          info: doc.payload.doc.data().info,
          role: doc.payload.doc.data().role,
          current_role: doc.payload.doc.data().current_role,
          cust_type: doc.payload.doc.data().cust_type,
          manager_flg: doc.payload.doc.data().manager_flg,
          cross_search_flg: doc.payload.doc.data().cross_search_flg,
          status: doc.payload.doc.data().status,
          created: doc.payload.doc.data().created,
          updated: doc.payload.doc.data().updated,
          updated_user: doc.payload.doc.data().updated_user,
          delete_flg: doc.payload.doc.data().delete_flg
        }))
      )
    );
    return this.accounts.pipe(
      catchError(this.handleError<Account[]>('searchAccountByEmail', []))
    );
  }

  /**
   * お気に入り追加／削除
   *
   * @param {string} item_key
   * @param {boolean} favorite
   * @returns {boolean} 成否
   * @memberof AccountService
   */
  updateFavorite(item_key: string, favorite_in: boolean): void {
    if (!this.session.data) return;
    this.accountDocument = this.firestore.collection('account').doc(this.session.data.uid);

    // 更新データ準備
    let favorite_items = this.session.data.favorite_items;
    if (favorite_in) {
      if (!favorite_items.includes(item_key)) {
        favorite_items.push(item_key);
      }
    } else {
      let index = favorite_items.indexOf(item_key);
      if (index > -1) {
        favorite_items.splice(index, 1);
      }
    }
    const update_data = {
      favorite_items: favorite_items,
      updated: new Date(),
      update_user: this.update_user
    }

    // 更新
    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(this.accountDocument.ref);
      transaction.update(this.accountDocument.ref, update_data);
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      this.local_storage.set('t-port_session', this.session);
      this.sv_session.loginCheck();

      this.favorite_update_subject.next(true);
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      this.favorite_update_subject.next(false);
    });
  }

  /**
   * マネージャーフラグ、会場横断検索フラグの更新
   *　（マネージャーのみ可能な操作）
   * @param {{id: string, manager_flg: boolean, cross_search_flg: boolean}} data
   * @returns {Observable<ProcessResult>}
   * @memberof AccountService
   */
  updatePermission(data: {id: string, manager_flg: boolean, cross_search_flg: boolean}): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    this.accountDocument = this.firestore.collection('account').doc(data.id);

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(this.accountDocument.ref);
      transaction.update(this.accountDocument.ref, {
        'manager_flg': data.manager_flg,
        'cross_search_flg': data.cross_search_flg,
        'updated': new Date(),
        'updated_user': this.update_user
      });
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }


  /**
   * 会場を追加する
   *
   * @param {string} key
   * @param {string} place_id
   * @param {boolean} del
   * @param {string | null} session_current_place
   * @returns {Observable<ProcessResult>}
   * @memberof AccountService
   */
  updatePlace(key: string, place_id: string, del: boolean = false, session_current_place: string | null = null): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    this.accountDocument = this.firestore.collection('account').doc(key);

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(this.accountDocument.ref);
      if (!del) {
        transaction.update(this.accountDocument.ref, {
          'place': firestore.arrayUnion(place_id),
          'current_place': place_id,
          'updated': new Date(),
          'updated_user': this.update_user
        });
      }
      else {
        if (place_id == session_current_place) {
          transaction.update(this.accountDocument.ref, {
            'place': firestore.arrayRemove(place_id),
            'current_place': "",
            'updated': new Date(),
            'updated_user': this.update_user
          });
        } else {
          transaction.update(this.accountDocument.ref, {
            'place': firestore.arrayRemove(place_id),
            'updated': new Date(),
            'updated_user': this.update_user
          });
        }
      }
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * アカウント新規登録・更新
   *
   * @param {string} key
   * @param {Account} account
   * @returns {Observable<ProcessResult>}
   * @memberof AccountService
   */
  save(key: string, account: Account): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    if (!key) {
      key = this.firestore.createId();
    }
    const account_ref = this.firestore.doc<Account>(`account/${key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(account_ref)
      .then(() => {
        transaction.set(account_ref, {
          'email': account.email,
          'event_id': account.event_id ? account.event_id : '',
          'first_name': account.first_name,
          'last_name': account.last_name,
          'first_kname': account.first_kname,
          'last_kname': account.last_kname,
          'zip': account.zip ? account.zip : '',
          'addr1': account.addr1 ? account.addr1 : '',
          'addr2': account.addr2 ? account.addr2 : '',
          'addr3': account.addr3 ? account.addr3 : '',
          'addr4': account.addr4 ? account.addr4 : '',
          'tel': account.tel ? account.tel : '',
          'favorite_items': account.favorite_items ? account.favorite_items : [],
          'company': account.company ? account.company : [],
          'place': account.place ? account.place : [],
          'current_company': account.current_company ? account.current_company : '',
          'current_place': account.current_place ? account.current_place : '',
          'info': account.info ? account.info : null,
          'role': account.role,
          'current_role': account.current_role,
          'cust_type': account.cust_type ? account.cust_type : 0,
          'manager_flg': account.manager_flg,
          'cross_search_flg': account.cross_search_flg,
          'status': account.status ? account.status : 1,
          'created': account.created ? account.created : new Date(),
          'updated': new Date(),
          'updated_user': account.updated_user ? account.updated_user : this.update_user,
          'delete_flg': false
        }, {'merge': true});
      })
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * アカウント削除
   *　（顧客自身が削除するのは顧客登録失敗時のみ）
   * @param {string} key
   * @param {{uid: string, name: string} | null} updated_user
   * @returns {Observable<ProcessResult>}
   * @memberof AccountService
   */
  del(key: string, updated_user: {uid: string, name: string} | null): Observable<ProcessResult> {
    let result_subject: Subject<ProcessResult> = new Subject();
    let result: Observable<ProcessResult> = result_subject.asObservable();
    const account_ref = this.firestore.doc<Account>(`account/${key}`).ref;

    this.firestore.firestore.runTransaction(async transaction => {
      await transaction.get(account_ref)
      .then(() => {
        transaction.update(account_ref, {
          'updated': new Date(),
          'updated_user': updated_user ? updated_user : this.update_user,
          'delete_flg': true
        });
      })
    })
    .then(() => {
      debugLog("Transaction successfully committed!");
      result_subject.next({result: true, msg: constant.MSG_UPDATE_SUCCESSED, id: null});
    })
    .catch(error => {
      debugLog("Transaction failed: ", error);
      result_subject.next({result: false, msg: constant.MSG_UPDATE_FAILED, id: null});
    });
    return result;
  }

  /**
   * 失敗したHttp操作を処理します。
   * アプリを持続させます。
   * @param operation - 失敗した操作の名前
   * @param result - observableな結果として返す任意の値
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: リモート上のロギング基盤にエラーを送信する
      console.error(error); // かわりにconsoleに出力

      // TODO: ユーザーへの開示のためにエラーの変換処理を改善する
      // this.log(`${operation} failed: ${error.message}`);

      // 空の結果を返して、アプリを持続可能にする
      return of(result as T);
    };
  }

}
