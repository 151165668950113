<div class="content-block content-block-max">
  <div class="content-block-inner">
    <div class="back-link"><a (click)="back()">戻る</a></div>

    <div class="flex-box">
      <div class="title-content mng mg-style2">登録ゲスト一覧</div>
    </div>

    <div class="mg-style2">
      <div class="flex-box">
        <dl class="flex-box j-content-start text-m-style2">
          <dt class="dl-title">挙式日</dt>
          <dd>{{event.date}}</dd>
        </dl>
      </div>
      <div class="title-content">{{event.name}}</div>
    </div>
  </div><!--/content-block-inner-->

  <div class="flex-box j-content-start a-items-start p10 mt10">
    <div class="icon-info"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg></div>
    <span style="align-self: center;">
      ゲストリストの編集・削除は新郎新婦様のみ実行可能です。<br>
      <span *ngIf="onew_link">
        ONE-Wから取り込んだゲストリストのデータはT-PORTゲストリストの必須入力項目を満たしていない場合があります。<br>
        エラーメッセージが表示されているゲストについては新郎新婦様に編集をご依頼ください。
      </span>
    </span>
  </div>

  <div class="list-wrap">
    <div *ngIf="guests.length === 0" class="mt50" style="text-align: center;">
      <span class="cap2 blank_l">現在ご登録のゲスト情報はありません。</span>
    </div>
    <div *ngIf="guests.length > 0" class="mt20 p10">
      <span>登録ゲスト数：{{guests.length}}名</span>
    </div>
    <div class="order-item-list pd-style mt20" *ngFor="let guest of guests; index as i">
      <div class="list-ind-box">
        <div class="list-ind-box-inner">
          <div class="order-list-name-wrap">
            <div class="order-list-name-ch">{{guest?.last_name}} {{guest?.first_name}}<span *ngIf="guest?.last_kname || guest?.first_kname">（{{guest?.last_kname}} {{guest?.first_kname}}）</span></div>
            <div class="order-list-add"><span *ngIf="guest?.zip">〒{{guest?.zip}}</span>{{guest?.addr1}}{{guest?.addr2}}{{guest?.addr3}}{{guest?.addr4}}</div>
            <div *ngIf="guest?.tel" class="order-list-tel"><span class="cap2 s blank_l">電話：</span>{{guest?.tel}}</div>
            <div *ngIf="invalid[i]"><span class="cap2 s red">入力が不足している項目があります。新郎新婦様に不足項目の入力をご依頼ください。</span></div>
          </div>
        </div>
      </div>
    </div><!--/item-list-->

  </div><!--/list-wrap-->

</div><!--/content-block-->
