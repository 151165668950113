<div class="content-block content-block-max">
  <div class="content-block-inner">
    <div class="back-link"><a (click)="back()">戻る</a></div>
    <div *ngIf="ready" class="list-wrap">

      <form #eventForm="ngForm" (ngSubmit)="save()" novalidate>
        <div class="item-list-open h-adr item-list-open-hed" style="border-bottom: none;">

          <div class="input-box-wrap">
            <div class="input-box-title">
              <div class="title-required"><span>挙式日</span></div>
              <div class="text-frame-s">必須</div>
            </div>
            <div class="flex-box j-content-start flow-style">
              <div class="input-box">
                <div *ngIf="date.errors?.['required'] && (date.dirty || date.touched)" class="error-text input-error-text">※挙式日は必須です</div>
                <div class="calendar-input w110">
                  <mat-form-field [class.error-item]="date.errors && (date.dirty || date.touched)">
                    <input name="date" matInput [matDatepicker]="picker" readonly
                      #date="ngModel" required
                      [(ngModel)]="editData.date"
                      (ngModelChange)="setRegistDate($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div><!--/input-box-wrap-->

          <div class="input-box-wrap">
            <div class="input-box-title">
              <div class="title-required"><span>挙式名</span></div>
                <div class="text-frame-s">必須</div>
            </div>
            <div class="flex-box j-content-start flow-style">
              <div class="input-box">
                <div *ngIf="name.errors?.['required'] && (name.dirty || name.touched)" class="error-text input-error-text">※挙式名は必須です</div>
                <input class="input-column input-size-m-style2" type="text" placeholder="例：山田 太郎・花子様" name="name" size="20"
                  required maxlength="100" #name="ngModel"
                  [(ngModel)]="editData.name"
                  [class.error-item]="name.errors && (name.dirty || name.touched)"/>
              </div>
            </div>
          </div><!--/input-box-wrap-->

          <div *ngIf="session.data && !session.data.current_company" class="input-box-wrap">
            <div class="input-box-title">
              <div class="title-required"><span>企業名</span></div>
            </div>
            <div class="input-box">
              <div *ngIf="company.errors?.['maxlength']" class="error-text input-error-text">※企業名は100文字までです</div>
              <input class="input-column" type="text" name="company_name" placeholder="企業名を入力してください" value=""
                #company="ngModel" required maxlength="100"
                [(ngModel)]="editData.company_name">
            </div>
          </div><!--/input-box-wrap-->

          <div class="input-box-wrap">
            <div class="input-box-title">
              <div class="title-required"><span>会場</span></div>
                <div class="text-frame-s">必須</div>
            </div>
            <div class="input-box">
              <div class="flex-box j-content-start flow-style">
                <div class="input-box">

                  <div *ngIf="session.data?.current_place">
                    <div *ngIf="place.errors?.['required'] && (place.dirty || place.touched)" class="error-text input-error-text">※会場は必須です</div>
                    <div class="select-wrap mgtb2">
                        <select data-ng-options="v as k for (k, v) in places" name="place" class="p-region-id"
                          #place="ngModel" required
                          [(ngModel)]="editData.place_id"
                          (ngModelChange)="select_place=$event"
                          (change)="onChangePlace()"
                          [class.error-item]="place.errors && (place.dirty || place.touched)">
                          <option *ngFor="let place of places" [ngValue]="place.key">{{place.value}}</option>
                        </select>
                    </div>
                  </div>

                  <div *ngIf="session.data && !session.data.current_place">
                    <div *ngIf="place.errors?.['required']" class="error-text input-error-text">※会場は必須です</div>
                    <div *ngIf="place.errors?.['maxlength']" class="error-text input-error-text">※会場名は100文字までです</div>
                    <div class="input-box js-OtherHall">
                      <input class="input-column" type="text" name="place_name" placeholder="会場名を入力してください" value=""
                        #place="ngModel" required maxlength="100"
                        [(ngModel)]="editData.place_name"
                        [class.error-item]="place.errors">
                    </div>
                  </div>

                </div>
              </div>
            </div><!--/input-box-->
          </div><!--/input-box-wrap-->

          <div class="input-box-wrap">
            <div class="input-box-title">
              <div class="title-required"><span>担当者1</span></div>
                <div class="text-frame-s">必須</div>
            </div>
            <div class="input-box">
              <div class="flex-box j-content-start flow-style">
                <div class="input-box">
                  <div *ngIf="staff1.errors?.['required'] && (staff1.dirty || staff1.touched)" class="error-text input-error-text">※担当者1は必須です</div>
                  <div class="select-wrap input-size-m">
                    <div class="select-wrap mgtb2">
                      <select data-ng-options="v as k for (k, v) in members1" name="staff1" class="p-region-id"
                        #staff1="ngModel" required
                        [(ngModel)]=editData.staff_ids[0]
                        (ngModelChange)="select_member=$event"
                        (change)="onChangeStaff(1)"
                        [disabled]="!members1"
                        [class.error-item]="(staff1.errors && (staff1.dirty || staff1.touched)) || !members1">
                        <option *ngFor="let member of members1" [ngValue]="member.key" [disabled]="member.disabled">{{member.value}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div><a (click)="setYourself()">担当者に自分を設定する</a></div>
              </div>
            </div><!--/input-box-->
          </div><!--/input-box-wrap-->

          <div class="input-box-wrap">
            <div class="input-box-title">
              <div class="title-required"><span>担当者2</span></div>
            </div>
            <div class="input-box">
              <div class="input-box">
                <div class="select-wrap input-size-m">
                  <select data-ng-options="v as k for (k, v) in members2" name="staff2" class="p-region-id"
                    #staff2="ngModel"
                    [(ngModel)]=editData.staff_ids[1]
                    (ngModelChange)="select_member=$event"
                    (change)="onChangeStaff(2)"
                    [disabled]="!members2">
                    <option *ngFor="let member of members2" [ngValue]="member.key" [disabled]="member.disabled">{{member.value}}</option>
                  </select>
                </div>
              </div>
            </div><!--/input-box-->
          </div><!--/input-box-wrap-->

          <div class="input-box-wrap">
            <div class="input-box-title input-box-title-style2">
              <div class="title-required"><span>メモ</span></div>
            </div>
            <div class="input-box">
              <div *ngIf="memo.errors?.['maxlength']" class="error-text input-error-text">※メモは300文字までです</div>
              <textarea class="input-size-h js-weddingInfoMemo mgt-style2" name="memo" [(ngModel)]="editData.memo"
                #memo="ngModel" maxlength="300"
                [class.error-item]="memo.errors">
              </textarea>
            </div><!--/input-box-->
            <div class="js-wordCount" style="width:50px;height:30px;"></div>
          </div><!--/input-box-wrap-->

          <div class="input-box-wrap">
            <div class="input-box-title">
              <div class="title-required"><span>顧客登録期限</span></div>
            </div>
            <div class="flex-box j-content-start flow-style">
              <div class="input-box">
                <div class="calendar-input w110">
                  <mat-form-field>
                    <input name="token_cust_limit" matInput [matDatepicker]="token_cust_limit_picker" readonly
                      #token_cust_limit="ngModel"
                      [min]="regist_min_date" [max]="regist_max_date"
                      [(ngModel)]="editData.token_cust.limit">
                    <mat-datepicker-toggle matSuffix [for]="token_cust_limit_picker"></mat-datepicker-toggle>
                    <mat-datepicker #token_cust_limit_picker ></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <span class="cap">※挙式日以降の日付は設定できません</span>
            </div>
          </div><!-- /input-box-wrap-->

          <div class="input-box-wrap">
            <div class="input-box-title">
              <div class="title-required"><span>ゲスト登録期限</span></div>
            </div>
            <div class="flex-box j-content-start flow-style">
              <div class="input-box">
                <div class="calendar-input w110">
                  <mat-form-field>
                    <input name="token_guest_limit" matInput [matDatepicker]="token_guest_limit_picker" readonly
                      #token_guest_limit="ngModel"
                      [min]="regist_min_date" [max]="regist_max_date"
                      [(ngModel)]="editData.token_guest.limit">
                    <mat-datepicker-toggle matSuffix [for]="token_guest_limit_picker"></mat-datepicker-toggle>
                    <mat-datepicker #token_guest_limit_picker ></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <span class="cap">※挙式日以降の日付は設定できません</span>
            </div>
          </div><!-- /input-box-wrap-->

          <div class="input-box-wrap">
            <div class="input-box-title">
                <div class="title-required"><span>卓上カード納品先<br>ご住所</span></div>
            </div>
            <div class="flex-box j-content-start flow-style">
                <div *ngIf="zip.errors?.['pattern']" class="error-text input-error-text">※郵便番号の形式が不正です</div>
                <div class="input-box input-size-s">
                <input type="text" pattern="{{pattern.ZIP}}" maxlength="7" #zip="ngModel" placeholder="郵便番号" class="input-column p-postal-code js-postalCode" name="zip"
                [(ngModel)]="editData.table_card_zip"
                (change)="changeZipCode()"
                [class.error-item]="zip.errors && (zip.dirty || zip.touched)"/>
                </div>
            </div>
          </div><!--/input-box-wrap-->

          <div class="input-box-wrap">
            <div class="input-box-title">
                <div class="title-required"></div>
            </div>
            <div class="flex-box j-content-start flow-style w100p">
                <div class="input-box">
                <div class="input-size-s mgtb2">
                    <input type="text" maxlength="10" #addr1="ngModel" placeholder="都道府県" class="input-column p-region-id" name="addr1" size="50" [(ngModel)]="editData.table_card_addr1" [class.error-item]="addr1.errors && (addr1.dirty || addr1.touched)"/>
                </div>
                <div class="mgtb2">
                    <input type="text" maxlength="100" #addr2="ngModel" placeholder="市区町村" class="input-column p-locality" name="addr2" size="50" [(ngModel)]="editData.table_card_addr2" [class.error-item]="addr2.errors && (addr2.dirty || addr2.touched)"/>
                </div>
                <div class="mgtb2">
                    <input type="text" maxlength="100" #addr3="ngModel" placeholder="町名・番地" class="input-column p-street-address" name="addr3" size="50" [(ngModel)]="editData.table_card_addr3" [class.error-item]="addr3.errors && (addr3.dirty || addr3.touched)"/>
                </div>
                <div class="mgtb2">
                    <input type="text" maxlength="100" #addr4="ngModel" placeholder="マンション名・建物名" class="input-column p-extended-address" name="addr4" size="50" [(ngModel)]="editData.table_card_addr4" />
                </div>
                </div>
            </div>
          </div><!--/input-box-wrap-->

          <div class="input-box-wrap">
            <div class="input-box-title">
                <div class="title-required"></div>
            </div>
            <div class="flex-box j-content-start flow-style">
                <div *ngIf="tel.errors?.['pattern']" class="error-text input-error-text">※電話の形式が不正です</div>
                <div class="input-box input-size-s">
                <input class="input-column" type="number" pattern="{{pattern.TEL}}" maxlength="20" #tel="ngModel" placeholder="電話番号" name="tel" [(ngModel)]="editData.table_card_tel" [class.error-item]="tel.errors && (tel.dirty || tel.touched)"/>
                </div>
            </div>
            <span class="cap m5 l pc">※ハイフンなし</span>
          </div><!--/input-box-wrap-->

          <div class="input-box-wrap">
            <div class="input-box-title">
                <div class="title-required"></div>
            </div>
            <div class="flex-box j-content-start flow-style">
              <span class="cap">※卓上カード納品先ご住所の指定がない場合は会場／サロンへのお届けとなります。</span>
            </div>
          </div><!--/input-box-wrap-->

          <div class="input-box-wrap">
            <div class="input-box-title">
              <div class="title-required"><span>挙式ステータス</span></div>
            </div>
            <div class="flex-box j-content-start flow-style">
              <div class="input-box">
                <div class="checkbox-wrap" style="margin:10px 10px 5px 0;">
                  <label>
                    <input type="checkbox" [(ngModel)]="unsubscribed" name="unsubscribed" class="header-checkbox" value=true (change)="setUnsubscribed()">
                    <span class="item-label">挙式を無効とする</span>
                  </label>
                </div>
              </div>
              <span class="cap">※無効に設定している間は新郎新婦様のログインもできなくなります。</span>
            </div>
          </div><!-- /input-box-wrap-->


          <div class="btn-box" style="margin-top: 30px;">
            <div class="flex-box j-content-center">
              <button class="btn-item" type="submit"
                [disabled]="date?.errors || name?.errors || company?.errors || place?.errors || staff1?.errors">
                <span>保存</span>
              </button>
              <button class="btn-item white js-modalCreateSendListCancelBtn" type="reset"><span>クリア</span></button>
            </div>
          </div><!--/input-box-->


        </div><!-- /item-list-open-->

      </form>
    </div><!--/list-wrap-->

  </div><!--/content-block-inner-->
</div><!--/content-block-->

<app-modal-message
  (simple_msg_event)="onModalSimpleMsgEvent($event)"
  (msg_event)="onModalConfirmMsgEvent($event)"
></app-modal-message>
<app-spinner *ngIf="!ready"></app-spinner>
